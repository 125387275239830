import { ModuleWithProviders } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { StoreRouterConnectingModule } from "@ngrx/router-store";

import { AccountComponent } from "./account/account.component";
import { ChangePasswordContainer } from "./account/change-password";
import { ConfirmEmailContainer } from "./account/confirm-email";
import { DeleteContainer } from "./account/delete";
import { LoginContainer } from "./account/login";
import { RegisterContainer } from "./account/register/register.container";
import { IS_EXTENSION } from "./constants";
import { ExporterComponent } from "./exporter";
import { BackgroundComponent } from "./extension/background";
import { PopupContainer } from "./extension/popup";
import { GroupContainer } from "./group";
import { AlreadyLoggedInGuard, LoggedInGuard } from "./guards";
import { ImporterContainer } from "./importer";
import { SecretListContainer } from "./list";
import { NoContentContainer } from "./no-content";
import { PopupLoggedInGuard } from "./guards/popup-logged-in.guard";
import { ConfirmEmailGuard } from "./account/confirm-email/confirm-email.guard";
import { ErrorReportingContainer } from "./account/error-reporting/error-reporting.container";

/* tslint:disable:object-literal-sort-keys */
const appRoutes: Routes = [
  {
    path: "login",
    component: LoginContainer,
    canActivate: [AlreadyLoggedInGuard],
    data: {
      title: "Log In",
      showNavBar: false
    }
  },
  { path: "", redirectTo: "list", pathMatch: "full" },
  {
    path: "account",
    component: AccountComponent,
    data: {
      title: "Account Management"
    }
  },
  {
    path: "account/change-password",
    component: ChangePasswordContainer,
    canActivate: [LoggedInGuard],
    data: {
      title: "Change Account Password"
    }
  },
  {
    path: "account/error-reporting",
    component: ErrorReportingContainer,
    canActivate: [LoggedInGuard],
    data: {
      title: "Error Reporting"
    }
  },
  {
    path: "account/delete",
    component: DeleteContainer,
    canActivate: [LoggedInGuard],
    data: {
      title: "Delete Account"
    }
  },
  {
    path: "register",
    component: RegisterContainer,
    canActivate: [AlreadyLoggedInGuard],
    data: {
      title: "Register",
      showNavBar: false
    }
  },
  {
    path: "confirm-email",
    component: ConfirmEmailContainer,
    canActivate: [ConfirmEmailGuard],
    data: {
      title: "Confirm Email"
    }
  },
  {
    path: "confirm-email/:code",
    component: ConfirmEmailContainer,
    data: {
      title: "Confirm Email"
    }
  },
  {
    path: "list",
    component: SecretListContainer,
    canActivate: [LoggedInGuard],
    data: {
      title: "Password List"
    }
  },
  {
    path: "groups",
    component: GroupContainer,
    canActivate: [LoggedInGuard],
    data: {
      title: "Groups List"
    }
  },
  {
    path: "import",
    component: ImporterContainer,
    canActivate: [LoggedInGuard],
    data: {
      title: "Import Passwords"
    }
  },
  {
    path: "export",
    canActivate: [LoggedInGuard],
    component: ExporterComponent
  },
  {
    path: "popup",
    component: PopupContainer,
    canActivate: [PopupLoggedInGuard],
    data: {
      showNavBar: false
    }
  },
  {
    path: "background",
    component: BackgroundComponent
  },
  {
    path: "**",
    component: NoContentContainer
  }
];

export const routing: ModuleWithProviders = RouterModule.forRoot(
  appRoutes,
  // use hashing when it is an extension, because the browsers can't
  // redirect other urls for SPA to the index.html
  { useHash: IS_EXTENSION }
);
export const routingStore = StoreRouterConnectingModule;
