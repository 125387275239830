import { Action } from "@ngrx/store";

import { IGroup } from "passit-sdk-js/js/api.interfaces";
import { IGroupForm } from "./group.interfaces";

export enum GroupActionTypes {
  ADD_GROUP = "Add Group",
  HIDE_GROUPS_CREATE = "Hide Groups Create",
  REMOVE_GROUP = "Remove Group",
  RESET_BLANK_GROUP_FORM = "Reset Blank Group Form",
  GET_GROUPS = "Get Groups",
  CREATE_GROUP = "Create Group",
  CREATE_GROUP_SUCCESS = "Create Group Success",
  DELETE_GROUP = "Delete Group",
  DELETE_GROUP_SUCCESS = "Delete Group Success",
  UPDATE_GROUP = "Update Group",
  UPDATE_GROUP_SUCCESS = "Update Group Success",
  ACCEPT_GROUP_INVITE = "[Group] Accept Group Invite",
  ACCEPT_GROUP_INVITE_SUCCESS = "[Group] Accept Group Invite Success",
  ACCEPT_GROUP_INVITE_FAILURE = "[Group] Accept Group Invite Failure",
  DECLINE_GROUP_INVITE = "[Group] Decline Group Invite",
  DECLINE_GROUP_INVITE_SUCCESS = "[Group] Decline Group Invite Success",
  DECLINE_GROUP_INVITE_FAILURE = "[Group] Decline Group Invite Failure",
  SET_GROUPS = "Set Groups",
  SHOW_GROUPS_CREATE = "Show Groups Create",
  CLEAR_MANAGED_GROUP = "clear managed group",
  SET_MANAGED_GROUP = "Set managed group",
  ROUTER_UPDATE_LOCATION = "[Router] Update Location",
  CONTACT_LOOKUP = "User Lookup",
  CONTACT_RESET = "Contact Reset",
  CONTACT_LOOKUP_SUCCESS = "User Lookup Success",
  CONTACT_LOOKUP_FAILURE = "User Lookup Failure",
  RESET_MEMBERS = "Reset Members",
  UPDATE_FORM = "Update Form"
}

export class AddGroup implements Action {
  readonly type = GroupActionTypes.ADD_GROUP;

  constructor(public payload: IGroup) {}
}

export class HideGroupsCreate implements Action {
  readonly type = GroupActionTypes.HIDE_GROUPS_CREATE;
}

export class RemoveGroup implements Action {
  readonly type = GroupActionTypes.REMOVE_GROUP;

  constructor(public payload: number) {}
}

export class ResetBlankGroupForm implements Action {
  readonly type = GroupActionTypes.RESET_BLANK_GROUP_FORM;
}

/** Request refreshing groups from api */
export class GetGroupsAction implements Action {
  readonly type = GroupActionTypes.GET_GROUPS;
}

export class CreateGroupAction implements Action {
  readonly type = GroupActionTypes.CREATE_GROUP;
}

export class CreateGroupSuccessAction implements Action {
  readonly type = GroupActionTypes.CREATE_GROUP_SUCCESS;
}

export class DeleteGroupAction implements Action {
  readonly type = GroupActionTypes.DELETE_GROUP;

  constructor(public payload: number) {}
}

export class DeleteGroupSuccessAction implements Action {
  readonly type = GroupActionTypes.DELETE_GROUP_SUCCESS;
}

export class UpdateGroupAction implements Action {
  readonly type = GroupActionTypes.UPDATE_GROUP;
}

export class UpdateGroupSuccessAction implements Action {
  readonly type = GroupActionTypes.UPDATE_GROUP_SUCCESS;
}

export class ContactLookupAction implements Action {
  readonly type = GroupActionTypes.CONTACT_LOOKUP;

  constructor(public payload: string) {}
}

export class ContactLookupSuccessAction implements Action {
  readonly type = GroupActionTypes.CONTACT_LOOKUP_SUCCESS;

  constructor(
    public payload: { label: string; value: any; disabled: boolean }
  ) {}
}

export class ContactResetAction implements Action {
  readonly type = GroupActionTypes.CONTACT_RESET;

  constructor(
    public payload: { label: string; value: any; disabled: boolean }
  ) {}
}

export class ContactLookupFailureAction implements Action {
  readonly type = GroupActionTypes.CONTACT_LOOKUP_FAILURE;
}

export class SetGroupsAction implements Action {
  readonly type = GroupActionTypes.SET_GROUPS;

  constructor(public payload: IGroup[]) {}
}

export class ShowGroupsCreate implements Action {
  readonly type = GroupActionTypes.SHOW_GROUPS_CREATE;
}

export class ClearManagedGroupAction implements Action {
  readonly type = GroupActionTypes.CLEAR_MANAGED_GROUP;
}

export class SetManagedGroupAction implements Action {
  readonly type = GroupActionTypes.SET_MANAGED_GROUP;

  constructor(public payload: number) {}
}

export class UpdateFormAction implements Action {
  readonly type = GroupActionTypes.UPDATE_FORM;

  constructor(public payload: IGroupForm) {}
}

export class RouterUpdateLocationAction implements Action {
  readonly type = GroupActionTypes.ROUTER_UPDATE_LOCATION;

  constructor(public payload?: any) {}
}

export class AcceptGroupInvite implements Action {
  readonly type = GroupActionTypes.ACCEPT_GROUP_INVITE;

  constructor(public payload: IGroup) {}
}

export class AcceptGroupInviteSuccess implements Action {
  readonly type = GroupActionTypes.ACCEPT_GROUP_INVITE_SUCCESS;
}

export class AcceptGroupInviteFailure implements Action {
  readonly type = GroupActionTypes.ACCEPT_GROUP_INVITE_FAILURE;
}

export class DeclineGroupInvite implements Action {
  readonly type = GroupActionTypes.DECLINE_GROUP_INVITE;

  constructor(public payload: IGroup) {}
}

export class DeclineGroupInviteSuccess implements Action {
  readonly type = GroupActionTypes.DECLINE_GROUP_INVITE_SUCCESS;
}

export class DeclineGroupInviteFailure implements Action {
  readonly type = GroupActionTypes.DECLINE_GROUP_INVITE_FAILURE;
}

export type GroupActionsUnion =
  | AddGroup
  | GetGroupsAction
  | HideGroupsCreate
  | RemoveGroup
  | ResetBlankGroupForm
  | SetGroupsAction
  | ShowGroupsCreate
  | ClearManagedGroupAction
  | SetManagedGroupAction
  | CreateGroupAction
  | CreateGroupSuccessAction
  | DeleteGroupAction
  | DeleteGroupSuccessAction
  | UpdateGroupAction
  | UpdateGroupSuccessAction
  | ContactLookupAction
  | ContactResetAction
  | ContactLookupSuccessAction
  | ContactLookupFailureAction
  | UpdateFormAction
  | RouterUpdateLocationAction
  | AcceptGroupInvite
  | AcceptGroupInviteSuccess
  | AcceptGroupInviteFailure
  | DeclineGroupInvite
  | DeclineGroupInviteSuccess
  | DeclineGroupInviteFailure;
