import { Component, EventEmitter, Input, Output, ChangeDetectionStrategy } from "@angular/core";

import { ISecret } from "passit-sdk-js/js/api.interfaces";

@Component({
  selector: "app-popup",
  templateUrl: "./popup.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ["./popup.component.scss"]
})
export class PopupComponent {
  @Input() secrets: ISecret[];
  @Input() selectedSecret: number | null;
  @Input() formFillMessage: string;
  @Input() search: string;
  @Input() matchedSecrets: ISecret[];
  @Input() usernameCopied: number | null;
  @Input() passwordCopied: number | null;
  @Output() setSelected = new EventEmitter<number>();
  @Output() closeSelected = new EventEmitter();
  @Output() searchUpdate = new EventEmitter<string>();
  @Output() openFull = new EventEmitter();
  @Output() openUrl = new EventEmitter<ISecret>();
  @Output() signIn = new EventEmitter<ISecret>();
  @Output() onCopyUsername = new EventEmitter<ISecret>();
  @Output() onCopyPassword = new EventEmitter<ISecret>();
  @Output() onDetail = new EventEmitter<ISecret>();
  @Output() openFullApp = new EventEmitter();
}
