import { take } from "rxjs/operators";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import "rxjs/add/operator/toPromise";

import { IPassitSDKError } from "../../ngsdk";
import { NgPassitSDK } from "../../ngsdk/sdk";
import { DeleteAccountFailureAction } from "../delete/delete.actions";
import * as fromAccount from "../account.reducer";
import { selectAuthState } from "../account.reducer";
import { LogoutSuccessAction, SetUrlAction } from "../account.actions";
import { IAuthStore } from "./user.interfaces";

@Injectable()
export class UserService {
  constructor(
    private sdk: NgPassitSDK,
    private store: Store<fromAccount.IAuthState>,
    private http: HttpClient
  ) {}

  /*
  * Check if username is available
  * returns true or false
  * if true: RegisterComponent calls register method below
  * if false: alert message triggered in RegisterComponent
  */
  public checkUsername(
    email: string
  ): Promise<{ isAvailable: boolean; error?: IPassitSDKError }> {
    return this.sdk
      .is_username_available(email)
      .then(isAvailable => {
        return {
          isAvailable
        };
      })
      .catch((err: IPassitSDKError) => {
        throw err;
      });
  }

  /* Figure out the URL before doing anything else */
  public checkAndSetUrl(url: string) {
    // strip https
    url = url.replace(/^https?\:\/\//i, "");

    // normalize ending with /api/
    url = url.replace(/\/api\/?/i, "");
    url = url + "/api/";

    // Try same domain first
    let apiUrl = "https://" + url;

    // for dev
    if (url === "api:8000/api/" || url === "localhost:8000/api/") {
      apiUrl = "http://" + url;
    }
    return this.http
      .get(apiUrl + "ping/")
      .toPromise()
      .then(() => {
        this.setSdkUrl(apiUrl);
        return apiUrl;
      })
      .catch(() => {
        // Try api.domain (legacy recommendation)
        // Force https only
        url = "https://api." + url;
        return this.http
          .get(url + "ping/")
          .toPromise()
          .then(() => {
            this.setSdkUrl(url);
            return url;
          });
      });
  }

  /** Check url to see if there is a passit server there and return resulting observable */
  checkUrl(url: string) {
    // strip https (we'll force https later)
    url = url.replace(/^https?\:\/\//i, "");

    // normalize ending with /api/
    url = url.replace(/\/api\/?/i, "");
    url = url + "/api/";

    let apiUrl = "https://" + url;

    // for dev
    if (url === "api:8000/api/" || url === "localhost:8000/api/") {
      apiUrl = "http://" + url;
    }
    return this.http.get(apiUrl + "ping/");
  }

  /*
  * send login get to sdk
  * email and password to login user
  */
  public login(
    email: string,
    password: string,
    rememberMe: boolean
  ): Promise<IAuthStore> {
    // Short lived session (3 hours) when not using remember me
    const expires = rememberMe ? undefined : 3;
    return this.sdk
      .log_in(email, password, expires)
      .then(resp => {
        const auth: IAuthStore = {
          privateKey: resp.privateKey,
          publicKey: resp.user.public_key,
          userId: resp.user.id,
          email,
          userToken: resp.token,
          rememberMe,
          optInErrorReporting: resp.user.opt_in_error_reporting
        };

        this.setUp(auth);
        return auth;
      })
      .catch(err => {
        let errorMessage = "Unexpected error.";
        if (err.res) {
          if (err.res.status === 401 || err.res.status === 404) {
            errorMessage = "Incorrect username or password.";
          } else if (err.res.status === 0) {
            errorMessage =
              "No Internet connection. Try again when you're back online.";
          }
        }
        throw new Error(errorMessage);
      });
  }

  public logout() {
    return this.sdk.logout();
  }

  /** Change the users password - note this can take some time.
   */
  public changePassword(
    oldPassword: string,
    newPassword: string
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      const email$ = this.store.select(fromAccount.getEmail);
      email$.pipe(take(1)).subscribe(email => {
        this.sdk
          .change_password(oldPassword, newPassword)
          .then(() => resolve())
          .catch(error => reject(error));
      });
    });
  }

  /** Delete User Account forever. */
  public deleteUserAccount(password: string) {
    return this.sdk
      .deleteOwnAccount(password)
      .then(() => {
        this.store.dispatch(new LogoutSuccessAction());
      })
      .catch(() => {
        this.store.dispatch(new DeleteAccountFailureAction());
      });
  }

  /*
  * create user post to sdk
  * pass email and password to sdk to register user
  */
  public register(
    email: string,
    password: string,
    rememberMe: boolean
  ): Promise<IAuthStore> {
    return this.sdk
      .sign_up(email, password)
      .then(resp => {
        const auth: IAuthStore = {
          privateKey: resp.privateKey,
          publicKey: resp.user.public_key,
          userId: resp.user.id,
          email,
          userToken: resp.token,
          rememberMe,
          optInErrorReporting: resp.user.opt_in_error_reporting
        };

        this.setUp(auth);
        return auth;
      })
      .catch(err => {
        throw new Error(err);
      });
  }

  /** call set_keys user service method give it public and private key */
  public rehydrate() {
    return this.store
      .select(selectAuthState)
      .pipe(take(1))
      .subscribe(auth => {
        if (
          auth.publicKey &&
          auth.privateKey &&
          auth.userId &&
          auth.email &&
          auth.userToken
        ) {
          const authStore: IAuthStore = {
            email: auth.email,
            userId: auth.userId,
            privateKey: auth.privateKey,
            publicKey: auth.publicKey,
            userToken: auth.userToken,
            rememberMe: auth.rememberMe,
            optInErrorReporting: auth.optInErrorReporting
          };
          this.setUp(authStore);
        }
      });
  }

  public resetRegisterCode() {
    return this.sdk.request_new_confirmation().then(resp => {
      return resp;
    });
  }

  setErrorReporting(optIn: boolean) {
    const userPatch = {
      opt_in_error_reporting: optIn
    };
    return this.sdk.update_user(this.sdk.userId, userPatch);
  }

  private setSdkUrl(url: string) {
    this.store.dispatch(new SetUrlAction(url));
  }

  /** Set key and user id state for sdk object
   * Note that the auth key will be provided to the api via subscription
   * instead of using the sdk's setUp function. See api.ts
   */
  private setUp(auth: IAuthStore): Promise<void> {
    return new Promise((resolve, reject) => {
      this.sdk.set_keys(auth.publicKey, auth.privateKey);
      this.sdk.userId = auth.userId;
      this.sdk.ready().then(() => {
        resolve();
      });
    });
  }
}
