import { Injectable } from "@angular/core";
import { parse } from "papaparse";
import * as URL from "url-parse";
import { NgPassitSDK } from "../ngsdk/sdk";
import { ImportableSecret } from "./importableSecret";

@Injectable()
export class ImporterService {
  constructor(private sdk: NgPassitSDK) {}

  public textToSecrets(importText: string): ImportableSecret[] {
    return this.deserialize(importText);
  }

  public processImport(secrets: ImportableSecret[]) {
    secrets = secrets.filter(secret => secret.doImport === true);
    this.saveSecrets(secrets);
  }

  public saveSecrets(secrets: ImportableSecret[]): Promise<any> {
    return new Promise((resolve, reject) => {
      const promises = [];
      for (const secret of secrets) {
        promises.push(
          this.sdk.create_secret(secret).catch(error => {
            console.error("Error creating secret", secret, error);
          })
        );
      }
      resolve(Promise.all(promises));
    });
  }

  private deserialize(importText: string): any[] {
    const importObjects = parse(importText, { header: true }).data;
    const result = [];
    if (importObjects.length > 0) {
      for (const importObject of importObjects) {
        if (Object.keys(importObject).length > 0) {
          const convertedSecret = this.deserializeSecret(importObject);
          if (convertedSecret.importable) {
            convertedSecret.name = this.validateName(convertedSecret);
          }
          if (
            convertedSecret.name !== undefined ||
            !convertedSecret.importable
          ) {
            result.push(convertedSecret);
          }
        }
      }
    } else {
      // console.log("This means that the CSV didn\'t convert to JSON correctly.");
    }
    return result;
  }

  private deserializeSecret(importObject: any): ImportableSecret {
    const secret: ImportableSecret = {
      doImport: true,
      importable: true,
      name: importObject.name,
      secrets: {
        password: importObject.password
      },
      type: "website",
      visible_data: {
        url: importObject.url,
        username: importObject.username
      }
    };
    if (importObject.extra) {
      secret.secrets!["notes"] = importObject.extra;
    }
    if (typeof importObject.password === "undefined") {
      secret.doImport = false;
      secret.importable = false;
    }
    return secret;
  }

  private validateName(secret: ImportableSecret): string | undefined {
    const name = secret.name;
    const password = (secret as any).secrets.password;
    const visibleData = secret.visible_data;
    const url = visibleData ? (secret as any).visible_data.url : "";
    const username = visibleData ? (secret as any).visible_data.username : "";

    const hasUrl = typeof url === "undefined" || url === "" ? false : true;
    const hasUsername =
      typeof username === "undefined" || username === "" ? false : true;
    const hasPassword =
      typeof password === "undefined" || password === "" ? false : true;

    if (name && name !== "") {
      return name;
    }
    if (hasUrl) {
      return this.urlToName(url);
    }
    if (hasUsername) {
      return `Anonymous (${username})`;
    }
    if (hasPassword) {
      return `Anonymous Password`;
    }
  }

  private urlToName(url: string) {
    const urlObject = new URL(url);
    let name = "";
    if (urlObject.origin !== new URL().origin) {
      name = urlObject.hostname;
    } else {
      name = urlObject.pathname.substr(1);
    }
    if (name === "") {
      name = urlObject.href;
    }
    return name;
  }
}
