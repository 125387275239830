import { map, take } from "rxjs/operators";
import { Injectable } from "@angular/core";
import { CanActivate } from "@angular/router";
import { Store } from "@ngrx/store";

import * as fromAccount from "../account.reducer";

@Injectable()
export class ConfirmEmailGuard implements CanActivate {
  constructor(public store: Store<fromAccount.IAccountState>) {}

  /** If the user already verified, then they shouldn't be here.
   * Note this method only checks if they just confirmed, it doesn't check against the server.
   */
  public canActivate() {
    return this.store.select(fromAccount.getConfirmIsVerified).pipe(
      map(isVerified => !isVerified),
      take(1)
    );
  }
}
