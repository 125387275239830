import { HttpClient, HttpHeaders, HttpResponse } from "@angular/common/http";
import { HttpObserve } from "@angular/common/http/src/client";
import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { getToken, getUrl, IAuthState } from "../account/account.reducer";
import { API_URL } from "../constants";

import sdkAPI, { IHeaders } from "passit-sdk-js/js/api";

@Injectable()
export class Api extends sdkAPI {
  constructor(public http: HttpClient, private store: Store<IAuthState>) {
    super();
    this.store.select(getToken).subscribe(token => {
      if (token !== null) {
        this.token = token;
      } else {
        this.token = "";
      }
    });
    // Override base url if set explicitly.
    this.store.select(getUrl).subscribe(url => {
      if (url) {
        this.baseUrl = url;
      } else {
        this.baseUrl = API_URL;
      }
    });
  }
  protected async sendInner<B>(
    method: string,
    url: string,
    body: B,
    headers: IHeaders,
    returnsJSON: boolean
  ): Promise<any> {
    const observe: HttpObserve = "response";
    const requestOptions = {
      body,
      headers: new HttpHeaders(headers),
      observe
    };
    let resp: HttpResponse<object>;
    try {
      resp = await this.http.request(method, url, requestOptions).toPromise();
    } catch (res) {
      throw { res };
    }
    if (returnsJSON) {
      return await resp.body;
    }
    return undefined;
  }
}
