import { Injectable } from "@angular/core";
import { Actions, Effect, ofType } from "@ngrx/effects";
import {
  switchMap,
  map,
  exhaustMap,
  tap,
  withLatestFrom
} from "rxjs/operators";
import { HttpErrorResponse } from "@angular/common/http";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";

import { UserService } from "../user";
import * as fromRoot from "../../app.reducers";
import {
  ResetRegisterCodeFailureAction,
  ResetRegisterCodeSuccessAction,
  ConfirmEmailTypes,
  VerifyEmail,
  VerifyEmailFailure,
  VerifyEmailSuccess,
  ResetRegisterCodeAction
} from "./confirm-email.actions";
import { NgPassitSDK } from "../../ngsdk/sdk";
import { getRouterPath } from "../../app.reducers";

@Injectable()
export class ConfirmEmailEffects {
  @Effect()
  ResetRegisterCode$ = this.actions$.pipe(
    ofType<ResetRegisterCodeAction>(ConfirmEmailTypes.RESET_REGISTER_CODE),
    switchMap(() => {
      return this.userService
        .resetRegisterCode()
        .then((resp: any) => new ResetRegisterCodeSuccessAction())
        .catch((error: any) => new ResetRegisterCodeFailureAction());
    })
  );

  @Effect()
  VerifyEmail$ = this.actions$.pipe(
    ofType<VerifyEmail>(ConfirmEmailTypes.VERIFY_EMAIL),
    map(action => action.payload),
    exhaustMap(code =>
      this.sdk
        .confirm_email_short_code(code)
        .then(() => new VerifyEmailSuccess())
        .catch(err => {
          const res: HttpErrorResponse = err.res;
          const status = res.status;
          let errorMessage = "Unexpected error.";
          if (status === 404 || status === 400) {
            errorMessage =
              "This code doesn't match what we sent. Please try again.";
          } else if (status === 406) {
            errorMessage = `We invalidated this code because of too many failed attempts.
            Press the "Resend confirmation" link below to receive a new code.`;
          } else if (status === 403) {
            return (errorMessage =
              "To validate via confirmation code, please log in first.");
          }
          return new VerifyEmailFailure(errorMessage);
        })
    )
  );

  @Effect({ dispatch: false })
  VerifyEmailSuccess$ = this.actions$.pipe(
    ofType<VerifyEmailSuccess>(ConfirmEmailTypes.VERIFY_EMAIL_SUCCESS),
    withLatestFrom(this.store.select(getRouterPath)),
    map(([action, path]) => path),
    tap(path => {
      // The confirm email page automatically redirects while register does not
      if (path === "/confirm-email") {
        this.router.navigate(["/list"]);
      }
    })
  );

  constructor(
    private actions$: Actions,
    private userService: UserService,
    private sdk: NgPassitSDK,
    private router: Router,
    private store: Store<fromRoot.IState>
  ) {}
}
