import {
  FormGroupState,
  createFormGroupState,
  validate,
  markAsSubmitted,
  updateGroup,
  createFormStateReducerWithUpdate,
  SetValueAction
} from "ngrx-forms";
import { required, pattern } from "ngrx-forms/validation";
import { environment } from "../../../environments/environment";

import { AccountActions, AccountActionTypes } from "../account.actions";
import { ILoginForm } from ".";
import { DEFAULT_API } from "../../constants";
import { oldPasswordValidators } from "../constants";
import { IBaseFormState } from "../../utils/interfaces";

const FORM_ID = "Login Form";

export const validateAndUpdateFormState = updateGroup<ILoginForm>({
  email: validate(required, pattern(/^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/)),
  password: validate(oldPasswordValidators)
});

const initialFormState = validateAndUpdateFormState(
  createFormGroupState<ILoginForm>(FORM_ID, {
    email: "",
    password: "",
    rememberMe:
      environment.extension || environment.nativescript ? true : false,
    showUrl: false,
    url: environment.extension || environment.nativescript ? DEFAULT_API : ""
  })
);

export interface ILoginState extends IBaseFormState {
  form: FormGroupState<ILoginForm>;
  errorMessage: string | null;
}

export const initialState: ILoginState = {
  form: initialFormState,
  hasStarted: false,
  hasFinished: false,
  errorMessage: null
};

export const formReducer = createFormStateReducerWithUpdate<ILoginForm>(
  validateAndUpdateFormState
);

export function reducer(
  state = initialState,
  action: AccountActions
): ILoginState {
  let form = formReducer(state.form, action);
  state = { ...state, form };

  switch (action.type) {
    case AccountActionTypes.LOGIN_REDIRECT:
      if (action.payload) {
        const setValueAction = new SetValueAction(
          form.controls.email.id,
          action.payload
        );
        form = formReducer(state.form, setValueAction);
      }
      return {
        ...state,
        form
      };

    case AccountActionTypes.LOGIN:
      return {
        ...state,
        form: markAsSubmitted(state.form),
        hasStarted: true,
        hasFinished: false,
        errorMessage: null
      };

    case AccountActionTypes.LOGIN_SUCCESS:
      return {
        ...state,
        hasStarted: false,
        hasFinished: true
      };

    case AccountActionTypes.LOGIN_FAILURE:
      return {
        ...state,
        hasStarted: false,
        errorMessage: action.payload
      };

    default:
      return state;
  }
}

export const getHasStarted = (state: ILoginState) => state.hasStarted;
export const getHasFinished = (state: ILoginState) => state.hasFinished;
export const getErrorMessage = (state: ILoginState) => state.errorMessage;
export const getForm = (state: ILoginState) => state.form;
