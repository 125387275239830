import { EventEmitter, ElementRef } from "@angular/core";
import { copyToClipboard } from "../../utils";
var SecretFormComponent = /** @class */ (function () {
    function SecretFormComponent() {
        this.togglePasswordIsMasked = new EventEmitter();
        this.setPasswordIsMasked = new EventEmitter();
        this.toggleShowNotes = new EventEmitter();
        this.hideAddSecretForm = new EventEmitter();
        this.saveSecret = new EventEmitter();
        this.deleteSecret = new EventEmitter();
        this.generatePassword = new EventEmitter();
        this.cancel = new EventEmitter();
        /* This is a work around for firefox. Both blur and click are triggered when clicking on the eye icon after clicking
        input. This prevents the eye icon from being toggled properly. blurIsFired is set when blur is triggered. */
        this.blurIsFired = false;
        this.groupOptions = [];
    }
    Object.defineProperty(SecretFormComponent.prototype, "groups", {
        set: function (groups) {
            this.groupOptions = groups.map(function (group) {
                return {
                    label: group.name,
                    value: group.id
                };
            });
        },
        enumerable: true,
        configurable: true
    });
    SecretFormComponent.prototype.onSubmit = function () {
        if (this.form.isValid) {
            this.saveSecret.emit(this.isNew);
        }
    };
    SecretFormComponent.prototype.clickMaskedSecret = function () {
        var _this = this;
        this.togglePasswordIsMasked.emit();
        setTimeout(function () { return _this.realPasswordInput.nativeElement.focus(); }, 0);
    };
    SecretFormComponent.prototype.toggleViewSecret = function () {
        var _this = this;
        if (this.blurIsFired === false) {
            this.togglePasswordIsMasked.emit();
            setTimeout(function () { return _this.realPasswordInput.nativeElement.focus(); }, 0);
        }
    };
    SecretFormComponent.prototype.togglePassword = function () {
        var _this = this;
        this.blurIsFired = true;
        this.togglePasswordIsMasked.emit();
        setTimeout(function () { return (_this.blurIsFired = false); }, 100);
    };
    SecretFormComponent.prototype.onDeleteSecret = function () {
        if (window.confirm("Once it's deleted, it's gone forever. Is that okay?")) {
            this.deleteSecret.emit();
        }
    };
    SecretFormComponent.prototype.copySecret = function () {
        this.triggerCopied("password");
        copyToClipboard(this.form.value.password);
    };
    SecretFormComponent.prototype.realPasswordBlur = function () {
        this.setPasswordIsMasked.emit(true);
    };
    /** This one is stupid, window.open wiht noopener doesn't actually work...
     * See https://stackoverflow.com/questions/49276569/window-open-with-noopener-opens-a-new-window-instead-of-a-new-tab
     * This opens a url safely in the same manner of adding noopener in an anchor tag.
     */
    SecretFormComponent.prototype.openUrl = function (url) {
        var yourWindow = window.open();
        yourWindow.opener = null;
        yourWindow.location = url;
        yourWindow.target = "_blank";
    };
    SecretFormComponent.prototype.goToUrl = function (value) {
        var url = value;
        if (!url.match(/^https?:\/\//)) {
            url = "http://" + url;
        }
        this.openUrl(url);
    };
    SecretFormComponent.prototype.triggerCopied = function (field) {
        var _this = this;
        if (field === "username") {
            this.usernameCopied = true;
            setTimeout(function () {
                _this.usernameCopied = false;
            }, 2000);
        }
        if (field === "password") {
            this.passwordCopied = true;
            setTimeout(function () {
                _this.passwordCopied = false;
            }, 2000);
        }
    };
    return SecretFormComponent;
}());
export { SecretFormComponent };
