import { NgPassitSDK } from "../ngsdk/sdk";
var GroupService = /** @class */ (function () {
    function GroupService(sdk) {
        this.sdk = sdk;
    }
    /*
    * add group to secret using group id and secret id
    */
    GroupService.prototype.addGroupToSecret = function (groupId, secret) {
        return this.sdk
            .add_group_to_secret(groupId, secret.id)
            .then(function (resp) { return resp; })
            .catch(function (err) { return console.error(err); });
    };
    /*
    * create group
    */
    GroupService.prototype.create = function (form, members) {
        var _this = this;
        // Add current user always!
        var userId = this.sdk.userId;
        if (form.members.indexOf(userId) === -1) {
            form.members.push(userId);
        }
        return this.sdk
            .create_group(form.name)
            .then(function (resp) {
            form.id = resp.id;
            return _this.updateGroupMembers(form.id, members);
        })
            .catch(function (err) { return console.error(err); });
    };
    GroupService.prototype.update = function (groupId, form) {
        return this.sdk
            .update_group({ id: groupId, name: form.name, slug: form.slug })
            .catch(function (err) { return console.error(err); });
    };
    /*
    * get specific group using id
    */
    GroupService.prototype.getGroup = function (groupId) {
        return this.sdk
            .get_group(groupId)
            .then(function (resp) { return resp; })
            .catch(function (err) { return console.error(err); });
    };
    /**
     * Get groups from sdk
     * Returns Promise indicating result.
     */
    GroupService.prototype.getGroups = function () {
        return this.sdk.list_groups();
    };
    GroupService.prototype.removeGroupFromSecret = function (secret) {
        var _this = this;
        return this.sdk
            .get_secret(secret.id)
            .then(function (data) {
            return _this.sdk
                .remove_group_from_secret(secret.id, data.secret_through_set[0].id)
                .then(function (resp) {
                return resp;
            })
                .catch(function (err) { return console.error(err); });
        })
            .catch(function (err) { return console.error(err); });
    };
    GroupService.prototype.updateGroupMembers = function (groupId, groupMembers) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            _this.sdk.get_group(groupId).then(function (group) {
                var existingMembers = [];
                for (var _i = 0, _a = group.groupuser_set; _i < _a.length; _i++) {
                    var usergroup = _a[_i];
                    existingMembers.push(usergroup.user);
                }
                var promises = [];
                for (var _b = 0, groupMembers_1 = groupMembers; _b < groupMembers_1.length; _b++) {
                    var member = groupMembers_1[_b];
                    // If new member is not an existing member then add them
                    if (existingMembers.indexOf(member.id) < 0) {
                        promises.push(_this.sdk.add_user_to_group(group.id, member.id, member.email));
                    }
                }
                var _loop_1 = function (member) {
                    // If existing member is not in new group members then remove them
                    if (groupMembers.map(function (groupMember) { return groupMember.id; }).indexOf(member) < 0) {
                        var memberGroupuser = group.groupuser_set.find(function (groupuser) { return groupuser.user === member; });
                        promises.push(_this.sdk.remove_user_from_group(group.id, memberGroupuser.id));
                    }
                };
                for (var _c = 0, existingMembers_1 = existingMembers; _c < existingMembers_1.length; _c++) {
                    var member = existingMembers_1[_c];
                    _loop_1(member);
                }
                Promise.all(promises).then(function () { return resolve(); });
            });
        });
    };
    GroupService.prototype.deleteGroup = function (groupId) {
        return this.sdk.delete_group(groupId);
    };
    GroupService.prototype.acceptGroup = function (group) {
        var groupUser = this.findPendingGroupUser(group, this.sdk.userId);
        if (groupUser) {
            return this.sdk.acceptGroupInvite(groupUser.id);
        }
        return Promise.reject(null);
    };
    GroupService.prototype.declineGroup = function (group) {
        var groupUser = this.findPendingGroupUser(group, this.sdk.userId);
        if (groupUser) {
            return this.sdk.declineGroupInvite(groupUser.id);
        }
        return Promise.reject(null);
    };
    GroupService.prototype.findPendingGroupUser = function (group, userId) {
        return group.groupuser_set.find(function (groupuser) { return groupuser.user === userId && groupuser.is_invite_pending; });
    };
    return GroupService;
}());
export { GroupService };
