/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "../secret-row/secret-row.component.scss.shim.ngstyle";
import * as i1 from "../list.component.scss.shim.ngstyle";
import * as i2 from "@angular/core";
import * as i3 from "../secret-form/secret-form.container.ngfactory";
import * as i4 from "../secret-form/secret-form.container";
import * as i5 from "@ngrx/store";
import * as i6 from "./secret-add.component";
var styles_SecretAddComponent = [i0.styles, i1.styles];
var RenderType_SecretAddComponent = i2.ɵcrt({ encapsulation: 0, styles: styles_SecretAddComponent, data: {} });
export { RenderType_SecretAddComponent as RenderType_SecretAddComponent };
export function View_SecretAddComponent_0(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 1, "h2", [["class", "secret__title secret__title--add"]], null, null, null, null, null)), (_l()(), i2.ɵted(-1, null, [" Add New Password "])), (_l()(), i2.ɵeld(2, 0, null, null, 1, "secret-form-container", [["class", "secret-form-component"]], [[2, "secret-form-component--is-active", null]], null, null, i3.View_SecretFormContainer_0, i3.RenderType_SecretFormContainer)), i2.ɵdid(3, 49152, null, 0, i4.SecretFormContainer, [i5.Store], null, null)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.secretAnimated; _ck(_v, 2, 0, currVal_0); }); }
export function View_SecretAddComponent_Host_0(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 1, "secret-add", [], null, null, null, View_SecretAddComponent_0, RenderType_SecretAddComponent)), i2.ɵdid(1, 114688, null, 0, i6.SecretAddComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SecretAddComponentNgFactory = i2.ɵccf("secret-add", i6.SecretAddComponent, View_SecretAddComponent_Host_0, {}, {}, []);
export { SecretAddComponentNgFactory as SecretAddComponentNgFactory };
