
    <register-component
      [form]="form$ | async"
      [urlForm]="urlForm$ | async"
      [stageValue]="stageValue$ | async"
      [isEmailTaken]="isEmailTaken$ | async"
      [isUrlValid]="isUrlValid$ | async"
      [urlDisplayName]="urlDisplayName$ | async"
      [showUrl]="showUrl$ | async"
      [errorMessage]="errorMessage$ | async"
      [hasSubmitStarted]="hasSubmitStarted$ | async"
      [hasSubmitFinished]="hasSubmitFinished$ | async"
      [isExtension]="isExtension"
      (register)="register()"
      (goToLogin)="goToLogin()"
      (incrementStage)="incrementStage()"
      (switchStage)="switchStage($event)"
      (checkEmail)="checkEmail()"
      (checkUrl)="checkUrl()"
      (displayUrlInput)="displayUrlInput()"
      (hideUrlInput)="hideUrlInput()"
      (toggleShowConfirm)="toggleConfirm()"
      (markAsSubmitted)="markAsSubmitted()"
      (setNewsletterSubscribe)="setNewsletterSubscribe()"
      (registrationFinished)="registrationFinished()"
    ></register-component>
  