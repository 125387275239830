import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { InlineSVGModule } from "ng-inline-svg";

import { ProgressIndicatorComponent } from "./progress-indicator.component";
export { ProgressIndicatorComponent } from "./progress-indicator.component";

const COMPONENTS = [ProgressIndicatorComponent];

@NgModule({
  imports: [CommonModule, InlineSVGModule],
  declarations: COMPONENTS,
  exports: COMPONENTS
})
export class ProgressIndicatorModule {}
