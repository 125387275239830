import { EntityState, createEntityAdapter } from "@ngrx/entity";
import { ISecret } from "passit-sdk-js/js/api.interfaces";
import { SecretActionTypes, SecretActionsUnion } from "./secret.actions";

export interface ISecretState extends EntityState<ISecret> {}

function sortByName(a: ISecret, b: ISecret) {
  return a.name.localeCompare(b.name);
}
const secretAdapter = createEntityAdapter<ISecret>({
  sortComparer: sortByName
});

export const initialState = secretAdapter.getInitialState();

export function secretReducer(
  state = initialState,
  action: SecretActionsUnion
): ISecretState {
  switch (action.type) {
    case SecretActionTypes.REMOVE_SECRET:
      return secretAdapter.removeOne(action.payload, state);

    case SecretActionTypes.REPLACE_SECRET_SUCCESS:
      return secretAdapter.upsertOne(action.payload, state);

    case SecretActionTypes.SET_SECRETS:
      return secretAdapter.addAll(action.payload, state);

    default:
      return state;
  }
}

const { selectAll } = secretAdapter.getSelectors();

export const getSecrets = selectAll;
