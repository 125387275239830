import { map, take } from "rxjs/operators";
import { Injectable } from "@angular/core";
import { CanActivate } from "@angular/router";
import { Store } from "@ngrx/store";

import { LoginRedirect } from "../account/account.actions";
import * as fromAccount from "../account/account.reducer";

@Injectable()
export class LoggedInGuard implements CanActivate {
  constructor(public store: Store<fromAccount.IAuthState>) {}

  public canActivate() {
    return this.store.select(fromAccount.getIsLoggedIn).pipe(
      take(1),
      map(authed => {
        if (!authed) {
          this.store.dispatch(new LoginRedirect());
          return false;
        }

        return true;
      })
    );
  }
}
