import { Component, EventEmitter, Input, Output } from "@angular/core";

import { IGroup, IGroupUser } from "passit-sdk-js/js/api.interfaces";
import { IContact } from "./contacts/contacts.interfaces";
import { IGroupForm } from "./group.interfaces";

interface ISelectOptions {
  label: string;
  value: any;
}

@Component({
  selector: "group-list",
  styleUrls: ["../list/list.component.scss"],
  templateUrl: "./group-list.component.html"
})
export class GroupListComponent {
  groupAnimated = false;

  @Input()
  groups: IGroup[];
  @Input()
  groupManaged: number;
  @Input()
  groupForm: IGroupForm;
  @Input()
  contacts: IContact[];
  @Input()
  showCreate: boolean;
  @Input()
  groupIsUpdating: boolean;
  @Input()
  groupIsUpdated: boolean;
  @Input()
  groupContacts: ISelectOptions[];
  @Input()
  searchedContacts: ISelectOptions[];
  @Input()
  isPrivateOrgMode: boolean;
  @Input()
  contactLookup: ISelectOptions[];
  @Input()
  singleGroupPendingContacts: IGroupUser[];

  @Output()
  groupSelected = new EventEmitter<IGroup>();
  @Output()
  hideAddSecretForm = new EventEmitter();
  @Output()
  save = new EventEmitter();
  @Output()
  saveNew = new EventEmitter();
  @Output()
  contactSearch = new EventEmitter<string>();
  @Output()
  delete = new EventEmitter<number>();
  @Output()
  resetSearch = new EventEmitter();
  @Output()
  updateFormValues = new EventEmitter<number>();
  @Output()
  cancelDeselect = new EventEmitter<number>();

  onGroupWasSelected(group: IGroup) {
    this.groupAnimated = true;
    // I don't know why this doesn't work but similar code in list.component.ts does
    // if (this.groupManaged !== group.id) {
    //   setTimeout(() => {
    //     this.groupAnimated = true;
    //   }, 1);
    // }
    this.groupSelected.emit(group);
  }
}
