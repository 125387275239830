/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./non-field-messages.styles.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./non-field-messages.component";
var styles_NonFieldMessagesComponent = [i0.styles];
var RenderType_NonFieldMessagesComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_NonFieldMessagesComponent, data: {} });
export { RenderType_NonFieldMessagesComponent as RenderType_NonFieldMessagesComponent };
function View_NonFieldMessagesComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "li", [["class", "message"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 1, 0, currVal_0); }); }
function View_NonFieldMessagesComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "ul", [["class", "container"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NonFieldMessagesComponent_2)), i1.ɵdid(2, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.messages; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_NonFieldMessagesComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_NonFieldMessagesComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.messages && (_co.messages.length > 0)); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_NonFieldMessagesComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-non-field-messages", [], null, null, null, View_NonFieldMessagesComponent_0, RenderType_NonFieldMessagesComponent)), i1.ɵdid(1, 49152, null, 0, i3.NonFieldMessagesComponent, [], null, null)], null, null); }
var NonFieldMessagesComponentNgFactory = i1.ɵccf("app-non-field-messages", i3.NonFieldMessagesComponent, View_NonFieldMessagesComponent_Host_0, { messages: "messages" }, {}, []);
export { NonFieldMessagesComponentNgFactory as NonFieldMessagesComponentNgFactory };
