var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Effect, Actions, ofType } from "@ngrx/effects";
import { ErrorReportingTypes, SaveFormSuccess, SaveFormFailure, ClearFinished } from "./error-reporting.actions";
import { withLatestFrom, map, exhaustMap, filter, switchMap } from "rxjs/operators";
import { Store } from "@ngrx/store";
import { getErrorReportingForm, getErrorReportingHasFinished } from "../account.reducer";
import { UserService } from "../user";
import { timer } from "rxjs";
var ErrorReportingEffects = /** @class */ (function () {
    function ErrorReportingEffects(actions$, store, userService) {
        var _this = this;
        this.actions$ = actions$;
        this.store = store;
        this.userService = userService;
        this.save$ = this.actions$.pipe(ofType(ErrorReportingTypes.SAVE_FORM), withLatestFrom(this.store.select(getErrorReportingForm)), map(function (_a) {
            var action = _a[0], form = _a[1];
            return form;
        }), exhaustMap(function (form) {
            return _this.userService
                .setErrorReporting(form.controls.optInErrorReporting.value)
                .then(function (user) { return new SaveFormSuccess(user); })
                .catch(function () { return new SaveFormFailure(); });
        }));
        /** Clear the finished indicator after a little bit */
        this.fadeFinished$ = this.store.select(getErrorReportingHasFinished).pipe(filter(function (hasFinished) { return hasFinished === true; }), switchMap(function () { return timer(2000).pipe(map(function () { return new ClearFinished(); })); }));
    }
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], ErrorReportingEffects.prototype, "save$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], ErrorReportingEffects.prototype, "fadeFinished$", void 0);
    return ErrorReportingEffects;
}());
export { ErrorReportingEffects };
