var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
import { PopupActionTypes } from "./popup.actions";
export var initialState = {
    selectedSecret: null,
    search: "",
    lastOpened: null,
    currentUrl: null,
    usernameCopied: null,
    passwordCopied: null
};
export function popupReducer(state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case PopupActionTypes.SET_SELECTED_SECRET:
            return __assign({}, state, { selectedSecret: action.payload, lastOpened: new Date().toString() });
        case PopupActionTypes.NULL_SELECTED_SECRET:
            return __assign({}, state, { selectedSecret: initialState.selectedSecret, lastOpened: new Date().toString() });
        case PopupActionTypes.SET_SEARCH:
            return __assign({}, state, { search: action.payload, lastOpened: new Date().toString() });
        case PopupActionTypes.SET_CURRENT_URL:
            return __assign({}, state, { currentUrl: action.payload });
        case PopupActionTypes.COPY_USERNAME:
            return __assign({}, state, { usernameCopied: action.payload.id });
        case PopupActionTypes.COPY_PASSWORD:
            return __assign({}, state, { passwordCopied: action.payload.id });
        case PopupActionTypes.CLEAR_COPIED:
            return __assign({}, state, { usernameCopied: null, passwordCopied: null });
        default:
            return state;
    }
}
