import { Action } from "@ngrx/store";
import { IUser } from "passit-sdk-js/js/api.interfaces";

export enum ErrorReportingTypes {
  SAVE_FORM = "[error reporting] Save Form",
  SAVE_FORM_SUCCESS = "[error reporting] Save Form Success",
  SAVE_FORM_FAILURE = "[error reporting] Save Form Failure",
  CLEAR_FINISHED = "[error reporting] Clear Finished"
}

export class SaveForm implements Action {
  readonly type = ErrorReportingTypes.SAVE_FORM;
}
export class SaveFormSuccess implements Action {
  readonly type = ErrorReportingTypes.SAVE_FORM_SUCCESS;

  constructor(public payload: IUser) {}
}
export class SaveFormFailure implements Action {
  readonly type = ErrorReportingTypes.SAVE_FORM_FAILURE;
}

export class ClearFinished implements Action {
  readonly type = ErrorReportingTypes.CLEAR_FINISHED;
}

export type ErrorReportingActionsUnion =
  | SaveForm
  | SaveFormSuccess
  | SaveFormFailure
  | ClearFinished;
