export let copyToClipboard = (() => {
  let dataString: string | null = null;
  document.addEventListener("copy", (e: any) => {
    if (dataString !== null) {
      try {
        e.clipboardData.setData("text/plain", dataString);
        e.preventDefault();
      } finally {
        dataString = null;
      }
    }
  });
  return (data: string) => {
    dataString = data;
    document.execCommand("copy");
  };
})();
