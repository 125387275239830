import { Injectable } from "@angular/core";

import { UserService } from "../../account/user";

@Injectable()
export class AppDataService {
  constructor(public userService: UserService) {}

  public rehydrate() {
    return this.userService.rehydrate();
  }
}
