<div class="secret">
  <div class="secret__heading">
    <h3 
      class="secret__title" 
      [class.secret__title--is-active]="active"
      (click)="toggleView(true)">
      {{ secret.name }}
    </h3>
    <div class="secret__header-actions">
      <button
        id="manageSecretBtn"
        type="button"
        class="button button--gray secret__button"
        (click)="toggleView()">
        {{ !active ? "Manage" : "Close" }}
      </button>
    </div>
  </div>
</div>
