import {
  createFormGroupState,
  FormGroupState,
  formGroupReducer
} from "ngrx-forms";
import { IBaseFormState } from "../../utils/interfaces";
import {
  ErrorReportingActionsUnion,
  ErrorReportingTypes
} from "./error-reporting.actions";

export interface IErrorReportingForm {
  optInErrorReporting: boolean;
}

export const FORM_ID = "ErrorReportingForm";

export const initialFormState = createFormGroupState<IErrorReportingForm>(
  FORM_ID,
  {
    optInErrorReporting: false
  }
);

export interface IState extends IBaseFormState {
  form: FormGroupState<IErrorReportingForm>;
}

const initialState: IState = {
  form: initialFormState,
  hasFinished: false,
  hasStarted: false
};

export function reducer(
  state = initialState,
  action: ErrorReportingActionsUnion
): IState {
  const form = formGroupReducer(state.form, action);
  if (form !== state.form) {
    state = { ...state, form };
  }

  switch (action.type) {
    case ErrorReportingTypes.SAVE_FORM: {
      return {
        ...state,
        hasFinished: false,
        hasStarted: true
      };
    }

    case ErrorReportingTypes.SAVE_FORM_SUCCESS: {
      return {
        ...state,
        hasStarted: false,
        hasFinished: true
      };
    }

    case ErrorReportingTypes.SAVE_FORM_FAILURE: {
      return {
        ...state,
        hasStarted: false,
        hasFinished: true
      };
    }

    case ErrorReportingTypes.CLEAR_FINISHED: {
      return {
        ...state,
        hasFinished: false
      };
    }

    default: {
      return state;
    }
  }
}

export const getForm = (state: IState) => state.form;
export const getHasStarted = (state: IState) => state.hasStarted;
export const getHasFinished = (state: IState) => state.hasFinished;
