/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./no-content.component.ngfactory";
import * as i2 from "./no-content.component";
import * as i3 from "@angular/common";
import * as i4 from "./no-content.container";
import * as i5 from "@ngrx/store";
import * as i6 from "@angular/router";
var styles_NoContentContainer = [];
var RenderType_NoContentContainer = i0.ɵcrt({ encapsulation: 2, styles: styles_NoContentContainer, data: {} });
export { RenderType_NoContentContainer as RenderType_NoContentContainer };
export function View_NoContentContainer_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "no-content", [], null, null, null, i1.View_NoContentComponent_0, i1.RenderType_NoContentComponent)), i0.ɵdid(1, 49152, null, 0, i2.NoContentComponent, [], { isLoggedIn: [0, "isLoggedIn"], currentRoute: [1, "currentRoute"], isPrivateOrgMode: [2, "isPrivateOrgMode"] }, null), i0.ɵpid(131072, i3.AsyncPipe, [i0.ChangeDetectorRef]), i0.ɵpid(131072, i3.AsyncPipe, [i0.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 1, 0, i0.ɵnov(_v, 2).transform(_co.isLoggedIn$)); var currVal_1 = _co.currentRoute; var currVal_2 = i0.ɵunv(_v, 1, 2, i0.ɵnov(_v, 3).transform(_co.isPrivateOrgMode$)); _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }, null); }
export function View_NoContentContainer_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "no-content-container", [], null, null, null, View_NoContentContainer_0, RenderType_NoContentContainer)), i0.ɵdid(1, 49152, null, 0, i4.NoContentContainer, [i5.Store, i6.Router, i5.Store], null, null)], null, null); }
var NoContentContainerNgFactory = i0.ɵccf("no-content-container", i4.NoContentContainer, View_NoContentContainer_Host_0, {}, {}, []);
export { NoContentContainerNgFactory as NoContentContainerNgFactory };
