import {
  Component,
  EventEmitter,
  Input,
  Output,
  ChangeDetectionStrategy
} from "@angular/core";
import { IS_EXTENSION } from "../../constants";
import { ILoginForm } from "./interfaces";
import { FormGroupState } from "ngrx-forms";

@Component({
  selector: "login-component",
  styleUrls: ["../account.component.scss"],
  templateUrl: "./login.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoginComponent {
  @Input() form: FormGroupState<ILoginForm>;
  @Input() errorMessage: string;
  @Input() hasLoginStarted: boolean;
  @Input() hasLoginFinished: boolean;
  @Input() isPopup: boolean;
  @Output() login = new EventEmitter();
  @Output() goToRegister = new EventEmitter();
  isExtension = IS_EXTENSION;

  constructor() {}

  onSubmit() {
    if (this.form.isValid) {
      this.login.emit();
    }
  }

  toggleDisplay() {
    this.goToRegister.emit();
  }
}
