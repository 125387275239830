import { createSelector } from "@ngrx/store";
import { setContacts } from "../../utils/contacts";
import { ContactsActionTypes, ContactsActionsUnion } from "./contacts.actions";
import { IContact } from "./contacts.interfaces";
import { EntityState, createEntityAdapter } from "@ngrx/entity";
import { GroupActionsUnion, GroupActionTypes } from "../group.actions";

export interface IContactsState extends EntityState<IContact> {}

const adapter = createEntityAdapter<IContact>();

export const initialState: IContactsState = adapter.getInitialState();

export function contactsReducer(
  state = initialState,
  action: ContactsActionsUnion | GroupActionsUnion
): IContactsState {
  switch (action.type) {
    case ContactsActionTypes.SET_CONTACTS:
      return adapter.addAll(action.payload, state);

    case GroupActionTypes.CONTACT_RESET:
      const selectedMember = action.payload;
      const newContact = {
        id: selectedMember.value,
        email: selectedMember.label,
        first_name: "",
        last_name: ""
      };
      return adapter.addOne(newContact, state);

    default:
      return state;
  }
}

const { selectAll } = adapter.getSelectors();

export const getContacts = selectAll;

export const getDisplayContacts = createSelector(getContacts, contactList =>
  setContacts(contactList, false)
);
