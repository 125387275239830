import { Component, OnDestroy, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";

import * as fromRoot from "../../app.reducers";
import * as Account from "../account.actions";
import * as fromAccount from "../account.reducer";
import { UserService } from "../user";
import * as Register from "./register.actions";
import { SetValueAction, MarkAsSubmittedAction } from "ngrx-forms";
import { IS_EXTENSION, IS_NATIVESCRIPT } from "../../constants";
import { FORM_ID } from "./register.reducer";
import { Router } from "@angular/router";

@Component({
  providers: [UserService],
  template: `
    <register-component
      [form]="form$ | async"
      [urlForm]="urlForm$ | async"
      [stageValue]="stageValue$ | async"
      [isEmailTaken]="isEmailTaken$ | async"
      [isUrlValid]="isUrlValid$ | async"
      [urlDisplayName]="urlDisplayName$ | async"
      [showUrl]="showUrl$ | async"
      [errorMessage]="errorMessage$ | async"
      [hasSubmitStarted]="hasSubmitStarted$ | async"
      [hasSubmitFinished]="hasSubmitFinished$ | async"
      [isExtension]="isExtension"
      (register)="register()"
      (goToLogin)="goToLogin()"
      (incrementStage)="incrementStage()"
      (switchStage)="switchStage($event)"
      (checkEmail)="checkEmail()"
      (checkUrl)="checkUrl()"
      (displayUrlInput)="displayUrlInput()"
      (hideUrlInput)="hideUrlInput()"
      (toggleShowConfirm)="toggleConfirm()"
      (markAsSubmitted)="markAsSubmitted()"
      (setNewsletterSubscribe)="setNewsletterSubscribe()"
      (registrationFinished)="registrationFinished()"
    ></register-component>
  `
})
export class RegisterContainer implements OnDestroy, OnInit {
  form$ = this.store.select(fromAccount.getRegisterForm);
  urlForm$ = this.store.select(fromAccount.getUrlForm);
  errorMessage$ = this.store.select(fromAccount.getRegisterErrorMessage);
  hasSubmitStarted$ = this.store.select(fromAccount.getHasSubmitStarted);
  hasSubmitFinished$ = this.store.select(fromAccount.getHasSubmitFinished);
  stageValue$ = this.store.select(fromAccount.getStage);
  isEmailTaken$ = this.store.select(fromAccount.getRegisterIsEmailTaken);
  isUrlValid$ = this.store.select(fromAccount.getisUrlValid);
  urlDisplayName$ = this.store.select(fromAccount.getUrlDisplayName);
  showUrl$ = this.store.select(fromAccount.getShowUrl);
  isExtension = IS_EXTENSION;

  showConfirm = true;

  constructor(private store: Store<fromRoot.IState>, private router: Router) {
    this.form$.subscribe(
      form => (this.showConfirm = form.controls.showConfirm.value)
    );
  }

  goToLogin(email?: string) {
    this.store.dispatch(new Account.LoginRedirect(email));
  }

  register() {
    this.store.dispatch(new Register.RegisterAction());
  }

  markAsSubmitted() {
    this.store.dispatch(new MarkAsSubmittedAction(FORM_ID));
  }

  incrementStage() {
    this.store.dispatch(new Register.IncrementStageAction());
  }

  checkEmail() {
    this.store.dispatch(new Register.CheckEmailAction());
  }

  checkUrl() {
    this.store.dispatch(new Register.CheckUrlAction());
  }

  switchStage(value: number) {
    this.store.dispatch(new Register.SwitchStageAction(value));
  }

  toggleConfirm() {
    this.store.dispatch(
      new SetValueAction(FORM_ID + ".showConfirm", !this.showConfirm)
    );
  }

  displayUrlInput() {
    this.store.dispatch(new Register.DisplayUrlAction());
  }

  hideUrlInput() {
    this.store.dispatch(new Register.HideUrlAction());
  }

  setNewsletterSubscribe() {
    this.store.dispatch(new Register.NewsletterSubscribeAction());
  }

  registrationFinished() {
    if (IS_EXTENSION) {
      this.router.navigate(["/popup"]);
    } else {
      this.router.navigate(["/list"]);
    }
  }

  ngOnDestroy() {
    this.store.dispatch(new Register.RegisterClearAction());
  }

  ngOnInit() {
    // A default server must be set initially when using the ext.
    if (IS_EXTENSION || IS_NATIVESCRIPT) {
      this.store.dispatch(new Register.CheckUrlAction());
    }
  }
}
