import { parse } from "papaparse";
import * as URL from "url-parse";
import { NgPassitSDK } from "../ngsdk/sdk";
var ImporterService = /** @class */ (function () {
    function ImporterService(sdk) {
        this.sdk = sdk;
    }
    ImporterService.prototype.textToSecrets = function (importText) {
        return this.deserialize(importText);
    };
    ImporterService.prototype.processImport = function (secrets) {
        secrets = secrets.filter(function (secret) { return secret.doImport === true; });
        this.saveSecrets(secrets);
    };
    ImporterService.prototype.saveSecrets = function (secrets) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            var promises = [];
            var _loop_1 = function (secret) {
                promises.push(_this.sdk.create_secret(secret).catch(function (error) {
                    console.error("Error creating secret", secret, error);
                }));
            };
            for (var _i = 0, secrets_1 = secrets; _i < secrets_1.length; _i++) {
                var secret = secrets_1[_i];
                _loop_1(secret);
            }
            resolve(Promise.all(promises));
        });
    };
    ImporterService.prototype.deserialize = function (importText) {
        var importObjects = parse(importText, { header: true }).data;
        var result = [];
        if (importObjects.length > 0) {
            for (var _i = 0, importObjects_1 = importObjects; _i < importObjects_1.length; _i++) {
                var importObject = importObjects_1[_i];
                if (Object.keys(importObject).length > 0) {
                    var convertedSecret = this.deserializeSecret(importObject);
                    if (convertedSecret.importable) {
                        convertedSecret.name = this.validateName(convertedSecret);
                    }
                    if (convertedSecret.name !== undefined ||
                        !convertedSecret.importable) {
                        result.push(convertedSecret);
                    }
                }
            }
        }
        else {
            // console.log("This means that the CSV didn\'t convert to JSON correctly.");
        }
        return result;
    };
    ImporterService.prototype.deserializeSecret = function (importObject) {
        var secret = {
            doImport: true,
            importable: true,
            name: importObject.name,
            secrets: {
                password: importObject.password
            },
            type: "website",
            visible_data: {
                url: importObject.url,
                username: importObject.username
            }
        };
        if (importObject.extra) {
            secret.secrets["notes"] = importObject.extra;
        }
        if (typeof importObject.password === "undefined") {
            secret.doImport = false;
            secret.importable = false;
        }
        return secret;
    };
    ImporterService.prototype.validateName = function (secret) {
        var name = secret.name;
        var password = secret.secrets.password;
        var visibleData = secret.visible_data;
        var url = visibleData ? secret.visible_data.url : "";
        var username = visibleData ? secret.visible_data.username : "";
        var hasUrl = typeof url === "undefined" || url === "" ? false : true;
        var hasUsername = typeof username === "undefined" || username === "" ? false : true;
        var hasPassword = typeof password === "undefined" || password === "" ? false : true;
        if (name && name !== "") {
            return name;
        }
        if (hasUrl) {
            return this.urlToName(url);
        }
        if (hasUsername) {
            return "Anonymous (" + username + ")";
        }
        if (hasPassword) {
            return "Anonymous Password";
        }
    };
    ImporterService.prototype.urlToName = function (url) {
        var urlObject = new URL(url);
        var name = "";
        if (urlObject.origin !== new URL().origin) {
            name = urlObject.hostname;
        }
        else {
            name = urlObject.pathname.substr(1);
        }
        if (name === "") {
            name = urlObject.href;
        }
        return name;
    };
    return ImporterService;
}());
export { ImporterService };
