
    <group-add-button (showAddGroupForm)="showAddGroupForm()"></group-add-button>
    <group-add
      [contacts]="contacts$ | async"
      [groupForm]="groupForm$ | async"
      [groupIsUpdating]="groupIsUpdating$ | async"
      [groupIsUpdated]="groupIsUpdated$ | async"
      [isPrivateOrgMode]="isPrivateOrgMode$ | async"
      [groupContacts]="groupContacts$ | async"
      [searchedContacts]="searchedContacts$ | async"
      [showCreate]="showCreate$ | async"
      [contactLookup]="contactLookup$ | async"
      [singleGroupPendingContacts]="singleGroupPendingContacts$ | async"
      (hideAddSecretForm)="hideAddSecretForm()"
      (resetSearch)="resetContactSearch($event)"
      (contactSearch)="getContactLookup($event)"
      (updateFormValues)="updateFormValues($event)"
      (saveNew)="groupAddSave()"
    ></group-add>
    <app-group-empty-state
      [showCreate]="showCreate$ | async"
      [groups]="groups$ | async"
      [pendingInviteGroups]="pendingInviteGroups$ | async"></app-group-empty-state>
    <app-group-pending-list
      [pendingInviteGroups]="pendingInviteGroups$ | async"
      (acceptGroup)="acceptGroup($event)"
      (declineGroup)="declineGroup($event)"
    ></app-group-pending-list>
    <group-list
      [groups]="groups$ | async"
      [groupManaged]="groupManaged$ | async"
      [groupForm]="groupForm$ | async"
      [contacts]="contacts$ | async"
      [showCreate]="showCreate$ | async"
      [groupIsUpdating]="groupIsUpdating$ | async"
      [groupIsUpdated]="groupIsUpdated$ | async"
      [isPrivateOrgMode]="isPrivateOrgMode$ | async"
      [groupContacts]="groupContacts$ | async"
      [searchedContacts]="searchedContacts$ | async"
      [contactLookup]="contactLookup$ | async"
      [singleGroupPendingContacts]="singleGroupPendingContacts$ | async"
      (groupSelected)="groupSelected($event)"
      (hideAddSecretForm)="hideAddSecretForm()"
      (resetSearch)="resetContactSearch($event)"
      (save)="groupEditSave()"
      (updateFormValues)="updateFormValues($event)"
      (contactSearch)="getContactLookup($event)"
      (delete)="groupDelete($event)"
    ></group-list>
  