import { OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import * as fromRoot from "../app.reducers";
import * as formActions from "./reset-form.actions";

export class ResetFormContainer implements OnInit {
  constructor(public store: Store<fromRoot.IState>) {}

  ngOnInit() {
    this.resetForms();
  }

  resetForms() {
    this.store.dispatch(new formActions.ResetForms());
  }
}
