import { Component, EventEmitter, Input, Output } from "@angular/core";

import { IContact } from "../contacts/contacts.interfaces";
import { IGroup } from "../group.interfaces";
import { IGroupForm } from "../group.interfaces";
import { IGroupUser } from "passit-sdk-js/js/api.interfaces";

interface ISelectOptions {
  label: string;
  value: any;
}

@Component({
  selector: "group-add",
  styleUrls: [
    "../../list/secret-row/secret-row.component.scss",
    "../../list/list.component.scss"
  ],
  templateUrl: "./group-add.component.html",
})
export class GroupAddComponent {
  group: IGroup;
  @Input() contacts: IContact[];
  @Input() groupIsUpdating: boolean;
  @Input() groupIsUpdated: boolean;
  @Input() searchedContacts: ISelectOptions[];
  @Input() isPrivateOrgMode: boolean;
  @Input() contactLookup: ISelectOptions[];
  @Input() groupContacts: ISelectOptions[];
  @Input() groupForm: IGroupForm;
  @Input() showCreate: boolean;
  @Input() singleGroupPendingContacts: IGroupUser[];

  @Output() saveNew = new EventEmitter();
  @Output() hideAddSecretForm = new EventEmitter();
  @Output() contactSearch = new EventEmitter<string>();
  @Output() resetSearch = new EventEmitter();
  @Output() updateFormValues = new EventEmitter<IGroupForm>();

  public onGroupCreated: EventEmitter<boolean>;

  public onSave() {
    this.saveNew.emit();
  }
}
