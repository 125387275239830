import { NgModule } from "@angular/core";

export const COMPONENTS = [];

@NgModule({
  imports: [],
  declarations: COMPONENTS,
  exports: COMPONENTS,
  providers: []
})
export class ResetFormModule {}
