import { IPopupState } from "./popup.reducer";
import { createSelector, createFeatureSelector } from "@ngrx/store";
import { getSecrets } from "../../app.reducers";
import { filterOnSearch } from "../../secrets";

export const getSelectedSecret = (state: IPopupState) => state.selectedSecret;
export const getSearch = (state: IPopupState) => state.search;
export const getCurrentUrl = (state: IPopupState) => state.currentUrl;
const getUsernameCopied = (state: IPopupState) => state.usernameCopied;
const getPasswordCopied = (state: IPopupState) => state.passwordCopied;

export const getPopupState = createFeatureSelector<IPopupState>("popup");
export const getPopupSelected = createSelector(
  getPopupState,
  getSelectedSecret
);
export const getPopupSearch = createSelector(getPopupState, getSearch);
export const getPopupCurrentUrl = createSelector(getPopupState, getCurrentUrl);
export const getPopupUsernameCopied = createSelector(
  getPopupState,
  getUsernameCopied
);
export const getPopupPasswordCopied = createSelector(
  getPopupState,
  getPasswordCopied
);

export const getPopupSecrets = createSelector(
  getPopupSearch,
  getSecrets,
  (search, secrets) => {
    return filterOnSearch(secrets, search);
  }
);

const extractHostname = (url: string) => {
  let hostname;
  // find & remove protocol (http, ftp, etc.) and get hostname

  if (url.indexOf("://") > -1) {
    hostname = url.split("/")[2];
  } else {
    hostname = url.split("/")[0];
  }

  // find & remove port number
  hostname = hostname.split(":")[0];
  // find & remove "?"
  hostname = hostname.split("?")[0];

  return hostname;
};

const extractRootDomain = (url: string) => {
  let domain = extractHostname(url);
  const splitArr = domain.split(".");
  const arrLen = splitArr.length;

  // extracting the root domain here
  // if there is a subdomain
  if (arrLen > 2) {
    domain = splitArr[arrLen - 2] + "." + splitArr[arrLen - 1];
    // check to see if it's using a Country Code Top Level Domain (ccTLD) (i.e. ".me.uk")
    if (
      splitArr[arrLen - 1].length === 2 &&
      splitArr[arrLen - 1].length === 2
    ) {
      // this is using a ccTLD
      domain = splitArr[arrLen - 3] + "." + domain;
    }
  }
  return domain;
};

/** Get secrets that match the current url - which may be more relevant for a user */
export const getMatchedSecrets = createSelector(
  getPopupSecrets,
  getPopupCurrentUrl,
  (secrets, url) => {
    if (!url) {
      return [];
    }

    url = extractRootDomain(url);
    return secrets.filter(secret => {
      if (secret.data.url !== undefined) {
        return extractRootDomain(secret.data.url!) === url;
      }
    });
  }
);
