
    <login-component
      [form]="form$ | async"
      [hasLoginStarted]="hasLoginStarted$ | async"
      [hasLoginFinished]="hasLoginFinished$ | async"
      [errorMessage]="errorMessage$ | async"
      [isPopup]="isPopup"
      (login)="login()"
      (goToRegister)="goToRegister()"
    ></login-component>
  