import {
  createFormGroupState,
  FormGroupState,
  validate,
  createFormStateReducerWithUpdate,
  updateGroup
} from "ngrx-forms";
import { oldPasswordValidators } from "../constants";
import {
  DeleteAccountActions,
  DeleteAccountActionTypes
} from "./delete.actions";
export const FORM_ID = "Delete Account Form";

export interface IDeleteAccountForm {
  password: string;
}

export interface IDeleteAccountState {
  form: FormGroupState<IDeleteAccountForm>;
  displayDeleteErrors: boolean;
}

const initialFormState = createFormGroupState<IDeleteAccountForm>(FORM_ID, {
  password: ""
});

const initialState = {
  form: initialFormState,
  displayDeleteErrors: false
};

export const validateAndUpdateFormState = updateGroup<IDeleteAccountForm>({
  password: validate<string>(oldPasswordValidators)
});

export const formReducer = createFormStateReducerWithUpdate<IDeleteAccountForm>(
  validateAndUpdateFormState
);

export function reducer(
  state = initialState,
  action: DeleteAccountActions
): IDeleteAccountState {
  const form = formReducer(state.form, action);
  if (form !== state.form) {
    state = { ...state, form };
  }
  switch (action.type) {
    case DeleteAccountActionTypes.DELETE_FAILURE:
      return {
        ...state,
        form: initialFormState,
        displayDeleteErrors: true
      };

    case DeleteAccountActionTypes.RESET_STATE:
      return initialState;

    default:
      return state;
  }
}

export const getForm = (state: IDeleteAccountState) => state.form;
export const getDeleteFormErrors = (state: IDeleteAccountState) =>
  state.displayDeleteErrors;
