import { Injectable } from "@angular/core";
import { Effect, Actions, ofType } from "@ngrx/effects";
import {
  SaveForm,
  ErrorReportingTypes,
  SaveFormSuccess,
  SaveFormFailure,
  ClearFinished
} from "./error-reporting.actions";
import {
  withLatestFrom,
  map,
  exhaustMap,
  filter,
  switchMap
} from "rxjs/operators";
import * as fromRoot from "../../app.reducers";
import { Store } from "@ngrx/store";
import {
  getErrorReportingForm,
  getErrorReportingHasFinished
} from "../account.reducer";
import { UserService } from "../user";
import { timer } from "rxjs";

@Injectable()
export class ErrorReportingEffects {
  @Effect()
  save$ = this.actions$.pipe(
    ofType<SaveForm>(ErrorReportingTypes.SAVE_FORM),
    withLatestFrom(this.store.select(getErrorReportingForm)),
    map(([action, form]) => form),
    exhaustMap(form =>
      this.userService
        .setErrorReporting(form.controls.optInErrorReporting.value)
        .then(user => new SaveFormSuccess(user))
        .catch(() => new SaveFormFailure())
    )
  );

  /** Clear the finished indicator after a little bit */
  @Effect()
  fadeFinished$ = this.store.select(getErrorReportingHasFinished).pipe(
    filter(hasFinished => hasFinished === true),
    switchMap(() => timer(2000).pipe(map(() => new ClearFinished())))
  );

  constructor(
    private actions$: Actions,
    private store: Store<fromRoot.IState>,
    private userService: UserService
  ) {}
}
