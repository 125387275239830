import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Store } from "@ngrx/store";
import { combineLatest, Observable } from "rxjs";
import { map } from "rxjs/operators";

import * as fromRoot from "../app.reducers";
import * as fromGroup from "../group/group.reducer";
import { GetGroupsAction } from "../group/group.actions";
import { ISelectOptions } from "../group/group-form/group-form.interfaces";
import * as fromList from "./list.reducer";

import { ResetFormContainer } from "../form/reset-form.container";
import * as list from "../list/list.actions";
import { filterOnSearch, filterOnSelectGroup } from "../secrets";
import * as secretActions from "../secrets/secret.actions";

import * as api from "passit-sdk-js/js/api.interfaces";

@Component({
  selector: "secret-list-container",
  template: `
    <secret-list-component
      [secrets]="secrets$ | async"
      [groups]="groups$ | async"
      [groupOptions]="groupOptions$ | async"
      [selectedGroupId]="selectedGroupId$ | async"
      [totalSecretsCount]="totalSecretsCount$ | async"
      [secretManaged]="secretManaged$ | async"
      [showCreate]="showCreate$ | async"
      [secretIdFromUrl]="secretIdFromUrl"
      [searchText]="searchText$ | async"
      [firstTimeLoadingComplete]="firstTimeLoadingComplete$ | async"
      (secretWasSelected)="secretWasSelected($event)"
      (hideAddSecretForm)="hideAddSecretForm()"
      (showAddSecretForm)="showAddSecretForm()"
      (updateSelectedGroup)="updateSelectedGroup($event)"
      (searchUpdate)="searchUpdate($event)"
      [firstTimeLoadingComplete]="firstTimeLoadingComplete$ | async"
    ></secret-list-component>
  `
})
export class SecretListContainer extends ResetFormContainer implements OnInit {
  showCreate$ = this.store.select(fromList.getListShowCreate);
  secrets$: Observable<api.ISecret[]>;
  groups$: Observable<api.IGroup[]>;
  selectedGroupId$: Observable<number | null>;
  groupOptions$: Observable<ISelectOptions[]>;
  secretManagedObject$: Observable<api.ISecret | undefined>;
  secretManaged: number | null;
  secretManaged$ = this.store.select(fromList.getListSecretManaged);
  totalSecretsCount$ = this.store.select(fromRoot.getSecretsCount);
  searchText$ = this.store.select(fromList.getSearchText);
  secretIdFromUrl: number | null = null;
  firstTimeLoadingComplete$ = this.store.select(
    fromList.getFirstTimeLoadingComplete
  );

  constructor(
    public store: Store<fromRoot.IState>,
    private route: ActivatedRoute
  ) {
    super(store);
    store
      .select(fromList.getListSecretManaged)
      .subscribe(secretId => (this.secretManaged = secretId));
    const secrets$ = combineLatest(
      this.store.select(fromRoot.getSecrets),
      this.searchText$,
      this.secretManaged$
    ).pipe(map(result => filterOnSearch(result[0], result[1], result[2]!)));

    this.secrets$ = combineLatest(
      this.store.select(fromList.getSelectedGroup),
      secrets$
    ).pipe(map(result => filterOnSelectGroup(result[0], result[1])));

    // Mobile app needs this.
    this.secretManagedObject$ = combineLatest(
      this.secrets$,
      this.secretManaged$
    ).pipe(
      map(([secrets, secretManagedId]) =>
        secrets.find(secret => secret.id === secretManagedId)
      )
    );

    this.groups$ = this.store.select(fromGroup.getGroups);
    this.groupOptions$ = this.store.select(fromGroup.getActiveGroupsAsOptions);
    this.selectedGroupId$ = this.store.select(fromList.getSelectedGroup);
  }

  public ngOnInit() {
    super.ngOnInit();
    this.getSecrets();
    // web ext popup uses this
    this.route.params.subscribe(params => {
      if (params["id"]) {
        const secretId = Number(params["id"]);
        this.secretIdFromUrl = secretId;
        this.store.dispatch(new list.SetManagedSecret(secretId));
      }
    });
    this.getGroups();
  }

  /** Trigger refresh of secrets data */
  public getSecrets() {
    this.store.dispatch(new secretActions.GetSecretsAction());
  }

  public getGroups() {
    this.store.dispatch(new GetGroupsAction());
  }

  public updateSelectedGroup(groupId: number | null) {
    this.store.dispatch(new list.UpdateSelectedGroup(groupId));
  }

  /*
  * receive secret from child when secret clicked
  */
  public secretWasSelected(secretId: number) {
    if (secretId === this.secretManaged) {
      this.store.dispatch(new list.ClearManagedSecret());
    } else {
      this.store.dispatch(new list.SetManagedSecret(secretId));
    }
  }

  public searchUpdate(term: string) {
    this.store.dispatch(new list.SetSearchText(term));
  }

  showAddSecretForm() {
    this.store.dispatch(new list.ShowCreate());
  }

  hideAddSecretForm() {
    this.store.dispatch(new list.HideCreate());
  }
}
