/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./popup.component.ngfactory";
import * as i2 from "./popup.component";
import * as i3 from "@angular/common";
import * as i4 from "./popup.container";
import * as i5 from "@ngrx/store";
import * as i6 from "../../secrets/secret.service";
import * as i7 from "angular2-hotkeys/src/hotkeys.service";
var styles_PopupContainer = [];
var RenderType_PopupContainer = i0.ɵcrt({ encapsulation: 2, styles: styles_PopupContainer, data: {} });
export { RenderType_PopupContainer as RenderType_PopupContainer };
export function View_PopupContainer_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 7, "app-popup", [], null, [[null, "setSelected"], [null, "closeSelected"], [null, "searchUpdate"], [null, "openUrl"], [null, "signIn"], [null, "onCopyUsername"], [null, "onCopyPassword"], [null, "onDetail"], [null, "openFullApp"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("setSelected" === en)) {
        var pd_0 = (_co.setSelected($event) !== false);
        ad = (pd_0 && ad);
    } if (("closeSelected" === en)) {
        var pd_1 = (_co.closeSelected() !== false);
        ad = (pd_1 && ad);
    } if (("searchUpdate" === en)) {
        var pd_2 = (_co.searchUpdate($event) !== false);
        ad = (pd_2 && ad);
    } if (("openUrl" === en)) {
        var pd_3 = (_co.openUrl($event) !== false);
        ad = (pd_3 && ad);
    } if (("signIn" === en)) {
        var pd_4 = (_co.signIn($event) !== false);
        ad = (pd_4 && ad);
    } if (("onCopyUsername" === en)) {
        var pd_5 = (_co.copyUsername($event) !== false);
        ad = (pd_5 && ad);
    } if (("onCopyPassword" === en)) {
        var pd_6 = (_co.copyPassword($event) !== false);
        ad = (pd_6 && ad);
    } if (("onDetail" === en)) {
        var pd_7 = (_co.openDetails($event) !== false);
        ad = (pd_7 && ad);
    } if (("openFullApp" === en)) {
        var pd_8 = (_co.openFullApp() !== false);
        ad = (pd_8 && ad);
    } return ad; }, i1.View_PopupComponent_0, i1.RenderType_PopupComponent)), i0.ɵdid(1, 49152, null, 0, i2.PopupComponent, [], { secrets: [0, "secrets"], selectedSecret: [1, "selectedSecret"], formFillMessage: [2, "formFillMessage"], search: [3, "search"], matchedSecrets: [4, "matchedSecrets"], usernameCopied: [5, "usernameCopied"], passwordCopied: [6, "passwordCopied"] }, { setSelected: "setSelected", closeSelected: "closeSelected", searchUpdate: "searchUpdate", openUrl: "openUrl", signIn: "signIn", onCopyUsername: "onCopyUsername", onCopyPassword: "onCopyPassword", onDetail: "onDetail", openFullApp: "openFullApp" }), i0.ɵpid(131072, i3.AsyncPipe, [i0.ChangeDetectorRef]), i0.ɵpid(131072, i3.AsyncPipe, [i0.ChangeDetectorRef]), i0.ɵpid(131072, i3.AsyncPipe, [i0.ChangeDetectorRef]), i0.ɵpid(131072, i3.AsyncPipe, [i0.ChangeDetectorRef]), i0.ɵpid(131072, i3.AsyncPipe, [i0.ChangeDetectorRef]), i0.ɵpid(131072, i3.AsyncPipe, [i0.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 1, 0, i0.ɵnov(_v, 2).transform(_co.secrets$)); var currVal_1 = i0.ɵunv(_v, 1, 1, i0.ɵnov(_v, 3).transform(_co.selectedSecret$)); var currVal_2 = _co.formFillMessage; var currVal_3 = i0.ɵunv(_v, 1, 3, i0.ɵnov(_v, 4).transform(_co.search$)); var currVal_4 = i0.ɵunv(_v, 1, 4, i0.ɵnov(_v, 5).transform(_co.matchedSecrets$)); var currVal_5 = i0.ɵunv(_v, 1, 5, i0.ɵnov(_v, 6).transform(_co.usernameCopied$)); var currVal_6 = i0.ɵunv(_v, 1, 6, i0.ɵnov(_v, 7).transform(_co.passwordCopied$)); _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }, null); }
export function View_PopupContainer_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-popup-container", [], null, null, null, View_PopupContainer_0, RenderType_PopupContainer)), i0.ɵdid(1, 114688, null, 0, i4.PopupContainer, [i5.Store, i6.SecretService, i7.HotkeysService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PopupContainerNgFactory = i0.ɵccf("app-popup-container", i4.PopupContainer, View_PopupContainer_Host_0, {}, {}, []);
export { PopupContainerNgFactory as PopupContainerNgFactory };
