import { Action } from "@ngrx/store";

export enum ConfirmEmailTypes {
  VERIFY_EMAIL = "[Confirm email] Veify Email",
  VERIFY_EMAIL_SUCCESS = "[Confirm email] Veify Email Success",
  VERIFY_EMAIL_FAILURE = "[Confirm email] Veify Email Failure",
  RESET_REGISTER_CODE = "[Confirm email] ResetRegisterCode",
  RESET_REGISTER_CODE_SUCCESS = "[Confirm email] Success",
  RESET_REGISTER_CODE_FAILURE = "[Confirm email] Failure"
}

export class VerifyEmail implements Action {
  readonly type = ConfirmEmailTypes.VERIFY_EMAIL;

  constructor(public payload: string) {}
}

export class VerifyEmailSuccess implements Action {
  readonly type = ConfirmEmailTypes.VERIFY_EMAIL_SUCCESS;
}

export class VerifyEmailFailure implements Action {
  readonly type = ConfirmEmailTypes.VERIFY_EMAIL_FAILURE;

  constructor(public payload: string) {}
}

export class ResetRegisterCodeAction implements Action {
  readonly type = ConfirmEmailTypes.RESET_REGISTER_CODE;
}

export class ResetRegisterCodeSuccessAction implements Action {
  readonly type = ConfirmEmailTypes.RESET_REGISTER_CODE_SUCCESS;
}

export class ResetRegisterCodeFailureAction implements Action {
  readonly type = ConfirmEmailTypes.RESET_REGISTER_CODE_FAILURE;
}

export type ConfirmEmailActions =
  | VerifyEmail
  | VerifyEmailSuccess
  | VerifyEmailFailure
  | ResetRegisterCodeAction
  | ResetRegisterCodeSuccessAction
  | ResetRegisterCodeFailureAction;
