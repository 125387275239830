<div
  class="save-progress"
  [class.save-progress--input-action]="inputAction">
  <div class="save-progress__group" [class.save-progress__group--visible]="inProgress">
    <div class="save-progress__icon save-progress__icon--saving" [inlineSVG]="'../assets/svg/spinner.svg'"></div>
    <span class="save-progress__text save-progress__text--saving">
      {{ inProgressText }}
    </span>
  </div>
  <div class="save-progress__group" [class.save-progress__group--visible]="completed">
    <div class="save-progress__icon save-progress__icon--saved" [inlineSVG]="'../assets/svg/lock.svg'"></div>
    <span class="save-progress__text save-progress__text--saved">
      {{ completedText }}
    </span>
  </div>
</div>