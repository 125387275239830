<div
  *ngIf="showCreate"
  class="secret-list-item secret-list-item--add-form"
  [class.secret-list-item--is-active]="showCreate">

  <h2 class="secret__title secret__title--add">
    Add New Group
  </h2>

  <group-form
    isNew="true"
    [contacts]="contacts"
    [groupIsUpdating]="groupIsUpdating"
    [groupIsUpdated]="groupIsUpdated"
    [groupForm]="groupForm"
    [isPrivateOrgMode]="isPrivateOrgMode"
    [groupContacts]="groupContacts"
    [searchedContacts]="searchedContacts"
    [contactLookup]="contactLookup"
    [singleGroupPendingContacts]="singleGroupPendingContacts"
    (hideAddSecretForm)="hideAddSecretForm.emit()"
    (keyup)="contactSearch.emit($event.target.value)"
    (save)="onSave()"
    (updateFormValues)="updateFormValues.emit($event)"
    (resetSearch)="resetSearch.emit($event)"
    class="secret-form secret-form--is-active"
  ></group-form>
</div>