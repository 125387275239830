import { Action } from "@ngrx/store";

import { IContact } from "./contacts.interfaces";

export enum ContactsActionTypes {
  GET_CONTACTS = "Get Contacts",
  SET_CONTACTS = "Set Contacts"
}

export class SetContactsAction implements Action {
  readonly type = ContactsActionTypes.SET_CONTACTS;

  constructor(public payload: IContact[]) {}
}

export class GetContactsAction implements Action {
  readonly type = ContactsActionTypes.GET_CONTACTS;
}

export type ContactsActionsUnion = SetContactsAction | GetContactsAction;
