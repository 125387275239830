import { mergeMap, withLatestFrom, map, catchError } from "rxjs/operators";
import { Injectable } from "@angular/core";
import { Actions, Effect } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { of, EMPTY } from "rxjs";
import { HandleAPIErrorAction } from "../account/account.actions";
import { IState } from "../app.reducers";
import * as fromRoot from "../app.reducers";

import { ConfTypes, SetConfAction } from "./conf.actions";
import { GetConfService } from "./get-conf.service";

@Injectable()
export class GetConfEffects {
  @Effect()
  getConf$ = this.actions$.ofType(ConfTypes.GET_CONF).pipe(
    withLatestFrom(this.store.select(fromRoot.getTimestamp)),
    map(([action, timestamp]) => timestamp),
    mergeMap(timestamp => {
      let performUpdate = false;
      if (timestamp === null) {
        performUpdate = true;
      } else {
        const timeNow = Date.now() / (1000 * 60 * 60);
        const timeStampHours = new Date(timestamp).getTime() / (1000 * 60 * 60);
        const timePassed = timeNow - timeStampHours;
        if (timePassed > 24) {
          performUpdate = true;
        }
      }

      if (performUpdate === true) {
        return this.getConfService.getConf().pipe(
          map(resp => new SetConfAction(resp)),
          catchError(error => of(new HandleAPIErrorAction(error)))
        );
      } else {
        return EMPTY;
      }
    })
  );

  constructor(
    private actions$: Actions,
    private getConfService: GetConfService,
    private store: Store<IState>
  ) {}
}
