var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Actions, Effect, ofType } from "@ngrx/effects";
import { SecretService } from "../../secrets/secret.service";
import { PopupActionTypes, ClearCopied } from "./popup.actions";
import { map, tap, switchMap } from "rxjs/operators";
import { copyToClipboard } from "../../utils";
import { timer } from "rxjs";
var PopupEffects = /** @class */ (function () {
    function PopupEffects(actions$, secretService) {
        var _this = this;
        this.actions$ = actions$;
        this.secretService = secretService;
        this.copyUsername$ = this.actions$.pipe(ofType(PopupActionTypes.COPY_USERNAME), map(function (action) { return action.payload; }), tap(function (secret) {
            return copyToClipboard(secret.data["username"]);
        }), switchMap(function () {
            return timer(1000).pipe(map(function () { return new ClearCopied(); }));
        }));
        this.copyPassword$ = this.actions$.pipe(ofType(PopupActionTypes.COPY_PASSWORD), map(function (action) { return action.payload; }), tap(function (secret) {
            _this.secretService.showSecret(secret).then(function (decrypted) {
                copyToClipboard(decrypted["password"]);
            });
        }), switchMap(function () {
            return timer(1000).pipe(map(function () { return new ClearCopied(); }));
        }));
    }
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], PopupEffects.prototype, "copyUsername$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], PopupEffects.prototype, "copyPassword$", void 0);
    return PopupEffects;
}());
export { PopupEffects };
