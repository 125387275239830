import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { InlineSVGModule } from "ng-inline-svg";
import { TooltipModule } from "ngx-tooltip";

import { DemoComponent, NavbarComponent, NavbarContainer } from "./navbar/";
import { SearchComponent } from "./search";
import { NgrxFormsModule } from "ngrx-forms";
import { CheckboxComponent } from "./checkbox/checkbox.component";
import { NonFieldMessagesComponent } from "./non-field-messages/non-field-messages.component";

export const COMPONENTS = [
  NavbarContainer,
  NavbarComponent,
  SearchComponent,
  CheckboxComponent,
  DemoComponent,
  NonFieldMessagesComponent
];

@NgModule({
  imports: [CommonModule, RouterModule, InlineSVGModule, TooltipModule, NgrxFormsModule],
  declarations: COMPONENTS,
  exports: COMPONENTS
})
export class SharedModule {}
