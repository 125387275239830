/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./login.component.ngfactory";
import * as i2 from "./login.component";
import * as i3 from "@angular/common";
import * as i4 from "./login.container";
import * as i5 from "@angular/router";
import * as i6 from "@ngrx/store";
var styles_LoginContainer = [];
var RenderType_LoginContainer = i0.ɵcrt({ encapsulation: 2, styles: styles_LoginContainer, data: {} });
export { RenderType_LoginContainer as RenderType_LoginContainer };
export function View_LoginContainer_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "login-component", [], null, [[null, "login"], [null, "goToRegister"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("login" === en)) {
        var pd_0 = (_co.login() !== false);
        ad = (pd_0 && ad);
    } if (("goToRegister" === en)) {
        var pd_1 = (_co.goToRegister() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i1.View_LoginComponent_0, i1.RenderType_LoginComponent)), i0.ɵdid(1, 49152, null, 0, i2.LoginComponent, [], { form: [0, "form"], errorMessage: [1, "errorMessage"], hasLoginStarted: [2, "hasLoginStarted"], hasLoginFinished: [3, "hasLoginFinished"], isPopup: [4, "isPopup"] }, { login: "login", goToRegister: "goToRegister" }), i0.ɵpid(131072, i3.AsyncPipe, [i0.ChangeDetectorRef]), i0.ɵpid(131072, i3.AsyncPipe, [i0.ChangeDetectorRef]), i0.ɵpid(131072, i3.AsyncPipe, [i0.ChangeDetectorRef]), i0.ɵpid(131072, i3.AsyncPipe, [i0.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 1, 0, i0.ɵnov(_v, 2).transform(_co.form$)); var currVal_1 = i0.ɵunv(_v, 1, 1, i0.ɵnov(_v, 3).transform(_co.errorMessage$)); var currVal_2 = i0.ɵunv(_v, 1, 2, i0.ɵnov(_v, 4).transform(_co.hasLoginStarted$)); var currVal_3 = i0.ɵunv(_v, 1, 3, i0.ɵnov(_v, 5).transform(_co.hasLoginFinished$)); var currVal_4 = _co.isPopup; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); }, null); }
export function View_LoginContainer_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "ng-component", [], null, null, null, View_LoginContainer_0, RenderType_LoginContainer)), i0.ɵdid(1, 49152, null, 0, i4.LoginContainer, [i5.Router, i6.Store], null, null)], null, null); }
var LoginContainerNgFactory = i0.ɵccf("ng-component", i4.LoginContainer, View_LoginContainer_Host_0, {}, {}, []);
export { LoginContainerNgFactory as LoginContainerNgFactory };
