import { Component, EventEmitter, Input, Output, ChangeDetectionStrategy } from "@angular/core";
import { ISecret } from "passit-sdk-js/js/api.interfaces";

@Component({
  selector: "app-popup-item",
  templateUrl: "./popup-item.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ["./popup-item.component.scss"]
})
export class PopupItemComponent {
  @Input() secret: ISecret;
  @Input() formFillMessage: string;
  @Input() isMatched = false;
  @Input() isSelected = false;
  @Input() passwordCopied = false;
  @Input() usernameCopied = false;
  @Output() openUrl = new EventEmitter();
  @Output() autofill = new EventEmitter();
  @Output() onCopyUsername = new EventEmitter();
  @Output() onCopyPassword = new EventEmitter();
  @Output() onDetail = new EventEmitter();
  @Output() setSelected = new EventEmitter<number>();
  @Output() closeSelected = new EventEmitter();

  clickAutofill(event: Event) {
    event.stopPropagation();
    this.autofill.emit();
  }

  clickUsername(event: Event) {
    event.stopPropagation();
    this.onCopyUsername.emit();
  }

  clickPassword(event: Event) {
    event.stopPropagation();
    if (this.secret.secret_through_set[0].data.password) {
      this.onCopyPassword.emit();
    }
  }
}
