import { Injectable } from "@angular/core";
import { Actions, Effect, ofType } from "@ngrx/effects";

import { of, from } from "rxjs";
import {
  exhaustMap,
  withLatestFrom,
  map,
  mergeMap,
  debounceTime,
  catchError
} from "rxjs/operators";
import {
  GetContactsAction,
  SetContactsAction,
  ContactsActionTypes
} from "./contacts/contacts.actions";

import { Store } from "@ngrx/store";
import { HandleAPIErrorAction } from "../account/account.actions";
import { IGroupState } from "../group/group.reducer";
import * as fromGroup from "../group/group.reducer";
import * as fromAccount from "../account/account.reducer";
import { IContact } from "./contacts/contacts.interfaces";
import { ContactsService } from "./contacts/contacts.service";
import {
  GroupActionTypes,
  ContactLookupAction,
  ContactLookupFailureAction,
  ContactLookupSuccessAction,
  CreateGroupAction,
  CreateGroupSuccessAction,
  DeleteGroupAction,
  DeleteGroupSuccessAction,
  SetGroupsAction,
  UpdateGroupAction,
  UpdateGroupSuccessAction,
  AcceptGroupInvite,
  AcceptGroupInviteSuccess,
  DeclineGroupInvite,
  DeclineGroupInviteSuccess
} from "./group.actions";
import { GroupService } from "./group.service";
import { IGroupForm } from "./group.interfaces";

@Injectable()
export class GroupEffects {
  @Effect()
  getGroups$ = this.actions$.pipe(
    ofType(
      GroupActionTypes.GET_GROUPS,
      GroupActionTypes.CREATE_GROUP_SUCCESS,
      GroupActionTypes.DELETE_GROUP_SUCCESS,
      GroupActionTypes.UPDATE_GROUP_SUCCESS,
      GroupActionTypes.ACCEPT_GROUP_INVITE_SUCCESS,
      GroupActionTypes.DECLINE_GROUP_INVITE_SUCCESS
    ),
    exhaustMap(() =>
      from(this.groupService.getGroups()).pipe(
        map(groups => new SetGroupsAction(groups)),
        catchError(error => of(new HandleAPIErrorAction(error)))
      )
    )
  );

  @Effect()
  createGroup$ = this.actions$.pipe(
    ofType<CreateGroupAction>(GroupActionTypes.CREATE_GROUP),
    withLatestFrom(
      this.store.select(fromGroup.getGroupForm),
      this.store.select(fromGroup.getCombinedContacts),
      this.store.select(fromAccount.getEmail),
      this.store.select(fromAccount.getUserId)
    ),
    map(
      ([action, groupForm, contacts, ownEmail, ownUserId]): [
        IGroupForm,
        IContact[]
      ] => {
        const members = groupForm.members.map(member => {
          if (member === ownUserId) {
            const myself: IContact = {
              id: ownUserId,
              email: ownEmail!
            };
            return myself;
          }
          const contact = contacts.find(contact_ => contact_.id === member);
          if (contact) {
            return contact;
          } else {
            throw new Error("Unable to lookup email for " + member);
          }
        });
        if (!groupForm.members.includes(ownUserId!)) {
          members.push({
            id: ownUserId!,
            email: ownEmail!
          });
        }
        return [groupForm, members];
      }
    ),
    mergeMap(([groupForm, members]) => {
      return this.groupService
        .create(groupForm, members)
        .then(() => new CreateGroupSuccessAction());
    })
  );

  @Effect()
  deleteGroup$ = this.actions$.pipe(
    ofType<DeleteGroupAction>(GroupActionTypes.DELETE_GROUP),
    map(action => action.payload),
    exhaustMap(groupId =>
      this.groupService
        .deleteGroup(groupId)
        .then(() => new DeleteGroupSuccessAction())
    )
  );

  @Effect()
  updateGroup$ = this.actions$.pipe(
    ofType<UpdateGroupAction>(GroupActionTypes.UPDATE_GROUP),
    withLatestFrom(
      this.store.select(fromGroup.getGroupManaged),
      this.store.select(fromGroup.getGroupForm),
      this.store.select(fromGroup.getCombinedContacts)
    ),
    mergeMap(([action, groupManaged, groupForm, contacts]) => {
      const members = groupForm.members.map(member => {
        const contact = contacts.find(contact_ => contact_.id === member);
        if (contact) {
          return contact;
        } else {
          throw new Error("Unable to lookup email for " + member);
        }
      });
      return this.groupService
        .updateGroupMembers(groupManaged!, members)
        .then(() =>
          this.groupService
            .update(groupManaged!, groupForm)
            .then(() => new UpdateGroupSuccessAction())
        );
    })
  );

  @Effect()
  getContacts$ = this.actions$.pipe(
    ofType<GetContactsAction>(
      ContactsActionTypes.GET_CONTACTS,
      GroupActionTypes.UPDATE_GROUP_SUCCESS,
      GroupActionTypes.CREATE_GROUP_SUCCESS
    ),
    mergeMap(() =>
      this.contactsService.getContacts().pipe(
        map((contacts: IContact[]) => new SetContactsAction(contacts)),
        catchError(error => of(new HandleAPIErrorAction(error)))
      )
    )
  );

  @Effect()
  contactLookup$ = this.actions$.pipe(
    ofType<ContactLookupAction>(GroupActionTypes.CONTACT_LOOKUP),
    debounceTime(300),
    map(action => action.payload),
    mergeMap(email => {
      return this.contactsService.contactLookup(email).pipe(
        map(
          resp =>
            new ContactLookupSuccessAction({
              value: resp,
              label: `${email}`,
              disabled: false
            })
        ),
        catchError(error => of(new ContactLookupFailureAction()))
      );
    })
  );

  @Effect()
  acceptGroupInvite = this.actions$.pipe(
    ofType<AcceptGroupInvite>(GroupActionTypes.ACCEPT_GROUP_INVITE),
    exhaustMap(action =>
      from(
        this.groupService.acceptGroup(action.payload)
      ).pipe(
        map(() => new AcceptGroupInviteSuccess()),
        catchError(error => of(new HandleAPIErrorAction(error)))
      )
    )
  );

  @Effect()
  declineGroupInvite = this.actions$.pipe(
    ofType<DeclineGroupInvite>(GroupActionTypes.DECLINE_GROUP_INVITE),
    exhaustMap(action =>
      from(
        this.groupService.declineGroup(action.payload)
      ).pipe(
        map(() => new DeclineGroupInviteSuccess()),
        catchError(error => of(new HandleAPIErrorAction(error)))
      )
    )
  );

  constructor(
    private actions$: Actions,
    private groupService: GroupService,
    private contactsService: ContactsService,
    private store: Store<IGroupState>
  ) {}
}
