import { Component, Input } from "@angular/core";

@Component({
  selector: "progress-indicator",
  templateUrl: "./progress-indicator.component.html",
  styleUrls: ["./progress-indicator.styles.scss"]
})
export class ProgressIndicatorComponent {
  @Input() inProgress: boolean;
  @Input() inProgressText: string;
  @Input() completed: boolean;
  @Input() completedText: string;
  @Input() inputAction = false;
}
