import {
  createFormGroupState,
  createFormStateReducerWithUpdate,
  FormGroupState,
  validate,
  ValidationErrors,
  updateGroup
} from "ngrx-forms";
import { oldPasswordValidators, passwordValidators } from "../constants";
import { Action } from "@ngrx/store";
import { equalTo, required } from "ngrx-forms/validation";

export const FORM_ID = "Change Password Form";

export interface IChangePasswordForm {
  oldPassword: string;
  newPassword: string;
  newPasswordConfirm: string;
  showConfirm: boolean;
}

export interface IChangePasswordState {
  form: FormGroupState<IChangePasswordForm>;
}

export function notEqualTo<T>(comparand: T) {
  return (value: T): ValidationErrors => {
    if (value !== comparand) {
      return {};
    }

    return {
      notEqualTo: {
        comparand,
        actual: value
      }
    };
  };
}

export const validateAndUpdateFormState = updateGroup<IChangePasswordForm>({
  oldPassword: validate(oldPasswordValidators),
  newPassword: (newPassword, form) => {
    return validate(newPassword, [
      ...passwordValidators,
      notEqualTo(form.value.oldPassword)
    ]);
  },
  newPasswordConfirm: (passwordConfirm, form) => {
    if (form.controls.showConfirm.value) {
      return validate(
        passwordConfirm,
        required,
        equalTo(form.value.newPassword)
      );
    }
    return validate(passwordConfirm, []);
  }
});

const initialFormState = validateAndUpdateFormState(
  createFormGroupState<IChangePasswordForm>(FORM_ID, {
    oldPassword: "",
    newPassword: "",
    newPasswordConfirm: "",
    showConfirm: true
  })
);

const initialState = {
  form: initialFormState
};

const formReducer = createFormStateReducerWithUpdate<IChangePasswordForm>(
  validateAndUpdateFormState
);

export function reducer(
  state = initialState,
  action: Action
): IChangePasswordState {
  const form = formReducer(state.form, action);
  if (form !== state.form) {
    state = { ...state, form };
  }

  return state;
}

export const getForm = (state: IChangePasswordState) => state.form;
