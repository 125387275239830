var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { from } from "rxjs";
import { NgPassitSDK } from "../ngsdk/sdk";
var SecretService = /** @class */ (function () {
    function SecretService(sdk) {
        this.sdk = sdk;
    }
    /*
    * decrypt recieving just id number not whole object
    */
    SecretService.prototype.copySecret = function (secretId) {
        var _this = this;
        return this.sdk
            .get_secret(secretId)
            .then(function (data) {
            return _this.sdk.decrypt_secret(data).then(function (resp) {
                return resp;
            });
        })
            .catch(function (err) { return console.error(err); });
    };
    /*
    * send create secret post to sdk
    */
    SecretService.prototype.createSecrets = function (data) {
        return this.sdk.create_secret(data);
    };
    /*
    * delete seccret
    */
    SecretService.prototype.deleteSecret = function (secretId) {
        return this.sdk
            .delete_secret(secretId)
            .then(function (resp) {
            return resp;
        })
            .catch(function (err) {
            console.error(err);
        });
    };
    /*
    * get secrets
    */
    SecretService.prototype.getSecrets = function () {
        return from(this.sdk.list_secrets());
    };
    /*
    * get secret from id
    * then decrypt secret
    * so user can see unencrypted secret
    */
    SecretService.prototype.showSecret = function (secret) {
        var _this = this;
        return this.sdk.ready().then(function () {
            return _this.sdk
                .decrypt_secret(secret)
                .then(function (resp) { return resp; })
                .catch(function (err) {
                console.error(err);
            });
        });
    };
    /*
    * edit/update secret
    */
    SecretService.prototype.updateSecret = function (secret) {
        return this.sdk
            .update_secret(secret)
            .then(function (resp) { return resp; })
            .catch(function (err) {
            console.error(err);
            return err;
        });
    };
    /** Decrypt a secret without network access.
     * This function has interesting error handling. A secret should never fail to decrypt
     * under any circumstance in typical operation. But what if that does not happen? Let's assume
     * a bad actor joins a group and mangles some (but not all) of the secret's encrypted data.
     *
     * Assume we have two "secret throughs". One is filled with garbage data, the other is valid.
     * This function will catch exceptions if the first one fails. Then try the next. If all
     * fail then it will return the last caught error.
     */
    SecretService.prototype.showOfflineSecret = function (secret, groups) {
        return __awaiter(this, void 0, void 0, function () {
            var handledError, throughs, _loop_1, this_1, _i, throughs_1, through, state_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.sdk.ready()];
                    case 1:
                        _a.sent();
                        throughs = secret.secret_through_set.filter(function (through) { return through.is_mine === true; });
                        _loop_1 = function (through) {
                            var foundGroup, response, error_1;
                            return __generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0:
                                        foundGroup = groups.find(function (group) { return group.id === through.group; });
                                        _a.label = 1;
                                    case 1:
                                        _a.trys.push([1, 3, , 4]);
                                        return [4 /*yield*/, this_1.sdk.offline_decrypt_secret(through.key_ciphertext, through.data, foundGroup)];
                                    case 2:
                                        response = _a.sent();
                                        return [2 /*return*/, { value: response }];
                                    case 3:
                                        error_1 = _a.sent();
                                        // This should never happen!
                                        console.error("Failed to decrypt secret!", error_1);
                                        handledError = error_1;
                                        return [3 /*break*/, 4];
                                    case 4: return [2 /*return*/];
                                }
                            });
                        };
                        this_1 = this;
                        _i = 0, throughs_1 = throughs;
                        _a.label = 2;
                    case 2:
                        if (!(_i < throughs_1.length)) return [3 /*break*/, 5];
                        through = throughs_1[_i];
                        return [5 /*yield**/, _loop_1(through)];
                    case 3:
                        state_1 = _a.sent();
                        if (typeof state_1 === "object")
                            return [2 /*return*/, state_1.value];
                        _a.label = 4;
                    case 4:
                        _i++;
                        return [3 /*break*/, 2];
                    case 5: 
                    // A return here indicates an error, it should never return.
                    throw handledError;
                }
            });
        });
    };
    /** Compared selected groups with existing groups from the database
     * Then makes changes to make the secret in sync
     * Promise resolves when all changes are finished.
     * Changes happen asyncronously and are not transactional at this time
     */
    SecretService.prototype.updateGroupsForSecret = function (groupIds, secretId) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            _this.sdk.get_secret(secretId).then(function (secret) {
                var existingGroupIds = [];
                for (var _i = 0, _a = secret.secret_through_set; _i < _a.length; _i++) {
                    var through = _a[_i];
                    if (through.group) {
                        existingGroupIds.push(through.group);
                    }
                }
                var promises = [];
                for (var _b = 0, groupIds_1 = groupIds; _b < groupIds_1.length; _b++) {
                    var groupId = groupIds_1[_b];
                    // if new group not in existing group ids
                    if (existingGroupIds.indexOf(groupId) < 0) {
                        promises.push(_this.sdk.add_group_to_secret(groupId, secretId));
                    }
                }
                var _loop_2 = function (groupId) {
                    // if existing group id not in new group ids.
                    if (groupIds.indexOf(groupId) < 0) {
                        var secretThrough = secret.secret_through_set.find(function (through) { return through.group === groupId; });
                        promises.push(_this.sdk.remove_group_from_secret(secretId, secretThrough.id));
                    }
                };
                for (var _c = 0, existingGroupIds_1 = existingGroupIds; _c < existingGroupIds_1.length; _c++) {
                    var groupId = existingGroupIds_1[_c];
                    _loop_2(groupId);
                }
                Promise.all(promises).then(function () {
                    resolve();
                });
            });
        });
    };
    /**
     * Takes existing secrets that were just decrypted and assigns them to the
     * object that the secret form uses
     */
    SecretService.prototype.setPlaintextSecrets = function (secrets) {
        var populatedSecrets = {};
        Object.keys(secrets).forEach(function (secret) { return (populatedSecrets[secret] = secrets[secret]); });
        return populatedSecrets;
    };
    /**
     * Takes fields from the secret form that are meant to be encrypted and
     * adds them to an object that gets attached if there is a non-empty value.
     */
    SecretService.prototype.getPlaintextSecrets = function (fields, secrets) {
        // Only include secret keys if they were changed
        var secretsToExport = {};
        fields.forEach(function (field) {
            if (secrets[field] && secrets[field] !== "") {
                secretsToExport[field] = secrets[field];
            }
        });
        return secretsToExport;
    };
    return SecretService;
}());
export { SecretService };
