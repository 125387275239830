<div class="export l-container-narrow u-margin-tb-50">
  
  <a class="text-link text-link--left-caret heading-back-link" [routerLink]=" ['/account'] ">Account Management</a>

  <h2 class="heading-main heading-main--pad-bottom">
     Export Passwords
  </h2>
  <div class="text--large">
    Export a CSV file with columns: name, username, url, password, extra
  </div>
  <div>
    <form (ngSubmit)="onExport()" #importForm="ngForm">
      <button type="submit" class="button button--primary">Download CSV</button>
    </form>
  </div>
</div>