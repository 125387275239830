var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
import { createFeatureSelector, createSelector } from "@ngrx/store";
import { AccountActionTypes } from "./account.actions";
import * as fromConfirmEmail from "./confirm-email/confirm-email.reducer";
import * as fromLogin from "./login/login.reducer";
import { RegisterActionTypes } from "./register/register.actions";
import { ErrorReportingTypes } from "./error-reporting/error-reporting.actions";
import * as fromRegister from "./register/register.reducer";
import * as fromChangePassword from "./change-password/change-password.reducer";
import * as fromDeleteAccount from "./delete/delete.reducer";
import * as fromErrorReporting from "./error-reporting/error-reporting.reducer";
export var initialState = {
    email: null,
    userId: null,
    url: null,
    userToken: null,
    privateKey: null,
    publicKey: null,
    rememberMe: false,
    optInErrorReporting: false
};
export function authReducer(state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case AccountActionTypes.LOGIN:
        case RegisterActionTypes.REGISTER:
            // Clear any stale state (except the url)
            return __assign({}, initialState, { url: state.url });
        case AccountActionTypes.LOGIN_SUCCESS:
        case RegisterActionTypes.REGISTER_SUCCESS:
            return __assign({}, state, { email: action.payload.email, userId: action.payload.userId, userToken: action.payload.userToken, privateKey: action.payload.privateKey, publicKey: action.payload.publicKey, rememberMe: action.payload.rememberMe, optInErrorReporting: action.payload.optInErrorReporting });
        case AccountActionTypes.SET_URL:
            return Object.assign({}, state, {
                url: action.payload
            });
        case ErrorReportingTypes.SAVE_FORM_SUCCESS:
            return __assign({}, state, { optInErrorReporting: action.payload.opt_in_error_reporting });
        default:
            return state;
    }
}
export var reducers = {
    auth: authReducer,
    login: fromLogin.reducer,
    register: fromRegister.reducer,
    confirmEmail: fromConfirmEmail.reducer,
    changePassword: fromChangePassword.reducer,
    deleteAccount: fromDeleteAccount.reducer,
    errorReporting: fromErrorReporting.reducer
};
export var selectAccountState = createFeatureSelector("account");
export var selectLoginState = createSelector(selectAccountState, function (state) { return state.login; });
export var getLoginHasStarted = createSelector(selectLoginState, fromLogin.getHasStarted);
export var getLoginHasFinished = createSelector(selectLoginState, fromLogin.getHasFinished);
export var getLoginErrorMessage = createSelector(selectLoginState, fromLogin.getErrorMessage);
export var getLoginForm = createSelector(selectLoginState, fromLogin.getForm);
export var selectAuthState = createSelector(selectAccountState, function (state) { return state.auth; });
export var getIsLoggedIn = createSelector(selectAuthState, function (state) { return (state.userToken ? true : false); });
export var getToken = createSelector(selectAuthState, function (state) { return state.userToken; });
export var getEmail = createSelector(selectAuthState, function (state) { return state.email; });
export var getUserId = createSelector(selectAuthState, function (state) { return state.userId; });
export var getUrl = createSelector(selectAuthState, function (state) { return state.url; });
export var getOptInErrorReporting = createSelector(selectAuthState, function (state) { return state.optInErrorReporting; });
export var getLoginState = function (state) { return state.login; };
export var selectRegisterState = createSelector(selectAccountState, function (state) { return state.register; });
export var getRegisterForm = createSelector(selectRegisterState, fromRegister.getForm);
export var getUrlForm = createSelector(selectRegisterState, fromRegister.getUrlForm);
export var getHasSubmitStarted = createSelector(selectRegisterState, fromRegister.getHasSubmitStarted);
export var getHasSubmitFinished = createSelector(selectRegisterState, fromRegister.getHasSubmitFinished);
export var getRegisterErrorMessage = createSelector(selectRegisterState, fromRegister.getErrorMessage);
export var getStage = createSelector(selectRegisterState, fromRegister.getStage);
export var getUrlDisplayName = createSelector(selectRegisterState, fromRegister.getUrlDisplayName);
export var getShowUrl = createSelector(selectRegisterState, fromRegister.getShowUrl);
export var getRegisterIsEmailTaken = createSelector(selectRegisterState, fromRegister.getIsEmailTaken);
export var getisUrlValid = createSelector(selectRegisterState, fromRegister.getIsUrlValid);
export var selectConfirmEmailState = createSelector(selectAccountState, function (state) { return state.confirmEmail; });
export var getConfirmEmailMessage = createSelector(selectConfirmEmailState, fromConfirmEmail.getResetCodeMessage);
export var getConfirmIsVerified = createSelector(selectConfirmEmailState, fromConfirmEmail.getIsVerified);
export var getConfirmHasStarted = createSelector(selectConfirmEmailState, fromConfirmEmail.getHasStarted);
export var getConfirmHasFinished = createSelector(selectConfirmEmailState, fromConfirmEmail.getHasFinished);
export var getConfirmErrorMessage = createSelector(selectConfirmEmailState, fromConfirmEmail.getErrorMessage);
export var selectChangePasswordState = createSelector(selectAccountState, function (state) { return state.changePassword; });
export var getChangePasswordForm = createSelector(selectChangePasswordState, fromChangePassword.getForm);
export var selectDeleteAccountState = createSelector(selectAccountState, function (state) { return state.deleteAccount; });
export var getDeleteAccountForm = createSelector(selectDeleteAccountState, fromDeleteAccount.getForm);
export var getDeleteFormErrors = createSelector(selectDeleteAccountState, fromDeleteAccount.getDeleteFormErrors);
export var selectErrorReportingState = createSelector(selectAccountState, function (state) { return state.errorReporting; });
export var getErrorReportingForm = createSelector(selectErrorReportingState, fromErrorReporting.getForm);
export var getErrorReportingHasFinished = createSelector(selectErrorReportingState, fromErrorReporting.getHasFinished);
export var getErrorReportingHasStarted = createSelector(selectErrorReportingState, fromErrorReporting.getHasStarted);
