import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
  ElementRef,
  ChangeDetectionStrategy
} from "@angular/core";

import * as api from "passit-sdk-js/js/api.interfaces";

import { copyToClipboard } from "../../utils";
import { FormGroupState } from "ngrx-forms";
import { ISecretForm } from "./secret-form.reducer";

interface ISelectOptions {
  label: string;
  value: any;
}

@Component({
  selector: "secret-form-component",
  styleUrls: ["../secret-row/secret-row.component.scss"],
  templateUrl: "./secret-form.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SecretFormComponent {
  @Input() form: FormGroupState<ISecretForm>;
  @Input() errorMessage: string | null;
  @Input() isNew: boolean;
  @Input() isUpdating: boolean;
  @Input() isUpdated: boolean;
  @Input() passwordIsMasked: boolean;
  @Input() showNotes: boolean;
  @Input() usernameCopied: boolean;
  @Input() passwordCopied: boolean;

  @Output() togglePasswordIsMasked = new EventEmitter();
  @Output() setPasswordIsMasked = new EventEmitter<boolean>();
  @Output() toggleShowNotes = new EventEmitter();
  @Output() hideAddSecretForm = new EventEmitter();
  @Output() saveSecret = new EventEmitter<boolean>();
  @Output() deleteSecret = new EventEmitter();
  @Output() generatePassword = new EventEmitter();
  @Output() cancel = new EventEmitter();

  /* This is a work around for firefox. Both blur and click are triggered when clicking on the eye icon after clicking
  input. This prevents the eye icon from being toggled properly. blurIsFired is set when blur is triggered. */
  blurIsFired = false;
  groupOptions: ISelectOptions[] = [];

  @Input()
  set groups(groups: api.IGroup[]) {
    this.groupOptions = groups.map(group => {
      return {
        label: group.name,
        value: group.id
      };
    });
  }

  @ViewChild("realPasswordInput") realPasswordInput: ElementRef;

  constructor() {}

  onSubmit() {
    if (this.form.isValid) {
      this.saveSecret.emit(this.isNew);
    }
  }

  clickMaskedSecret() {
    this.togglePasswordIsMasked.emit();
    setTimeout(() => this.realPasswordInput.nativeElement.focus(), 0);
  }

  toggleViewSecret() {
    if (this.blurIsFired === false) {
      this.togglePasswordIsMasked.emit();
      setTimeout(() => this.realPasswordInput.nativeElement.focus(), 0);
    }
  }

  togglePassword() {
    this.blurIsFired = true;
    this.togglePasswordIsMasked.emit();
    setTimeout(() => (this.blurIsFired = false), 100);
  }

  onDeleteSecret() {
    if (window.confirm("Once it's deleted, it's gone forever. Is that okay?")) {
      this.deleteSecret.emit();
    }
  }

  copySecret() {
    this.triggerCopied("password");
    copyToClipboard(this.form.value.password);
  }

  realPasswordBlur() {
    this.setPasswordIsMasked.emit(true);
  }

  /** This one is stupid, window.open wiht noopener doesn't actually work...
   * See https://stackoverflow.com/questions/49276569/window-open-with-noopener-opens-a-new-window-instead-of-a-new-tab
   * This opens a url safely in the same manner of adding noopener in an anchor tag.
   */
  openUrl(url: string) {
    const yourWindow = window.open() as any;
    yourWindow.opener = null;
    yourWindow.location = url;
    yourWindow.target = "_blank";
  }

  goToUrl(value: string) {
    let url = value;
    if (!url.match(/^https?:\/\//)) {
      url = "http://" + url;
    }
    this.openUrl(url);
  }

  triggerCopied(field: string) {
    if (field === "username") {
      this.usernameCopied = true;
      setTimeout(() => {
        this.usernameCopied = false;
      }, 2000);
    }
    if (field === "password") {
      this.passwordCopied = true;
      setTimeout(() => {
        this.passwordCopied = false;
      }, 2000);
    }
  }
}
