import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { StoreModule } from "@ngrx/store";
import { InlineSVGModule } from "ng-inline-svg";
import { SelectModule } from "ng-select";
import { ClipboardModule } from "ngx-clipboard";

import { SecretListComponent } from "./list.component";
import { SecretListContainer } from "./list.container";
import { SecretAddComponent } from "./secret-add/secret-add.component";
import { SecretFormComponent } from "./secret-form/secret-form.component";
import { SecretRowComponent } from "./secret-row/secret-row.component";

import { reducers } from "./list.reducer";

import { SharedModule } from "../shared";
import { SecretFormContainer } from "./secret-form/secret-form.container";
import { NgrxFormsModule } from "ngrx-forms";
import { EffectsModule } from "@ngrx/effects";
import { SecretFormEffects } from "./secret-form/secret-form.effects";

export const COMPONENTS = [
  SecretListComponent,
  SecretListContainer,
  SecretAddComponent,
  SecretFormComponent,
  SecretFormContainer,
  SecretRowComponent
];

@NgModule({
  imports: [
    CommonModule,
    ClipboardModule,
    FormsModule,
    InlineSVGModule,
    RouterModule,
    NgrxFormsModule,
    SharedModule,
    SelectModule,
    StoreModule.forFeature("list", reducers),
    EffectsModule.forFeature([SecretFormEffects])
  ],
  declarations: COMPONENTS,
  exports: COMPONENTS,
  providers: []
})
export class ListModule {}
