import { Component } from "@angular/core";
import { Store } from "@ngrx/store";
import { IState } from "../app.reducers";
import { LogoutAction } from "./account.actions";

@Component({
  selector: "account",
  styleUrls: ["./account.component.scss"],
  templateUrl: "./account.component.html"
})
export class AccountComponent {
  constructor(private store: Store<IState>) {}

  logout() {
    this.store.dispatch(new LogoutAction());
  }
}
