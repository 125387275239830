import { map, take } from "rxjs/operators";
import { Injectable } from "@angular/core";
import { CanActivate, Router } from "@angular/router";
import { Store } from "@ngrx/store";

import * as fromAccount from "../account/account.reducer";
import { IS_EXTENSION } from "../constants";

@Injectable()
export class AlreadyLoggedInGuard implements CanActivate {
  constructor(
    private store: Store<fromAccount.IAuthState>,
    private router: Router
  ) {}

  public canActivate() {
    if (IS_EXTENSION) {
      return true;
    }
    return this.store.select(fromAccount.getIsLoggedIn).pipe(
      take(1),
      map(authed => {
        if (authed) {
          // I'd like to display a "already logged in" message here once we have a notification area
          this.router.navigate([""]);
          return false;
        }
        return true;
      })
    );
  }
}
