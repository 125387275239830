import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IGroup } from "passit-sdk-js/js/api.interfaces";

@Component({
  selector: 'app-group-empty-state',
  templateUrl: './group-empty-state.component.html',
  styleUrls: ['./group-empty-state.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GroupEmptyStateComponent {
  @Input() showCreate: boolean;
  @Input() groups: IGroup[];
  @Input() pendingInviteGroups: IGroup[];

  constructor() { }
}
