/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./change-password.component.ngfactory";
import * as i2 from "./change-password.component";
import * as i3 from "@angular/common";
import * as i4 from "./change-password.container";
import * as i5 from "@ngrx/store";
import * as i6 from "../user/user.service";
var styles_ChangePasswordContainer = [];
var RenderType_ChangePasswordContainer = i0.ɵcrt({ encapsulation: 2, styles: styles_ChangePasswordContainer, data: {} });
export { RenderType_ChangePasswordContainer as RenderType_ChangePasswordContainer };
export function View_ChangePasswordContainer_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "change-password", [], null, [[null, "changePassword"], [null, "toggleConfirm"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("changePassword" === en)) {
        var pd_0 = (_co.changePassword() !== false);
        ad = (pd_0 && ad);
    } if (("toggleConfirm" === en)) {
        var pd_1 = (_co.toggleConfirm() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i1.View_ChangePasswordComponent_0, i1.RenderType_ChangePasswordComponent)), i0.ɵdid(1, 49152, null, 0, i2.ChangePasswordComponent, [], { form: [0, "form"], hasStarted: [1, "hasStarted"], hasFinished: [2, "hasFinished"], nonFieldErrors: [3, "nonFieldErrors"] }, { changePassword: "changePassword", toggleConfirm: "toggleConfirm" }), i0.ɵpid(131072, i3.AsyncPipe, [i0.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 1, 0, i0.ɵnov(_v, 2).transform(_co.form$)); var currVal_1 = _co.hasStarted; var currVal_2 = _co.hasFinished; var currVal_3 = _co.nonFieldErrors; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3); }, null); }
export function View_ChangePasswordContainer_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "change-password-container", [], null, null, null, View_ChangePasswordContainer_0, RenderType_ChangePasswordContainer)), i0.ɵdid(1, 49152, null, 0, i4.ChangePasswordContainer, [i5.Store, i6.UserService], null, null)], null, null); }
var ChangePasswordContainerNgFactory = i0.ɵccf("change-password-container", i4.ChangePasswordContainer, View_ChangePasswordContainer_Host_0, {}, {}, []);
export { ChangePasswordContainerNgFactory as ChangePasswordContainerNgFactory };
