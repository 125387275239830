import { Component, Input, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Store } from "@ngrx/store";

import * as fromRoot from "../../app.reducers";
import { NgPassitSDK } from "../../ngsdk/sdk";
import * as fromAccount from "../account.reducer";
import * as emailActions from "./confirm-email.actions";

/**
 * Confirm email container
 */
@Component({
  selector: "confirm-email-container",
  template: `
    <confirm-email-component
      [hasStarted]="hasStarted$ | async"
      [hasFinished]="hasFinished$ | async"
      [errorMessage]="errorMessage$ | async"
      [confirmCodeMessage]="confirmCodeMessage$ | async"
      [code]="code"
      (confirmEmail)="confirmEmail($event)"
      (resetRegisterCode)="resetRegisterCode()"
      [inline]="inline"
    ></confirm-email-component>
  `
})
export class ConfirmEmailContainer implements OnInit {
  /**
   * The error message, passed to the template
   */
  errorMessage$ = this.store.select(fromAccount.getConfirmErrorMessage);

  confirmCodeMessage$ = this.store.select(fromAccount.getConfirmEmailMessage);

  /**
   * Lets the template know if a server call has started
   //  */
  hasStarted$ = this.store.select(fromAccount.getConfirmHasStarted);

  /**
   * Lets the template know if a server call has finished (successfully)
   */
  hasFinished$ = this.store.select(fromAccount.getConfirmHasFinished);

  /**
   * The confirmation code
   */
  code = "";

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private ngPassitSDK: NgPassitSDK,
    private store: Store<fromRoot.IState>
  ) {}

  /**
   * Confirm email will temporarily appear in two different ways which requires
   * logic to distinguish
   */
  @Input() inline: boolean;

  /**
   * On init, look for the code param in the URL. If it's there, submit it.
   */
  ngOnInit() {
    this.route.params.subscribe(params => {
      this.code = params["code"];
      if (params["code"]) {
        this.confirmLongCode(params["code"]);
      }
    });
  }

  /**
   * Confirm a long code - authentication not required.
   * @param code long code from email web link
   */
  confirmLongCode(code: string) {
    this.ngPassitSDK
      .confirm_email_long_code(code)
      .then(() => {
        this.router.navigate(["/list"]);
      })
      .catch(err => {});
  }

  /**
   * Submit function
   * If code submits successfully, redirect away.
   * Otherwise, throw an error that the template can read
   *
   * @param code The code used to confirm the email address
   */
  confirmEmail(code: string) {
    this.store.dispatch(new emailActions.VerifyEmail(code));
  }

  resetRegisterCode() {
    this.store.dispatch(new emailActions.ResetRegisterCodeAction());
  }
}
