
import {take} from 'rxjs/operators';
import { Component } from "@angular/core";
import { Store } from "@ngrx/store";

import * as fromRoot from "../../app.reducers";
import * as fromAccount from "../account.reducer";
import * as fromChangePassword from "./change-password.reducer";
import { LogoutAction } from "../account.actions";
import { UserService } from "../user";
import { SetValueAction } from "ngrx-forms";

@Component({
  selector: "change-password-container",
  template: `
  <change-password
    [form]="form$ | async"
    [hasStarted]="hasStarted"
    [hasFinished]="hasFinished"
    [nonFieldErrors]="nonFieldErrors"
    (changePassword)="changePassword()"
    (toggleConfirm)="toggleConfirm()">
    </change-password>
    `
})
export class ChangePasswordContainer {
  form$ = this.store.select(fromAccount.getChangePasswordForm);
  hasStarted = false;
  hasFinished = false;
  showConfirm: boolean;
  nonFieldErrors: string[];

  constructor(
    private store: Store<fromRoot.IState>,
    private userService: UserService
  ) {
    this.form$.subscribe(
      form => (this.showConfirm = form.controls.showConfirm.value)
    );
  }

  toggleConfirm() {
    this.store.dispatch(
      new SetValueAction(
        fromChangePassword.FORM_ID + ".showConfirm",
        !this.showConfirm
      )
    );
  }

  changePassword() {
    this.form$.pipe(take(1)).subscribe(form => {
      this.hasStarted = true;
      this.hasFinished = false;
      this.nonFieldErrors = [];
      setTimeout(() => {
        this.userService
          .changePassword(
            form.controls.oldPassword.value,
            form.controls.newPassword.value
          )
          .then(() => {
            this.hasStarted = false;
            this.hasFinished = true;
            this.store.dispatch(new LogoutAction());
            setTimeout(() => (this.hasFinished = false), 5000);
          })
          .catch(err => {
            this.hasStarted = false;
            // console.error(err);
            if (err.res && err.res.status === 400) {
              if (err.res.error.old_password) {
                this.nonFieldErrors = this.nonFieldErrors.concat("Current password is not correct.");
              } else {
                this.nonFieldErrors = this.nonFieldErrors.concat("Unknown error. Password was not reset.");
              }
            } else {
              this.nonFieldErrors = this.nonFieldErrors.concat("Unknown error.");
            }
          });
      }, 200);
    });
  }
}
