var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import PassitSDK from "passit-sdk-js";
import { Api } from "./api";
/**
 * Angular wrapper of PassitSDK that swaps out the api class with one that uses
 * $http instead of fetch. This is done for better integration with angular
 * tooling like Protractor.
 */
var NgPassitSDK = /** @class */ (function (_super) {
    __extends(NgPassitSDK, _super);
    function NgPassitSDK(api) {
        var _this = _super.call(this) || this;
        _this.api = api;
        return _this;
    }
    return NgPassitSDK;
}(PassitSDK));
export { NgPassitSDK };
