// tslint:disable
const INIT_ACTION = "@ngrx/store/init";
const UPDATE_ACTION = "@ngrx/store/update-reducers";
const detectDate = /(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})/;

// correctly parse dates from local storage
export const dateReviver = (key: string, value: any) => {
  if (typeof value === "string" && detectDate.test(value)) {
    return new Date(value);
  }
  return value;
};

const dummyReviver = (key: string, value: any) => value;

const validateStateKeys = (keys: any[]) => {
  return keys.map(key => {
    let attr = key;

    if (typeof key === "object") {
      attr = Object.keys(key)[0];
    }

    if (typeof attr !== "string") {
      throw new TypeError(
        `localStorageSync Unknown Parameter Type: ` +
          `Expected type of string, got ${typeof attr}`
      );
    }
    return key;
  });
};

export const rehydrateApplicationState = (
  keys: any[],
  storage: Storage,
  storageKeySerializer: (key: string) => string,
  restoreDates: boolean
) => {
  return keys.reduce((acc, curr) => {
    let key = curr;
    let reviver = restoreDates ? dateReviver : dummyReviver;
    let deserialize = undefined;
    let decrypt = undefined;

    if (typeof key === "object") {
      key = Object.keys(key)[0];
      // use the custom reviver function
      if (typeof curr[key] === "function") {
        reviver = curr[key];
      } else {
        // use custom reviver function if available
        if (curr[key].reviver) {
          reviver = curr[key].reviver;
        }
        // use custom serialize function if available
        if (curr[key].deserialize) {
          deserialize = curr[key].deserialize;
        }
      }

      // Ensure that encrypt and decrypt functions are both presents
      if (curr[key].encrypt && curr[key].decrypt) {
        if (
          typeof curr[key].encrypt === "function" &&
          typeof curr[key].decrypt === "function"
        ) {
          decrypt = curr[key].decrypt;
        } else {
          console.error(
            `Either encrypt or decrypt is not a function on '${
              curr[key]
            }' key object.`
          );
        }
      } else if (curr[key].encrypt || curr[key].decrypt) {
        // Let know that one of the encryption functions is not provided
        console.error(
          `Either encrypt or decrypt function is not present on '${
            curr[key]
          }' key object.`
        );
      }
    }

    let stateSlice: any = storage.getItem(storageKeySerializer(key));
    if (stateSlice) {
      // Use provided decrypt function
      if (decrypt) {
        stateSlice = decrypt(stateSlice);
      }

      const isObjectRegex = new RegExp("{|\\[");
      let raw = stateSlice;

      if (isObjectRegex.test(stateSlice.charAt(0))) {
        try {
          raw = JSON.parse(stateSlice, reviver);
        } catch (e) {
          // Bad json schema, better to return nothing than to break
          // https://gitlab.com/passit/passit-frontend/issues/145
          raw = {};
        }
      }

      return Object.assign({}, acc, {
        [key]: deserialize ? deserialize(raw) : raw
      });
    }
    return acc;
  }, {});
};

export const syncStateUpdate = (
  state: any,
  keys: any[],
  storage: Storage,
  storageKeySerializer: (key: string) => string,
  removeOnUndefined: boolean,
  syncCondition?: (state: any) => any
) => {
  if (syncCondition) {
    try {
      if (syncCondition(state) !== true) {
        return;
      }
    } catch (e) {
      // Treat TypeError as do not sync
      if (e instanceof TypeError) {
        return;
      }
      throw e;
    }
  }
  keys.forEach(key => {
    let stateSlice = state[key];
    let replacer = undefined;
    let space = undefined;
    let encrypt = undefined;

    if (typeof key === "object") {
      let name = Object.keys(key)[0];
      stateSlice = state[name];

      if (typeof stateSlice !== "undefined" && key[name]) {
        // use serialize function if specified.
        if (key[name].serialize) {
          stateSlice = key[name].serialize(stateSlice);
        } else {
          // if serialize function is not specified filter on fields if an array has been provided.
          let filter = undefined;
          if (key[name].reduce) {
            filter = key[name];
          } else if (key[name].filter) {
            filter = key[name].filter;
          }
          if (filter) {
            stateSlice = filter.reduce((memo: any, attr: any) => {
              memo[attr] = stateSlice[attr];
              return memo;
            }, {});
          }

          // Check if encrypt and decrypt are present, also checked at this#rehydrateApplicationState()
          if (key[name].encrypt && key[name].decrypt) {
            if (typeof key[name].encrypt === "function") {
              encrypt = key[name].encrypt;
            }
          } else if (key[name].encrypt || key[name].decrypt) {
            // If one of those is not present, then let know that one is missing
            console.error(
              `Either encrypt or decrypt function is not present on '${
                key[name]
              }' key object.`
            );
          }
        }

        /*
                    Replacer and space arguments to pass to JSON.stringify.
                    If these fields don't exist, undefined will be passed.
                */
        replacer = key[name].replacer;
        space = key[name].space;
      }

      key = name;
    }

    if (typeof stateSlice !== "undefined") {
      try {
        if (encrypt) {
          // ensure that a string message is passed
          stateSlice = encrypt(
            typeof stateSlice === "string"
              ? stateSlice
              : JSON.stringify(stateSlice, replacer, space)
          );
        }
        storage.setItem(
          storageKeySerializer(key),
          typeof stateSlice === "string"
            ? stateSlice
            : JSON.stringify(stateSlice, replacer, space)
        );
      } catch (e) {
        console.warn("Unable to save state to localStorage:", e);
      }
    } else if (typeof stateSlice === "undefined" && removeOnUndefined) {
      try {
        storage.removeItem(storageKeySerializer(key));
      } catch (e) {
        console.warn(
          `Exception on removing/cleaning undefined '${key}' state`,
          e
        );
      }
    }
  });
};

export const localStorageSync = (config: LocalStorageConfig) => (
  reducer: any
) => {
  if (config.storage === undefined) {
    config.storage = localStorage || window.localStorage;
  }

  if (config.storageKeySerializer === undefined) {
    config.storageKeySerializer = key => key;
  }

  if (config.restoreDates === undefined) {
    config.restoreDates = true;
  }

  const stateKeys = validateStateKeys(config.keys);
  const rehydratedState = config.rehydrate
    ? rehydrateApplicationState(
        stateKeys,
        config.storage,
        config.storageKeySerializer,
        config.restoreDates
      )
    : undefined;

  return function(state: object, action: any) {
    /*
         Handle case where state is rehydrated AND initial state is supplied.
         Any additional state supplied will override rehydrated state for the given key.
         */
    if (
      (action.type === INIT_ACTION || action.type === UPDATE_ACTION) &&
      state
    ) {
      Object.keys(state).forEach(function(key) {
        if (
          state[key] instanceof Array &&
          rehydratedState[key] instanceof Array
        ) {
          state[key] = rehydratedState[key];
        } else if (
          typeof state[key] === "object" &&
          typeof rehydratedState[key] === "object"
        ) {
          state[key] = Object.assign({}, state[key], rehydratedState[key]);
        } else {
          state[key] = rehydratedState[key];
        }
      });
    }
    const nextState = reducer(state, action);
    syncStateUpdate(
      nextState,
      stateKeys,
      (config as any).storage,
      (config as any).storageKeySerializer,
      (config as any).removeOnUndefined,
      config.syncCondition
    );
    return nextState;
  };
};

/*
    @deprecated: Use localStorageSync(LocalStorageConfig)

    Wraps localStorageSync functionality acepting the removeOnUndefined boolean parameter in order
    to clean/remove the state from the browser on situations like state reset or logout.
    Defines localStorage as default storage.
*/
export const localStorageSyncAndClean = (
  keys: any[],
  rehydrate: boolean = false,
  removeOnUndefined: boolean = false
) => (reducer: any) => {
  let config: LocalStorageConfig = {
    keys: keys,
    rehydrate: rehydrate,
    storage: localStorage,
    removeOnUndefined: removeOnUndefined
  };

  return this.localStorageSync(config);
};

export interface LocalStorageConfig {
  keys: any[];
  rehydrate?: boolean;
  storage?: Storage;
  removeOnUndefined?: boolean;
  restoreDates?: boolean;
  storageKeySerializer?: (key: string) => string;
  syncCondition?: (state: any) => any;
}
