import { Component, OnInit, ChangeDetectionStrategy } from "@angular/core";
import { Store } from "@ngrx/store";

import { sanitizeUrl } from "@braintree/sanitize-url";
import * as fromRoot from "../../app.reducers";
import {
  getPopupSecrets,
  getPopupSearch,
  getPopupSelected,
  getMatchedSecrets,
  getPopupUsernameCopied,
  getPopupPasswordCopied
} from "./popup.selectors";
import * as secretActions from "../../secrets/secret.actions";
import { SecretService } from "../../secrets/secret.service";
import {
  SetCurrentUrlAction,
  SetSearchAction,
  SetSelectedSecretAction,
  NullSelectedSecretAction,
  CopyUsername,
  CopyPassword
} from "./popup.actions";
import { HotkeysService, Hotkey } from "angular2-hotkeys";
import { formFill } from "./autofill-compiled";
import { ISecret } from "passit-sdk-js/js/api.interfaces";

@Component({
  selector: "app-popup-container",
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <app-popup
      [secrets]="secrets$ | async"
      [selectedSecret]="selectedSecret$ | async"
      [search]="search$ | async"
      [formFillMessage]="formFillMessage"
      [matchedSecrets]="matchedSecrets$ | async"
      [usernameCopied]="usernameCopied$ | async"
      [passwordCopied]="passwordCopied$ | async"
      (setSelected)="setSelected($event)"
      (closeSelected)="closeSelected()"
      (searchUpdate)="searchUpdate($event)"
      (openUrl)="openUrl($event)"
      (signIn)="signIn($event)"
      (onCopyUsername)="copyUsername($event)"
      (onCopyPassword)="copyPassword($event)"
      (onDetail)="openDetails($event)"
      (openFullApp)="openFullApp()"
    ></app-popup>
  `
})
export class PopupContainer implements OnInit {
  secrets$ = this.store.select(getPopupSecrets);
  search$ = this.store.select(getPopupSearch);
  selectedSecret$ = this.store.select(getPopupSelected);
  matchedSecrets$ = this.store.select(getMatchedSecrets);
  usernameCopied$ = this.store.select(getPopupUsernameCopied);
  passwordCopied$ = this.store.select(getPopupPasswordCopied);
  formFillMessage = "";

  constructor(
    private store: Store<fromRoot.IState>,
    private secretService: SecretService,
    private _hotkeysService: HotkeysService
  ) {}

  ngOnInit() {
    // TODO make this not happen EVERY time
    this.setupHotkey();
    this.getSecrets();
    this.checkUrlForMatch();
  }

  private setupHotkey() {
    this._hotkeysService.add(
      new Hotkey(
        "ctrl+shift+l",
        (event: KeyboardEvent): boolean => {
          this.matchedSecrets$.subscribe(
            secrets => (secrets.length > 0 ? this.signIn(secrets[0]) : null)
          );
          return false;
        },
        ["input"]
      )
    );
  }

  getSecrets() {
    this.store.dispatch(new secretActions.GetSecretsAction());
  }

  setSelected(selected: number) {
    this.store.dispatch(new SetSelectedSecretAction(selected));
    this.formFillMessage = "";
  }

  closeSelected() {
    this.store.dispatch(new NullSelectedSecretAction());
  }

  searchUpdate(term: string) {
    this.store.dispatch(new SetSearchAction(term));
  }

  openFullApp() {
    browser.tabs.create({
      url: "/index.html"
    });
    window.close();
  }

  copyUsername(secret: ISecret) {
    this.store.dispatch(new CopyUsername(secret));
  }

  copyPassword(secret: ISecret) {
    this.store.dispatch(new CopyPassword(secret));
  }

  openDetails(secret: ISecret) {
    browser.tabs.create({
      url: "/index.html#/list;id=" + secret.id
    });
    window.close();
  }

  signIn(secret: ISecret) {
    const username = secret.data["username"]!;
    this.secretService.showSecret(secret).then(decrypted => {
      const password = decrypted["password"];
      this.executeAutofillScript(username, password).then(() => {
        window.close();
      });
    });
  }

  checkUrlForMatch() {
    // TODO critical path for potential security audit
    // This url comes from the current tab domain - it could attempt to inject code.
    this.getActiveTab().then(tab => {
      if (tab && tab.url !== undefined) {
        const url: string = sanitizeUrl(tab.url);
        this.store.dispatch(new SetCurrentUrlAction(url));
      }
    });
  }

  /** Run the autofill script on the current tab, requires using the activeTab permission.
   */
  executeAutofillScript(username: string, password: string) {
    return this.getActiveTab().then(tab => {
      if (!tab) {
        return;
      }

      // critical path for potential security audit
      username = username.substring(0, 200);
      password = password.substring(0, 200);
      return browser.tabs
        .executeScript(tab.id, {
          code: formFill
        })
        .then(response => {
          browser.tabs.sendMessage(tab.id!, { username, password });
        });
    });
  }

  /** Firefox and Chrome compatible way to get the active tab. tabs.getCurrent does not work in Firefox.
   * from https://stackoverflow.com/a/41943267
   */
  async getActiveTab() {
    if (browser.tabs) {
      const tabs = await browser.tabs.query({
        active: true,
        currentWindow: true
      });
      return tabs[0];
    } else {
      console.warn("no browser.tabs in popup, are you debugging?");
    }
  }

  openUrl(secret: ISecret) {
    const url = secret.data["url"];
    if (url !== undefined) {
      return browser.tabs.create({ url: sanitizeUrl(url) });
    }
  }
}
