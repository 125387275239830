import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from "@angular/core";

@Component({
  selector: "navbar",
  styleUrls: ["./navbar.component.scss"],
  templateUrl: "./navbar.component.html"
})
export class NavbarComponent implements OnInit {
  @Input() isLoggedIn: boolean;
  @Input() isPrivateOrgMode: boolean;
  @Input() hasGroupInvites: boolean;
  @Output() logout = new EventEmitter();
  windowWidth: number;

  ngOnInit() {
    this.windowWidth = document.body.clientWidth;

    window.onresize = () => {
      this.windowWidth = document.body.clientWidth;
    };
  }
}
