/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "../../list/secret-row/secret-row.component.scss.shim.ngstyle";
import * as i1 from "../../list/list.component.scss.shim.ngstyle";
import * as i2 from "@angular/core";
import * as i3 from "../group-form/group-form.component.ngfactory";
import * as i4 from "../group-form/group-form.component";
import * as i5 from "@angular/forms";
import * as i6 from "@angular/common";
import * as i7 from "./group-add.component";
var styles_GroupAddComponent = [i0.styles, i1.styles];
var RenderType_GroupAddComponent = i2.ɵcrt({ encapsulation: 0, styles: styles_GroupAddComponent, data: {} });
export { RenderType_GroupAddComponent as RenderType_GroupAddComponent };
function View_GroupAddComponent_1(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 4, "div", [["class", "secret-list-item secret-list-item--add-form"]], [[2, "secret-list-item--is-active", null]], null, null, null, null)), (_l()(), i2.ɵeld(1, 0, null, null, 1, "h2", [["class", "secret__title secret__title--add"]], null, null, null, null, null)), (_l()(), i2.ɵted(-1, null, [" Add New Group "])), (_l()(), i2.ɵeld(3, 0, null, null, 1, "group-form", [["class", "secret-form secret-form--is-active"], ["isNew", "true"]], null, [[null, "hideAddSecretForm"], [null, "keyup"], [null, "save"], [null, "updateFormValues"], [null, "resetSearch"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("hideAddSecretForm" === en)) {
        var pd_0 = (_co.hideAddSecretForm.emit() !== false);
        ad = (pd_0 && ad);
    } if (("keyup" === en)) {
        var pd_1 = (_co.contactSearch.emit($event.target.value) !== false);
        ad = (pd_1 && ad);
    } if (("save" === en)) {
        var pd_2 = (_co.onSave() !== false);
        ad = (pd_2 && ad);
    } if (("updateFormValues" === en)) {
        var pd_3 = (_co.updateFormValues.emit($event) !== false);
        ad = (pd_3 && ad);
    } if (("resetSearch" === en)) {
        var pd_4 = (_co.resetSearch.emit($event) !== false);
        ad = (pd_4 && ad);
    } return ad; }, i3.View_GroupFormComponent_0, i3.RenderType_GroupFormComponent)), i2.ɵdid(4, 114688, null, 0, i4.GroupFormComponent, [i5.FormBuilder], { groupContacts: [0, "groupContacts"], isNew: [1, "isNew"], groupIsUpdating: [2, "groupIsUpdating"], groupIsUpdated: [3, "groupIsUpdated"], isPrivateOrgMode: [4, "isPrivateOrgMode"], contactLookup: [5, "contactLookup"], groupForm: [6, "groupForm"], singleGroupPendingContacts: [7, "singleGroupPendingContacts"], contacts: [8, "contacts"], searchedContacts: [9, "searchedContacts"] }, { hideAddSecretForm: "hideAddSecretForm", save: "save", resetSearch: "resetSearch", updateFormValues: "updateFormValues" })], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.groupContacts; var currVal_2 = "true"; var currVal_3 = _co.groupIsUpdating; var currVal_4 = _co.groupIsUpdated; var currVal_5 = _co.isPrivateOrgMode; var currVal_6 = _co.contactLookup; var currVal_7 = _co.groupForm; var currVal_8 = _co.singleGroupPendingContacts; var currVal_9 = _co.contacts; var currVal_10 = _co.searchedContacts; _ck(_v, 4, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.showCreate; _ck(_v, 0, 0, currVal_0); }); }
export function View_GroupAddComponent_0(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵand(16777216, null, null, 1, null, View_GroupAddComponent_1)), i2.ɵdid(1, 16384, null, 0, i6.NgIf, [i2.ViewContainerRef, i2.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.showCreate; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_GroupAddComponent_Host_0(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 1, "group-add", [], null, null, null, View_GroupAddComponent_0, RenderType_GroupAddComponent)), i2.ɵdid(1, 49152, null, 0, i7.GroupAddComponent, [], null, null)], null, null); }
var GroupAddComponentNgFactory = i2.ɵccf("group-add", i7.GroupAddComponent, View_GroupAddComponent_Host_0, { contacts: "contacts", groupIsUpdating: "groupIsUpdating", groupIsUpdated: "groupIsUpdated", searchedContacts: "searchedContacts", isPrivateOrgMode: "isPrivateOrgMode", contactLookup: "contactLookup", groupContacts: "groupContacts", groupForm: "groupForm", showCreate: "showCreate", singleGroupPendingContacts: "singleGroupPendingContacts" }, { saveNew: "saveNew", hideAddSecretForm: "hideAddSecretForm", contactSearch: "contactSearch", resetSearch: "resetSearch", updateFormValues: "updateFormValues" }, []);
export { GroupAddComponentNgFactory as GroupAddComponentNgFactory };
