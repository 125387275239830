import { Action } from "@ngrx/store";

export enum ListActionTypes {
  CLEAR_MANAGED_SECRET = "clear managed secret",
  SET_MANAGED_SECRET = "Set managed secret",
  SET_SEARCH_TEXT = "Set search text",
  SHOW_CREATE = "Show create secret",
  HIDE_CREATE = "Hide create secret",
  UPDATE_SELECTED_GROUP = "Update selected group"
}

export class ClearManagedSecret implements Action {
  readonly type = ListActionTypes.CLEAR_MANAGED_SECRET;
}

export class SetManagedSecret implements Action {
  readonly type = ListActionTypes.SET_MANAGED_SECRET;

  constructor(public payload: number) {}
}

export class SetSearchText implements Action {
  readonly type = ListActionTypes.SET_SEARCH_TEXT;

  constructor(public payload: string) {}
}

export class ShowCreate implements Action {
  readonly type = ListActionTypes.SHOW_CREATE;
}

export class HideCreate implements Action {
  readonly type = ListActionTypes.HIDE_CREATE;
}

export class UpdateSelectedGroup implements Action {
  readonly type = ListActionTypes.UPDATE_SELECTED_GROUP;

  constructor(public payload: number | null) {}
}

export type ListActionsUnion =
  | ClearManagedSecret
  | SetManagedSecret
  | SetSearchText
  | ShowCreate
  | HideCreate
  | UpdateSelectedGroup;
