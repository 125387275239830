import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { EffectsModule } from "@ngrx/effects";
import { StoreModule } from "@ngrx/store";
import { InlineSVGModule } from "ng-inline-svg";
import { NgrxFormsModule } from "ngrx-forms";

import { AccountComponent } from "./account.component";
import { LoginEffects } from "./account.effects";
import { reducers } from "./account.reducer";
import {
  ChangePasswordComponent,
  ChangePasswordContainer
} from "./change-password";
import { ConfirmEmailComponent, ConfirmEmailContainer } from "./confirm-email";
import { ConfirmEmailEffects } from "./confirm-email/confirm-email.effects";
import { DeleteComponent, DeleteContainer } from "./delete";
import { LoginContainer } from "./login";
import { RegisterEffects } from "./register/register.effects";

import { UserService } from "./user";

import { ProgressIndicatorModule } from "../progress-indicator";
import { LoginComponent } from "./login/login.component";
import { RegisterComponent } from "./register/register.component";
import { RegisterContainer } from "./register/register.container";
import { SharedModule } from "../shared";
import { LoginFormEffects } from "./login/login.effects";
import { ConfirmEmailGuard } from "./confirm-email/confirm-email.guard";
import { ErrorReportingComponent } from "./error-reporting/error-reporting.component";
import { ErrorReportingContainer } from "./error-reporting/error-reporting.container";
import { ErrorReportingEffects } from "./error-reporting/error-reporting.effects";

export const COMPONENTS = [
  AccountComponent,
  ChangePasswordComponent,
  ChangePasswordContainer,
  ConfirmEmailComponent,
  ConfirmEmailContainer,
  DeleteContainer,
  DeleteComponent,
  ErrorReportingComponent,
  ErrorReportingContainer,
  LoginComponent,
  LoginContainer,
  RegisterComponent,
  RegisterContainer
];

export const SERVICES = [UserService, ConfirmEmailGuard];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    InlineSVGModule,
    SharedModule,
    RouterModule,
    ReactiveFormsModule,
    NgrxFormsModule,
    ProgressIndicatorModule,
    StoreModule.forFeature("account", reducers),
    EffectsModule.forFeature([
      LoginEffects,
      LoginFormEffects,
      RegisterEffects,
      ConfirmEmailEffects,
      ErrorReportingEffects
    ])
  ],
  declarations: COMPONENTS,
  exports: COMPONENTS,
  providers: [SERVICES]
})
export class AccountModule {}
