import { Component } from "@angular/core";
import { Store } from "@ngrx/store";
import * as fromRoot from "../app.reducers";
import { ImportableSecret } from "./importableSecret";
import * as importer from "./importer.actions";
import { ImporterService } from "./importer.service";

@Component({
  template: `
    <importer-component
      [fileName]="fileName"
      [finishedSavingMessage]="finishedSavingMessage"
      [hasStartedSaving]="hasStartedSaving"
      [hasFinishedSaving]="hasFinishedSaving"
      [secrets]="secrets"
      (resetForm)="resetForm()"
      (resetFinishedMessage)="resetFinishedMessage()"
      (setFileName)="setFileName($event)"
      (setImportableSecrets)="setImportableSecrets($event)"
      (saveSecrets)="saveSecrets($event)"
    ></importer-component>
  `
})
export class ImporterContainer {
  fileName: string | null;
  finishedSavingMessage: string | null = null;
  hasStartedSaving = false;
  hasFinishedSaving = false;
  secrets: ImportableSecret[] | null;

  constructor(
    private store: Store<fromRoot.IState>,
    private importerService: ImporterService
  ) {
    store.select(fromRoot.getImporterSecrets).subscribe(secrets => {
      this.secrets = secrets;
    });
    store.select(fromRoot.getImporterFileName).subscribe(fileName => {
      this.fileName = fileName;
    });
  }

  resetForm() {
    this.store.dispatch(new importer.ResetImportSecretsAction());
    this.store.dispatch(new importer.ResetFileNameAction());
  }

  resetFinishedMessage() {
    this.finishedSavingMessage = null;
  }

  setFileName(fileName: string) {
    this.store.dispatch(new importer.SetFileNameAction(fileName));
  }

  setImportableSecrets(secrets: ImportableSecret[]) {
    this.store.dispatch(new importer.SetImportSecretsAction(secrets));
  }

  saveSecrets(secrets: ImportableSecret[]) {
    this.hasStartedSaving = true;
    this.hasFinishedSaving = false;
    secrets = secrets.filter(secret => secret.doImport === true);
    setTimeout(() => {
      this.importerService
        .saveSecrets(secrets)
        .then(() => {
          this.hasStartedSaving = false;
          this.hasFinishedSaving = true;
          this.finishedSavingMessage = `Successfully imported “${
            this.fileName
          }”`;
          setTimeout(() => {
            this.hasFinishedSaving = false;
            this.resetForm();
          }, 3000);
        })
        .catch(error => {
          this.hasStartedSaving = false;
          this.hasFinishedSaving = false;
          this.finishedSavingMessage =
            "Something went wrong. Some secrets may not have imported.";
        });
    }, 1000);
  }
}
