import { ActionReducer, createSelector, MetaReducer } from "@ngrx/store";
import "rxjs/add/operator/let";
import "rxjs/add/operator/switchMap";

import { localStorageSync } from "./store-localstorage";

import { routerReducer, RouterReducerState } from "@ngrx/router-store";
import { AccountActionTypes } from "./account/account.actions";
import * as popupState from "./extension/popup/popup.reducer";
import * as confState from "./get-conf/conf.reducer";
import * as importerState from "./importer/importer.reducer";
import * as secretState from "./secrets/secrets.reducer";

export interface IState {
  importer: importerState.IImporterState;
  router: RouterReducerState;
  secrets: secretState.ISecretState;
  conf: confState.IConfState;
}

export const reducers: any = {
  importer: importerState.importerReducer,
  router: routerReducer,
  secrets: secretState.secretReducer,
  conf: confState.reducer
};

export function popupDeserialize(popup: popupState.IPopupState) {
  // Only rehydrate if popup was recently interacted with
  const now = new Date();
  const lastOpened = new Date(popup.lastOpened!);
  const FIVE_MIN = 5 * 60 * 1000;
  return now.getTime() - lastOpened.getTime() < FIVE_MIN
    ? popup
    : popupState.initialState;
}
const shouldSync = (state: any) => state.account.auth.rememberMe;
export function localStorageSyncReducer(
  reducer: ActionReducer<any>
): ActionReducer<any> {
  return localStorageSync({
    keys: [
      { account: ["auth"] },
      "contacts",
      "group",
      {
        list: {
          list: ["searchText", "secretManaged", "firstTimeLoadingComplete"]
        }
      },
      "secrets",
      { popup: { deserialize: popupDeserialize } },
      {
        conf: [
          "isDemo",
          "isPrivateOrgMode",
          "environment",
          "ravenDsn",
          "timestamp"
        ]
      }
    ],
    rehydrate: true,
    syncCondition: shouldSync
  })(reducer);
}

export function logout(reducer: ActionReducer<any>): ActionReducer<any> {
  return (state: IState, action: any) =>
    reducer(
      action.type === AccountActionTypes.LOGOUT_SUCCESS ? undefined : state,
      action
    );
}

export const metaReducers: Array<MetaReducer<any>> = [
  localStorageSyncReducer,
  logout
];

export const getImporterState = (state: IState) => state.importer;
export const getImporterSecrets = createSelector(
  getImporterState,
  importerState.getSecrets
);
export const getImporterFileName = createSelector(
  getImporterState,
  importerState.getFileName
);

export const getSecretState = (state: IState) => state.secrets;
export const getSecrets = createSelector(
  getSecretState,
  secretState.getSecrets
);
export const getSecretsCount = createSelector(
  getSecrets,
  secrets => secrets.length
);

export const getConfState = (state: IState) => state.conf;
export const getIsDemo = createSelector(getConfState, confState.getIsDemo);
export const getIsPrivateOrgMode = createSelector(
  getConfState,
  confState.getIsPrivateOrgMode
);
export const getIsPopup = createSelector(getConfState, confState.getIsPopup);
export const getTimestamp = createSelector(
  getConfState,
  confState.getTimestamp
);
export const getRavenDsn = createSelector(getConfState, confState.getRavenDsn);

export const getRouterState = (state: IState) => state.router;
export const getRouterPath = createSelector(
  getRouterState,
  (state: RouterReducerState) => {
    if (state) {
      return state.state.url;
    }
  }
);
