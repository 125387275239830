<label
  class="form-field__checkbox"
  [class.form-field__checkbox--inline]="inline">
  <input 
    type="checkbox"
    [ngrxFormControlState]="control"
    class="form-field__checkbox__input"
  />
  <div class="form-field__checkbox__check form-field__checkbox__check--inline">
    <div [inlineSVG]="'../assets/svg/check.svg'"></div>
  </div>
  <div class="form-field__checkbox__text">
    {{ title }}
    <div *ngIf="subtext" class="form-field__checkbox__subtext">
      {{ subtext }}
    </div>
  </div>
</label>