/*
* @angular
*/
import { APP_BASE_HREF } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import { NgModule, ErrorHandler } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { Params } from "@angular/router";
import { ServiceWorkerModule } from "@angular/service-worker";
import { environment } from "../environments/environment";
import { routing, routingStore } from "./app.routing";

/*
* Third Party Other
*/
import { EffectsModule } from "@ngrx/effects";
import { RouterStateSerializer } from "@ngrx/router-store";
import { StoreModule } from "@ngrx/store";
import { StoreDevtoolsModule } from "@ngrx/store-devtools";
import { InlineSVGModule } from "ng-inline-svg";
import { SelectModule } from "ng-select";
import { TooltipModule } from "ngx-tooltip";
import { HotkeyModule } from "angular2-hotkeys";

/*
* Passit
*/
import { AccountModule } from "./account";
import { MoonMail } from "./account/moonmail/moonmail.service";
import { AppComponent } from "./app.component";
import { ExporterComponent } from "./exporter";
import { ResetFormModule } from "./form/reset-form.module";
import { GroupModule } from "./group";
import { AlreadyLoggedInGuard, LoggedInGuard } from "./guards";
import { ImporterComponent, ImporterContainer } from "./importer";
import { ListModule } from "./list";
import { NoContentComponent, NoContentContainer } from "./no-content";
import { SharedModule } from "./shared";

import { ExtensionModule } from "./extension";
import { ProgressIndicatorModule } from "./progress-indicator";

import { metaReducers, reducers } from "./app.reducers";
import { IS_EXTENSION } from "./constants";
import { ExporterService } from "./exporter/exporter.service";
import { GetConfService } from "./get-conf/";
import { GetConfEffects } from "./get-conf/conf.effects";
import { ImporterService } from "./importer/importer.service";
import { Api } from "./ngsdk/api";
import { NgPassitSDK } from "./ngsdk/sdk";
import { GeneratorService } from "./secrets";
import { SecretEffects } from "./secrets/secret.effects";
import { SecretService } from "./secrets/secret.service";
import { AppDataService } from "./shared/app-data/app-data.service";
import { PopupLoggedInGuard } from "./guards/popup-logged-in.guard";
import { RavenErrorHandler } from "./error-handler";

// Why is this not default ngrx store, why is crashing default?
export interface IRouterStateUrl {
  url: string;
  queryParams: Params;
}

export class CustomSerializer
  implements RouterStateSerializer<IRouterStateUrl> {
  serialize(routerState: any): IRouterStateUrl {
    const { url } = routerState;
    const queryParams = routerState.root.queryParams;

    // Only return an object including the URL and query params
    // instead of the entire snapshot
    return { url, queryParams };
  }
}

/* tslint:disable:object-literal-sort-keys */
@NgModule({
  declarations: [
    AppComponent,
    ImporterContainer,
    ImporterComponent,
    NoContentContainer,
    NoContentComponent,
    ExporterComponent
  ],
  imports: [
    BrowserModule,
    HotkeyModule.forRoot(),
    BrowserAnimationsModule,
    AccountModule,
    StoreModule.forRoot(reducers, { metaReducers }),
    EffectsModule.forRoot([SecretEffects, GetConfEffects]),
    !environment.production
      ? StoreDevtoolsModule.instrument({ maxAge: 25 })
      : [],
    FormsModule,
    GroupModule,
    ListModule,
    HttpClientModule,
    InlineSVGModule.forRoot(),
    ProgressIndicatorModule,
    ReactiveFormsModule,
    ResetFormModule,
    routing,
    routingStore,
    SelectModule,
    !IS_EXTENSION
      ? ServiceWorkerModule.register("ngsw-worker.js", {
          enabled: environment.production
        })
      : [],
    SharedModule,
    TooltipModule,
    ExtensionModule
  ],
  providers: [
    {
      provide: APP_BASE_HREF,
      useValue: "/"
    },
    GetConfService,
    { provide: AppDataService, useClass: AppDataService },
    { provide: ImporterService, useClass: ImporterService },
    AlreadyLoggedInGuard,
    LoggedInGuard,
    PopupLoggedInGuard,
    { provide: ExporterService, useClass: ExporterService },
    { provide: MoonMail, useClass: MoonMail },
    { provide: SecretService, useClass: SecretService },
    GeneratorService,
    { provide: RouterStateSerializer, useClass: CustomSerializer },
    { provide: Api, useClass: Api },
    { provide: NgPassitSDK, useClass: NgPassitSDK },
    { provide: ErrorHandler, useClass: RavenErrorHandler }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
