import { Injectable } from "@angular/core";
import { from } from "rxjs";

import * as api from "passit-sdk-js/js/api.interfaces";
import * as sdkI from "passit-sdk-js/js/sdk.interfaces";

import { IGroup } from "../group/group.interfaces";
import { NgPassitSDK } from "../ngsdk/sdk";

@Injectable()
export class SecretService {
  constructor(public sdk: NgPassitSDK) {}

  /*
  * decrypt recieving just id number not whole object
  */
  public copySecret(secretId: number) {
    return this.sdk
      .get_secret(secretId)
      .then(data => {
        return this.sdk.decrypt_secret(data).then(resp => {
          return resp;
        });
      })
      .catch(err => console.error(err));
  }

  /*
  * send create secret post to sdk
  */
  public createSecrets(data: sdkI.INewSecret): Promise<api.ISecret> {
    return this.sdk.create_secret(data);
  }

  /*
  * delete seccret
  */
  public deleteSecret(secretId: number): Promise<any> {
    return this.sdk
      .delete_secret(secretId)
      .then(resp => {
        return resp;
      })
      .catch(err => {
        console.error(err);
      });
  }

  /*
  * get secrets
  */
  public getSecrets() {
    return from(this.sdk.list_secrets());
  }

  /*
  * get secret from id
  * then decrypt secret
  * so user can see unencrypted secret
  */

  public showSecret(secret: api.ISecret) {
    return this.sdk.ready().then(() => {
      return this.sdk
        .decrypt_secret(secret)
        .then((resp: any) => resp)
        .catch((err: any) => {
          console.error(err);
        });
    });
  }

  /*
  * edit/update secret
  */
  public updateSecret(secret: sdkI.ISecret): Promise<api.ISecret> {
    return this.sdk
      .update_secret(secret)
      .then(resp => resp)
      .catch(err => {
        console.error(err);
        return err;
      });
  }

  /** Decrypt a secret without network access.
   * This function has interesting error handling. A secret should never fail to decrypt
   * under any circumstance in typical operation. But what if that does not happen? Let's assume
   * a bad actor joins a group and mangles some (but not all) of the secret's encrypted data.
   *
   * Assume we have two "secret throughs". One is filled with garbage data, the other is valid.
   * This function will catch exceptions if the first one fails. Then try the next. If all
   * fail then it will return the last caught error.
   */
  public async showOfflineSecret(secret: api.ISecret, groups: IGroup[]) {
    await this.sdk.ready();

    let handledError: any;
    const throughs = secret.secret_through_set.filter(
      through => through.is_mine === true
    );
    for (const through of throughs) {
      const foundGroup = groups.find(group => group.id === through.group);
      try {
        const response = await this.sdk.offline_decrypt_secret(
          through.key_ciphertext,
          through.data,
          foundGroup
        );
        return response;
      } catch (error) {
        // This should never happen!
        console.error("Failed to decrypt secret!", error);
        handledError = error;
      }
    }
    // A return here indicates an error, it should never return.
    throw handledError;
  }

  /** Compared selected groups with existing groups from the database
   * Then makes changes to make the secret in sync
   * Promise resolves when all changes are finished.
   * Changes happen asyncronously and are not transactional at this time
   */
  public updateGroupsForSecret(
    groupIds: number[],
    secretId: number
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      this.sdk.get_secret(secretId).then(secret => {
        const existingGroupIds = [];
        for (const through of secret.secret_through_set) {
          if (through.group) {
            existingGroupIds.push(through.group);
          }
        }
        const promises: Array<Promise<any>> = [];
        for (const groupId of groupIds) {
          // if new group not in existing group ids
          if (existingGroupIds.indexOf(groupId) < 0) {
            promises.push(this.sdk.add_group_to_secret(groupId, secretId));
          }
        }
        for (const groupId of existingGroupIds) {
          // if existing group id not in new group ids.
          if (groupIds.indexOf(groupId) < 0) {
            const secretThrough = secret.secret_through_set.find(
              through => through.group === groupId
            );
            promises.push(
              this.sdk.remove_group_from_secret(secretId, secretThrough!.id!)
            );
          }
        }
        Promise.all(promises).then(() => {
          resolve();
        });
      });
    });
  }

  /**
   * Takes existing secrets that were just decrypted and assigns them to the
   * object that the secret form uses
   */
  public setPlaintextSecrets(secrets: any) {
    const populatedSecrets = {};
    Object.keys(secrets).forEach(
      secret => (populatedSecrets[secret] = secrets[secret])
    );
    return populatedSecrets;
  }

  /**
   * Takes fields from the secret form that are meant to be encrypted and
   * adds them to an object that gets attached if there is a non-empty value.
   */
  public getPlaintextSecrets(fields: string[], secrets: any) {
    // Only include secret keys if they were changed
    const secretsToExport = {};
    fields.forEach(field => {
      if (secrets[field] && secrets[field] !== "") {
        secretsToExport[field] = secrets[field];
      }
    });
    return secretsToExport;
  }
}
