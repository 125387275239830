var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { sodium } from "simple-asymmetric-js/js/asymmetric_encryption";
var GeneratorService = /** @class */ (function () {
    function GeneratorService() {
        this.LOWER = "abcdefghijklmnopqrstuwxyz";
        this.UPPER = "ABCDEFGHIJKLMNOPQRSTUWXYZ";
        this.NUMBERS = "0123456789";
        /** easy to reach special chars */
        this.BASIC_SPECIAL_CHARS = "!@#$%^&*()_+";
        /** OWASP easily typed password special chars */
        this.KEYBOARD_SPECIAL_CHARS = " !\"#$%&'()*+,-./:;<=>?@[\\]^_`{|}~";
        /** Upper and lower case characters plus numbers. No symbols */
        this.ALPHANUMBERIC = this.LOWER + this.UPPER + this.NUMBERS;
        this.DEFAULT_HOW_MANY = 20;
        this.ready = sodium.ready;
    }
    /** Generate a cryptographically secure random typeable password
     * Uses libsodium randombytes_buf to achieve randomness
     */
    GeneratorService.prototype.generatePassword = function (options) {
        if (options === void 0) { options = {}; }
        return __awaiter(this, void 0, void 0, function () {
            var chars, howMany, rnd, value, len, i;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, sodium.ready];
                    case 1:
                        _a.sent();
                        chars = options.chars ? options.chars : this.ALPHANUMBERIC;
                        howMany = options.howMany ? options.howMany : this.DEFAULT_HOW_MANY;
                        rnd = sodium.randombytes_buf(howMany);
                        value = new Array(howMany);
                        len = chars.length;
                        for (i = 0; i < howMany; i++) {
                            value[i] = chars[rnd[i] % len];
                        }
                        return [2 /*return*/, value.join("")];
                }
            });
        });
    };
    return GeneratorService;
}());
export { GeneratorService };
