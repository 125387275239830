import { Component, ChangeDetectionStrategy } from "@angular/core";
import { Store } from "@ngrx/store";

import * as fromRoot from "../../app.reducers";
import * as fromList from "../list.reducer";
import * as fromGroup from "../../group/group.reducer";
import {
  TogglePasswordIsMasked,
  SetPasswordIsMasked,
  ToggleShowNotes,
  UpdateSecret,
  DeleteSecret,
  GeneratePassword,
  CreateSecret
} from "./secret-form.actions";
import { HideCreate } from "../list.actions";

@Component({
  selector: "secret-form-container",
  template: `
    <secret-form-component
      [form]="form$ | async"
      [errorMessage]="errorMessage$ | async"
      [isNew]="isNew$ | async"
      [isUpdating]="isUpdating$ | async"
      [isUpdated]="isUpdated$ | async"
      [passwordIsMasked]="passwordIsMasked$ | async"
      [showNotes]="showNotes$ | async"
      [groups]="groups$ | async"
      (togglePasswordIsMasked)="togglePasswordIsMasked()"
      (setPasswordIsMasked)="setPasswordIsMasked($event)"
      (toggleShowNotes)="toggleShowNotes()"
      (saveSecret)="saveSecret($event)"
      (deleteSecret)="deleteSecret()"
      (generatePassword)="generatePassword()"
      (cancel)="cancel()"
    ></secret-form-component>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SecretFormContainer {
  form$ = this.store.select(fromList.getSecretForm);
  errorMessage$ = this.store.select(fromList.getSecretFormErrorMessage);
  isNew$ = this.store.select(fromList.getSecretIsNew);
  isUpdating$ = this.store.select(fromList.getSecretIsUpdating);
  isUpdated$ = this.store.select(fromList.getSecretIsUpdated);
  passwordIsMasked$ = this.store.select(fromList.getSecretPasswordIsMasked);
  showNotes$ = this.store.select(fromList.getSecretShowNotes);
  groups$ = this.store.select(fromGroup.getActiveGroups);

  constructor(public store: Store<fromRoot.IState>) {}

  togglePasswordIsMasked() {
    this.store.dispatch(new TogglePasswordIsMasked());
  }

  setPasswordIsMasked(value: boolean) {
    this.store.dispatch(new SetPasswordIsMasked(value));
  }

  toggleShowNotes() {
    this.store.dispatch(new ToggleShowNotes());
  }

  saveSecret(create: boolean) {
    if (create) {
      this.store.dispatch(new CreateSecret());
    } else {
      this.store.dispatch(new UpdateSecret());
    }
  }

  deleteSecret() {
    this.store.dispatch(new DeleteSecret());
  }

  generatePassword() {
    this.store.dispatch(new GeneratePassword());
  }

  cancel() {
    this.store.dispatch(new HideCreate());
  }
}
