import {
  Component,
  ChangeDetectionStrategy,
  EventEmitter,
  Output,
  Input
} from "@angular/core";
import { IGroup } from "../group.interfaces";

@Component({
  selector: "app-group-pending-list",
  templateUrl: "./group-pending-list.component.html",
  styleUrls: [
    "../../list/list.component.scss",
    "../../list/secret-row/secret-row.component.scss",
    "./group-pending-list.component.scss"
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GroupPendingListComponent {
  @Input() pendingInviteGroups: IGroup[];
  @Output() acceptGroup = new EventEmitter<IGroup>();
  @Output() declineGroup = new EventEmitter<IGroup>();

  constructor() {}
}
