import { RouterModule } from "@angular/router";
import { StoreRouterConnectingModule } from "@ngrx/router-store";
import { AccountComponent } from "./account/account.component";
import { ChangePasswordContainer } from "./account/change-password";
import { ConfirmEmailContainer } from "./account/confirm-email";
import { DeleteContainer } from "./account/delete";
import { LoginContainer } from "./account/login";
import { RegisterContainer } from "./account/register/register.container";
import { IS_EXTENSION } from "./constants";
import { ExporterComponent } from "./exporter";
import { BackgroundComponent } from "./extension/background";
import { PopupContainer } from "./extension/popup";
import { GroupContainer } from "./group";
import { AlreadyLoggedInGuard, LoggedInGuard } from "./guards";
import { ImporterContainer } from "./importer";
import { SecretListContainer } from "./list";
import { NoContentContainer } from "./no-content";
import { PopupLoggedInGuard } from "./guards/popup-logged-in.guard";
import { ConfirmEmailGuard } from "./account/confirm-email/confirm-email.guard";
import { ErrorReportingContainer } from "./account/error-reporting/error-reporting.container";
var ɵ0 = {
    title: "Log In",
    showNavBar: false
}, ɵ1 = {
    title: "Account Management"
}, ɵ2 = {
    title: "Change Account Password"
}, ɵ3 = {
    title: "Error Reporting"
}, ɵ4 = {
    title: "Delete Account"
}, ɵ5 = {
    title: "Register",
    showNavBar: false
}, ɵ6 = {
    title: "Confirm Email"
}, ɵ7 = {
    title: "Confirm Email"
}, ɵ8 = {
    title: "Password List"
}, ɵ9 = {
    title: "Groups List"
}, ɵ10 = {
    title: "Import Passwords"
}, ɵ11 = {
    showNavBar: false
};
/* tslint:disable:object-literal-sort-keys */
var appRoutes = [
    {
        path: "login",
        component: LoginContainer,
        canActivate: [AlreadyLoggedInGuard],
        data: ɵ0
    },
    { path: "", redirectTo: "list", pathMatch: "full" },
    {
        path: "account",
        component: AccountComponent,
        data: ɵ1
    },
    {
        path: "account/change-password",
        component: ChangePasswordContainer,
        canActivate: [LoggedInGuard],
        data: ɵ2
    },
    {
        path: "account/error-reporting",
        component: ErrorReportingContainer,
        canActivate: [LoggedInGuard],
        data: ɵ3
    },
    {
        path: "account/delete",
        component: DeleteContainer,
        canActivate: [LoggedInGuard],
        data: ɵ4
    },
    {
        path: "register",
        component: RegisterContainer,
        canActivate: [AlreadyLoggedInGuard],
        data: ɵ5
    },
    {
        path: "confirm-email",
        component: ConfirmEmailContainer,
        canActivate: [ConfirmEmailGuard],
        data: ɵ6
    },
    {
        path: "confirm-email/:code",
        component: ConfirmEmailContainer,
        data: ɵ7
    },
    {
        path: "list",
        component: SecretListContainer,
        canActivate: [LoggedInGuard],
        data: ɵ8
    },
    {
        path: "groups",
        component: GroupContainer,
        canActivate: [LoggedInGuard],
        data: ɵ9
    },
    {
        path: "import",
        component: ImporterContainer,
        canActivate: [LoggedInGuard],
        data: ɵ10
    },
    {
        path: "export",
        canActivate: [LoggedInGuard],
        component: ExporterComponent
    },
    {
        path: "popup",
        component: PopupContainer,
        canActivate: [PopupLoggedInGuard],
        data: ɵ11
    },
    {
        path: "background",
        component: BackgroundComponent
    },
    {
        path: "**",
        component: NoContentContainer
    }
];
export var routing = RouterModule.forRoot(appRoutes, 
// use hashing when it is an extension, because the browsers can't
// redirect other urls for SPA to the index.html
{ useHash: IS_EXTENSION });
export var routingStore = StoreRouterConnectingModule;
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7, ɵ8, ɵ9, ɵ10, ɵ11 };
