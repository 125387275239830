/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./app.style.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./shared/navbar/navbar.container.ngfactory";
import * as i3 from "./shared/navbar/navbar.container";
import * as i4 from "@angular/router";
import * as i5 from "@ngrx/store";
import * as i6 from "./app.component";
import * as i7 from "./shared/app-data/app-data.service";
import * as i8 from "@angular/platform-browser";
var styles_AppComponent = [i0.styles];
var RenderType_AppComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_AppComponent, data: {} });
export { RenderType_AppComponent as RenderType_AppComponent };
export function View_AppComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "navbar-container", [], null, null, null, i2.View_NavbarContainer_0, i2.RenderType_NavbarContainer)), i1.ɵdid(1, 49152, null, 0, i3.NavbarContainer, [i4.Router, i5.Store, i5.Store], null, null), (_l()(), i1.ɵeld(2, 0, null, null, 2, "main", [], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(4, 212992, null, 0, i4.RouterOutlet, [i4.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 4, 0); }, null); }
export function View_AppComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-root", [], null, null, null, View_AppComponent_0, RenderType_AppComponent)), i1.ɵdid(1, 114688, null, 0, i6.AppComponent, [i7.AppDataService, i5.Store, i4.Router, i8.Title], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AppComponentNgFactory = i1.ɵccf("app-root", i6.AppComponent, View_AppComponent_Host_0, {}, {}, []);
export { AppComponentNgFactory as AppComponentNgFactory };
