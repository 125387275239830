<div class="secret">
  <form novalidate [ngrxFormState]="form" (submit)="onSubmit()">

    <ul *ngIf="errorMessage" class="form-field__error-list">
      <li class="form-field__error">
        {{ errorMessage }}
      </li>
    </ul>

    <div class="secret__details">
      <div class="form-field secret__field">
          <div>
              <label [for]="form.controls.name.id" class="form-field__label secret__label">
                  Name &nbsp;<span *ngIf="form.errors && form.errors._name && form.isSubmitted" class="form-field__error">Required</span>
              </label>
            </div>
        <input
          type="text"
          [ngrxFormControlState]="form.controls.name"
          tabindex="1"
          class="form-field__input secret__input form-field--dirty-feedback"
          [ngClass]="{'form-field__input--invalid' : form.errors._name && form.isSubmitted }"
        >
      </div>
      
      <div class="form-field secret__field secret__field--last">
        <label [for]="form.controls.username.id" class="form-field__label secret__label">
          Username
        </label>
        <input
          type="text"
          [ngrxFormControlState]="form.controls.username"
          tabindex="3"
          class="form-field__input secret__input form-field--dirty-feedback"
          [class.form-field__input--one-action]="!isNew"
        >
          <div class="form-field__actions" *ngIf="!isNew">
            <button type="button"
              ngxClipboard [cbContent]="form.value.username"
              (click)="triggerCopied('username')"
              class="form-field__action">
              <div
                class="button__icon button__icon--no-text"
                [class.button__icon--active]="usernameCopied"
                [inlineSVG]="usernameCopied ? '../assets/svg/check.svg' : '../assets/svg/clipboard.svg'"></div>
            </button>
          </div>
      </div>

      <div class="form-field secret__field">
        <label [for]="form.controls.url.id" class="form-field__label secret__label">
          URL
        </label>
        <input
          type="text"
          [ngrxFormControlState]="form.controls.url"
          tabindex="2"
          class="form-field__input secret__input form-field--dirty-feedback"
          [class.form-field__input--one-action]="!isNew"
        >
        <div class="form-field__actions" *ngIf="!isNew && form.value.url">
          <button type="button"
            (click)="goToUrl(form.value.url)"
            class="form-field__action">
            <div class="button__icon button__icon--no-text" [inlineSVG]="'../assets/svg/external-link.svg'"></div>
          </button>
        </div>
      </div>

      <div class="form-field secret__field secret__field--last">
        <label for="form.controls.password.id" class="form-field__label secret__label">
          Password
        </label>
        <div *ngIf="isNew">
          <input
            tabindex="4"
            name="updatePassword"
            [ngrxFormControlState]="form.controls.password"
            autocomplete="off"
            #realPasswordInput
            class="form-field__input secret__input form-field--dirty-feedback  form-field--dirty-feedback-next form-field__input--two-actions"
          >
          <div class="form-field__actions">
            <button
              type="button"
              class="form-field__action"
              id="generatePasswordButton"
              (click)="generatePassword.emit()">
              <div style="font-size:22px; line-height:40px;">⚂</div>
            </button>
          </div>
        </div>
        <div *ngIf="!isNew">
          <input
            tabindex="4"
            name="updatePassword"
            [ngrxFormControlState]="form.controls.password"
            autocomplete="off"
            [class.u-invisible]="passwordIsMasked"
            #realPasswordInput
            class="form-field__input secret__input form-field--dirty-feedback  form-field--dirty-feedback-next form-field__input--two-actions"
            (blur)="togglePassword()"
          >
          <input
            tabindex="4"
            [class.u-invisible]="!passwordIsMasked"
            class="form-field__input secret__input secret__input--mask form-field--dirty-feedback form-field__input--two-actions"
            value="{{ form.value.password ? 'Click to reveal or edit' : '' }}"
            (focus)="clickMaskedSecret()">
          <div class="form-field__actions">
            <button type="button"
              (click)="toggleViewSecret()"
              class="form-field__action t-password-viewer__actions">
              <div class="button__icon button__icon--no-text" [inlineSVG]="passwordIsMasked ? '../assets/svg/eye.svg' : '../assets/svg/eye-slash.svg'"></div>
            </button>
            <button type="button"
              (click)="copySecret()"
              class="form-field__action">
              <div
                class="button__icon button__icon--no-text"
                [class.button__icon--active]="passwordCopied"
                [inlineSVG]="passwordCopied ? '../assets/svg/check.svg' : '../assets/svg/clipboard.svg'"></div>
            </button>
          </div>
        </div>
      </div>

      <div class="form-field secret__field">
        <label for="form.controls.groups.id" class="form-field__label secret__label">
          Group(s)
        </label>
        <ng-select
          [id]="form.controls.groups.id"
          [ngrxFormControlState]="form.controls.groups"
          class="secret__ng-select"
          [options]="groupOptions"
          multiple="true"
          [ngStyle]="{'background-color': 'white'}"> 
        </ng-select>
      </div>

      <div class="form-field secret__field secret__field--full">
        <a *ngIf="!showNotes" for="notes" class="form-field__label secret__add-link" (click)="toggleShowNotes.emit()">
          Add Notes
        </a>
        <label *ngIf="showNotes" for="form.controls.notes.id" class="form-field__label secret__label">
          Notes
        </label>
        <textarea
          *ngIf="showNotes"
          tabindex="2"
          [ngrxFormControlState]="form.controls.notes"
          class="form-field__textarea secret__textarea form-field--dirty-feedback"
        ></textarea>
      </div>

      <div class="secret__buttons">
        <button
          *ngIf="!isNew"
          class="button button--text button--text-danger"
          type="button"
          (click)="onDeleteSecret()">
          Delete
        </button>
        <button
          *ngIf="isNew"
          class="button button--text button--text-danger"
          type="button"
          (click)="cancel.emit()">
          Cancel
        </button>
        <button
          id="submit-button"
          class="button button--primary"
          [class.button--is-saving]="isUpdating"
          [disabled]="isUpdating"
          type="submit">
          <span *ngIf="isNew">Save New Password</span>
          <span *ngIf="!isNew">Save Changes</span>
        </button>
      </div>
    </div>
    <div class="secret__progress-bar"
      [class.secret__progress-bar--is-saving]="isUpdating"
      [class.secret__progress-bar--is-saved]="isUpdated"></div>
  </form>
</div>
