<div class="delete-page l-container-narrow u-margin-tb-50">
  <a class="text-link text-link--left-caret heading-back-link" [routerLink]=" ['/account'] ">Account Management</a>

  <h2 class="heading-main heading-main--pad-bottom">
     Delete Account
  </h2>
  <div class="text--large">
    Here's what happens when you delete your account:
    <ul>
      <li>Your account is permanently deleted.*</li>
      <li>All of your personal data is permanently deleted.*</li>
      <li>If you shared data with a group, it does not get deleted. However, you will no longer be able to access it.</li>
      <li>This decision <em>cannot</em> be undone.</li>
    </ul>

    <p>Remember, you can <a [routerLink]=" ['/export'] " class="text-link">export</a> your passwords before deleting if you want to keep them.</p>

    <p>If you want to delete your account, type your password below.</p>
  </div>
  <form novalidate [ngrxFormState]="form" (submit)="doAccountDelete.emit(form.value.password)">
      
      <div class="form-field form-field--large">
        <input type="{{ showConfirm ? 'password' : 'text' }}"
          id="password"
          placeholder="Enter Your Password"
          [ngrxFormControlState]="form.controls.password"
          class="form-field__input form-field__input--one-action"
          name="password"
          autocomplete="off"
        />
        <div class="form-field__actions">
          <button type="button" 
            tabindex="-1"
            (click)="toggleConfirm()"
            class="form-field__action">
            <div class="button__icon button__icon--no-text" 
            [inlineSVG]="showConfirm ? '../assets/svg/eye.svg' : '../assets/svg/eye-slash.svg'"></div>
          </button>
        </div>
      </div>
      <ul *ngIf="formErrors" class="form-field__error-list">
        <li class="form-field__error">
           Incorrect password.
        </li>
      </ul>
      <button
      class="button button--primary"
      [disabled]="form.isInvalid || form.isPristine"
      type="submit">
      Delete account permanently
    </button>
    </form>



  <div class="delete-page__footnote">
    *If the service that hosts Passit for you has database backups (and it probably does), your encrypted data will remain in that backup. We don't have a mechanism for restoring that data for one user, so for all reasonable intents and purposes, your account and personal data are gone. However, there is a non-zero chance that your data could be compromised after deleting your account.
  </div>
</div>