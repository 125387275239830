<div class="secret-list">
  <div
    *ngFor="let group of groups"
    class="secret-list-item"
    [class.secret-list-item--is-active]="group.id === groupManaged">
    <group-detail
      [active]="group.id === groupManaged"
      [group]="group"
      (groupSelected)="onGroupWasSelected($event)"
    ></group-detail>
    <group-form
      *ngIf="group.id === groupManaged"
      [group]="group"
      [contacts]="contacts"
      [groupForm]="groupForm"
      [groupManaged]="groupManaged"
      [groupIsUpdating]="groupIsUpdating"
      [groupIsUpdated]="groupIsUpdated"
      [isPrivateOrgMode]="isPrivateOrgMode"
      [groupContacts]="groupContacts"
      [searchedContacts]="searchedContacts"
      [contactLookup]="contactLookup"
      [isNew]="false"
      [singleGroupPendingContacts]="singleGroupPendingContacts"
      (contactSearch)="contactSearch.emit($event)"
      (save)="save.emit()"
      (updateFormValues)="updateFormValues.emit($event)"
      (resetSearch)="resetSearch.emit($event)"
      (delete)="delete.emit($event)"
      class="secret-form secret-form--is-active">
    </group-form>
  </div>
</div>
<div
  *ngIf="groups.length === 0"
  class="list-placeholders"
  [class.list-placeholders--empty]="groups.length === 0">
  <div class="list-placeholder" *ngFor="let number of ' '.repeat(20).split(''), let x = index">
    <div class="list-placeholder__heading">
      <h3 class="list-placeholder__title">
        <div class="list-placeholder__text list-placeholder__text--title"></div>
      </h3>
      <div class="secret__header-actions">
        <button
          type="button"
          disabled="disabled"
          class="button button--placeholder list-placeholder__button">
          <div class="list-placeholder__text list-placeholder__text--button"></div>
        </button>
      </div>
    </div>
  </div>
</div>
