/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./search.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./search.component";
var styles_SearchComponent = [i0.styles];
var RenderType_SearchComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SearchComponent, data: {} });
export { RenderType_SearchComponent as RenderType_SearchComponent };
export function View_SearchComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "form-field"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, [["newquery", 1]], null, 0, "input", [["autofocus", "autofocus"], ["class", "form-field__input"], ["id", "search"], ["placeholder", "Search passwords"], ["type", "text"]], [[8, "disabled", 0], [8, "value", 0]], [[null, "keyup"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("keyup" === en)) {
        var pd_0 = (_co.submitSearch(i1.ɵnov(_v, 1).value) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.secretsLength === 0); var currVal_1 = _co.query; _ck(_v, 1, 0, currVal_0, currVal_1); }); }
export function View_SearchComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "search", [], null, null, null, View_SearchComponent_0, RenderType_SearchComponent)), i1.ɵdid(1, 49152, null, 0, i2.SearchComponent, [], null, null)], null, null); }
var SearchComponentNgFactory = i1.ɵccf("search", i2.SearchComponent, View_SearchComponent_Host_0, { query: "query", secretsLength: "secretsLength" }, { reset: "reset", searchTerm: "searchTerm" }, []);
export { SearchComponentNgFactory as SearchComponentNgFactory };
