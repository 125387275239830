/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./delete.component.ngfactory";
import * as i2 from "./delete.component";
import * as i3 from "@angular/common";
import * as i4 from "./delete.container";
import * as i5 from "../user/user.service";
import * as i6 from "@ngrx/store";
var styles_DeleteContainer = [];
var RenderType_DeleteContainer = i0.ɵcrt({ encapsulation: 2, styles: styles_DeleteContainer, data: {} });
export { RenderType_DeleteContainer as RenderType_DeleteContainer };
export function View_DeleteContainer_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "app-delete", [], null, [[null, "doAccountDelete"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("doAccountDelete" === en)) {
        var pd_0 = (_co.doAccountDelete($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_DeleteComponent_0, i1.RenderType_DeleteComponent)), i0.ɵdid(1, 49152, null, 0, i2.DeleteComponent, [], { form: [0, "form"], formErrors: [1, "formErrors"] }, { doAccountDelete: "doAccountDelete" }), i0.ɵpid(131072, i3.AsyncPipe, [i0.ChangeDetectorRef]), i0.ɵpid(131072, i3.AsyncPipe, [i0.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 1, 0, i0.ɵnov(_v, 2).transform(_co.form$)); var currVal_1 = i0.ɵunv(_v, 1, 1, i0.ɵnov(_v, 3).transform(_co.formErrors$)); _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_DeleteContainer_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-delete-container", [], null, null, null, View_DeleteContainer_0, RenderType_DeleteContainer)), i0.ɵdid(1, 114688, null, 0, i4.DeleteContainer, [i5.UserService, i6.Store], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DeleteContainerNgFactory = i0.ɵccf("app-delete-container", i4.DeleteContainer, View_DeleteContainer_Host_0, {}, {}, []);
export { DeleteContainerNgFactory as DeleteContainerNgFactory };
