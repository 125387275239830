import { RegisterActionTypes } from "./register.actions";
import * as register from "./register.actions";
import {
  FormGroupState,
  createFormGroupState,
  validate,
  markAsUnsubmitted,
  SetValueAction,
  setValue,
  markAsSubmitted,
  updateGroup,
  createFormStateReducerWithUpdate
} from "ngrx-forms";

import { required, pattern, notEqualTo, equalTo } from "ngrx-forms/validation";
import { IRegisterForm, IUrlForm } from "./interfaces";
import { environment } from "../../../environments/environment";
import { RegisterStages, passwordValidators } from "../constants";
import { DEFAULT_API } from "../../constants";
import {
  VerifyEmailSuccess,
  ConfirmEmailTypes
} from "../confirm-email/confirm-email.actions";

export const FORM_ID = "RegisterForm";
export const URL_FORM_ID = "UrlForm";

export const validateAndUpdateFormState = updateGroup<IRegisterForm>({
  email: validate(required, pattern(/^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/)),
  password: (password, form) => {
    return validate(password, [
      ...passwordValidators,
      notEqualTo(form.value.email)
    ]);
  },
  passwordConfirm: (passwordConfirm, form) => {
    if (form.controls.showConfirm.value) {
      return validate(passwordConfirm, [
        required,
        equalTo(form.value.password)
      ]);
    }
    return validate(passwordConfirm, []);
  }
});

const initialRegisterFormState = validateAndUpdateFormState(
  createFormGroupState<IRegisterForm>(FORM_ID, {
    email: "",
    password: "",
    passwordConfirm: "",
    showConfirm: true,
    signUpNewsletter: false,
    rememberMe: environment.extension || environment.nativescript ? true : false
  })
);

const initialUrlFormState = createFormGroupState<IUrlForm>(URL_FORM_ID, {
  url: DEFAULT_API
});

export interface IRegisterState {
  form: FormGroupState<IRegisterForm>;
  urlForm: FormGroupState<IUrlForm>;
  errorMessage: string | null;
  currentStage: RegisterStages;
  isEmailTaken: boolean;
  isUrlValid: boolean | undefined;
  urlDisplayName: string;
  showUrl: boolean;
  hasSubmitStarted: boolean;
  hasSubmitFinished: boolean;
}

export const initialState: IRegisterState = {
  form: initialRegisterFormState,
  urlForm: initialUrlFormState,
  errorMessage: null,
  currentStage: RegisterStages.Email,
  isEmailTaken: false,
  urlDisplayName: DEFAULT_API,
  isUrlValid: false,
  showUrl: false,
  hasSubmitStarted: false,
  hasSubmitFinished: false
};

const formReducer = createFormStateReducerWithUpdate<IRegisterForm>(
  validateAndUpdateFormState
);

export const urlValidateAndUpdateFormState = updateGroup<IUrlForm>({
  url: validate(required)
});
export const urlFormReducer = createFormStateReducerWithUpdate<IUrlForm>(
  urlValidateAndUpdateFormState
);

export function reducer(
  state = initialState,
  action: register.RegisterActions | SetValueAction<any> | VerifyEmailSuccess
): IRegisterState {
  let form = formReducer(state.form, action);
  if (form !== state.form) {
    state = { ...state, form };
  }
  const urlForm = urlFormReducer(state.urlForm, action);
  if (urlForm !== state.urlForm) {
    state = { ...state, urlForm };
  }

  switch (action.type) {
    case SetValueAction.TYPE:
      return {
        ...state,
        isEmailTaken: false
      };

    case RegisterActionTypes.REGISTER:
      return {
        ...state,
        hasSubmitStarted: true,
        hasSubmitFinished: false,
        errorMessage: null
      };

    case RegisterActionTypes.REGISTER_SUCCESS:
      return {
        ...state,
        hasSubmitStarted: false,
        hasSubmitFinished: true,
        currentStage: state.currentStage + 1
      };

    case RegisterActionTypes.REGISTER_FAILURE:
      return {
        ...state,
        hasSubmitStarted: false,
        errorMessage: action.payload
      };

    case RegisterActionTypes.CHECK_EMAIL:
      return {
        ...state,
        form: markAsSubmitted(state.form),
        hasSubmitStarted: true
      };

    case RegisterActionTypes.SET_IS_EMAIL_TAKEN:
      return {
        ...state,
        hasSubmitStarted: false,
        isEmailTaken: action.payload
      };

    case RegisterActionTypes.CHECK_EMAIL_SUCCESS:
      form = markAsUnsubmitted(form);
      state = { ...state, form };
      return {
        ...state,
        hasSubmitStarted: false,
        isEmailTaken: false,
        errorMessage: initialState.errorMessage,
        currentStage: state.currentStage + 1
      };

    case RegisterActionTypes.CHECK_EMAIL_FAILURE:
      return {
        ...state,
        errorMessage: action.payload,
        hasSubmitStarted: false
      };

    case RegisterActionTypes.CHECK_URL:
      return {
        ...state,
        urlForm: markAsSubmitted(state.urlForm)
      };

    case RegisterActionTypes.CHECK_URL_SUCCESS:
      const updateForm = setValue(initialState.form, {
        ...initialState.form.value
      });
      return {
        ...state,
        hasSubmitStarted: false,
        isUrlValid: true,
        showUrl: false,
        urlDisplayName: state.urlForm.value.url,
        currentStage: RegisterStages.Email,
        form: formReducer(updateForm, action)
      };

    case RegisterActionTypes.DISPLAY_URL:
      return {
        ...state,
        showUrl: true
      };

    case RegisterActionTypes.HIDE_URL:
      return {
        ...state,
        showUrl: false
      };

    case RegisterActionTypes.CHECK_URL_FAILURE:
      return {
        ...state,
        isUrlValid: false
      };

    case RegisterActionTypes.INCREMENT_STAGE:
    case ConfirmEmailTypes.VERIFY_EMAIL_SUCCESS:
      return {
        ...state,
        currentStage: state.currentStage + 1
      };

    case RegisterActionTypes.SWITCH_STAGE:
      const newForm = setValue(initialState.form, {
        ...initialState.form.value,
        email: state.form.value.email,
        password:
          action.payload === RegisterStages.Password
            ? state.form.value.password
            : ""
      });
      return {
        ...state,
        currentStage: action.payload,
        form: formReducer(newForm, action)
      };

    case RegisterActionTypes.REGISTER_CLEAR:
      return initialState;

    default:
      return state;
  }
}

export const getErrorMessage = (state: IRegisterState) => state.errorMessage;
export const getForm = (state: IRegisterState) => state.form;
export const getUrlForm = (state: IRegisterState) => state.urlForm;
export const getStage = (state: IRegisterState) => state.currentStage;
export const getUrlDisplayName = (state: IRegisterState) =>
  state.urlDisplayName;
export const getHasSubmitStarted = (state: IRegisterState) =>
  state.hasSubmitStarted;
export const getHasSubmitFinished = (state: IRegisterState) =>
  state.hasSubmitFinished;
export const getIsUrlValid = (state: IRegisterState) => state.isUrlValid;
export const getShowUrl = (state: IRegisterState) => state.showUrl;
export const getIsEmailTaken = (state: IRegisterState) => state.isEmailTaken;
