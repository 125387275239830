import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import { IGroup } from "passit-sdk-js/js/api.interfaces";
import { Observable } from "rxjs";
import * as fromRoot from "../app.reducers";
import { getIsPrivateOrgMode } from "../app.reducers";
import { ResetFormContainer } from "../form/reset-form.container";
import * as fromGroup from "../group/group.reducer";
import * as contactsActions from "./contacts/contacts.actions";
import { ISelectOptions } from "./group-form/group-form.interfaces";
import * as groupActions from "./group.actions";
import { IGroupForm } from "./group.interfaces";

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: "group",
  template: `
    <group-add-button (showAddGroupForm)="showAddGroupForm()"></group-add-button>
    <group-add
      [contacts]="contacts$ | async"
      [groupForm]="groupForm$ | async"
      [groupIsUpdating]="groupIsUpdating$ | async"
      [groupIsUpdated]="groupIsUpdated$ | async"
      [isPrivateOrgMode]="isPrivateOrgMode$ | async"
      [groupContacts]="groupContacts$ | async"
      [searchedContacts]="searchedContacts$ | async"
      [showCreate]="showCreate$ | async"
      [contactLookup]="contactLookup$ | async"
      [singleGroupPendingContacts]="singleGroupPendingContacts$ | async"
      (hideAddSecretForm)="hideAddSecretForm()"
      (resetSearch)="resetContactSearch($event)"
      (contactSearch)="getContactLookup($event)"
      (updateFormValues)="updateFormValues($event)"
      (saveNew)="groupAddSave()"
    ></group-add>
    <app-group-empty-state
      [showCreate]="showCreate$ | async"
      [groups]="groups$ | async"
      [pendingInviteGroups]="pendingInviteGroups$ | async"></app-group-empty-state>
    <app-group-pending-list
      [pendingInviteGroups]="pendingInviteGroups$ | async"
      (acceptGroup)="acceptGroup($event)"
      (declineGroup)="declineGroup($event)"
    ></app-group-pending-list>
    <group-list
      [groups]="groups$ | async"
      [groupManaged]="groupManaged$ | async"
      [groupForm]="groupForm$ | async"
      [contacts]="contacts$ | async"
      [showCreate]="showCreate$ | async"
      [groupIsUpdating]="groupIsUpdating$ | async"
      [groupIsUpdated]="groupIsUpdated$ | async"
      [isPrivateOrgMode]="isPrivateOrgMode$ | async"
      [groupContacts]="groupContacts$ | async"
      [searchedContacts]="searchedContacts$ | async"
      [contactLookup]="contactLookup$ | async"
      [singleGroupPendingContacts]="singleGroupPendingContacts$ | async"
      (groupSelected)="groupSelected($event)"
      (hideAddSecretForm)="hideAddSecretForm()"
      (resetSearch)="resetContactSearch($event)"
      (save)="groupEditSave()"
      (updateFormValues)="updateFormValues($event)"
      (contactSearch)="getContactLookup($event)"
      (delete)="groupDelete($event)"
    ></group-list>
  `
})
export class GroupContainer extends ResetFormContainer implements OnInit {
  showCreate$: Observable<boolean>;
  groups$ = this.store.select(fromGroup.getActiveGroups);
  pendingInviteGroups$ = this.store.select(fromGroup.getPendingGroups);
  groupManaged$ = this.store.select(fromGroup.getGroupManaged);
  singleGroupPendingContacts$ = this.store.select(
    fromGroup.getSingleGroupPendingContacts
  );
  groupForm$: Observable<IGroupForm>;
  contacts$: Observable<ISelectOptions[]>;
  groupManaged?: number | null;
  groupIsUpdating$: Observable<boolean>;
  groupIsUpdated$: Observable<boolean>;
  isPrivateOrgMode$: Observable<boolean>;
  contactLookup$: Observable<ISelectOptions | null>;
  groupContacts$: Observable<ISelectOptions[]>;
  searchedContacts$: Observable<ISelectOptions[] | undefined>;
  group: any[];

  constructor(
    // private contactsService: ContactsService,
    public store: Store<fromRoot.IState>
  ) {
    super(store);
    this.showCreate$ = store.select(fromGroup.getGroupShowCreate);
    this.contacts$ = store.select(fromGroup.getDisplayContacts);
    this.groupIsUpdating$ = store.select(fromGroup.getGroupIsUpdating);
    this.groupIsUpdated$ = store.select(fromGroup.getGroupIsUpdated);
    this.groupManaged$.subscribe(groupManaged => {
      this.groupManaged = groupManaged;
    });
    this.groupForm$ = store.select(fromGroup.getGroupForm);
    this.contactLookup$ = this.store.select(fromGroup.getContactLookup);
    this.isPrivateOrgMode$ = this.store.select(getIsPrivateOrgMode);
    this.groupContacts$ = this.store.select(fromGroup.getGroupContacts);
    this.searchedContacts$ = this.store.select(
      fromGroup.getGroupMembersForDisplay
    );
  }

  ngOnInit() {
    super.ngOnInit();
    this.getContacts();
    this.getGroups();
  }

  /** Refresh contacts list state */
  getContacts() {
    this.store.dispatch(new contactsActions.GetContactsAction());
  }

  /* update groups list state */
  getGroups() {
    this.store.dispatch(new groupActions.GetGroupsAction());
  }

  /** Create a new group and hide the new group form */
  groupAddSave() {
    this.store.dispatch(new groupActions.CreateGroupAction());
  }

  /** Save a existing group and refresh list of all groups */
  groupEditSave() {
    this.store.dispatch(new groupActions.UpdateGroupAction());
  }

  /** Validate if string is an email with decent effeciency */
  validateEmail(email: string) {
    // tslint:disable-next-line:max-line-length
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email.toLowerCase());
  }
  /** Lookup a contact to add to a group if email is valid */
  getContactLookup(email: string) {
    // Don't even try if it's not a email format
    if (this.validateEmail(email)) {
      this.store.dispatch(new groupActions.ContactLookupAction(email));
    }
  }

  updateFormValues(data: IGroupForm) {
    this.store.dispatch(new groupActions.UpdateFormAction(data));
  }

  resetContactSearch(member: ISelectOptions) {
    this.store.dispatch(new groupActions.ContactResetAction(member));
  }

  resetContact() {
    this.store.dispatch(new groupActions.ContactLookupFailureAction());
  }

  /** Delete this group by id, then refresh groups */
  groupDelete(groupId: number) {
    this.store.dispatch(new groupActions.DeleteGroupAction(groupId));
  }

  /** Show the create new group form */
  showAddGroupForm() {
    this.store.dispatch(new groupActions.ClearManagedGroupAction());
    this.store.dispatch(new groupActions.ShowGroupsCreate());
  }

  /** Show the detail/edit view of a group selected */
  groupSelected(group: IGroup) {
    if (this.groupManaged === group.id) {
      this.store.dispatch(new groupActions.ClearManagedGroupAction());
    } else {
      this.store.dispatch(new groupActions.HideGroupsCreate());
      this.store.dispatch(new groupActions.SetManagedGroupAction(group.id));
    }
  }

  hideAddSecretForm() {
    return this.store.dispatch(new groupActions.HideGroupsCreate());
  }

  acceptGroup(group: IGroup) {
    this.store.dispatch(new groupActions.AcceptGroupInvite(group));
  }

  declineGroup(group: IGroup) {
    this.store.dispatch(new groupActions.DeclineGroupInvite(group));
  }
}
