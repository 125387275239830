import { Component, EventEmitter, Input, Output } from "@angular/core";

import { IGroup } from "../group/group.interfaces";

@Component({
  selector: "group-detail",
  styleUrls: ["../list/secret-row/secret-row.component.scss"],
  templateUrl: "./group-detail.html"
})
export class GroupDetailComponent {
  @Input() active: boolean;
  @Input() group: IGroup;

  @Output() groupSelected = new EventEmitter<IGroup>();

  toggleView(onlyWhenClosed: boolean = false) {
    // One of our toggles only needs to work when the secret is closed;
    // the other one acts as a true toggle
    if ((onlyWhenClosed && !this.active) || !onlyWhenClosed) {
      this.groupSelected.emit(this.group);
    }
  }
}
