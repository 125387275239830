
    <confirm-email-component
      [hasStarted]="hasStarted$ | async"
      [hasFinished]="hasFinished$ | async"
      [errorMessage]="errorMessage$ | async"
      [confirmCodeMessage]="confirmCodeMessage$ | async"
      [code]="code"
      (confirmEmail)="confirmEmail($event)"
      (resetRegisterCode)="resetRegisterCode()"
      [inline]="inline"
    ></confirm-email-component>
  