import { Component, EventEmitter, Output, Input } from "@angular/core";
import { IDeleteAccountForm } from "./delete.reducer";
import { FormGroupState } from "ngrx-forms";

@Component({
  selector: "app-delete",
  templateUrl: "./delete.component.html",
  styleUrls: ["./delete.component.scss"]
})
export class DeleteComponent {
  @Output() doAccountDelete = new EventEmitter<string>();
  @Input() form: FormGroupState<IDeleteAccountForm>;
  @Input() formErrors: boolean;
  showConfirm = true;

  toggleConfirm() {
    this.showConfirm = !this.showConfirm;
  }
}
