var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Store } from "@ngrx/store";
import { combineLatest } from "rxjs";
import { map } from "rxjs/operators";
import * as fromRoot from "../app.reducers";
import * as fromGroup from "../group/group.reducer";
import { GetGroupsAction } from "../group/group.actions";
import * as fromList from "./list.reducer";
import { ResetFormContainer } from "../form/reset-form.container";
import * as list from "../list/list.actions";
import { filterOnSearch, filterOnSelectGroup } from "../secrets";
import * as secretActions from "../secrets/secret.actions";
var SecretListContainer = /** @class */ (function (_super) {
    __extends(SecretListContainer, _super);
    function SecretListContainer(store, route) {
        var _this = _super.call(this, store) || this;
        _this.store = store;
        _this.route = route;
        _this.showCreate$ = _this.store.select(fromList.getListShowCreate);
        _this.secretManaged$ = _this.store.select(fromList.getListSecretManaged);
        _this.totalSecretsCount$ = _this.store.select(fromRoot.getSecretsCount);
        _this.searchText$ = _this.store.select(fromList.getSearchText);
        _this.secretIdFromUrl = null;
        _this.firstTimeLoadingComplete$ = _this.store.select(fromList.getFirstTimeLoadingComplete);
        store
            .select(fromList.getListSecretManaged)
            .subscribe(function (secretId) { return (_this.secretManaged = secretId); });
        var secrets$ = combineLatest(_this.store.select(fromRoot.getSecrets), _this.searchText$, _this.secretManaged$).pipe(map(function (result) { return filterOnSearch(result[0], result[1], result[2]); }));
        _this.secrets$ = combineLatest(_this.store.select(fromList.getSelectedGroup), secrets$).pipe(map(function (result) { return filterOnSelectGroup(result[0], result[1]); }));
        // Mobile app needs this.
        _this.secretManagedObject$ = combineLatest(_this.secrets$, _this.secretManaged$).pipe(map(function (_a) {
            var secrets = _a[0], secretManagedId = _a[1];
            return secrets.find(function (secret) { return secret.id === secretManagedId; });
        }));
        _this.groups$ = _this.store.select(fromGroup.getGroups);
        _this.groupOptions$ = _this.store.select(fromGroup.getActiveGroupsAsOptions);
        _this.selectedGroupId$ = _this.store.select(fromList.getSelectedGroup);
        return _this;
    }
    SecretListContainer.prototype.ngOnInit = function () {
        var _this = this;
        _super.prototype.ngOnInit.call(this);
        this.getSecrets();
        // web ext popup uses this
        this.route.params.subscribe(function (params) {
            if (params["id"]) {
                var secretId = Number(params["id"]);
                _this.secretIdFromUrl = secretId;
                _this.store.dispatch(new list.SetManagedSecret(secretId));
            }
        });
        this.getGroups();
    };
    /** Trigger refresh of secrets data */
    SecretListContainer.prototype.getSecrets = function () {
        this.store.dispatch(new secretActions.GetSecretsAction());
    };
    SecretListContainer.prototype.getGroups = function () {
        this.store.dispatch(new GetGroupsAction());
    };
    SecretListContainer.prototype.updateSelectedGroup = function (groupId) {
        this.store.dispatch(new list.UpdateSelectedGroup(groupId));
    };
    /*
    * receive secret from child when secret clicked
    */
    SecretListContainer.prototype.secretWasSelected = function (secretId) {
        if (secretId === this.secretManaged) {
            this.store.dispatch(new list.ClearManagedSecret());
        }
        else {
            this.store.dispatch(new list.SetManagedSecret(secretId));
        }
    };
    SecretListContainer.prototype.searchUpdate = function (term) {
        this.store.dispatch(new list.SetSearchText(term));
    };
    SecretListContainer.prototype.showAddSecretForm = function () {
        this.store.dispatch(new list.ShowCreate());
    };
    SecretListContainer.prototype.hideAddSecretForm = function () {
        this.store.dispatch(new list.HideCreate());
    };
    return SecretListContainer;
}(ResetFormContainer));
export { SecretListContainer };
