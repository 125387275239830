var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { map, exhaustMap, filter, tap, withLatestFrom } from "rxjs/operators";
import "rxjs/add/operator/do";
import "rxjs/add/operator/exhaustMap";
import "rxjs/add/operator/map";
import "rxjs/add/operator/filter";
import { Actions, Effect } from "@ngrx/effects";
import * as fromAccount from "../account.reducer";
import { HandleAPIErrorAction } from "../account.actions";
import { RegisterActionTypes, RegisterFailureAction, SetIsEmailTaken, CheckEmailSuccess, CheckUrlFailureAction, CheckUrlSuccessAction, CheckEmailFailure, RegisterSuccessAction } from "./register.actions";
import { UserService } from "../user";
import { MoonMail } from "../moonmail/moonmail.service";
import { Store } from "@ngrx/store";
var RegisterEffects = /** @class */ (function () {
    function RegisterEffects(actions$, userService, moonmailService, store) {
        var _this = this;
        this.actions$ = actions$;
        this.userService = userService;
        this.moonmailService = moonmailService;
        this.store = store;
        this.checkEmail$ = this.actions$
            .ofType(RegisterActionTypes.CHECK_EMAIL)
            .pipe(withLatestFrom(this.store.select(fromAccount.getRegisterForm)), map(function (_a) {
            var action = _a[0], form = _a[1];
            return form;
        }), exhaustMap(function (form) {
            return _this.userService
                .checkUsername(form.value.email)
                .then(function (resp) {
                if (resp.isAvailable) {
                    return new CheckEmailSuccess();
                }
                else {
                    return new SetIsEmailTaken(true);
                }
            })
                .catch(function (err) {
                if (err.res.status === 404) {
                    return new CheckEmailFailure("Enter a valid email address.");
                }
                else if (err.res.status === 500) {
                    return new CheckEmailFailure("Server Error");
                }
                else if (err.res.status === 0) {
                    return new CheckEmailFailure("Unable to connect to server.");
                }
                return new HandleAPIErrorAction(err);
            });
        }));
        this.checkUrl$ = this.actions$
            .ofType(RegisterActionTypes.CHECK_URL)
            .pipe(withLatestFrom(this.store.select(fromAccount.getUrlForm)), map(function (_a) {
            var action = _a[0], form = _a[1];
            return form;
        }), exhaustMap(function (form) {
            return _this.userService
                .checkAndSetUrl(form.value.url)
                .then(function () {
                return new CheckUrlSuccessAction();
            })
                .catch(function () {
                return new CheckUrlFailureAction();
            });
        }));
        this.newsletterSubscribe$ = this.actions$
            .ofType(RegisterActionTypes.NEWSLETTER_SUBSCRIBE)
            .pipe(withLatestFrom(this.store.select(fromAccount.getRegisterForm)), map(function (_a) {
            var action = _a[0], form = _a[1];
            return form;
        }), filter(function (form) { return form.value.signUpNewsletter; }), map(function (form) { return form.value.email; }), tap(function (email) { return _this.moonmailService.subscribeEmail(email); }));
        this.register$ = this.actions$
            .ofType(RegisterActionTypes.REGISTER)
            .pipe(withLatestFrom(this.store.select(fromAccount.getRegisterForm)), map(function (_a) {
            var action = _a[0], form = _a[1];
            return form.value;
        }), exhaustMap(function (auth) {
            return _this.userService
                .register(auth.email, auth.password, auth.rememberMe ? auth.rememberMe : false)
                .then(function (resp) { return new RegisterSuccessAction(resp); })
                .catch(function (err) {
                if (err.res.status === 500) {
                    return new RegisterFailureAction("Server Error");
                }
                else if (err.res.status === 0) {
                    return new RegisterFailureAction("Unable to connect to server");
                }
                return new RegisterFailureAction("Unknown Error");
            });
        }));
    }
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], RegisterEffects.prototype, "checkEmail$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], RegisterEffects.prototype, "checkUrl$", void 0);
    __decorate([
        Effect({ dispatch: false }),
        __metadata("design:type", Object)
    ], RegisterEffects.prototype, "newsletterSubscribe$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], RegisterEffects.prototype, "register$", void 0);
    return RegisterEffects;
}());
export { RegisterEffects };
