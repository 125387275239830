<div class="list-actions">

  <!--<search
    (searchTerm)="searchUpdate.emit($event)"
    (reset)="searchReset($event)" class="list-actions__search"></search>-->

  <div class="list-actions__actions">
    <button
      id="showAddGroupform"
      type="button"
      (click)="showAddGroupForm.emit()"
      class="button button--gray">
      <div class="button__icon" [inlineSVG]="'../assets/svg/plus.svg'"></div>
      Add New <span class="u-hidden--all-but-xl">Group</span>
    </button>
  </div>

</div>
