var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Actions, Effect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { ListActionTypes } from "../list.actions";
import { SetFormData, SecretFormActionTypes, CreateSecretSuccessAction, DecryptError } from "./secret-form.actions";
import * as fromList from "../list.reducer";
import * as fromRoot from "../../app.reducers";
import * as fromGroup from "../../group/group.reducer";
import { SECRET_FORM_ID } from "./secret-form.reducer";
import { SecretService } from "../../secrets/secret.service";
import { ResetAction, SetValueAction, box, unbox } from "ngrx-forms";
import { ReplaceSecretSuccessAction, RemoveSecretAction } from "../../secrets/secret.actions";
import { GeneratorService } from "../../secrets";
import { withLatestFrom, map, mergeMap, exhaustMap } from "rxjs/operators";
var SecretFormEffects = /** @class */ (function () {
    function SecretFormEffects(actions$, store, secretService, generatorService) {
        var _this = this;
        this.actions$ = actions$;
        this.store = store;
        this.secretService = secretService;
        this.generatorService = generatorService;
        this.decryptSecret$ = this.actions$.pipe(ofType(ListActionTypes.SET_MANAGED_SECRET), withLatestFrom(this.store.select(fromRoot.getSecrets)), map(function (_a) {
            var action = _a[0], secrets = _a[1];
            return secrets.find(function (secret) { return secret.id === action.payload; });
        }), withLatestFrom(this.store.select(fromGroup.getActiveGroups)), mergeMap(function (_a) {
            var secret = _a[0], groups = _a[1];
            if (secret) {
                var groupIds = Array.from(new Set(secret.secret_through_set.map(function (item) { return item.group; }))).filter(function (group) { return group !== null; });
                var formData_1 = {
                    id: secret.id,
                    name: secret.name,
                    username: secret.data.username || "",
                    url: secret.data.url || "",
                    password: "",
                    groups: box(groupIds),
                    notes: ""
                };
                return _this.secretService
                    .showOfflineSecret(secret, groups)
                    .then(function (decrypted) {
                    formData_1.password = decrypted.password || "";
                    formData_1.notes = decrypted.notes || "";
                    return new SetFormData(formData_1);
                })
                    .catch(function (err) {
                    var message = "Couldn't decrypt secret";
                    console.error(message);
                    return new DecryptError({
                        message: message,
                        form: formData_1
                    });
                });
            }
            else {
                return Promise.resolve(new ResetAction(SECRET_FORM_ID));
            }
        }));
        this.createSecret$ = this.actions$.pipe(ofType(SecretFormActionTypes.CREATE_SECRET), withLatestFrom(this.store.select(fromList.getSecretForm)), map(function (_a) {
            var action = _a[0], form = _a[1];
            return {
                name: form.value.name,
                visible_data: {
                    url: form.value.url,
                    username: form.value.username
                },
                secrets: {
                    password: form.value.password,
                    notes: form.value.notes
                },
                groups: form.value.groups
            };
        }), exhaustMap(function (secret) {
            return _this.secretService.createSecrets(secret).then(function (newSecret) {
                return _this.secretService
                    .updateGroupsForSecret(unbox(secret.groups), newSecret.id)
                    .then(function () { return new CreateSecretSuccessAction(); });
            });
        }));
        this.updateSecret$ = this.actions$.pipe(ofType(SecretFormActionTypes.UPDATE_SECRET), withLatestFrom(this.store.select(fromList.getSecretForm)), withLatestFrom(this.store.select(fromList.getSecretId)), map(function (_a) {
            var _b = _a[0], action = _b[0], form = _b[1], id = _a[1];
            return {
                id: id,
                name: form.value.name,
                visible_data: {
                    url: form.value.url,
                    username: form.value.username
                },
                secrets: {
                    password: form.value.password,
                    notes: form.value.notes
                },
                groups: form.value.groups
            };
        }), exhaustMap(function (secret) {
            return _this.secretService
                .updateGroupsForSecret(unbox(secret.groups), secret.id)
                .then(function () {
                return _this.secretService
                    .updateSecret(secret)
                    .then(function (result) { return new ReplaceSecretSuccessAction(result); });
            });
        }));
        this.deleteSecret$ = this.actions$.pipe(ofType(SecretFormActionTypes.DELETE_SECRET), withLatestFrom(this.store.select(fromList.getSecretId)), map(function (_a) {
            var action = _a[0], secretId = _a[1];
            return secretId;
        }), exhaustMap(function (secretId) {
            return _this.secretService
                .deleteSecret(secretId)
                .then(function () { return new RemoveSecretAction(secretId); });
        }));
        this.generatePassword$ = this.actions$.pipe(ofType(SecretFormActionTypes.GENERATE_PASSWORD), exhaustMap(function () {
            return _this.generatorService
                .generatePassword()
                .then(function (password) { return new SetValueAction(SECRET_FORM_ID + ".password", password); });
        }));
    }
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], SecretFormEffects.prototype, "decryptSecret$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], SecretFormEffects.prototype, "createSecret$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], SecretFormEffects.prototype, "updateSecret$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], SecretFormEffects.prototype, "deleteSecret$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], SecretFormEffects.prototype, "generatePassword$", void 0);
    return SecretFormEffects;
}());
export { SecretFormEffects };
