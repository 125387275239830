import { map, exhaustMap, filter, tap, withLatestFrom } from "rxjs/operators";
import "rxjs/add/operator/do";
import "rxjs/add/operator/exhaustMap";
import "rxjs/add/operator/map";
import "rxjs/add/operator/filter";

import { Injectable } from "@angular/core";
import { Actions, Effect } from "@ngrx/effects";
import * as fromAccount from "../account.reducer";

import { HandleAPIErrorAction } from "../account.actions";
import {
  NewsletterSubscribeAction,
  RegisterActionTypes,
  RegisterFailureAction,
  CheckEmailAction,
  SetIsEmailTaken,
  CheckEmailSuccess,
  CheckUrlAction,
  CheckUrlFailureAction,
  CheckUrlSuccessAction,
  RegisterAction,
  CheckEmailFailure,
  RegisterSuccessAction
} from "./register.actions";

import { UserService } from "../user";
import { MoonMail } from "../moonmail/moonmail.service";

import { Store } from "@ngrx/store";
import { IState } from "../../app.reducers";
import { IPassitSDKError } from "../../ngsdk";

@Injectable()
export class RegisterEffects {
  @Effect()
  checkEmail$ = this.actions$
    .ofType<CheckEmailAction>(RegisterActionTypes.CHECK_EMAIL)
    .pipe(
      withLatestFrom(this.store.select(fromAccount.getRegisterForm)),
      map(([action, form]) => form),
      exhaustMap(form => {
        return this.userService
          .checkUsername(form.value.email)
          .then(resp => {
            if (resp.isAvailable) {
              return new CheckEmailSuccess();
            } else {
              return new SetIsEmailTaken(true);
            }
          })
          .catch((err: IPassitSDKError) => {
            if (err.res.status === 404) {
              return new CheckEmailFailure("Enter a valid email address.");
            } else if (err.res.status === 500) {
              return new CheckEmailFailure("Server Error");
            } else if (err.res.status === 0) {
              return new CheckEmailFailure("Unable to connect to server.");
            }
            return new HandleAPIErrorAction(err);
          });
      })
    );

  @Effect()
  checkUrl$ = this.actions$
    .ofType<CheckUrlAction>(RegisterActionTypes.CHECK_URL)
    .pipe(
      withLatestFrom(this.store.select(fromAccount.getUrlForm)),
      map(([action, form]) => form),
      exhaustMap(form => {
        return this.userService
          .checkAndSetUrl(form.value.url)
          .then(() => {
            return new CheckUrlSuccessAction();
          })
          .catch(() => {
            return new CheckUrlFailureAction();
          });
      })
    );

  @Effect({ dispatch: false })
  newsletterSubscribe$ = this.actions$
    .ofType<NewsletterSubscribeAction>(RegisterActionTypes.NEWSLETTER_SUBSCRIBE)
    .pipe(
      withLatestFrom(this.store.select(fromAccount.getRegisterForm)),
      map(([action, form]) => form),
      filter(form => form.value.signUpNewsletter),
      map(form => form.value.email),
      tap(email => this.moonmailService.subscribeEmail(email))
    );

  @Effect()
  register$ = this.actions$
    .ofType<RegisterAction>(RegisterActionTypes.REGISTER)
    .pipe(
      withLatestFrom(this.store.select(fromAccount.getRegisterForm)),
      map(([action, form]) => form.value),
      exhaustMap(auth => {
        return this.userService
          .register(
            auth.email,
            auth.password,
            auth.rememberMe ? auth.rememberMe : false
          )
          .then(resp => new RegisterSuccessAction(resp))
          .catch((err: IPassitSDKError) => {
            if (err.res.status === 500) {
              return new RegisterFailureAction("Server Error");
            } else if (err.res.status === 0) {
              return new RegisterFailureAction("Unable to connect to server");
            }
            return new RegisterFailureAction("Unknown Error");
          });
      })
    );

  constructor(
    private actions$: Actions,
    private userService: UserService,
    private moonmailService: MoonMail,
    private store: Store<IState>
  ) {}
}
