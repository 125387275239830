/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./popup.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./popup-item/popup-item.component.ngfactory";
import * as i3 from "./popup-item/popup-item.component";
import * as i4 from "@angular/common";
import * as i5 from "ng-inline-svg/lib/svg-cache.service";
import * as i6 from "ng-inline-svg/lib/inline-svg.config";
import * as i7 from "@angular/common/http";
import * as i8 from "ng-inline-svg/lib/inline-svg.directive";
import * as i9 from "ng-inline-svg/lib/inline-svg.service";
import * as i10 from "ngx-tooltip/Tooltip";
import * as i11 from "../../shared/search/search.component.ngfactory";
import * as i12 from "../../shared/search/search.component";
import * as i13 from "./popup.component";
var styles_PopupComponent = [i0.styles];
var RenderType_PopupComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PopupComponent, data: {} });
export { RenderType_PopupComponent as RenderType_PopupComponent };
function View_PopupComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "app-popup-item", [["isMatched", "true"]], null, [[null, "autofill"], [null, "openUrl"], [null, "onCopyUsername"], [null, "onCopyPassword"], [null, "setSelected"], [null, "closeSelected"], [null, "onDetail"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("autofill" === en)) {
        var pd_0 = (_co.signIn.emit(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } if (("openUrl" === en)) {
        var pd_1 = (_co.openUrl.emit(_v.context.$implicit) !== false);
        ad = (pd_1 && ad);
    } if (("onCopyUsername" === en)) {
        var pd_2 = (_co.onCopyUsername.emit(_v.context.$implicit) !== false);
        ad = (pd_2 && ad);
    } if (("onCopyPassword" === en)) {
        var pd_3 = (_co.onCopyPassword.emit(_v.context.$implicit) !== false);
        ad = (pd_3 && ad);
    } if (("setSelected" === en)) {
        var pd_4 = (_co.setSelected.emit($event) !== false);
        ad = (pd_4 && ad);
    } if (("closeSelected" === en)) {
        var pd_5 = (_co.setSelected.emit() !== false);
        ad = (pd_5 && ad);
    } if (("onDetail" === en)) {
        var pd_6 = (_co.onDetail.emit(_v.context.$implicit) !== false);
        ad = (pd_6 && ad);
    } return ad; }, i2.View_PopupItemComponent_0, i2.RenderType_PopupItemComponent)), i1.ɵdid(2, 49152, null, 0, i3.PopupItemComponent, [], { secret: [0, "secret"], formFillMessage: [1, "formFillMessage"], isMatched: [2, "isMatched"], isSelected: [3, "isSelected"], passwordCopied: [4, "passwordCopied"], usernameCopied: [5, "usernameCopied"] }, { openUrl: "openUrl", autofill: "autofill", onCopyUsername: "onCopyUsername", onCopyPassword: "onCopyPassword", onDetail: "onDetail", setSelected: "setSelected", closeSelected: "closeSelected" }), (_l()(), i1.ɵted(-1, null, [" >"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.$implicit; var currVal_1 = _co.formFillMessage; var currVal_2 = "true"; var currVal_3 = (_v.context.$implicit.id === _co.selectedSecret); var currVal_4 = (_v.context.$implicit.id === _co.passwordCopied); var currVal_5 = (_v.context.$implicit.id === _co.usernameCopied); _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5); }, null); }
function View_PopupComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "popup__password-list-header popup__password-list-header--matched"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Passwords associated with this site"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PopupComponent_2)), i1.ɵdid(4, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.matchedSecrets; _ck(_v, 4, 0, currVal_0); }, null); }
function View_PopupComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "popup__password-list-header"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["All Passwords"]))], null, null); }
function View_PopupComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "app-popup-item", [], null, [[null, "openUrl"], [null, "onCopyUsername"], [null, "onCopyPassword"], [null, "setSelected"], [null, "closeSelected"], [null, "onDetail"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("openUrl" === en)) {
        var pd_0 = (_co.openUrl.emit(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } if (("onCopyUsername" === en)) {
        var pd_1 = (_co.onCopyUsername.emit(_v.context.$implicit) !== false);
        ad = (pd_1 && ad);
    } if (("onCopyPassword" === en)) {
        var pd_2 = (_co.onCopyPassword.emit(_v.context.$implicit) !== false);
        ad = (pd_2 && ad);
    } if (("setSelected" === en)) {
        var pd_3 = (_co.setSelected.emit($event) !== false);
        ad = (pd_3 && ad);
    } if (("closeSelected" === en)) {
        var pd_4 = (_co.setSelected.emit() !== false);
        ad = (pd_4 && ad);
    } if (("onDetail" === en)) {
        var pd_5 = (_co.onDetail.emit(_v.context.$implicit) !== false);
        ad = (pd_5 && ad);
    } return ad; }, i2.View_PopupItemComponent_0, i2.RenderType_PopupItemComponent)), i1.ɵdid(2, 49152, null, 0, i3.PopupItemComponent, [], { secret: [0, "secret"], formFillMessage: [1, "formFillMessage"], isSelected: [2, "isSelected"], passwordCopied: [3, "passwordCopied"], usernameCopied: [4, "usernameCopied"] }, { openUrl: "openUrl", onCopyUsername: "onCopyUsername", onCopyPassword: "onCopyPassword", onDetail: "onDetail", setSelected: "setSelected", closeSelected: "closeSelected" }), (_l()(), i1.ɵted(-1, null, [" >"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.$implicit; var currVal_1 = _co.formFillMessage; var currVal_2 = (_v.context.$implicit.id === _co.selectedSecret); var currVal_3 = (_v.context.$implicit.id === _co.passwordCopied); var currVal_4 = (_v.context.$implicit.id === _co.usernameCopied); _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); }, null); }
export function View_PopupComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 17, "div", [["class", "popup"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", "popup__header"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openFullApp.emit() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(2, 16777216, null, null, 2, "div", [["class", "popup__logo"]], null, null, null, null, null)), i1.ɵprd(512, null, i5.SVGCacheService, i5.SVGCacheService, [[2, i4.APP_BASE_HREF], [2, i4.PlatformLocation], [2, i6.InlineSVGConfig], i7.HttpClient, i1.RendererFactory2]), i1.ɵdid(4, 737280, null, 0, i8.InlineSVGDirective, [i1.ElementRef, i1.ViewContainerRef, i1.ComponentFactoryResolver, i5.SVGCacheService, i1.Renderer2, i9.InlineSVGService, i1.PLATFORM_ID], { inlineSVG: [0, "inlineSVG"] }, null), (_l()(), i1.ɵeld(5, 16777216, null, null, 4, "a", [["class", "popup__full-app-link"], ["href", "javascript:void(0)"], ["tooltip", "Open full app"], ["tooltipPlacement", "right"]], null, [[null, "click"], [null, "focusin"], [null, "mouseenter"], [null, "focusout"], [null, "mouseleave"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("focusin" === en)) {
        var pd_0 = (i1.ɵnov(_v, 6).show() !== false);
        ad = (pd_0 && ad);
    } if (("mouseenter" === en)) {
        var pd_1 = (i1.ɵnov(_v, 6).show() !== false);
        ad = (pd_1 && ad);
    } if (("focusout" === en)) {
        var pd_2 = (i1.ɵnov(_v, 6).hide() !== false);
        ad = (pd_2 && ad);
    } if (("mouseleave" === en)) {
        var pd_3 = (i1.ɵnov(_v, 6).hide() !== false);
        ad = (pd_3 && ad);
    } if (("click" === en)) {
        var pd_4 = (_co.openFullApp.emit() !== false);
        ad = (pd_4 && ad);
    } return ad; }, null, null)), i1.ɵdid(6, 16384, null, 0, i10.Tooltip, [i1.ViewContainerRef, i1.ComponentFactoryResolver], { content: [0, "content"], tooltipPlacement: [1, "tooltipPlacement"] }, null), (_l()(), i1.ɵeld(7, 16777216, null, null, 2, "div", [["class", "popup__full-app-icon"]], null, null, null, null, null)), i1.ɵprd(512, null, i5.SVGCacheService, i5.SVGCacheService, [[2, i4.APP_BASE_HREF], [2, i4.PlatformLocation], [2, i6.InlineSVGConfig], i7.HttpClient, i1.RendererFactory2]), i1.ɵdid(9, 737280, null, 0, i8.InlineSVGDirective, [i1.ElementRef, i1.ViewContainerRef, i1.ComponentFactoryResolver, i5.SVGCacheService, i1.Renderer2, i9.InlineSVGService, i1.PLATFORM_ID], { inlineSVG: [0, "inlineSVG"] }, null), (_l()(), i1.ɵeld(10, 0, null, null, 1, "search", [["class", "popup__search"]], null, [[null, "searchTerm"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("searchTerm" === en)) {
        var pd_0 = (_co.searchUpdate.emit($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i11.View_SearchComponent_0, i11.RenderType_SearchComponent)), i1.ɵdid(11, 49152, null, 0, i12.SearchComponent, [], { query: [0, "query"] }, { searchTerm: "searchTerm" }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PopupComponent_1)), i1.ɵdid(13, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PopupComponent_3)), i1.ɵdid(15, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PopupComponent_4)), i1.ɵdid(17, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "../../../assets/svg/passit-logo.svg"; _ck(_v, 4, 0, currVal_0); var currVal_1 = "Open full app"; var currVal_2 = "right"; _ck(_v, 6, 0, currVal_1, currVal_2); var currVal_3 = "../../../assets/svg/full-app.svg"; _ck(_v, 9, 0, currVal_3); var currVal_4 = _co.search; _ck(_v, 11, 0, currVal_4); var currVal_5 = _co.matchedSecrets.length; _ck(_v, 13, 0, currVal_5); var currVal_6 = (_co.matchedSecrets.length > 0); _ck(_v, 15, 0, currVal_6); var currVal_7 = _co.secrets; _ck(_v, 17, 0, currVal_7); }, null); }
export function View_PopupComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-popup", [], null, null, null, View_PopupComponent_0, RenderType_PopupComponent)), i1.ɵdid(1, 49152, null, 0, i13.PopupComponent, [], null, null)], null, null); }
var PopupComponentNgFactory = i1.ɵccf("app-popup", i13.PopupComponent, View_PopupComponent_Host_0, { secrets: "secrets", selectedSecret: "selectedSecret", formFillMessage: "formFillMessage", search: "search", matchedSecrets: "matchedSecrets", usernameCopied: "usernameCopied", passwordCopied: "passwordCopied" }, { setSelected: "setSelected", closeSelected: "closeSelected", searchUpdate: "searchUpdate", openFull: "openFull", openUrl: "openUrl", signIn: "signIn", onCopyUsername: "onCopyUsername", onCopyPassword: "onCopyPassword", onDetail: "onDetail", openFullApp: "openFullApp" }, []);
export { PopupComponentNgFactory as PopupComponentNgFactory };
