<div class="popup-item" [class.popup-item--active]="isSelected" (click)="setSelected.emit(secret.id)" >
  <div class="popup-item__name">{{ secret.name }}</div>
  <div class="popup-item__subtitle">
    <div class="popup-item__subtitle__item">
      {{ secret.data.username }}
    </div>
    <div class="popup-item__subtitle__item">
      Click to view more options
    </div>
  </div>
  <div class="popup-item__actions" [class.popup-item__actions--matched]="isMatched">
    <div class="popup-item__action popup-item__action--matched" *ngIf="isMatched" (click)="clickAutofill($event)">
      <div class="popup-item__action-inner">
        <div class="popup-item__action-text">Fill form fields</div>
        <svg
          class="popup-item__icon"
          [class.popup-item__icon--copied]="false">
          <use [attr.xlink:href]="false ? '#icon-check' : '#icon-autofill'"></use>
        </svg>
      </div>
    </div>
    <div
      [title]="isSelected ? '' : 'Copy Username'"
      class="popup-item__action"
      [class.popup-item__action--disabled]="!secret.data.username"
      [class.popup-item__action--copied]="usernameCopied"
      (click)="secret.data.username ? clickUsername($event) : null">
      <div class="popup-item__action-inner">
        <div class="popup-item__action-text">Copy Username</div>
        <svg class="popup-item__icon">
          <use [attr.xlink:href]="usernameCopied ? '#icon-check' : '#icon-copy-username'"></use>
        </svg>
      </div>
    </div>
    <div
      class="popup-item__action"
      [class.popup-item__action--disabled]="!secret.secret_through_set[0].data.password"
      [class.popup-item__action--copied]="passwordCopied"
      [title]="isSelected ? '' : 'Copy Password'"
      (click)="clickPassword($event)">
      <div class="popup-item__action-inner">
        <div class="popup-item__action-text">Copy Password</div>
        <svg class="popup-item__icon">
          <use [attr.xlink:href]="passwordCopied ? '#icon-check' : '#icon-copy-password'"></use>
        </svg>
      </div>
    </div>
    <div class="popup-item__action" [class.popup-item__action--disabled]="!secret.data.url">
      <div class="popup-item__action-inner">
        <a
          (click)="secret.data.url ? openUrl.emit() : null"
          class="popup-item__action-text">
          Open saved URL
        </a>
        <svg class="popup-item__icon">
          <use xlink:href="#icon-external-link"></use>
        </svg>
      </div>
    </div>
    <div class="popup-item__action">
      <div class="popup-item__action-inner">
        <a (click)="onDetail.emit()" class="popup-item__action-text">View/Edit Details</a>
        <svg class="popup-item__icon">
          <use xlink:href="#icon-edit"></use>
        </svg>
      </div>
    </div>
  </div>
  <div class="popup-item__caret">∟</div>
</div>

<div class="autofill__message__container" *ngIf="formFillMessage !== '' && isSelected">
  <div>{{formFillMessage}}</div> 
</div>
