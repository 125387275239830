import { Action } from "@ngrx/store";
import { IConf } from "passit-sdk-js/js/api.interfaces";

export enum ConfTypes {
  SET_CONF = "[conf] Set Conf",
  GET_CONF = "[conf] Get Conf",
  SET_IS_POPUP = "[conf] Set Is Popup"
}

/** Set entire conf state from server */
export class SetConfAction implements Action {
  readonly type = ConfTypes.SET_CONF;

  constructor(public payload: IConf) {}
}

export class SetIsPopup implements Action {
  readonly type = ConfTypes.SET_IS_POPUP;
}

export class GetConfAction implements Action {
  readonly type = ConfTypes.GET_CONF;
}

export type Actions = SetConfAction | GetConfAction | SetIsPopup;
