import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  SimpleChange,
  SimpleChanges,
  ViewChild,
  OnChanges,
  AfterViewInit
} from "@angular/core";

import * as api from "passit-sdk-js/js/api.interfaces";
import { ISelectOptions } from "../group/group-form/group-form.interfaces";

@Component({
  selector: "secret-list-component",
  styleUrls: ["./list.component.scss"],
  templateUrl: "./list.component.html"
})
export class SecretListComponent implements OnChanges, AfterViewInit {
  secretAnimated = true; // Default to true in case it starts in open state
  animateAddForm = false;
  changedSecretManaged: SimpleChange;

  @Input() secrets: api.ISecret[];
  @Input() groups: api.IGroup[];
  @Input() groupOptions: ISelectOptions[];
  @Input() selectedGroupId: number | null;
  @Input() totalSecretsCount: number;
  @Input() secretManaged: number;
  @Input() showCreate: boolean;
  @Input() secretIdFromUrl: number;
  @Input() searchText: string;
  @Input() firstTimeLoadingComplete: boolean;

  @Output() secretWasSelected = new EventEmitter<number>();
  @Output() hideAddSecretForm = new EventEmitter();
  @Output() showAddSecretForm = new EventEmitter();
  @Output() updateSelectedGroup = new EventEmitter<number | null>();
  @Output() searchUpdate = new EventEmitter<string>();

  @ViewChild("secretList") secretList: ElementRef;
  @ViewChild("groupFilterSelect") groupFilterSelect: any;

  ngOnChanges(changes: SimpleChanges) {
    const changedSecretManaged = changes["secretManaged"];

    if (changedSecretManaged) {
      this.changedSecretManaged = changedSecretManaged;
      this.scrollActiveSecretRowIntoView();
    }
  }

  ngAfterViewInit() {
    this.scrollActiveSecretRowIntoView();
  }

  scrollActiveSecretRowIntoView() {
    if (
      this.changedSecretManaged &&
      typeof this.changedSecretManaged.currentValue === "number" &&
      this.changedSecretManaged.currentValue !==
        this.changedSecretManaged.previousValue
    ) {
      const secretManagedElement = this.secretList.nativeElement.querySelector(
        "#secret-list-item--" + this.changedSecretManaged.currentValue
      );
      if (secretManagedElement) {
        if (this.secretIdFromUrl === this.changedSecretManaged.currentValue) {
          secretManagedElement.scrollIntoView({ behavior: "smooth" });
        } else {
          setTimeout(
            () => secretManagedElement.scrollIntoView({ behavior: "smooth" }),
            200
          );
        }
      }
    }
  }

  hideAdd() {
    this.hideAddSecretForm.emit();
    this.animateAddForm = false;
  }

  showAdd() {
    this.showAddSecretForm.emit();
    setTimeout(() => (this.animateAddForm = true), 0);
  }

  updateGroup(event: ISelectOptions) {
    this.updateSelectedGroup.emit(event.value);
  }

  resetSelectedGroup() {
    this.groupFilterSelect.clear();
    this.updateSelectedGroup.emit(null);
  }

  /*
  * receive secret from child when secret clicked
  */
  onSecretWasSelected(secret: api.ISecret) {
    this.secretAnimated = false;
    if (this.secretManaged !== secret.id) {
      setTimeout(() => {
        this.secretAnimated = true;
      }, 1);
    }
    this.secretWasSelected.emit(secret.id);
  }
}
