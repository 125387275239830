<div class="account-container">
  <div class="account-column account-column--left">
    <div class="account-column__inner account-column__inner--left">
      <div class="account-column__logo" [inlineSVG]="'../assets/svg/passit-logo.svg'"></div>
      <h1 class="account-column__heading">Gain peace of mind for your passwords</h1>
      <p class="account-column__text">
        Store your passwords. Make them safer. Access them anywhere. Share them with others. It all starts here.
      </p>
    </div>
  </div>
  <div class="account-column account-column--right">
    <div class="account-column__inner account-column__inner--right">
      <div class="account-column__right-heading">
        <h1 class="heading-medium">Sign&nbsp;Up</h1>
        <div class="account-column__link">
          <a class="text-link text-link--caret text-link--large" id="btn-signup" href="javascript:void(0)" (click)="toggleDisplay()">Already&nbsp;registered? Log&nbsp;in</a>
        </div>
      </div>

      <form novalidate [ngrxFormState]="form" (submit)="submit()">

        <app-non-field-messages *ngIf="errorMessage" [messages]="[errorMessage]"></app-non-field-messages>

        <div class="form-field form-field--large">

          <label [for]="form.controls.email.id" class="register-step__heading" [class.form-field__label]="stageValue === stages.Email"
            [class.register-step__heading--completed]="stageValue !== stages.Email">
            Email
            <span class="register-step__icon" [class.register-step__icon--active]="stageValue !== stages.Email" [inlineSVG]="'../assets/svg/check.svg'"></span>
          </label>
          <div [class.register-step__verified-input]="stageValue !== stages.Email">
            <input
              type="email"
              [ngrxFormControlState]="form.controls.email"
              autofocus
              class="form-field__input"
              [disabled]="stageValue !== stages.Email"
              [class.form-field__input--invalid]="(form.errors._email || isEmailTaken) && form.isSubmitted"
              [class.form-field__input--valid]="!form.errors._email && !isEmailTaken"
              [class.register-step__verified-content]="stageValue !== stages.Email"
            />
            <a
              href="javascript:void(0)"
              (click)="switchCurrentStage(stages.Email)"
              *ngIf="stageValue === stages.Password || stageValue === stages.Newsletter"
              class="register-step__verified-content--edit"
            >(edit)</a>
          </div>


          <div *ngIf="stageValue === stages.Email">
            <div class="register-step__feedback register-step__feedback--fixed-height">
              <span [class.register-step__feedback__element--active]="!form.isSubmitted && !form.controls.email.isValid" class="register-step__feedback__element">
                Your email address is your unique username.
                <br /> We don’t share it with others.
              </span>
              <span [class.register-step__feedback__element--active]="form.controls.email.isValid && !isEmailTaken" class="register-step__feedback__element">
                Looks good to us! Make sure it’s correct, then press &ldquo;Next&rdquo; to go to the next step.
              </span>
              <ul *ngIf="form.isSubmitted && (!form.controls.email.isValid || isEmailTaken)" class="form-field__error-list register-step__error-list">
                <li *ngIf="isEmailTaken" class="form-field__error">
                  There is already an account registered with this email address.
                  <a id="btn-signup-taken" href="javascript:void(0)" (click)="goToLoginWith()" class="text-link text-link--caret text-link--large">Log in?</a>
                </li>
                <li *ngIf="form.errors._email && form.isSubmitted" class="form-field__error">
                  Enter a valid email address.
                </li>
              </ul>
            </div>

            <div class="register-step__actions">
              <button id="email-button" class="button button--primary">
                Next
              </button>

              <progress-indicator [inProgress]="hasSubmitStarted" inProgressText="Verifying" [completed]="hasSubmitFinished"
              completedText=""></progress-indicator>
            </div>

          </div>
        </div>
        <div class="form-field form-field--large">
          <label [for]="form.controls.password.id" class="register-step__heading" [class.form-field__label]="stageValue === stages.Password"
            [class.register-step__heading--completed]="stageValue !== stages.Password && !form.errors._password" [class.register-step__heading--inactive]="stageValue !== stages.Password && form.errors._password">
            Password
            <span class="register-step__icon" [class.register-step__icon--active]="stageValue !== stages.Password && !form.errors._password"
              [inlineSVG]="'../assets/svg/check.svg'"></span>
          </label>

          <div class="form-field form-field--large">
            <div
              *ngIf="stageValue !== stages.Email"
              class="form-field__input-with-actions"
              [class.register-step__verified-input]="stageValue !== stages.Password">

              <input #passwordInput [type]="form.value.showConfirm ? 'password' : 'text'" [ngrxFormControlState]="form.controls.password"
                autocomplete="off" class="form-field__input form-field__input--one-action" [class.form-field__input--invalid]="form.errors._password && !form.errors._password?.minLength || form.errors._password && form.isSubmitted"
                [class.form-field__input--valid]="!form.errors._password" [class.register-step__verified-content]="stageValue !== stages.Password"
                [disabled]="stageValue !== stages.Password" />

              <a href="javascript:void(0)" (click)="switchCurrentStage(stages.Password)" *ngIf="stageValue === stages.Newsletter" class="register-step__verified-content--edit">(edit)</a>
              <div *ngIf="stageValue === stages.Password" class="form-field__actions">
                <button type="button" tabindex="-1" (click)="toggleShowConfirm.emit()" class="form-field__action">
                  <div id="eye-icon" class="button__icon button__icon--no-text" [inlineSVG]="form.value.showConfirm ? '../assets/svg/eye.svg' : '../assets/svg/eye-slash.svg'"></div>
                </button>
              </div>
            </div>


            <div *ngIf="stageValue == stages.Password">
              <div class="register-step__feedback">
                <ul *ngIf="form.controls.password.isInvalid && form.isSubmitted" class="form-field__error-list register-step__error-list">
                    <li *ngIf="form.errors._password.required" class="form-field__error">
                        Enter a password for your account.
                    </li>
                  </ul>
                <div *ngIf="form.errors._password?.minLength" class="register-step__feedback__static-element">
                  Make a
                  <strong>long</strong> password.
                  <span *ngIf="form.errors._password?.minLength && form.value.password.length < 1">
                    We require at least 10 characters.
                  </span>
                  <span *ngIf="form.errors._password?.minLength && form.value.password.length >= 1">
                    Enter at least {{ form.errors._password.minLength.minLength - form.value.password.length }} more character<span *ngIf="form.errors._password.minLength.minLength - form.value.password.length !== 1">s</span>.
                  </span>
                </div>

                <ul *ngIf="form.controls.password.isInvalid && form.value.password.length >= 10" class="form-field__error-list register-step__error-list">
                  <li *ngIf="form.errors._password.hasUniqueChars" class="form-field__error">
                    Password must have more than 5 unique characters.
                  </li>
                  <li *ngIf="form.errors._password.notNumeric" class="form-field__error">
                    Password must not be only numbers.
                  </li>
                  <li *ngIf="form.errors._password.notEqualTo" class="form-field__error">
                    Password must not be email address.
                  </li>
                </ul>
                <div *ngIf="form.errors._password" class="register-step__feedback__static-element">
                  Make a
                  <strong>memorable</strong> password, or write it down.
                  <br />Passit cannot reset your password if you forget it.
                </div>
                <div *ngIf="!form.errors._password && !form.value.passwordConfirm && form.value.showConfirm" class="register-step__feedback__static-element">
                  Good! Now click the eye icon or retype your password below to confirm what you entered.
                </div>
                <div *ngIf="!form.errors._password && !form.value.showConfirm" class="form-field__note u-margin-b-20">
                  Remember, Passit
                  <strong>cannot</strong> reset a forgotten password. Consider writing it down and storing it somewhere safe.
                </div>
              </div>
            </div>
          </div>

          <div *ngIf="stageValue == stages.Password">
            <div class="form-field form-field--large" *ngIf="form.value.showConfirm">
              <label [for]="form.controls.passwordConfirm.id" class="form-field__label">Confirm Password</label>
              <input type="password" [ngrxFormControlState]="form.controls.passwordConfirm" 
              class="form-field__input" 
              [class.form-field__input--invalid]="form.errors._passwordConfirm && form.isSubmitted"
              [class.form-field__input--valid]="!form.errors._passwordConfirm" />
              <div class="register-step__feedback">
                <div *ngIf="!form.errors._passwordConfirm && form.value.showConfirm" class="form-field__note">
                  That’s a match! Press &ldquo;Next&rdquo; to continue.
                </div>
                <div *ngIf="!form.errors._passwordConfirm && form.value.showConfirm" class="form-field__note u-margin-b-20">
                  Remember, Passit
                  <strong>cannot</strong> reset a forgotten password. Consider writing it down and storing it somewhere safe.
                </div>
                <ul *ngIf="form.errors._passwordConfirm &&
                         form.value.showConfirm &&
                         (form.value.passwordConfirm.length >= form.value.password.length || form.isSubmitted)" class="form-field__error-list register-step__error-list">
                  <li *ngIf="form.errors._passwordConfirm.equalTo" class="form-field__error">
                    Passwords do not match.
                  </li>
                </ul>
              </div>
            </div>

            <button id="password-button" class="button button--primary">
              Next
            </button>
          </div>
        </div>


        <div class="form-field form-field--large">
          <label [for]="form.controls.signUpNewsletter.id" class="register-step__heading" [class.form-field__label]="stageValue === stages.Newsletter"
            [class.register-step__heading--completed]="stageValue !== stages.Newsletter && (stageValue === stages.ConfirmEmail || stageValue === stages.Verified)"
            [class.register-step__heading--inactive]="stageValue !== stages.Newsletter">
            Newsletter
            <span class="register-step__icon" [class.register-step__icon--active]="stageValue !== stages.Newsletter && (stageValue === stages.ConfirmEmail || stageValue === stages.Verified)"
              [inlineSVG]="'../assets/svg/check.svg'"></span>
          </label>
          <div *ngIf="stageValue !== stages.Newsletter && (stageValue === stages.ConfirmEmail || stageValue === stages.Verified)" class="register-step__verified-content">
            {{ form.value.signUpNewsletter ? "Yes" : "No" }}
          </div>
          <div *ngIf="stageValue === stages.Newsletter">
            <label [for]="form.controls.signUpNewsletter.id" class="form-field__checkbox form-field__checkbox--block register-step__newsletter-input">
              <input #newsletterInput type="checkbox" class="form-field__checkbox__input" [ngrxFormControlState]="form.controls.signUpNewsletter" />
              <div class="form-field__checkbox__check form-field__checkbox__check--inline">
                <div [inlineSVG]="'../assets/svg/check.svg'"></div>
              </div>
              <div class="form-field__checkbox__text">
                Subscribe to Passit’s newsletter to get extra updates, tips, and offers
              </div>
            </label>
            
            <div class="register-step__actions">
              <button id="newsletter-button" class="button button--primary">
                Next
              </button>
              
              <progress-indicator [inProgress]="hasSubmitStarted" inProgressText="" [completed]="hasSubmitFinished"
              completedText=""></progress-indicator>
            </div>
            
            <div class="register-step__feedback" [@flyInOut]="true" *ngIf="hasSubmitStarted">
              Passit is creating your account. This can take a little time, so don’t navigate away.
            </div>
          </div>
        </div>

        <div
          class="form-field form-field--large"
          [class.form-field--no-bottom-margin]="stageValue === stages.ConfirmEmail">
          <label for="userEmail" class="register-step__heading" [class.form-field__label]="stageValue === stages.ConfirmEmail" [class.register-step__heading--completed]="stageValue === stages.Verified"
            [class.register-step__heading--inactive]="stageValue !== stages.ConfirmEmail">
            Verify Email Address
            <span class="register-step__icon" [class.register-step__icon--active]="stageValue === stages.Verified" [inlineSVG]="'../assets/svg/check.svg'"></span>
          </label>
          <div *ngIf="stageValue === stages.Verified" class="register-step__verified-content">
            Verified
          </div>
        </div>

        <confirm-email-container
          *ngIf="stageValue === stages.ConfirmEmail"
          inline="true"></confirm-email-container>

        <div *ngIf="stageValue === stages.Verified" class="register-step__heading--verified form-field form-field--large">
          You're ready to start securing your passwords!
        </div>

        <div *ngIf="stageValue === stages.Verified" class="form-field__note u-margin-b-20">
          Remember, Passit
          <strong>cannot</strong> reset a forgotten password. Consider writing it down and storing it somewhere safe.
        </div>

        <div class="auth-form__actions">
          <button class="register-form__submit button button--large button--green" [disabled]="stageValue !== stages.Verified">
            Get Started
          </button>
        </div>

      </form>

      <form novalidate [ngrxFormState]="urlForm" (submit)="urlSubmit()">
        <div *ngIf="isExtension">
          <div>
            <p class="register-form__paragraph">You are signing up for Passit at
              <a class="text-link text-link--large" id="btn-signup" href="https://{{ urlDisplayName }}" target="_blank">{{urlDisplayName}}</a>. Access the website when you're on the go.</p>
            <p class="register-form__paragraph register-form__paragraph--small">If you're signing up with your company or if you're self-hosting, you can
              <a class="text-link text-link--large" id="btn-signup" (click)="displayUrl()" href="javascript:void(0)">change the server you connect to</a>.</p>
          </div>
          <div *ngIf="showUrl">
            <div class="form-field form-field--large">
              <label [for]="urlForm.controls.url.id" class="form-field__label">Server</label>
              <input type="url" placeholder="app.passit.io" [ngrxFormControlState]="urlForm.controls.url" class="form-field__input" [class.form-field__input--invalid]="isUrlValid === false && urlForm.isSubmitted"
              />
              <div class="form-field__note">
                <div class="form-field__error" *ngIf="isUrlValid === false && urlForm.isSubmitted">
                  The URL you entered is not a valid Passit server.
                </div>
                Enter an alternate server URL here. For example, passit.mycompany.com.
              </div>
            </div>
            <button class="button button--primary">
              Change Server
            </button>
            <a class="register-form__change-server-cancel button button--text" (click)="hideUrl()" href="javascript:void(0)">Cancel</a>
          </div>

        </div>
      </form>
    </div>
  </div>
</div>