import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

@Injectable()
export class MoonMail {
  url = "https://api.moonmail.io/lists/cj5cigh41000601pb7qr6a6s4/subscribe";
  u = "Z29vZ2xlLW9hdXRoMnwxMDc5MTk2MzIzODU5NTg2MTEzNDE";

  constructor(private http: HttpClient) {}

  subscribeEmail(formEmail: string) {
    return this.http.post(this.url, {
      u: this.u,
      email: formEmail
    })
    .toPromise();
  }
}
