import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild
} from "@angular/core";
import { ImportableSecret } from "./importableSecret";
import { ImporterService } from "./importer.service";

@Component({
  selector: "importer-component",
  templateUrl: "./importer.component.html",
  styleUrls: [
    "./importer.styles.scss",
    "../account/change-password/change-password.styles.scss"
  ]
})
export class ImporterComponent {
  previewHeader = ["name", "username", "url", "password", "notes"];
  importText = "";
  autoPreview = true;
  toggleAllState = true;
  toggleAllUseMinus = false;
  tooltipDisabled = false;

  @Input() fileName: string;
  @Input() finishedSavingMessage: string;
  @Input() hasStartedSaving: boolean;
  @Input() hasFinishedSaving: boolean;
  @Input() secrets: ImportableSecret[];
  @Output() resetForm = new EventEmitter();
  @Output() resetFinishedMessage = new EventEmitter();
  @Output() setFileName = new EventEmitter<string>();
  @Output() setImportableSecrets = new EventEmitter<ImportableSecret[]>();
  @Output() saveSecrets = new EventEmitter<ImportableSecret[]>();
  @ViewChild("fileInput") fileInput: ElementRef;

  constructor(private importerService: ImporterService) {}

  onReset() {
    this.resetForm.emit();
  }

  onSubmit() {
    this.saveSecrets.emit(this.secrets);
  }

  togglePreview() {
    this.autoPreview = !this.autoPreview;
  }

  /*
  ** Processing the CSV data
  */
  cleanFile($event: Event): void {
    const target = $event.target as HTMLInputElement;
    if (target.files!.length > 0) {
      this.readThis(target);
    }
  }

  readThis(inputValue: any): void {
    const file: File = inputValue.files[0];
    this.setFileName.emit(file.name);
    const myReader: FileReader = new FileReader();

    myReader.onloadend = e => {
      this.resetFinishedMessage.emit();
      this.fileInput.nativeElement.value = "";
      this.importText = myReader.result;
      const secrets = this.importerService.textToSecrets(this.importText);
      this.setImportableSecrets.emit(secrets);

      // TODO? check if data appear valid before allowing user to attempt to import?
    };

    myReader.readAsText(file);
  }

  /*
  ** Table display
  */
  toggleAll() {
    const secrets = this.secrets.slice(0);
    secrets.forEach((secret, index: number) => {
      const updatedSecret = Object.assign({}, secret);
      if (secret.importable) {
        updatedSecret.doImport = !this.toggleAllState;
        secrets[index] = updatedSecret;
      }
    });
    this.toggleAllState = !this.toggleAllState;
    this.setImportableSecrets.emit(secrets);
    this.toggleAllUseMinus = false;
  }

  toggleImport(i: number) {
    const secrets = this.secrets.slice(0);
    if (secrets[i].importable) {
      const updatedSecret = Object.assign({}, secrets[i]);
      updatedSecret.doImport = !updatedSecret.doImport;
      secrets[i] = updatedSecret;
      this.setImportableSecrets.emit(secrets);
      this.setToggleAllState(secrets);
    }
  }

  setToggleAllState(secrets: ImportableSecret[]) {
    let secretsToImport = 0;
    secrets.forEach((secret, index: number) => {
      if (secret.doImport) {
        secretsToImport++;
      }
    });
    if (secrets.length !== secretsToImport && secretsToImport !== 0) {
      this.toggleAllState = true;
      this.toggleAllUseMinus = true;
    } else {
      this.toggleAllUseMinus = false;
      if (secretsToImport === 0) {
        this.toggleAllState = false;
      }
    }
  }

  // Gives a performance boost with no side effects?
  // TODO: I don't think this secret will ever have an ID, if it is an
  // ImportableSecret. If so, should remove this code.
  trackSecret(index: number, secret: { id: number }) {
    return secret ? secret.id : undefined;
  }

  checkIfTooltipIsNecessary($event: Event, maxWidth: number) {
    const target = $event.target as HTMLElement;
    const span =
      target.tagName === "DIV"
        ? (target.firstElementChild as HTMLElement)
        : target;
    const textWidth = span ? span.offsetWidth : -1;
    this.tooltipDisabled = textWidth >= maxWidth ? false : true;
  }
}
