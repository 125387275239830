var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
import { createFormGroupState, validate, createFormStateReducerWithUpdate, updateGroup } from "ngrx-forms";
import { oldPasswordValidators } from "../constants";
import { DeleteAccountActionTypes } from "./delete.actions";
export var FORM_ID = "Delete Account Form";
var initialFormState = createFormGroupState(FORM_ID, {
    password: ""
});
var initialState = {
    form: initialFormState,
    displayDeleteErrors: false
};
export var validateAndUpdateFormState = updateGroup({
    password: validate(oldPasswordValidators)
});
export var formReducer = createFormStateReducerWithUpdate(validateAndUpdateFormState);
export function reducer(state, action) {
    if (state === void 0) { state = initialState; }
    var form = formReducer(state.form, action);
    if (form !== state.form) {
        state = __assign({}, state, { form: form });
    }
    switch (action.type) {
        case DeleteAccountActionTypes.DELETE_FAILURE:
            return __assign({}, state, { form: initialFormState, displayDeleteErrors: true });
        case DeleteAccountActionTypes.RESET_STATE:
            return initialState;
        default:
            return state;
    }
}
export var getForm = function (state) { return state.form; };
export var getDeleteFormErrors = function (state) {
    return state.displayDeleteErrors;
};
