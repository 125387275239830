import { Injectable } from "@angular/core";
import { Actions, Effect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { ListActionTypes, SetManagedSecret } from "../list.actions";
import {
  SetFormData,
  UpdateSecret,
  SecretFormActionTypes,
  DeleteSecret,
  GeneratePassword,
  CreateSecret,
  CreateSecretSuccessAction,
  DecryptError
} from "./secret-form.actions";
import * as fromList from "../list.reducer";
import * as fromRoot from "../../app.reducers";
import * as fromGroup from "../../group/group.reducer";
import { SECRET_FORM_ID } from "./secret-form.reducer";
import { SecretService } from "../../secrets/secret.service";
import { ResetAction, SetValueAction, box, unbox } from "ngrx-forms";
import {
  ReplaceSecretSuccessAction,
  RemoveSecretAction
} from "../../secrets/secret.actions";
import { GeneratorService } from "../../secrets";
import { withLatestFrom, map, mergeMap, exhaustMap } from "rxjs/operators";

@Injectable()
export class SecretFormEffects {
  @Effect()
  decryptSecret$ = this.actions$.pipe(
    ofType<SetManagedSecret>(ListActionTypes.SET_MANAGED_SECRET),
    withLatestFrom(this.store.select(fromRoot.getSecrets)),
    map(([action, secrets]) =>
      secrets.find(secret => secret.id === action.payload)
    ),
    withLatestFrom(this.store.select(fromGroup.getActiveGroups)),
    mergeMap(
      ([secret, groups]): Promise<DecryptError | SetFormData | ResetAction> => {
        if (secret) {
          const groupIds = Array.from(
            new Set(secret.secret_through_set.map(item => item.group))
          ).filter(group => group !== null) as number[];
          const formData = {
            id: secret.id,
            name: secret.name,
            username: secret.data.username || "",
            url: secret.data.url || "",
            password: "",
            groups: box(groupIds),
            notes: ""
          };
          return this.secretService
            .showOfflineSecret(secret, groups)
            .then(decrypted => {
              formData.password = decrypted.password || "";
              formData.notes = decrypted.notes || "";
              return new SetFormData(formData);
            })
            .catch(err => {
              const message = "Couldn't decrypt secret";
              console.error(message);
              return new DecryptError({
                message,
                form: formData
              });
            });
        } else {
          return Promise.resolve(new ResetAction(SECRET_FORM_ID));
        }
      }
    )
  );

  @Effect()
  createSecret$ = this.actions$.pipe(
    ofType<CreateSecret>(SecretFormActionTypes.CREATE_SECRET),
    withLatestFrom(this.store.select(fromList.getSecretForm)),
    map(([action, form]) => {
      return {
        name: form.value.name,
        visible_data: {
          url: form.value.url,
          username: form.value.username
        },
        secrets: {
          password: form.value.password,
          notes: form.value.notes
        },
        groups: form.value.groups
      };
    }),
    exhaustMap(secret => {
      return this.secretService.createSecrets(secret!).then(newSecret => {
        return this.secretService
          .updateGroupsForSecret(unbox(secret.groups), newSecret.id)
          .then(() => new CreateSecretSuccessAction());
      });
    })
  );

  @Effect()
  updateSecret$ = this.actions$.pipe(
    ofType<UpdateSecret>(SecretFormActionTypes.UPDATE_SECRET),
    withLatestFrom(this.store.select(fromList.getSecretForm)),
    withLatestFrom(this.store.select(fromList.getSecretId)),
    map(([[action, form], id]) => {
      return {
        id: id!,
        name: form.value.name,
        visible_data: {
          url: form.value.url,
          username: form.value.username
        },
        secrets: {
          password: form.value.password,
          notes: form.value.notes
        },
        groups: form.value.groups
      };
    }),
    exhaustMap(secret => {
      return this.secretService
        .updateGroupsForSecret(unbox(secret.groups), secret.id)
        .then(() =>
          this.secretService
            .updateSecret(secret)
            .then(result => new ReplaceSecretSuccessAction(result))
        );
    })
  );

  @Effect()
  deleteSecret$ = this.actions$.pipe(
    ofType<DeleteSecret>(SecretFormActionTypes.DELETE_SECRET),
    withLatestFrom(this.store.select(fromList.getSecretId)),
    map(([action, secretId]) => secretId),
    exhaustMap(secretId => {
      return this.secretService
        .deleteSecret(secretId!)
        .then(() => new RemoveSecretAction(secretId!));
    })
  );

  @Effect()
  generatePassword$ = this.actions$.pipe(
    ofType<GeneratePassword>(SecretFormActionTypes.GENERATE_PASSWORD),
    exhaustMap(() =>
      this.generatorService
        .generatePassword()
        .then(
          password => new SetValueAction(SECRET_FORM_ID + ".password", password)
        )
    )
  );

  constructor(
    private actions$: Actions,
    private store: Store<fromRoot.IState>,
    private secretService: SecretService,
    private generatorService: GeneratorService
  ) {}
}
