import { Injectable } from "@angular/core";

import { sodium } from "simple-asymmetric-js/js/asymmetric_encryption";

interface IPasswordConfig {
  /** String of allow chars for password */
  chars?: string;
  /** number of chars to return for password */
  howMany?: number;
}

@Injectable()
export class GeneratorService {
  readonly LOWER = "abcdefghijklmnopqrstuwxyz";
  readonly UPPER = "ABCDEFGHIJKLMNOPQRSTUWXYZ";
  readonly NUMBERS = "0123456789";
  /** easy to reach special chars */
  readonly BASIC_SPECIAL_CHARS = "!@#$%^&*()_+";
  /** OWASP easily typed password special chars */
  readonly KEYBOARD_SPECIAL_CHARS = " !\"#$%&'()*+,-./:;<=>?@[\\]^_`{|}~";
  /** Upper and lower case characters plus numbers. No symbols */
  readonly ALPHANUMBERIC = this.LOWER + this.UPPER + this.NUMBERS;
  readonly DEFAULT_HOW_MANY = 20;
  ready = sodium.ready;

  /** Generate a cryptographically secure random typeable password
   * Uses libsodium randombytes_buf to achieve randomness
   */
  async generatePassword(options: IPasswordConfig = {}) {
    await sodium.ready;
    const chars = options.chars ? options.chars : this.ALPHANUMBERIC;
    const howMany = options.howMany ? options.howMany : this.DEFAULT_HOW_MANY;
    const rnd = sodium.randombytes_buf(howMany);
    const value = new Array(howMany);
    const len = chars.length;

    for (let i = 0; i < howMany; i++) {
      value[i] = chars[rnd[i] % len];
    }

    return value.join("");
  }
}
