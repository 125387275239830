import { tap, withLatestFrom, map, exhaustMap } from "rxjs/operators";
import { of as observableOf } from "rxjs";

import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Actions, Effect, ofType } from "@ngrx/effects";

import {
  AccountActionTypes,
  APIFailureNetworkDownAction,
  CriticalAPIErrorAction,
  HandleAPIErrorAction,
  LoginAction,
  LoginFailureAction,
  LoginSuccessAction,
  LogoutAction,
  UserMustConfirmEmailAction,
  LogoutSuccessAction
} from "./account.actions";
import * as fromAccount from "./account.reducer";

import { IS_EXTENSION } from "../constants";
import { UserService } from "./user";
import { Store } from "@ngrx/store";
import { IState } from "../app.reducers";
import { HttpErrorResponse } from "@angular/common/http";

@Injectable()
export class LoginEffects {
  @Effect()
  login$ = this.actions$.pipe(
    ofType<LoginAction>(AccountActionTypes.LOGIN),
    withLatestFrom(this.store.select(fromAccount.getLoginForm)),
    map(([action, form]) => form.value),
    exhaustMap(auth => {
      const callLogin = () => {
        return this.userService
          .login(
            auth.email,
            auth.password,
            auth.rememberMe ? auth.rememberMe : false
          )
          .then(resp => new LoginSuccessAction(resp))
          .catch(err => new LoginFailureAction(err));
      };
      const callCheckAndSetUrl = (url: string) => {
        return this.userService
          .checkAndSetUrl(url)
          .then(() => callLogin())
          .catch(err => new LoginFailureAction(err));
      };

      if (auth.url) {
        return callCheckAndSetUrl(auth.url);
      } else {
        return callLogin();
      }
    })
  );

  @Effect({ dispatch: false })
  loginSuccess$ = this.actions$.ofType(AccountActionTypes.LOGIN_SUCCESS).pipe(
    tap(() => {
      if (IS_EXTENSION) {
        this.router.navigate(["/popup"]);
      } else {
        this.router.navigate(["/list"]);
      }
    })
  );

  @Effect({ dispatch: false })
  loginRedirect$ = this.actions$
    .ofType(
      AccountActionTypes.LOGIN_REDIRECT,
      AccountActionTypes.LOGOUT_SUCCESS
    )
    .pipe(
      tap(() => {
        this.router.navigate(["/login"], { replaceUrl: true });
      })
    );

  @Effect()
  logout$ = this.actions$.ofType(AccountActionTypes.LOGOUT).pipe(
    exhaustMap(() =>
      this.userService
        .logout()
        .then(() => new LogoutSuccessAction())
        .catch(() => new LogoutSuccessAction())
    )
  );

  @Effect({ dispatch: false })
  logoutSuccess$ = this.actions$
    .ofType(AccountActionTypes.LOGOUT_SUCCESS)
    .pipe(tap(() => localStorage.clear()));

  @Effect()
  handleAPIError$ = this.actions$
    .ofType<HandleAPIErrorAction>(AccountActionTypes.HANDLE_API_ERROR)
    .pipe(
      map(action => action.payload),
      exhaustMap(err => {
        const res: HttpErrorResponse = err.res;
        if (res) {
          if (res.status === 0) {
            // Not so bad, network is just down
            return observableOf(new APIFailureNetworkDownAction());
          }
          if ([401, 403].includes(res.status)) {
            if (
              res.status === 403 &&
              res.error &&
              res.error.detail === "User's email is not confirmed."
            ) {
              return observableOf(new UserMustConfirmEmailAction());
            }
            return observableOf(new LogoutAction());
          }
        }

        if (err.name === "Passit SDK Authentication Error") {
          return observableOf(new LogoutAction());
        }

        // This should never run
        console.error("Unable to use api and unable to handle error", err);
        return observableOf(new CriticalAPIErrorAction(err));
      })
    );

  @Effect({ dispatch: false })
  userMustConfirmEmail$ = this.actions$
    .ofType(AccountActionTypes.USER_MUST_CONFIRM_EMAIL)
    .pipe(tap(() => this.router.navigate(["/confirm-email"])));

  constructor(
    private actions$: Actions,
    private userService: UserService,
    private router: Router,
    private store: Store<IState>
  ) {}
}
