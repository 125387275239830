<div class="error-page l-container-narrow u-margin-tb-50">
  <h2 class="heading-main heading-main--pad-bottom">
    Page not found
  </h2>

  <div class="text--large error-page__text">
    The page you tried to access doesn't exist:
  </div>

  <div class="error-page__url">
     {{ currentRoute }}
  </div>

  <div class="text--large error-page__text">
    This might have happened because we removed or changed the location of a page. It also could have happened because you mistyped the URL or were given a bad link.
  </div>

  <div class="text--large error-page__text">
    Here are some links to help you get around Passit:
  </div>

  <ul *ngIf="isLoggedIn" class="error-page__link-list">
    <li>
      <a [routerLink]="['/list']" class="text-link">
        View password list
      </a>
    </li>
    <li>
      <a [routerLink]="['/groups']" class="text-link">
        View groups list
      </a>
    </li>
    <li>
      <a [routerLink]="['/account']" class="text-link">
        Account settings
      </a>
    </li>
  </ul>
  <ul *ngIf="!isLoggedIn" class="error-page__link-list">
    <li>
      <a [routerLink]="['/register']" class="text-link">
        Sign up
      </a>
    </li>
    <li>
      <a [routerLink]="['/login']" class="text-link">
        Log in
      </a>
    </li>
    <li><a href="https://passit.io" class="text-link">Home</a></li>
  </ul>

  <div class="error-page__error-code">
    Error code: 404 Not Found
  </div>
</div>