import {
  ConfirmEmailActions,
  ConfirmEmailTypes
} from "./confirm-email.actions";

export interface IConfirmEmailState {
  resetCodeMessage: string | null;
  isVerified: boolean;
  hasStarted: boolean;
  hasFinished: boolean;
  errorMessage: string | null;
}

export const initialState: IConfirmEmailState = {
  resetCodeMessage: null,
  isVerified: false,
  hasStarted: false,
  hasFinished: false,
  errorMessage: null
};

export function reducer(
  state = initialState,
  action: ConfirmEmailActions
): IConfirmEmailState {
  switch (action.type) {
    case ConfirmEmailTypes.VERIFY_EMAIL:
      return {
        ...state,
        hasStarted: true,
        errorMessage: null
      };

    case ConfirmEmailTypes.VERIFY_EMAIL_SUCCESS:
      return {
        ...state,
        hasStarted: false,
        hasFinished: true,
        errorMessage: null,
        isVerified: true
      };

    case ConfirmEmailTypes.VERIFY_EMAIL_FAILURE:
      return {
        ...state,
        hasStarted: false,
        hasFinished: false,
        errorMessage: action.payload
      };

    case ConfirmEmailTypes.RESET_REGISTER_CODE:
      return {
        ...state,
        resetCodeMessage: null
      };

    case ConfirmEmailTypes.RESET_REGISTER_CODE_SUCCESS:
      return {
        ...state,
        resetCodeMessage: "Check your email for a new confirmation code."
      };

    case ConfirmEmailTypes.RESET_REGISTER_CODE_FAILURE:
      return {
        ...state,
        resetCodeMessage: "Unable to resend confirmation code."
      };

    default:
      return state;
  }
}

export const getResetCodeMessage = (state: IConfirmEmailState) =>
  state.resetCodeMessage;
export const getIsVerified = (state: IConfirmEmailState) => state.isVerified;
export const getHasStarted = (state: IConfirmEmailState) => state.hasStarted;
export const getHasFinished = (state: IConfirmEmailState) => state.hasFinished;
export const getErrorMessage = (state: IConfirmEmailState) =>
  state.errorMessage;
