<div class="secret">
  <form [formGroup]="createGroupForm"
    (ngSubmit)="onSubmit()">
    <div class="secret__details">
      <div class="form-field secret__field">
        <div>
          <label for="name" class="form-field__label secret__label">
            Name 
            <span *ngIf="createGroupForm.controls['name'].errors && submitted"
              class="form-field__error">&nbsp;Required
            </span>
          </label>
        </div>
        <input type="text"
          id="nameInput"
          class="form-field__input"
          [class.form-field__input--invalid]="createGroupForm.controls['name'].errors && submitted"
          required
          maxlength="1000"
          name="name"
          formControlName="name"/>
      </div>
      <div class="form-field secret__field">
        <label for="membersInput" class="form-field__label secret__label">
          Members
        </label>
          <ng-select
            id="membersInput"
            name="updateMembers"
            [options]="isPrivateOrgMode ? privateOrgMemberOptions : memberOptions"
            multiple="true"
            (selected)="onSelected($event)"
            (deselected)="onDeselected($event)"
            (keyup)="!isPrivateOrgMode && contactSearch.emit($event.target.value)"
            formControlName="members"
            >
          </ng-select>
          <div
            *ngIf="singleGroupPendingContacts.length > 0"
            class="secret__subtext">
            <strong>Note: </strong> <span *ngIf="singleGroupPendingContacts.length === 1">One user has</span><span *ngIf="singleGroupPendingContacts.length > 1">{{ singleGroupPendingContacts.length }} users have</span> been invited to this group to share passwords but <span *ngIf="singleGroupPendingContacts.length === 1">hasn&rsquo;t</span><span *ngIf="singleGroupPendingContacts.length > 1">haven&rsquo;t</span> yet accepted or declined.
          </div>
      </div>

      <div class="secret__buttons">
        <button
          *ngIf="!isNew"
          class="button button--text button--text-danger group-form__delete-btn"
          type="button"
          (click)="onDelete()">
          Delete Group
        </button>
        <button
          *ngIf="isNew"
          class="button button--text button--text-danger group-form__cancel-btn"
          type="button"
          (click)="hideAddSecretForm.emit()">
          Cancel
        </button>

        <button 
        [class.button--is-saving]="groupIsUpdating"
        [disabled]="groupIsUpdating"
        type="submit" class="button button--primary group-form__save-btn">
          Save Group
        </button>
      </div>
    </div>
    <div class="secret__progress-bar"
      [class.secret__progress-bar--is-saving]="groupIsUpdating"
      [class.secret__progress-bar--is-saved]="groupIsUpdated"></div>
  </form>
</div>
