import { Component } from "@angular/core";
import { ExporterService } from "./exporter.service";

@Component({
  selector: "exporter",
  templateUrl: "./exporter.component.html"
})
export class ExporterComponent {
  constructor(private exporterService: ExporterService) {}

  public onExport() {
    this.exporterService.exportSecrets();
  }
}
