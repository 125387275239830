import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import * as fromAccount from "../account.reducer";
import * as fromErrorReporting from "./error-reporting.reducer";
import { Store } from "@ngrx/store";
import { SaveForm } from "./error-reporting.actions";
import { SetValueAction } from "ngrx-forms";

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `<app-error-reporting
    [form]="form$ | async"
    [hasStarted]="hasStarted$ | async"
    [hasFinished]="hasFinished$ | async"
    (submit)="submit()"
  ></app-error-reporting>`
})
export class ErrorReportingContainer implements OnInit {
  form$ = this.store.select(fromAccount.getErrorReportingForm);
  hasStarted$ = this.store.select(fromAccount.getErrorReportingHasStarted);
  hasFinished$ = this.store.select(fromAccount.getErrorReportingHasFinished);
  optInErrorReporting: boolean;

  constructor(private store: Store<any>) {
    this.store
      .select(fromAccount.getOptInErrorReporting)
      .subscribe(optIn => (this.optInErrorReporting = optIn));
  }

  submit() {
    this.store.dispatch(new SaveForm());
  }

  ngOnInit() {
    this.store.dispatch(
      new SetValueAction(fromErrorReporting.FORM_ID, {
        optInErrorReporting: this.optInErrorReporting
      })
    );
  }
}
