var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
import { createFormGroupState, formGroupReducer } from "ngrx-forms";
import { ErrorReportingTypes } from "./error-reporting.actions";
export var FORM_ID = "ErrorReportingForm";
export var initialFormState = createFormGroupState(FORM_ID, {
    optInErrorReporting: false
});
var initialState = {
    form: initialFormState,
    hasFinished: false,
    hasStarted: false
};
export function reducer(state, action) {
    if (state === void 0) { state = initialState; }
    var form = formGroupReducer(state.form, action);
    if (form !== state.form) {
        state = __assign({}, state, { form: form });
    }
    switch (action.type) {
        case ErrorReportingTypes.SAVE_FORM: {
            return __assign({}, state, { hasFinished: false, hasStarted: true });
        }
        case ErrorReportingTypes.SAVE_FORM_SUCCESS: {
            return __assign({}, state, { hasStarted: false, hasFinished: true });
        }
        case ErrorReportingTypes.SAVE_FORM_FAILURE: {
            return __assign({}, state, { hasStarted: false, hasFinished: true });
        }
        case ErrorReportingTypes.CLEAR_FINISHED: {
            return __assign({}, state, { hasFinished: false });
        }
        default: {
            return state;
        }
    }
}
export var getForm = function (state) { return state.form; };
export var getHasStarted = function (state) { return state.hasStarted; };
export var getHasFinished = function (state) { return state.hasFinished; };
