import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";

import * as fromRoot from "../../app.reducers";
import * as Account from "../account.actions";
import * as fromAccount from "../account.reducer";

@Component({
  template: `
    <login-component
      [form]="form$ | async"
      [hasLoginStarted]="hasLoginStarted$ | async"
      [hasLoginFinished]="hasLoginFinished$ | async"
      [errorMessage]="errorMessage$ | async"
      [isPopup]="isPopup"
      (login)="login()"
      (goToRegister)="goToRegister()"
    ></login-component>
  `
})
export class LoginContainer {
  form$ = this.store.select(fromAccount.getLoginForm);
  errorMessage$ = this.store.select(fromAccount.getLoginErrorMessage);
  hasLoginStarted$ = this.store.select(fromAccount.getLoginHasStarted);
  hasLoginFinished$ = this.store.select(fromAccount.getLoginHasFinished);
  isPopup = false;

  constructor(private router: Router, private store: Store<fromRoot.IState>) {
    store
      .select(fromRoot.getIsPopup)
      .subscribe(isPopup => (this.isPopup = isPopup));
  }

  goToRegister() {
    if (this.isPopup) {
      browser.tabs.create({
        url: "/index.html#/register"
      });
      window.close();
    } else {
      this.router.navigate(["/register"]);
    }
  }

  login() {
    this.store.dispatch(new Account.LoginAction());
  }
}
