import { IContact } from "../group/contacts/contacts.interfaces";

export const setContacts = (
  contactsArray: IContact[],
  isDisabled: boolean | false
) => {
  return contactsArray.map((contact: any) => {
    return {
      label: contact.email,
      value: contact.id,
      disabled: isDisabled
    };
  });
};
