var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
import { createFormGroupState, createFormStateReducerWithUpdate, validate, updateGroup } from "ngrx-forms";
import { oldPasswordValidators, passwordValidators } from "../constants";
import { equalTo, required } from "ngrx-forms/validation";
export var FORM_ID = "Change Password Form";
export function notEqualTo(comparand) {
    return function (value) {
        if (value !== comparand) {
            return {};
        }
        return {
            notEqualTo: {
                comparand: comparand,
                actual: value
            }
        };
    };
}
export var validateAndUpdateFormState = updateGroup({
    oldPassword: validate(oldPasswordValidators),
    newPassword: function (newPassword, form) {
        return validate(newPassword, passwordValidators.concat([
            notEqualTo(form.value.oldPassword)
        ]));
    },
    newPasswordConfirm: function (passwordConfirm, form) {
        if (form.controls.showConfirm.value) {
            return validate(passwordConfirm, required, equalTo(form.value.newPassword));
        }
        return validate(passwordConfirm, []);
    }
});
var initialFormState = validateAndUpdateFormState(createFormGroupState(FORM_ID, {
    oldPassword: "",
    newPassword: "",
    newPasswordConfirm: "",
    showConfirm: true
}));
var initialState = {
    form: initialFormState
};
var formReducer = createFormStateReducerWithUpdate(validateAndUpdateFormState);
export function reducer(state, action) {
    if (state === void 0) { state = initialState; }
    var form = formReducer(state.form, action);
    if (form !== state.form) {
        state = __assign({}, state, { form: form });
    }
    return state;
}
export var getForm = function (state) { return state.form; };
