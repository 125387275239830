/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./demo.component.ngfactory";
import * as i2 from "./demo.component";
import * as i3 from "./navbar.component.ngfactory";
import * as i4 from "./navbar.component";
import * as i5 from "@angular/common";
import * as i6 from "./navbar.container";
import * as i7 from "@angular/router";
import * as i8 from "@ngrx/store";
var styles_NavbarContainer = [];
var RenderType_NavbarContainer = i0.ɵcrt({ encapsulation: 2, styles: styles_NavbarContainer, data: {} });
export { RenderType_NavbarContainer as RenderType_NavbarContainer };
function View_NavbarContainer_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "demo", [], null, null, null, i1.View_DemoComponent_0, i1.RenderType_DemoComponent)), i0.ɵdid(1, 49152, null, 0, i2.DemoComponent, [], null, null)], null, null); }
function View_NavbarContainer_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "navbar", [], null, [[null, "logout"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("logout" === en)) {
        var pd_0 = (_co.logout() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_NavbarComponent_0, i3.RenderType_NavbarComponent)), i0.ɵdid(1, 114688, null, 0, i4.NavbarComponent, [], { isLoggedIn: [0, "isLoggedIn"], isPrivateOrgMode: [1, "isPrivateOrgMode"], hasGroupInvites: [2, "hasGroupInvites"] }, { logout: "logout" }), i0.ɵpid(131072, i5.AsyncPipe, [i0.ChangeDetectorRef]), i0.ɵpid(131072, i5.AsyncPipe, [i0.ChangeDetectorRef]), i0.ɵpid(131072, i5.AsyncPipe, [i0.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 1, 0, i0.ɵnov(_v, 2).transform(_co.isLoggedIn$)); var currVal_1 = i0.ɵunv(_v, 1, 1, i0.ɵnov(_v, 3).transform(_co.isPrivateOrgMode$)); var currVal_2 = i0.ɵunv(_v, 1, 2, i0.ɵnov(_v, 4).transform(_co.hasGroupInvites$)); _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }, null); }
export function View_NavbarContainer_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 2, null, View_NavbarContainer_1)), i0.ɵdid(1, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), i0.ɵpid(131072, i5.AsyncPipe, [i0.ChangeDetectorRef]), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_NavbarContainer_2)), i0.ɵdid(4, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 1, 0, i0.ɵnov(_v, 2).transform(_co.isDemo$)); _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.isVisible; _ck(_v, 4, 0, currVal_1); }, null); }
export function View_NavbarContainer_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "navbar-container", [], null, null, null, View_NavbarContainer_0, RenderType_NavbarContainer)), i0.ɵdid(1, 49152, null, 0, i6.NavbarContainer, [i7.Router, i8.Store, i8.Store], null, null)], null, null); }
var NavbarContainerNgFactory = i0.ɵccf("navbar-container", i6.NavbarContainer, View_NavbarContainer_Host_0, {}, {}, []);
export { NavbarContainerNgFactory as NavbarContainerNgFactory };
