import {
  createFeatureSelector,
  createSelector,
  ActionReducerMap
} from "@ngrx/store";
import {
  ResetFormActionTypes,
  ResetFormActionsUnion
} from "../form/reset-form.actions";
import * as fromSecretForm from "./secret-form/secret-form.reducer";

import {
  SecretFormActionTypes,
  SecretFormActionsUnion
} from "./secret-form/secret-form.actions";
import {
  SecretActionsUnion,
  SecretActionTypes
} from "../secrets/secret.actions";
import { ListActionTypes, ListActionsUnion } from "./list.actions";
import { ROUTER_NAVIGATION, RouterNavigationAction } from "@ngrx/router-store";

export interface IListState {
  searchText: string;
  secretManaged: number | null;
  showCreate: boolean;
  firstTimeLoadingComplete: boolean;
  selectedGroupId: number | null;
}

export const initialListState: IListState = {
  searchText: "",
  secretManaged: null,
  showCreate: false,
  firstTimeLoadingComplete: false,
  selectedGroupId: null
};

export function listReducer(
  state = initialListState,
  action:
    | ListActionsUnion
    | SecretActionsUnion
    | SecretFormActionsUnion
    | ResetFormActionsUnion
    | RouterNavigationAction
): IListState {
  switch (action.type) {
    case ListActionTypes.CLEAR_MANAGED_SECRET:
      return Object.assign({}, state, {
        secretManaged: null
      });

    case ListActionTypes.SET_MANAGED_SECRET:
      const secretManaged = action.payload;
      return Object.assign({}, state, {
        secretManaged: secretManaged,
        secretIsUpdating: false,
        secretIsUpdated: false,
        showCreate: false
      });

    case ListActionTypes.SET_SEARCH_TEXT:
      return Object.assign({}, state, {
        searchText: action.payload
      });

    case ListActionTypes.SHOW_CREATE:
      return Object.assign({}, state, {
        showCreate: true,
        secretManaged: null,
        secretIsUpdating: false,
        secretIsUpdated: false
      });

    case ListActionTypes.HIDE_CREATE:
      return Object.assign({}, state, {
        showCreate: false
      });

    case ListActionTypes.UPDATE_SELECTED_GROUP:
      return Object.assign({}, state, {
        selectedGroupId: action.payload
      });

    case ResetFormActionTypes.RESET_FORMS:
      return Object.assign({}, state, {
        ...initialListState
      });

    case SecretActionTypes.SET_SECRETS:
      return Object.assign({}, state, {
        firstTimeLoadingComplete: true
      });

    case SecretFormActionTypes.CREATE_SECRET:
      return Object.assign({}, state, {
        showCreate: true
      });

    case SecretFormActionTypes.CREATE_SECRET_SUCCESS:
      return Object.assign({}, state, {
        showCreate: false
      });

    case ROUTER_NAVIGATION:
      return Object.assign({}, state, {
        secretManaged: null,
        showCreate: false,
        searchText: null
      });

    default:
      return state;
  }
}

interface IState {
  list: IListState;
  secretForm: fromSecretForm.ISecretFormState;
}

export const initialState = {
  list: initialListState,
  secretForm: fromSecretForm.initialFormState
};

export const reducers: ActionReducerMap<IState> = {
  list: listReducer,
  secretForm: fromSecretForm.reducer
};

export const getState = createFeatureSelector<IState>("list");
export const getListState = createSelector(
  getState,
  (state: IState) => state.list
);
export const getSecretFormState = createSelector(
  getState,
  (state: IState) => state.secretForm
);

const getSecretManaged = (state: IListState) => state.secretManaged;
export const getListSecretManaged = createSelector(
  getListState,
  getSecretManaged
);

const getShowCreate = (state: IListState) => state.showCreate;
export const getListShowCreate = createSelector(getListState, getShowCreate);

export const getSearchText = createSelector(
  getListState,
  (state: IListState) => state.searchText
);

export const getSelectedGroup = createSelector(
  getListState,
  (state: IListState) => state.selectedGroupId
);

export const getFirstTimeLoadingComplete = createSelector(
  getListState,
  (state: IListState) => state.firstTimeLoadingComplete
);

export const getSecretForm = createSelector(
  getSecretFormState,
  fromSecretForm.getForm
);
export const getSecretFormErrorMessage = createSelector(
  getSecretFormState,
  fromSecretForm.getErrorMessage
);
export const getSecretId = createSelector(
  getSecretFormState,
  fromSecretForm.getSecretId
);
export const getSecretIsNew = createSelector(
  getSecretFormState,
  fromSecretForm.getSecretIsNew
);
export const getSecretIsUpdating = createSelector(
  getSecretFormState,
  fromSecretForm.getIsUpdating
);
export const getSecretIsUpdated = createSelector(
  getSecretFormState,
  fromSecretForm.getIsUpdated
);
export const getSecretPasswordIsMasked = createSelector(
  getSecretFormState,
  fromSecretForm.getPasswordIsMasked
);
export const getSecretShowNotes = createSelector(
  getSecretFormState,
  fromSecretForm.getShowNotes
);
