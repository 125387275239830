var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
import { createFormGroupState, validate, updateGroup, createFormStateReducerWithUpdate, box } from "ngrx-forms";
import { SecretFormActionTypes } from "./secret-form.actions";
import { SecretActionTypes } from "../../secrets/secret.actions";
import { required } from "ngrx-forms/validation";
import { ListActionTypes } from "../list.actions";
export var SECRET_FORM_ID = "Secret Form";
export var initialFormState = createFormGroupState(SECRET_FORM_ID, {
    name: "",
    username: "",
    url: "",
    password: "",
    groups: box([]),
    notes: ""
});
export var setFormValues = function (values) {
    return createFormGroupState(SECRET_FORM_ID, values);
};
export var initialState = {
    secretId: null,
    form: initialFormState,
    errorMessage: null,
    isUpdated: false,
    isUpdating: false,
    showNotes: false,
    passwordIsMasked: true
};
export var validateAndUpdateFormState = updateGroup({
    name: validate(required)
});
export var formReducer = createFormStateReducerWithUpdate(validateAndUpdateFormState);
export function reducer(state, action) {
    if (state === void 0) { state = initialState; }
    var form = formReducer(state.form, action);
    if (form !== state.form) {
        state = __assign({}, state, { form: form });
    }
    switch (action.type) {
        case SecretFormActionTypes.SET_FORM_DATA:
            var showNotes = false;
            if (action.payload.notes) {
                showNotes = true;
            }
            return __assign({}, initialState, { secretId: action.payload.id, form: setFormValues(action.payload), showNotes: showNotes });
        case SecretFormActionTypes.TOGGLE_PASSWORD_IS_MASKED:
            return __assign({}, state, { passwordIsMasked: !state.passwordIsMasked });
        case SecretFormActionTypes.SET_PASSWORD_IS_MASKED:
            return __assign({}, state, { passwordIsMasked: action.payload });
        case SecretFormActionTypes.TOGGLE_SHOW_NOTES:
            return __assign({}, state, { showNotes: !state.showNotes });
        case SecretFormActionTypes.CREATE_SECRET:
            return Object.assign({}, state, {
                secretIsUpdating: true,
                secretIsUpdated: false
            });
        case SecretFormActionTypes.CREATE_SECRET_SUCCESS:
            return __assign({}, state, { isUpdating: false, isUpdated: true, errorMessage: initialState.errorMessage });
        case SecretActionTypes.REPLACE_SECRET:
            return __assign({}, state, { isUpdating: true, isUpdated: false });
        case SecretFormActionTypes.DECRYPT_ERROR:
            return __assign({}, state, { form: setFormValues(action.payload.form), errorMessage: action.payload.message });
        case SecretActionTypes.REPLACE_SECRET_SUCCESS:
            return __assign({}, state, { isUpdating: false, isUpdated: true, errorMessage: initialState.errorMessage });
        case ListActionTypes.SHOW_CREATE:
            return __assign({}, initialState, { form: formReducer(initialState.form, action) });
    }
    return state;
}
export var getForm = function (state) { return state.form; };
export var getErrorMessage = function (state) { return state.errorMessage; };
export var getSecretId = function (state) { return state.secretId; };
export var getSecretIsNew = function (state) {
    return state.secretId === null;
};
export var getIsUpdating = function (state) { return state.isUpdating; };
export var getIsUpdated = function (state) { return state.isUpdated; };
export var getPasswordIsMasked = function (state) {
    return state.passwordIsMasked;
};
export var getShowNotes = function (state) { return state.showNotes; };
