import { Action } from "@ngrx/store";
import { IAuthStore } from "./user/user.interfaces";

export enum AccountActionTypes {
  LOGIN = "[Login] Login",
  LOGIN_SUCCESS = "[Login] Login Success",
  LOGIN_FAILURE = "[Login] Login Failure",
  LOGIN_REDIRECT = "[Login] Login Redirect",
  LOGOUT = "Logout",
  LOGOUT_SUCCESS = "Logout Success",
  SET_URL = "SET_URL",
  HANDLE_API_ERROR = "Handle API Error",
  CRITICAL_API_ERROR = "Critical API Error - should never happen",
  API_FAILURE_NETWORK_DOWN = "API Failure due to network being down",
  USER_MUST_CONFIRM_EMAIL = "User must confirm email"
}

export class LoginAction implements Action {
  readonly type = AccountActionTypes.LOGIN;
}

export class LoginSuccessAction implements Action {
  readonly type = AccountActionTypes.LOGIN_SUCCESS;

  constructor(public payload: IAuthStore) {}
}

export class LoginFailureAction implements Action {
  readonly type = AccountActionTypes.LOGIN_FAILURE;

  constructor(public payload: string) {}
}

export class LoginRedirect implements Action {
  readonly type = AccountActionTypes.LOGIN_REDIRECT;

  constructor(public payload?: string) {}
}

export class LogoutAction implements Action {
  readonly type = AccountActionTypes.LOGOUT;
}

export class LogoutSuccessAction implements Action {
  readonly type = AccountActionTypes.LOGOUT_SUCCESS;
}

export class SetUrlAction implements Action {
  readonly type = AccountActionTypes.SET_URL;

  constructor(public payload: string) {}
}

export class HandleAPIErrorAction implements Action {
  readonly type = AccountActionTypes.HANDLE_API_ERROR;

  constructor(public payload: any) {}
}

export class APIFailureNetworkDownAction implements Action {
  readonly type = AccountActionTypes.API_FAILURE_NETWORK_DOWN;
}

export class UserMustConfirmEmailAction implements Action {
  readonly type = AccountActionTypes.USER_MUST_CONFIRM_EMAIL;
}

export class CriticalAPIErrorAction implements Action {
  readonly type = AccountActionTypes.CRITICAL_API_ERROR;

  constructor(public payload: any) {}
}

export type AccountActions =
  | LoginAction
  | LoginSuccessAction
  | LoginFailureAction
  | LoginRedirect
  | LogoutAction
  | LogoutSuccessAction
  | SetUrlAction
  | HandleAPIErrorAction
  | APIFailureNetworkDownAction
  | UserMustConfirmEmailAction
  | CriticalAPIErrorAction;
