import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
  ElementRef
} from "@angular/core";
import { flyInOut } from "../../app.animations";
import { IRegisterForm, IUrlForm } from "./interfaces";
import { FormGroupState } from "ngrx-forms";
import { RegisterStages } from "../constants";

@Component({
  selector: "register-component",
  styleUrls: ["../account.component.scss"],
  templateUrl: "./register.component.html",
  animations: [flyInOut]
})
export class RegisterComponent {
  @Input() errorMessage: string;
  @Input() form: FormGroupState<IRegisterForm>;
  @Input() urlForm: FormGroupState<IUrlForm>;
  @Input() isEmailTaken: boolean;
  @Input() isUrlValid: boolean | undefined;
  @Input() showUrl: string;
  @Input() isExtension: boolean;
  @Input() urlDisplayName: string;
  @Input() hasSubmitStarted: boolean;
  @Input() hasSubmitFinished: boolean;

  @Output() register = new EventEmitter();
  @Output() goToLogin = new EventEmitter<string>();
  @Output() checkEmail = new EventEmitter();
  @Output() checkUrl = new EventEmitter<string>();
  @Output() toggleShowConfirm = new EventEmitter();
  @Output() markAsSubmitted = new EventEmitter();
  @Output() incrementStage = new EventEmitter();
  @Output() switchStage = new EventEmitter<number>();
  @Output() displayUrlInput = new EventEmitter();
  @Output() hideUrlInput = new EventEmitter();
  @Output() setNewsletterSubscribe = new EventEmitter();
  @Output() registrationFinished = new EventEmitter();

  _stageValue: number;
  passwordFocused = false;
  checked: boolean;
  stages = RegisterStages;

  @ViewChild("passwordInput") passwordInput: ElementRef;

  @ViewChild("newsletterInput") newsletterInput: ElementRef;

  constructor() {}

  @Input()
  set stageValue(value: RegisterStages) {
    this._stageValue = value;
    switch (value) {
      case this.stages.Password:
        setTimeout(() => this.passwordInput.nativeElement.focus(), 0);
        return;
      case this.stages.Newsletter:
        setTimeout(() => this.newsletterInput.nativeElement.focus(), 0);
        return;
    }
  }

  get stageValue(): RegisterStages {
    return this._stageValue;
  }

  submit() {
    switch (this.stageValue) {
      case this.stages.Email:
        this.submitEmail();
        return;

      case this.stages.Password:
        if (!this.form.errors._password && !this.form.errors._passwordConfirm) {
          this.incrementStage.emit();
        }
        return;

      case this.stages.Newsletter:
        this.setNewsletterSubscribe.emit();
        this.submitForm();
        return;

      case this.stages.Verified:
        this.registrationFinished.emit();
        return;
    }
  }

  submitEmail() {
    if (this.form.controls.email.isValid) {
      this.checkEmail.emit();
    }
  }

  urlSubmit() {
    this.checkUrl.emit();
  }

  switchCurrentStage(value: number) {
    this.switchStage.emit(value);
  }

  displayUrl() {
    this.displayUrlInput.emit();
  }

  hideUrl() {
    this.hideUrlInput.emit();
  }

  submitForm() {
    if (this.form.isValid) {
      this.register.emit();
    } else {
      this.markAsSubmitted.emit();
    }
  }

  toggleDisplay() {
    this.goToLogin.emit();
  }

  goToLoginWith() {
    this.goToLogin.emit(this.form.value.email);
  }
}
