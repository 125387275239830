/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "../../list/list.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "ng-inline-svg/lib/svg-cache.service";
import * as i3 from "@angular/common";
import * as i4 from "ng-inline-svg/lib/inline-svg.config";
import * as i5 from "@angular/common/http";
import * as i6 from "ng-inline-svg/lib/inline-svg.directive";
import * as i7 from "ng-inline-svg/lib/inline-svg.service";
import * as i8 from "./group-add-button.component";
var styles_GroupAddButtonComponent = [i0.styles];
var RenderType_GroupAddButtonComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_GroupAddButtonComponent, data: {} });
export { RenderType_GroupAddButtonComponent as RenderType_GroupAddButtonComponent };
export function View_GroupAddButtonComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "list-actions"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 7, "div", [["class", "list-actions__actions"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 6, "button", [["class", "button button--gray"], ["id", "showAddGroupform"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.showAddGroupForm.emit() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(3, 16777216, null, null, 2, "div", [["class", "button__icon"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.SVGCacheService, i2.SVGCacheService, [[2, i3.APP_BASE_HREF], [2, i3.PlatformLocation], [2, i4.InlineSVGConfig], i5.HttpClient, i1.RendererFactory2]), i1.ɵdid(5, 737280, null, 0, i6.InlineSVGDirective, [i1.ElementRef, i1.ViewContainerRef, i1.ComponentFactoryResolver, i2.SVGCacheService, i1.Renderer2, i7.InlineSVGService, i1.PLATFORM_ID], { inlineSVG: [0, "inlineSVG"] }, null), (_l()(), i1.ɵted(-1, null, [" Add New "])), (_l()(), i1.ɵeld(7, 0, null, null, 1, "span", [["class", "u-hidden--all-but-xl"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Group"]))], function (_ck, _v) { var currVal_0 = "../assets/svg/plus.svg"; _ck(_v, 5, 0, currVal_0); }, null); }
export function View_GroupAddButtonComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "group-add-button", [], null, null, null, View_GroupAddButtonComponent_0, RenderType_GroupAddButtonComponent)), i1.ɵdid(1, 49152, null, 0, i8.GroupAddButtonComponent, [], null, null)], null, null); }
var GroupAddButtonComponentNgFactory = i1.ɵccf("group-add-button", i8.GroupAddButtonComponent, View_GroupAddButtonComponent_Host_0, {}, { showAddGroupForm: "showAddGroupForm" }, []);
export { GroupAddButtonComponentNgFactory as GroupAddButtonComponentNgFactory };
