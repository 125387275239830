<div
  class="account-container"
  [class.account-container--popup]="isPopup">
  <div class="account-column account-column--left">
    <div class="account-column__inner account-column__inner--left">
      <div class="account-column__logo" [inlineSVG]="'../assets/svg/passit-logo.svg'"></div>
      <h1 class="account-column__heading">Gain peace of mind for your passwords</h1>
      <p class="account-column__text">Store your passwords. Make them safer. Access them anywhere. Share them with others. It all starts here.</p>
    </div>
  </div>
  <div class="account-column account-column--right">
    <div class="account-column__inner account-column__inner--right">
      <div class="account-column__right-heading">
        <h1 class="heading-medium">Log&nbsp;In</h1>
        <div class="account-column__link">
          <a class="text-link text-link--caret text-link--large" id="btn-signup" href="javascript:void(0)" (click)="toggleDisplay()">Not&nbsp;registered? Sign&nbsp;up</a>
        </div>
      </div>

      <form novalidate [ngrxFormState]="form" (submit)="onSubmit()" class="auth-form__form">

        <app-non-field-messages *ngIf="errorMessage" [messages]="[errorMessage]"></app-non-field-messages>

        <div class="form-field form-field--large">
          <label [for]="form.controls.email.id" class="form-field__label">Email</label>
          <input
            type="email"
            autofocus
            [ngrxFormControlState]="form.controls.email"
            class="form-field__input"
            [ngClass]="{'form-field__input--invalid' : form.errors._email && form.isSubmitted }"
          >
        </div>

        <ul *ngIf="form.isInvalid && form.isSubmitted"
        class="form-field__error-list">
          <li *ngIf="form.errors._email" class="form-field__error">
            Enter your account's email address.
          </li>
        </ul>

        <div class="form-field form-field--large">
          <label [for]="form.controls.password.id" class="form-field__label">Password</label>
          <input
            type="password"
            [ngrxFormControlState]="form.controls.password"
            class="form-field__input"
            [ngClass]="{'form-field__input--invalid' : form.errors._password && form.isSubmitted }"
          >
        </div>

        <ul *ngIf="form.isSubmitted"
        class="form-field__error-list">
          <li *ngIf="form.errors._password" class="form-field__error">
            Enter your account's password.
          </li>
        </ul>

        <div *ngIf="!isExtension">
          <app-checkbox
            title="Stay logged in on this device"
            [control]="form.controls.rememberMe"
          ></app-checkbox>
      </div>

        <div *ngIf="isExtension">
          <app-checkbox
            title="My company has its own Passit server"
            subtext="If you signed up for Passit anywhere other than app.passit.io (e.g. passit.mycompany.com, or your self-hosted server), you’ll need to specify where you want to log&nbsp;in."
            [control]="form.controls.showUrl"
          ></app-checkbox>

          <div *ngIf="form.value.showUrl" class="form-field form-field--large">
            <label [for]="form.controls.url.id" class="form-field__label">Server URL</label>
            <input
              type="url"
              [ngrxFormControlState]="form.controls.url"
              class="form-field__input"
              [class.form-field__input--invalid]="form.errors._url"
              [class.form-field__input--one-action]="form.controls.url.isValidationPending"
            >
            <div class="form-field__actions" *ngIf="form.controls.url.isValidationPending">
              <progress-indicator
                [inProgress]="form.controls.url.isValidationPending"
                [inputAction]="true"></progress-indicator>
            </div>
          </div>
          <ul class="form-field__error-list">
            <li *ngIf="form.errors._url?.$exists" class="form-field__error">
              Cannot connect to {{ form.errors._url?.$exists }}
            </li>
          </ul>
        </div>

        <div class="auth-form__actions">
          <button
            id="loginSubmit"
            class="button button--large button--green"
            [disabled]="hasLoginStarted"
            type="submit">
            Log in
          </button>

          <progress-indicator
            [inProgress]="hasLoginStarted"
            inProgressText="Logging In"
            [completed]="hasLoginFinished"
            completedText="Logged In"></progress-indicator>
        </div>

      </form>
    </div>
  </div>
</div>
