import { environment } from "../environments/environment";

const USE_SAME_DOMAIN = true;
export let API_URL: string;
if (environment.hasOwnProperty("api_url")) {
  API_URL = environment["api_url"];
} else {
  if (environment.production) {
    if (USE_SAME_DOMAIN === true) {
      const hostname = window.location.hostname;
      API_URL = "https://" + hostname + "/api/";
    } else {
      // Assume api is at current domain with api subdomain prefix
      // Assume https. Should never not use https
      const domain = window.location.hostname;
      API_URL = "https://api." + domain + "/api/";
    }
  } else {
    // Assume dev in local environment
    API_URL = "http://127.0.0.1:8000/api/";
  }
}

// if acccessing via e2e tests in docker
if (environment.docker && window.location.hostname === "web") {
  API_URL = "http://api:8000/api/";
}

export const IS_EXTENSION = environment.extension;
export const IS_NATIVESCRIPT = environment.nativescript;
export const DEFAULT_API = "app.passit.io";
