import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { getIsLoggedIn, IAuthState } from "../account/account.reducer";
import { getIsPrivateOrgMode, IState } from "./../app.reducers";

@Component({
  selector: "no-content-container",
  template: `
    <no-content
      [isLoggedIn]="isLoggedIn$ | async"
      [isPrivateOrgMode]="isPrivateOrgMode$ | async"
      [currentRoute]="currentRoute"
    ></no-content>
  `
})
export class NoContentContainer {
  currentRoute = "";
  isLoggedIn$ = this.accountStore.select(getIsLoggedIn);
  isPrivateOrgMode$ = this.store.select(getIsPrivateOrgMode);

  constructor(
    private accountStore: Store<IAuthState>,
    private router: Router,
    private store: Store<IState>
  ) {
    this.router.events.subscribe((route: any) => {
      this.currentRoute = route.url;
    });
  }
}
