/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "../../list/list.component.scss.shim.ngstyle";
import * as i1 from "../../list/secret-row/secret-row.component.scss.shim.ngstyle";
import * as i2 from "./group-pending-list.component.scss.shim.ngstyle";
import * as i3 from "@angular/core";
import * as i4 from "@angular/common";
import * as i5 from "./group-pending-list.component";
var styles_GroupPendingListComponent = [i0.styles, i1.styles, i2.styles];
var RenderType_GroupPendingListComponent = i3.ɵcrt({ encapsulation: 0, styles: styles_GroupPendingListComponent, data: {} });
export { RenderType_GroupPendingListComponent as RenderType_GroupPendingListComponent };
function View_GroupPendingListComponent_2(_l) { return i3.ɵvid(0, [(_l()(), i3.ɵeld(0, 0, null, null, 9, "div", [["class", "secret-list-item group-pending-list-item"]], null, null, null, null, null)), (_l()(), i3.ɵeld(1, 0, null, null, 8, "div", [["class", "secret"]], null, null, null, null, null)), (_l()(), i3.ɵeld(2, 0, null, null, 7, "div", [["class", "secret__heading"]], null, null, null, null, null)), (_l()(), i3.ɵeld(3, 0, null, null, 1, "h3", [["class", "secret__title"]], null, null, null, null, null)), (_l()(), i3.ɵted(4, null, [" ", " "])), (_l()(), i3.ɵeld(5, 0, null, null, 4, "div", [["class", "secret__header-actions"]], null, null, null, null, null)), (_l()(), i3.ɵeld(6, 0, null, null, 1, "button", [["class", "button button--primary secret__button"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.acceptGroup.emit(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i3.ɵted(-1, null, [" Accept "])), (_l()(), i3.ɵeld(8, 0, null, null, 1, "button", [["class", "button button--primary secret__button"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.declineGroup.emit(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i3.ɵted(-1, null, [" Decline "]))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit.name; _ck(_v, 4, 0, currVal_0); }); }
function View_GroupPendingListComponent_1(_l) { return i3.ɵvid(0, [(_l()(), i3.ɵeld(0, 0, null, null, 2, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i3.ɵand(16777216, null, null, 1, null, View_GroupPendingListComponent_2)), i3.ɵdid(2, 278528, null, 0, i4.NgForOf, [i3.ViewContainerRef, i3.TemplateRef, i3.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.pendingInviteGroups; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_GroupPendingListComponent_0(_l) { return i3.ɵvid(2, [(_l()(), i3.ɵand(16777216, null, null, 1, null, View_GroupPendingListComponent_1)), i3.ɵdid(1, 16384, null, 0, i4.NgIf, [i3.ViewContainerRef, i3.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.pendingInviteGroups && (_co.pendingInviteGroups.length > 0)); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_GroupPendingListComponent_Host_0(_l) { return i3.ɵvid(0, [(_l()(), i3.ɵeld(0, 0, null, null, 1, "app-group-pending-list", [], null, null, null, View_GroupPendingListComponent_0, RenderType_GroupPendingListComponent)), i3.ɵdid(1, 49152, null, 0, i5.GroupPendingListComponent, [], null, null)], null, null); }
var GroupPendingListComponentNgFactory = i3.ɵccf("app-group-pending-list", i5.GroupPendingListComponent, View_GroupPendingListComponent_Host_0, { pendingInviteGroups: "pendingInviteGroups" }, { acceptGroup: "acceptGroup", declineGroup: "declineGroup" }, []);
export { GroupPendingListComponentNgFactory as GroupPendingListComponentNgFactory };
