<div class="popup">
  <div (click)="openFullApp.emit()" class="popup__header">
    <div class="popup__logo" [inlineSVG]="'../../../assets/svg/passit-logo.svg'"></div>
  </div>
  <a
    class="popup__full-app-link"
    href="javascript:void(0)"
    tooltip="Open full app"
    tooltipPlacement="right"
    (click)="openFullApp.emit()">
    <div class="popup__full-app-icon" [inlineSVG]="'../../../assets/svg/full-app.svg'"></div>
  </a>

  <search
    [query]="search"
    (searchTerm)="searchUpdate.emit($event)"
    class="popup__search"></search>

  <div *ngIf="matchedSecrets.length">
    <div class="popup__password-list-header popup__password-list-header--matched">Passwords associated with this site</div>
    <div *ngFor="let secret of matchedSecrets">
      <app-popup-item
        [secret]="secret" 
        [isSelected]="secret.id === selectedSecret"
        [formFillMessage]="formFillMessage"
        [usernameCopied]="secret.id === usernameCopied"
        [passwordCopied]="secret.id === passwordCopied"
        isMatched="true"
        (autofill)="signIn.emit(secret)"
        (openUrl)="openUrl.emit(secret)"
        (onCopyUsername)="onCopyUsername.emit(secret)"
        (onCopyPassword)="onCopyPassword.emit(secret)"
        (setSelected)="setSelected.emit($event)"
        (closeSelected)="setSelected.emit()"
        (onDetail)="onDetail.emit(secret)">
      ></app-popup-item>
    </div>
  </div>

  <div class="popup__password-list-header" *ngIf="matchedSecrets.length > 0">All Passwords</div>
  <div *ngFor="let secret of secrets">
    <app-popup-item
      [secret]="secret"
      [isSelected]="secret.id === selectedSecret"
      [formFillMessage]="formFillMessage"
      [usernameCopied]="secret.id === usernameCopied"
      [passwordCopied]="secret.id === passwordCopied"
      (openUrl)="openUrl.emit(secret)"
      (onCopyUsername)="onCopyUsername.emit(secret)"
      (onCopyPassword)="onCopyPassword.emit(secret)"
      (setSelected)="setSelected.emit($event)"
      (closeSelected)="setSelected.emit()"
      (onDetail)="onDetail.emit(secret)">
    ></app-popup-item>
  </div>
</div>
