import { Injectable } from "@angular/core";
import PassitSDK from "passit-sdk-js";
import { Api } from "./api";

/**
 * Angular wrapper of PassitSDK that swaps out the api class with one that uses
 * $http instead of fetch. This is done for better integration with angular
 * tooling like Protractor.
 */
@Injectable()
export class NgPassitSDK extends PassitSDK {
  constructor(api: Api) {
    super();
    this.api = api;
  }
}
