var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
import { createFeatureSelector, createSelector } from "@ngrx/store";
import { ResetFormActionTypes } from "../form/reset-form.actions";
import { setContacts } from "../utils/contacts";
import { GroupActionTypes } from "./group.actions";
import { getUserId } from "../account/account.reducer";
import { createEntityAdapter } from "@ngrx/entity";
import * as fromContacts from "./contacts/contacts.reducer";
function sortByName(a, b) {
    return a.name.localeCompare(b.name);
}
export var adapter = createEntityAdapter({
    sortComparer: sortByName
});
export var initialState = adapter.getInitialState({
    groupForm: {
        name: "",
        members: []
    },
    groupManaged: null,
    showCreate: false,
    groupIsUpdating: false,
    groupIsUpdated: false,
    contactLookup: null,
    newContacts: [],
    contacts: fromContacts.initialState
});
export function groupReducer(state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case GroupActionTypes.ADD_GROUP: {
            return adapter.addOne(action.payload, state);
        }
        case GroupActionTypes.REMOVE_GROUP: {
            return adapter.removeOne(action.payload, state);
        }
        case GroupActionTypes.RESET_BLANK_GROUP_FORM: {
            return Object.assign({}, state, {
                groupForm: initialState.groupForm
            });
        }
        case GroupActionTypes.SET_GROUPS:
            return adapter.addAll(action.payload, state);
        case GroupActionTypes.SHOW_GROUPS_CREATE:
            return __assign({}, state, { showCreate: true, contactLookup: null, groupIsUpdating: false, groupIsUpdated: false, groupForm: {
                    members: [],
                    name: ""
                } });
        case GroupActionTypes.CREATE_GROUP:
            return __assign({}, state, { groupIsUpdating: true });
        case GroupActionTypes.CREATE_GROUP_SUCCESS:
            return __assign({}, state, { groupIsUpdating: false, groupIsUpdated: true, showCreate: false, contactLookup: null });
        case GroupActionTypes.HIDE_GROUPS_CREATE:
            return __assign({}, state, { showCreate: false, groupForm: initialState.groupForm });
        case GroupActionTypes.UPDATE_GROUP:
            return __assign({}, state, { groupIsUpdating: true });
        case GroupActionTypes.UPDATE_GROUP_SUCCESS:
            return __assign({}, state, { groupIsUpdating: false, groupIsUpdated: true, contactLookup: null });
        case GroupActionTypes.CLEAR_MANAGED_GROUP:
            return __assign({}, state, { groupManaged: null, groupForm: initialState.groupForm });
        case ResetFormActionTypes.RESET_FORMS:
            return __assign({}, initialState, { entities: state.entities, ids: state.ids });
        case GroupActionTypes.UPDATE_FORM:
            return __assign({}, state, { groupForm: {
                    name: action.payload.name,
                    members: action.payload.members
                } });
        case GroupActionTypes.SET_MANAGED_GROUP:
            var groupId = action.payload;
            var foundGroup = state.entities[groupId];
            var users = foundGroup.groupuser_set.map(function (groupMember) { return groupMember.user; });
            return __assign({}, state, { groupManaged: groupId, groupIsUpdating: false, groupIsUpdated: false, groupForm: {
                    name: foundGroup.name,
                    members: users
                } });
        case GroupActionTypes.CONTACT_LOOKUP_SUCCESS:
            return __assign({}, state, { contactLookup: action.payload });
        // Add selected contact to contact list
        case GroupActionTypes.CONTACT_RESET:
            return __assign({}, state, { contacts: fromContacts.contactsReducer(state.contacts, action), contactLookup: null });
        case GroupActionTypes.CONTACT_LOOKUP_FAILURE:
            return __assign({}, state, { contactLookup: null });
        case GroupActionTypes.ROUTER_UPDATE_LOCATION:
            return __assign({}, state, { groupManaged: null, showCreate: false });
        default:
            var contacts = fromContacts.contactsReducer(state.contacts, action);
            return __assign({}, state, { contacts: contacts });
    }
}
export var getGroupState = createFeatureSelector("group");
var selectAll = adapter.getSelectors().selectAll;
/** Get all groups including those pending invite acceptance.
 * You probably want to use getPendingGroups or getActiveGroups instead.
 */
export var getGroups = createSelector(getGroupState, selectAll);
/** Get pending group invites */
export var getPendingGroups = createSelector(getGroups, getUserId, function (groups, userId) {
    return groups.filter(function (group) {
        return group.groupuser_set.find(function (groupuser) { return groupuser.user === userId && groupuser.is_invite_pending; });
    });
});
/** Get active groups that have accepted invite status */
export var getActiveGroups = createSelector(getGroups, getUserId, function (groups, userId) {
    return groups.filter(function (group) {
        return !group.groupuser_set.find(function (groupuser) { return groupuser.user === userId && groupuser.is_invite_pending; });
    });
});
/** Determines if any group invites exist */
export var hasGroupInvites = createSelector(getGroups, getUserId, function (groups, userId) {
    return (typeof groups.find(function (group) {
        return (typeof group.groupuser_set.find(function (groupuser) {
            return groupuser.user === userId && groupuser.is_invite_pending;
        }) !== "undefined");
    }) !== "undefined");
});
export var getGroupShowCreate = createSelector(getGroupState, function (state) { return state.showCreate; });
export var getGroupManaged = createSelector(getGroupState, function (state) { return state.groupManaged; });
export var getGroupForm = createSelector(getGroupState, function (state) { return state.groupForm; });
export var getGroupIsUpdating = createSelector(getGroupState, function (state) { return state.groupIsUpdating; });
export var getGroupIsUpdated = createSelector(getGroupState, function (state) { return state.groupIsUpdated; });
export var getContactLookup = createSelector(getGroupState, function (state) { return state.contactLookup; });
var ɵ0 = function (state) { return state.contacts; };
var getContactsState = createSelector(getGroupState, ɵ0);
var ɵ1 = function (state) { return state.newContacts; };
var getNewContacts = createSelector(getGroupState, ɵ1);
export var getContacts = createSelector(getContactsState, fromContacts.getContacts);
/** Get contacts plus "new contacts" which are ones found from looking them up by email */
export var getCombinedContacts = createSelector(getContacts, getNewContacts, function (contacts, newContacts) { return contacts.concat(newContacts); });
/* Return contacts for UI display. If searched contact exists, return all
   contacts plus searched contact.
*/
export var getGroupMembersForDisplay = createSelector(getContacts, getContactLookup, function (members, searchedContact) {
    if (searchedContact) {
        var searchedMembersArray = setContacts(members, true);
        var duplicateContact_1 = searchedMembersArray.find(function (contact) { return contact.label === searchedContact.label; });
        if (duplicateContact_1) {
            searchedMembersArray.map(function (contact) {
                contact !== duplicateContact_1
                    ? (contact.disabled = true)
                    : (contact.disabled = false);
            });
            return searchedMembersArray;
        }
        return searchedMembersArray.concat(searchedContact);
    }
});
// Return all contacts for UI display.
export var getGroupContacts = createSelector(getContacts, function (members) {
    var membersArray = setContacts(members, false);
    return membersArray;
});
export var getDisplayContacts = createSelector(getContactsState, fromContacts.getDisplayContacts);
export var getSingleGroupPendingContacts = createSelector(getGroups, getGroupManaged, function (groups, managedGroup) {
    var managedGroupObject = groups.find(function (group) { return group.id === managedGroup; });
    if (managedGroupObject) {
        return managedGroupObject.groupuser_set.filter(function (groupUser) { return groupUser.is_invite_pending; });
    }
    else {
        return [];
    }
});
/** Put groups in a format that select2 can use. */
export var getActiveGroupsAsOptions = createSelector(getActiveGroups, function (groups) {
    var activeGroupsAsOptions = [];
    groups.forEach(function (group) {
        return activeGroupsAsOptions.push({
            value: group.id,
            label: group.name,
            disabled: false
        });
    });
    return activeGroupsAsOptions;
});
export { ɵ0, ɵ1 };
