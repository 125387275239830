import { createFeatureSelector, createSelector } from "@ngrx/store";
import {
  ResetFormActionTypes,
  ResetFormActionsUnion
} from "../form/reset-form.actions";
import { setContacts } from "../utils/contacts";
import { ContactsActionsUnion } from "./contacts/contacts.actions";
import { ISelectOptions } from "./group-form/group-form.interfaces";
import { GroupActionsUnion, GroupActionTypes } from "./group.actions";
import { IGroup } from "./group.interfaces";
import { IGroupForm } from "./group.interfaces";
import { IContact } from "./contacts/contacts.interfaces";
import { getUserId } from "../account/account.reducer";
import { EntityState, createEntityAdapter } from "@ngrx/entity";
import * as fromContacts from "./contacts/contacts.reducer";

export interface IGroupState extends EntityState<IGroup> {
  groupForm: IGroupForm;
  groupManaged?: number | null;
  showCreate: boolean;
  groupIsUpdating: boolean;
  groupIsUpdated: boolean;
  contactLookup: ISelectOptions | null;
  newContacts: IContact[];
  contacts: fromContacts.IContactsState;
}

function sortByName(a: IGroup, b: IGroup) {
  return a.name.localeCompare(b.name);
}
export const adapter = createEntityAdapter<IGroup>({
  sortComparer: sortByName
});

export const initialState: IGroupState = adapter.getInitialState({
  groupForm: {
    name: "",
    members: []
  },
  groupManaged: null,
  showCreate: false,
  groupIsUpdating: false,
  groupIsUpdated: false,
  contactLookup: null,
  newContacts: [],
  contacts: fromContacts.initialState
});

export function groupReducer(
  state = initialState,
  action: ResetFormActionsUnion | GroupActionsUnion | ContactsActionsUnion
): IGroupState {
  switch (action.type) {
    case GroupActionTypes.ADD_GROUP: {
      return adapter.addOne(action.payload, state);
    }

    case GroupActionTypes.REMOVE_GROUP: {
      return adapter.removeOne(action.payload, state);
    }

    case GroupActionTypes.RESET_BLANK_GROUP_FORM: {
      return Object.assign({}, state, {
        groupForm: initialState.groupForm
      });
    }

    case GroupActionTypes.SET_GROUPS:
      return adapter.addAll(action.payload, state);

    case GroupActionTypes.SHOW_GROUPS_CREATE:
      return {
        ...state,
        showCreate: true,
        contactLookup: null,
        groupIsUpdating: false,
        groupIsUpdated: false,
        groupForm: {
          members: [],
          name: ""
        }
      };

    case GroupActionTypes.CREATE_GROUP:
      return {
        ...state,
        groupIsUpdating: true
      };

    case GroupActionTypes.CREATE_GROUP_SUCCESS:
      return {
        ...state,
        groupIsUpdating: false,
        groupIsUpdated: true,
        showCreate: false,
        contactLookup: null
      };

    case GroupActionTypes.HIDE_GROUPS_CREATE:
      return {
        ...state,
        showCreate: false,
        groupForm: initialState.groupForm
      };

    case GroupActionTypes.UPDATE_GROUP:
      return {
        ...state,
        groupIsUpdating: true
      };

    case GroupActionTypes.UPDATE_GROUP_SUCCESS:
      return {
        ...state,
        groupIsUpdating: false,
        groupIsUpdated: true,
        contactLookup: null
      };

    case GroupActionTypes.CLEAR_MANAGED_GROUP:
      return {
        ...state,
        groupManaged: null,
        groupForm: initialState.groupForm
      };

    case ResetFormActionTypes.RESET_FORMS:
      return {
        ...initialState,
        entities: state.entities,
        ids: state.ids
      };

    case GroupActionTypes.UPDATE_FORM:
      return {
        ...state,
        groupForm: {
          name: action.payload.name,
          members: action.payload.members
        }
      };

    case GroupActionTypes.SET_MANAGED_GROUP:
      const groupId = action.payload;
      const foundGroup = state.entities[groupId];
      const users = foundGroup!.groupuser_set.map(
        groupMember => groupMember.user
      );
      return {
        ...state,
        groupManaged: groupId,
        groupIsUpdating: false,
        groupIsUpdated: false,
        groupForm: {
          name: foundGroup!.name,
          members: users
        }
      };

    case GroupActionTypes.CONTACT_LOOKUP_SUCCESS:
      return {
        ...state,
        contactLookup: action.payload
      };

    // Add selected contact to contact list
    case GroupActionTypes.CONTACT_RESET:
      return {
        ...state,
        contacts: fromContacts.contactsReducer(state.contacts, action),
        contactLookup: null
      };

    case GroupActionTypes.CONTACT_LOOKUP_FAILURE:
      return {
        ...state,
        contactLookup: null
      };

    case GroupActionTypes.ROUTER_UPDATE_LOCATION:
      return {
        ...state,
        groupManaged: null,
        showCreate: false
      };

    default:
      const contacts = fromContacts.contactsReducer(
        state.contacts,
        action as ContactsActionsUnion
      );
      return { ...state, contacts };
  }
}

export const getGroupState = createFeatureSelector<IGroupState>("group");

const { selectAll } = adapter.getSelectors();

/** Get all groups including those pending invite acceptance.
 * You probably want to use getPendingGroups or getActiveGroups instead.
 */
export const getGroups = createSelector(getGroupState, selectAll);

/** Get pending group invites */
export const getPendingGroups = createSelector(
  getGroups,
  getUserId,
  (groups, userId) =>
    groups.filter(group =>
      group.groupuser_set.find(
        groupuser => groupuser.user === userId && groupuser.is_invite_pending
      )
    )
);

/** Get active groups that have accepted invite status */
export const getActiveGroups = createSelector(
  getGroups,
  getUserId,
  (groups, userId) =>
    groups.filter(
      group =>
        !group.groupuser_set.find(
          groupuser => groupuser.user === userId && groupuser.is_invite_pending
        )
    )
);

/** Determines if any group invites exist */
export const hasGroupInvites = createSelector(
  getGroups,
  getUserId,
  (groups, userId) => {
    return (
      typeof groups.find(group => {
        return (
          typeof group.groupuser_set.find(
            groupuser =>
              groupuser.user === userId && groupuser.is_invite_pending
          ) !== "undefined"
        );
      }) !== "undefined"
    );
  }
);

export const getGroupShowCreate = createSelector(
  getGroupState,
  (state: IGroupState) => state.showCreate
);
export const getGroupManaged = createSelector(
  getGroupState,
  (state: IGroupState) => state.groupManaged
);
export const getGroupForm = createSelector(
  getGroupState,
  (state: IGroupState) => state.groupForm
);
export const getGroupIsUpdating = createSelector(
  getGroupState,
  (state: IGroupState) => state.groupIsUpdating
);
export const getGroupIsUpdated = createSelector(
  getGroupState,
  (state: IGroupState) => state.groupIsUpdated
);
export const getContactLookup = createSelector(
  getGroupState,
  (state: IGroupState) => state.contactLookup
);

const getContactsState = createSelector(
  getGroupState,
  (state: IGroupState) => state.contacts
);
const getNewContacts = createSelector(
  getGroupState,
  (state: IGroupState) => state.newContacts
);

export const getContacts = createSelector(
  getContactsState,
  fromContacts.getContacts
);
/** Get contacts plus "new contacts" which are ones found from looking them up by email */
export const getCombinedContacts = createSelector(
  getContacts,
  getNewContacts,
  (contacts, newContacts) => contacts.concat(newContacts)
);

/* Return contacts for UI display. If searched contact exists, return all
   contacts plus searched contact.
*/
export const getGroupMembersForDisplay = createSelector(
  getContacts,
  getContactLookup,
  (members, searchedContact) => {
    if (searchedContact) {
      const searchedMembersArray: ISelectOptions[] = setContacts(members, true);
      const duplicateContact = searchedMembersArray.find(
        contact => contact.label === searchedContact.label
      );
      if (duplicateContact) {
        searchedMembersArray.map(contact => {
          contact !== duplicateContact
            ? (contact.disabled = true)
            : (contact.disabled = false);
        });
        return searchedMembersArray;
      }
      return searchedMembersArray.concat(searchedContact);
    }
  }
);

// Return all contacts for UI display.
export const getGroupContacts = createSelector(getContacts, members => {
  const membersArray: ISelectOptions[] = setContacts(members, false);
  return membersArray;
});

export const getDisplayContacts = createSelector(
  getContactsState,
  fromContacts.getDisplayContacts
);

export const getSingleGroupPendingContacts = createSelector(
  getGroups,
  getGroupManaged,
  (groups, managedGroup) => {
    const managedGroupObject = groups.find(group => group.id === managedGroup);
    if (managedGroupObject) {
      return managedGroupObject.groupuser_set.filter(
        groupUser => groupUser.is_invite_pending
      );
    } else {
      return [];
    }
  }
);

/** Put groups in a format that select2 can use. */
export const getActiveGroupsAsOptions = createSelector(
  getActiveGroups,
  groups => {
    const activeGroupsAsOptions: ISelectOptions[] = [];
    groups.forEach(group =>
      activeGroupsAsOptions.push({
        value: group.id,
        label: group.name,
        disabled: false
      })
    );
    return activeGroupsAsOptions;
  }
);
