var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Effect } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import * as fromAccount from "../account.reducer";
import { filter, switchMap, map, distinctUntilChanged, catchError } from "rxjs/operators";
import { timer } from "rxjs/observable/timer";
import { StartAsyncValidationAction, ClearAsyncErrorAction, SetAsyncErrorAction } from "ngrx-forms";
import { UserService } from "../user";
import { concat } from "rxjs";
var LoginFormEffects = /** @class */ (function () {
    function LoginFormEffects(store, userService) {
        var _this = this;
        this.store = store;
        this.userService = userService;
        /** Implements "as you type" check to determine if the server url is valid or not */
        this.asyncServerUrlCheck$ = this.store.select(fromAccount.getLoginForm).pipe(filter(function (form) { return form.value.showUrl; }), distinctUntilChanged(function (first, second) { return first.value.url === second.value.url; }), switchMap(function (form) {
            return concat(timer(300).pipe(map(function () { return new StartAsyncValidationAction(form.controls.url.id, "exists"); })), _this.userService.checkUrl(form.value.url).pipe(map(function () { return new ClearAsyncErrorAction(form.controls.url.id, "exists"); }), catchError(function () { return [
                new SetAsyncErrorAction(form.controls.url.id, "exists", form.value.url)
            ]; })));
        }));
    }
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], LoginFormEffects.prototype, "asyncServerUrlCheck$", void 0);
    return LoginFormEffects;
}());
export { LoginFormEffects };
