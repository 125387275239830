var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Actions, Effect, ofType } from "@ngrx/effects";
import { switchMap, map, exhaustMap, tap, withLatestFrom } from "rxjs/operators";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { UserService } from "../user";
import { ResetRegisterCodeFailureAction, ResetRegisterCodeSuccessAction, ConfirmEmailTypes, VerifyEmailFailure, VerifyEmailSuccess } from "./confirm-email.actions";
import { NgPassitSDK } from "../../ngsdk/sdk";
import { getRouterPath } from "../../app.reducers";
var ConfirmEmailEffects = /** @class */ (function () {
    function ConfirmEmailEffects(actions$, userService, sdk, router, store) {
        var _this = this;
        this.actions$ = actions$;
        this.userService = userService;
        this.sdk = sdk;
        this.router = router;
        this.store = store;
        this.ResetRegisterCode$ = this.actions$.pipe(ofType(ConfirmEmailTypes.RESET_REGISTER_CODE), switchMap(function () {
            return _this.userService
                .resetRegisterCode()
                .then(function (resp) { return new ResetRegisterCodeSuccessAction(); })
                .catch(function (error) { return new ResetRegisterCodeFailureAction(); });
        }));
        this.VerifyEmail$ = this.actions$.pipe(ofType(ConfirmEmailTypes.VERIFY_EMAIL), map(function (action) { return action.payload; }), exhaustMap(function (code) {
            return _this.sdk
                .confirm_email_short_code(code)
                .then(function () { return new VerifyEmailSuccess(); })
                .catch(function (err) {
                var res = err.res;
                var status = res.status;
                var errorMessage = "Unexpected error.";
                if (status === 404 || status === 400) {
                    errorMessage =
                        "This code doesn't match what we sent. Please try again.";
                }
                else if (status === 406) {
                    errorMessage = "We invalidated this code because of too many failed attempts.\n            Press the \"Resend confirmation\" link below to receive a new code.";
                }
                else if (status === 403) {
                    return (errorMessage =
                        "To validate via confirmation code, please log in first.");
                }
                return new VerifyEmailFailure(errorMessage);
            });
        }));
        this.VerifyEmailSuccess$ = this.actions$.pipe(ofType(ConfirmEmailTypes.VERIFY_EMAIL_SUCCESS), withLatestFrom(this.store.select(getRouterPath)), map(function (_a) {
            var action = _a[0], path = _a[1];
            return path;
        }), tap(function (path) {
            // The confirm email page automatically redirects while register does not
            if (path === "/confirm-email") {
                _this.router.navigate(["/list"]);
            }
        }));
    }
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], ConfirmEmailEffects.prototype, "ResetRegisterCode$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], ConfirmEmailEffects.prototype, "VerifyEmail$", void 0);
    __decorate([
        Effect({ dispatch: false }),
        __metadata("design:type", Object)
    ], ConfirmEmailEffects.prototype, "VerifyEmailSuccess$", void 0);
    return ConfirmEmailEffects;
}());
export { ConfirmEmailEffects };
