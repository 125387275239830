var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { mergeMap, withLatestFrom, map, catchError } from "rxjs/operators";
import { Actions, Effect } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { of, EMPTY } from "rxjs";
import { HandleAPIErrorAction } from "../account/account.actions";
import * as fromRoot from "../app.reducers";
import { ConfTypes, SetConfAction } from "./conf.actions";
import { GetConfService } from "./get-conf.service";
var GetConfEffects = /** @class */ (function () {
    function GetConfEffects(actions$, getConfService, store) {
        var _this = this;
        this.actions$ = actions$;
        this.getConfService = getConfService;
        this.store = store;
        this.getConf$ = this.actions$.ofType(ConfTypes.GET_CONF).pipe(withLatestFrom(this.store.select(fromRoot.getTimestamp)), map(function (_a) {
            var action = _a[0], timestamp = _a[1];
            return timestamp;
        }), mergeMap(function (timestamp) {
            var performUpdate = false;
            if (timestamp === null) {
                performUpdate = true;
            }
            else {
                var timeNow = Date.now() / (1000 * 60 * 60);
                var timeStampHours = new Date(timestamp).getTime() / (1000 * 60 * 60);
                var timePassed = timeNow - timeStampHours;
                if (timePassed > 24) {
                    performUpdate = true;
                }
            }
            if (performUpdate === true) {
                return _this.getConfService.getConf().pipe(map(function (resp) { return new SetConfAction(resp); }), catchError(function (error) { return of(new HandleAPIErrorAction(error)); }));
            }
            else {
                return EMPTY;
            }
        }));
    }
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], GetConfEffects.prototype, "getConf$", void 0);
    return GetConfEffects;
}());
export { GetConfEffects };
