<div class="list-actions">
  <div class="list-actions__search">
    <search
      [secretsLength]="totalSecretsCount"
      (searchTerm)="searchUpdate.emit($event)"></search>
    <div class="list-actions__select">
      <ng-select
        #groupFilterSelect
        *ngIf="groups.length"
        id="secretFilterByGroup"
        name="secretFilterByGroup"
        placeholder="Filter by group"
        noFilter="5"
        [options]="groupOptions"
        (selected)="updateGroup($event)"
        >
      </ng-select>
      <a
        *ngIf="selectedGroupId"
        href="javascript:void(0)"
        (click)="resetSelectedGroup()"
        class="list-actions__select-reset">
        ×
      </a>
    </div>
  </div>

  <div class="list-actions__actions">
    <button
      type="button"
      class="button list-actions__button"
      [class.button--gray]="totalSecretsCount > 0"
      [class.list-actions__button--animated]="totalSecretsCount === 0"
      [class.list-actions__button--not-animated]="totalSecretsCount !== 0"
      [class.button--primary]="totalSecretsCount === 0"
      id="add-new-password-button"
      (click)="showAdd()">
      <div class="button__icon" [inlineSVG]="'../assets/svg/plus.svg'"></div>
      Add<span class="u-visible--all-but-small"> New</span><span class="u-hidden--all-small"> Password</span>
    </button>

    <div *ngIf="totalSecretsCount === 0" class="list-actions__import-link">
      or <a [routerLink]=" ['/import'] " class="text-link text-link--caret text-link--large">Import a CSV file</a>
    </div>
  </div>
</div>
<div *ngIf="totalSecretsCount === 0 && !showCreate && firstTimeLoadingComplete" class="l-container list-empty-state">
  <div class="list-empty-state__copy">
    <h2 class="heading-medium">
      Store your passwords&nbsp;here.
    </h2>
    <p class="text--large">
      Press “Add<span class="u-visible--all-but-small"> New</span><span class="u-hidden--all-small"> Password</span>” to save your first password and start filling this page with your logins.<span class="u-visible--all-but-small"> If you&rsquo;re bringing data from another password manager, try our&nbsp;importer.</span>
    </p>
  </div>
</div>
<div
  *ngIf="totalSecretsCount === 1 && !showCreate && secrets.length !== 0 && firstTimeLoadingComplete && !selectedGroupId"
  class="l-container list-empty-state">
  <div class="list-empty-state__copy">
    <h2 class="heading-medium">
      Your first password is secured.
    </h2>
    <p class="text--large">
      As you add more usernames and passwords, use the search bar above to quickly find them. Click “Groups” above to learn how to share your passwords, and click <span class="u-visible--all-but-xl">the user icon</span><span class="u-hidden--all-but-xl">“Account”</span> to access tools and settings.
    </p>
  </div>
</div>

<div class="secret-list" #secretList>
  <div
    *ngIf="showCreate"
    class="secret-list-item"
    [class.secret-list-item--is-active]="animateAddForm">
    <secret-add
      (hideAddSecretForm)="hideAdd()"
    ></secret-add>
  </div>
  <div 
    *ngFor="let secret of secrets"
    class="secret-list-item"
    id="secret-list-item--{{ secret.id }}"
    [class.secret-list-item--is-active]="secret.id === secretManaged">
    <secret-row 
      [secret]="secret"
      [active]="secret.id === secretManaged"
      (onSecretSelected)="onSecretWasSelected($event)">
    </secret-row>
    <secret-form-container
      *ngIf="secret.id === secretManaged"
      class="secret-form-component"
      [class.secret-form-component--is-active]="secretAnimated"
    ></secret-form-container>
  </div>
</div>
<div
  *ngIf="totalSecretsCount > 0 && secrets.length === 0"
  class="l-container">
  <p
    *ngIf="selectedGroupId && !searchText"
    class="text--large text--less-bottom-margin">
    There are no passwords in this group. To add one, <a href="javascript:void(0);" (click)="resetSelectedGroup()" class="text-link">clear the group filter</a>, select a password to manage, and add the group to it. Then, anyone in your group will be able to see and edit that&nbsp;password.
  </p>
  <p
    *ngIf="searchText"
    class="text--large">
    <span *ngIf="selectedGroupId">
      Your search &ldquo;{{ searchText }}&rdquo; did not match anything saved in this group. To use this search for all passwords, <a href="javascript:void(0);" (click)="resetSelectedGroup()" class="text-link">clear the group filter</a>.
    </span>
    <span *ngIf="!selectedGroupId">
      Your search &ldquo;{{ searchText }}&rdquo; did not match anything saved in this list.
    </span>
  </p>
</div>

<div *ngIf="!firstTimeLoadingComplete">
  Loading...
</div>

<div
  *ngIf="firstTimeLoadingComplete && totalSecretsCount === 0 || (totalSecretsCount === 1 && secrets.length !== 0 && !selectedGroupId)"
  class="list-placeholders"
  [class.list-placeholders--empty]="totalSecretsCount === 0">
  <div class="list-placeholder" *ngFor="let number of ' '.repeat(20).split(''), let x = index">
    <div class="list-placeholder__heading">
      <h3 class="list-placeholder__title">
        <div class="list-placeholder__text list-placeholder__text--title"></div>
      </h3>
      <div class="secret__header-actions">
        <button
          type="button"
          disabled="disabled"
          class="button button--placeholder list-placeholder__button">
          <div class="list-placeholder__text list-placeholder__text--button"></div>
        </button>
      </div>
    </div>
  </div>
</div>
