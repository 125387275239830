<div class="account l-container-narrow u-margin-tb-50">

  <h2 class="heading-main">
    Account Management
  </h2>

  <ul class="account__list">
    <li class="account__list-item">
      <a [routerLink]=" ['./change-password'] " class="text-link text-link--caret text-link--large">
        <h3 class="account__item-heading">Change Account Password</h3>
      </a>
    </li>

    <li class="account__list-item">
      <a [routerLink]=" ['../import'] " class="text-link text-link--caret text-link--large">
        <h3 class="account__item-heading">Import Passwords</h3>
      </a>
    </li>
 
    <li class="account__list-item">
      <a [routerLink]=" ['../export'] " class="text-link text-link--caret text-link--large">
        <h3 class="account__item-heading">Export Passwords</h3>
      </a>
    </li>

    <li class="account__list-item">
      <a [routerLink]=" ['./error-reporting'] " class="text-link text-link--caret text-link--large">
        <h3 class="account__item-heading">Error Reporting</h3>
      </a>
    </li>

    <li class="account__list-item">
      <a [routerLink]=" ['./delete'] " class="text-link text-link--caret text-link--large">
        <h3 class="account__item-heading">Delete Account</h3>
      </a>
    </li>

    <li class="account__list-item">
      <a (click)="logout()" href="#"  class="text-link text-link--caret text-link--large">
        <h3 class="account__item-heading">Log Out</h3>
      </a>
    </li>
  </ul>
  <br/><br/>

  <h2 class="heading-main">
    Browser Extensions
  </h2>

  <ul class="account__list">
    <li class="account__list-item">
      <span class="account__icon-extension" [inlineSVG]="'../assets/svg/firefox-icon.svg'"></span>
      <a href="https://addons.mozilla.org/addon/passit_io/" class="text-link text-link--caret text-link--large">
        <h3 class="account__item-heading">Get Firefox Add-On</h3>
      </a>
    </li>

    <li class="account__list-item">
      <span class="account__icon-extension" [inlineSVG]="'../assets/svg/chrome-icon.svg'"></span>
      <a href="https://chrome.google.com/webstore/detail/passit/pgcleadjbkbghamecomebcdakdmahkeh" class="text-link text-link--caret text-link--large">
        <h3 class="account__item-heading">Get Chrome Extension</h3>
      </a>
    </li>
  </ul>

</div>