import { ChangeDetectionStrategy, Component, Input } from "@angular/core";

@Component({
  selector: "app-non-field-messages",
  styleUrls: ["./non-field-messages.styles.scss"],
  templateUrl: "./non-field-messages.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NonFieldMessagesComponent {
  /**
   * Receives multiple messages (or a single message as an array of one) and
   * displays them
   */
  @Input() messages: string[];

  constructor() {}
}
