import { EventEmitter, ElementRef } from "@angular/core";
import { RegisterStages } from "../constants";
var RegisterComponent = /** @class */ (function () {
    function RegisterComponent() {
        this.register = new EventEmitter();
        this.goToLogin = new EventEmitter();
        this.checkEmail = new EventEmitter();
        this.checkUrl = new EventEmitter();
        this.toggleShowConfirm = new EventEmitter();
        this.markAsSubmitted = new EventEmitter();
        this.incrementStage = new EventEmitter();
        this.switchStage = new EventEmitter();
        this.displayUrlInput = new EventEmitter();
        this.hideUrlInput = new EventEmitter();
        this.setNewsletterSubscribe = new EventEmitter();
        this.registrationFinished = new EventEmitter();
        this.passwordFocused = false;
        this.stages = RegisterStages;
    }
    Object.defineProperty(RegisterComponent.prototype, "stageValue", {
        get: function () {
            return this._stageValue;
        },
        set: function (value) {
            var _this = this;
            this._stageValue = value;
            switch (value) {
                case this.stages.Password:
                    setTimeout(function () { return _this.passwordInput.nativeElement.focus(); }, 0);
                    return;
                case this.stages.Newsletter:
                    setTimeout(function () { return _this.newsletterInput.nativeElement.focus(); }, 0);
                    return;
            }
        },
        enumerable: true,
        configurable: true
    });
    RegisterComponent.prototype.submit = function () {
        switch (this.stageValue) {
            case this.stages.Email:
                this.submitEmail();
                return;
            case this.stages.Password:
                if (!this.form.errors._password && !this.form.errors._passwordConfirm) {
                    this.incrementStage.emit();
                }
                return;
            case this.stages.Newsletter:
                this.setNewsletterSubscribe.emit();
                this.submitForm();
                return;
            case this.stages.Verified:
                this.registrationFinished.emit();
                return;
        }
    };
    RegisterComponent.prototype.submitEmail = function () {
        if (this.form.controls.email.isValid) {
            this.checkEmail.emit();
        }
    };
    RegisterComponent.prototype.urlSubmit = function () {
        this.checkUrl.emit();
    };
    RegisterComponent.prototype.switchCurrentStage = function (value) {
        this.switchStage.emit(value);
    };
    RegisterComponent.prototype.displayUrl = function () {
        this.displayUrlInput.emit();
    };
    RegisterComponent.prototype.hideUrl = function () {
        this.hideUrlInput.emit();
    };
    RegisterComponent.prototype.submitForm = function () {
        if (this.form.isValid) {
            this.register.emit();
        }
        else {
            this.markAsSubmitted.emit();
        }
    };
    RegisterComponent.prototype.toggleDisplay = function () {
        this.goToLogin.emit();
    };
    RegisterComponent.prototype.goToLoginWith = function () {
        this.goToLogin.emit(this.form.value.email);
    };
    return RegisterComponent;
}());
export { RegisterComponent };
