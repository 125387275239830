var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import { getIsPrivateOrgMode } from "../app.reducers";
import { ResetFormContainer } from "../form/reset-form.container";
import * as fromGroup from "../group/group.reducer";
import * as contactsActions from "./contacts/contacts.actions";
import * as groupActions from "./group.actions";
var GroupContainer = /** @class */ (function (_super) {
    __extends(GroupContainer, _super);
    function GroupContainer(
    // private contactsService: ContactsService,
    store) {
        var _this = _super.call(this, store) || this;
        _this.store = store;
        _this.groups$ = _this.store.select(fromGroup.getActiveGroups);
        _this.pendingInviteGroups$ = _this.store.select(fromGroup.getPendingGroups);
        _this.groupManaged$ = _this.store.select(fromGroup.getGroupManaged);
        _this.singleGroupPendingContacts$ = _this.store.select(fromGroup.getSingleGroupPendingContacts);
        _this.showCreate$ = store.select(fromGroup.getGroupShowCreate);
        _this.contacts$ = store.select(fromGroup.getDisplayContacts);
        _this.groupIsUpdating$ = store.select(fromGroup.getGroupIsUpdating);
        _this.groupIsUpdated$ = store.select(fromGroup.getGroupIsUpdated);
        _this.groupManaged$.subscribe(function (groupManaged) {
            _this.groupManaged = groupManaged;
        });
        _this.groupForm$ = store.select(fromGroup.getGroupForm);
        _this.contactLookup$ = _this.store.select(fromGroup.getContactLookup);
        _this.isPrivateOrgMode$ = _this.store.select(getIsPrivateOrgMode);
        _this.groupContacts$ = _this.store.select(fromGroup.getGroupContacts);
        _this.searchedContacts$ = _this.store.select(fromGroup.getGroupMembersForDisplay);
        return _this;
    }
    GroupContainer.prototype.ngOnInit = function () {
        _super.prototype.ngOnInit.call(this);
        this.getContacts();
        this.getGroups();
    };
    /** Refresh contacts list state */
    GroupContainer.prototype.getContacts = function () {
        this.store.dispatch(new contactsActions.GetContactsAction());
    };
    /* update groups list state */
    GroupContainer.prototype.getGroups = function () {
        this.store.dispatch(new groupActions.GetGroupsAction());
    };
    /** Create a new group and hide the new group form */
    GroupContainer.prototype.groupAddSave = function () {
        this.store.dispatch(new groupActions.CreateGroupAction());
    };
    /** Save a existing group and refresh list of all groups */
    GroupContainer.prototype.groupEditSave = function () {
        this.store.dispatch(new groupActions.UpdateGroupAction());
    };
    /** Validate if string is an email with decent effeciency */
    GroupContainer.prototype.validateEmail = function (email) {
        // tslint:disable-next-line:max-line-length
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email.toLowerCase());
    };
    /** Lookup a contact to add to a group if email is valid */
    GroupContainer.prototype.getContactLookup = function (email) {
        // Don't even try if it's not a email format
        if (this.validateEmail(email)) {
            this.store.dispatch(new groupActions.ContactLookupAction(email));
        }
    };
    GroupContainer.prototype.updateFormValues = function (data) {
        this.store.dispatch(new groupActions.UpdateFormAction(data));
    };
    GroupContainer.prototype.resetContactSearch = function (member) {
        this.store.dispatch(new groupActions.ContactResetAction(member));
    };
    GroupContainer.prototype.resetContact = function () {
        this.store.dispatch(new groupActions.ContactLookupFailureAction());
    };
    /** Delete this group by id, then refresh groups */
    GroupContainer.prototype.groupDelete = function (groupId) {
        this.store.dispatch(new groupActions.DeleteGroupAction(groupId));
    };
    /** Show the create new group form */
    GroupContainer.prototype.showAddGroupForm = function () {
        this.store.dispatch(new groupActions.ClearManagedGroupAction());
        this.store.dispatch(new groupActions.ShowGroupsCreate());
    };
    /** Show the detail/edit view of a group selected */
    GroupContainer.prototype.groupSelected = function (group) {
        if (this.groupManaged === group.id) {
            this.store.dispatch(new groupActions.ClearManagedGroupAction());
        }
        else {
            this.store.dispatch(new groupActions.HideGroupsCreate());
            this.store.dispatch(new groupActions.SetManagedGroupAction(group.id));
        }
    };
    GroupContainer.prototype.hideAddSecretForm = function () {
        return this.store.dispatch(new groupActions.HideGroupsCreate());
    };
    GroupContainer.prototype.acceptGroup = function (group) {
        this.store.dispatch(new groupActions.AcceptGroupInvite(group));
    };
    GroupContainer.prototype.declineGroup = function (group) {
        this.store.dispatch(new groupActions.DeclineGroupInvite(group));
    };
    return GroupContainer;
}(ResetFormContainer));
export { GroupContainer };
