import { Injectable } from "@angular/core";

import { NgPassitSDK } from "../ngsdk/sdk";
import { IGroupForm } from "./group.interfaces";
import { IContact } from "./contacts/contacts.interfaces";
import { IGroup } from "passit-sdk-js/js/api.interfaces";

@Injectable()
export class GroupService {
  constructor(public sdk: NgPassitSDK) {}

  /*
  * add group to secret using group id and secret id
  */
  public addGroupToSecret(groupId: number, secret: any) {
    return this.sdk
      .add_group_to_secret(groupId, secret.id)
      .then(resp => resp)
      .catch(err => console.error(err));
  }

  /*
  * create group
  */
  public create(form: IGroupForm, members: IContact[]) {
    // Add current user always!
    const userId = this.sdk.userId;
    if (form.members.indexOf(userId) === -1) {
      form.members.push(userId);
    }
    return this.sdk
      .create_group(form.name!)
      .then(resp => {
        form.id = resp.id;
        return this.updateGroupMembers(form.id, members);
      })
      .catch(err => console.error(err));
  }

  public update(groupId: number, form: IGroupForm) {
    return this.sdk
      .update_group({ id: groupId, name: form.name!, slug: form.slug! })
      .catch((err: any) => console.error(err));
  }

  /*
  * get specific group using id
  */
  public getGroup(groupId: number): Promise<any> {
    return this.sdk
      .get_group(groupId)
      .then(resp => resp)
      .catch(err => console.error(err));
  }

  /**
   * Get groups from sdk
   * Returns Promise indicating result.
   */
  public getGroups() {
    return this.sdk.list_groups();
  }

  public removeGroupFromSecret(secret: any) {
    return this.sdk
      .get_secret(secret.id)
      .then(data => {
        return this.sdk
          .remove_group_from_secret(secret.id, data.secret_through_set[0].id!)
          .then(resp => {
            return resp;
          })
          .catch(err => console.error(err));
      })
      .catch(err => console.error(err));
  }

  public updateGroupMembers(groupId: number, groupMembers: IContact[]) {
    return new Promise((resolve, reject) => {
      this.sdk.get_group(groupId!).then(group => {
        const existingMembers = [];
        for (const usergroup of group.groupuser_set) {
          existingMembers.push(usergroup.user);
        }
        const promises: Array<Promise<any>> = [];
        for (const member of groupMembers) {
          // If new member is not an existing member then add them
          if (existingMembers.indexOf(member.id) < 0) {
            promises.push(
              this.sdk.add_user_to_group(group.id!, member.id, member.email)
            );
          }
        }
        for (const member of existingMembers) {
          // If existing member is not in new group members then remove them
          if (
            groupMembers.map(groupMember => groupMember.id).indexOf(member) < 0
          ) {
            const memberGroupuser = group.groupuser_set.find(
              groupuser => groupuser.user === member
            );
            promises.push(
              this.sdk.remove_user_from_group(group.id!, memberGroupuser!.id)
            );
          }
        }
        Promise.all(promises).then(() => resolve());
      });
    });
  }

  public deleteGroup(groupId: number) {
    return this.sdk.delete_group(groupId);
  }

  acceptGroup(group: IGroup) {
    const groupUser = this.findPendingGroupUser(group, this.sdk.userId);
    if (groupUser) {
      return this.sdk.acceptGroupInvite(groupUser.id);
    }
    return Promise.reject(null);
  }

  declineGroup(group: IGroup) {
    const groupUser = this.findPendingGroupUser(group, this.sdk.userId);
    if (groupUser) {
      return this.sdk.declineGroupInvite(groupUser.id);
    }
    return Promise.reject(null);
  }

  private findPendingGroupUser(group: IGroup, userId: number) {
    return group.groupuser_set.find(
      groupuser => groupuser.user === userId && groupuser.is_invite_pending
    );
  }
}
