import { Action } from "@ngrx/store";

export enum ResetFormActionTypes {
  RESET_FORMS = "Reset Forms"
}

export class ResetForms implements Action {
  readonly type = ResetFormActionTypes.RESET_FORMS;
}

export type ResetFormActionsUnion = ResetForms;
