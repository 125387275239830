import { Component } from "@angular/core";
import { Router, RoutesRecognized } from "@angular/router";
import { Store } from "@ngrx/store";
import { LogoutAction } from "../../account/account.actions";
import { getIsLoggedIn, IAuthState } from "../../account/account.reducer";
import { getIsDemo, getIsPrivateOrgMode, IState } from "../../app.reducers";
import { hasGroupInvites } from "../../group/group.reducer";

@Component({
  selector: "navbar-container",
  template: `
    <demo *ngIf="isDemo$ | async"></demo>
    <navbar
      [isLoggedIn]="isLoggedIn$ | async"
      [isPrivateOrgMode]="isPrivateOrgMode$ | async"
      [hasGroupInvites]="hasGroupInvites$ | async"
      (logout)="logout()"
      *ngIf="isVisible"
    ></navbar>
  `
})
export class NavbarContainer {
  isLoggedIn$ = this.accountStore.select(getIsLoggedIn);
  isDemo$ = this.store.select(getIsDemo);
  isPrivateOrgMode$ = this.store.select(getIsPrivateOrgMode);
  hasGroupInvites$ = this.store.select(hasGroupInvites);
  isVisible: boolean;

  constructor(
    private router: Router,
    private store: Store<IState>,
    private accountStore: Store<IAuthState>
  ) {
    // Determine is isVisible by checking data attr in showNavBar
    this.isVisible = true;
    this.router.events.subscribe(event => {
      if (event instanceof RoutesRecognized) {
        if (
          typeof event.state.root.firstChild!.data["showNavBar"] !== "undefined"
        ) {
          this.isVisible = event.state.root.firstChild!.data["showNavBar"];
        } else {
          this.isVisible = true;
        }
      }
    });
  }

  logout() {
    this.store.dispatch(new LogoutAction());
  }
}
