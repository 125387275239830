import { Injectable } from "@angular/core";
import { saveAs } from "file-saver";
import { unparse } from "papaparse";
import { ISecret } from "passit-sdk-js/js/sdk.interfaces";
import { NgPassitSDK } from "../ngsdk/sdk";

@Injectable()
export class ExporterService {
  HEADERS = ["name", "username", "url", "password", "extra"];

  constructor(private sdk: NgPassitSDK) {}

  async exportSecrets(): Promise<void> {
    const secrets = await this.getSecrets();
    const csv = this.serialize(secrets);
    const blob = new Blob([csv], { type: "text/csv" });
    saveAs(blob, "secrets.csv");
  }

  private serialize(secrets: ISecret[]): string {
    return unparse({
      fields: this.HEADERS,
      data: secrets.map(secret => [
        secret.name,
        secret.visible_data!["username"],
        secret.visible_data!["url"],
        secret.secrets!["password"],
        secret.secrets!["notes"]
      ])
    });
  }

  private async getSecrets(): Promise<ISecret[]> {
    const dbSecrets = await this.sdk.list_secrets();
    const decryptedSecrets = dbSecrets.map(async dbSecret => ({
      id: dbSecret.id!,
      name: dbSecret.name,
      type: dbSecret.type,
      visible_data: dbSecret.data,
      secrets: await this.sdk.decrypt_secret(dbSecret)
    }));
    return Promise.all(decryptedSecrets);
  }
}
