<nav class="main-nav">
  <div class="main-nav__inner-container">
    <a [routerLink]=" ['./list'] " title="Passit" class="main-nav__logo-link">
      <div class="main-nav__logo" [inlineSVG]="'../assets/svg/passit-logo.svg'"></div>
    </a>

    <div *ngIf="isLoggedIn" class="main-nav__main-links">
      <a [routerLink]=" ['./list'] " [routerLinkActive]="['main-nav__main-link--active']" class="main-nav__main-link">
        Passwords
      </a>
      <a
        [routerLink]=" ['./groups'] "
        [routerLinkActive]="['main-nav__main-link--active']"
        class="main-nav__main-link"
        [class.main-nav__main-link--notification]="hasGroupInvites"
        [tooltipDisabled]="!hasGroupInvites || windowWidth < 768"
        [tooltip]="groupNotificationTooltip"
        [tooltipAnimation]="true"
        tooltipPlacement="bottom">
          Groups
      </a>
      <tooltip-content
        #groupNotificationTooltip
        class="group-notification-tooltip"
        placement="bottom">
        Someone wants to share passwords with you. Click to manage your group invitations.
      </tooltip-content>
    </div>

    <div class="main-nav__secondary-links">
      <a *ngIf="!isLoggedIn" [routerLink]=" ['./register'] " class="main-nav__secondary-link">
        Register
      </a>
      <a *ngIf="!isLoggedIn" [routerLink]=" ['./login'] " class="main-nav__secondary-link">
        Log In
      </a>
      <a *ngIf="isLoggedIn" id="btn-logout" (click)="logout.emit()" href="javascript:void(0);" class="main-nav__secondary-link u-hidden--all-but-xl">
        Log Out
      </a>
      <a *ngIf="isLoggedIn" [routerLink]=" ['./account'] " class="main-nav__secondary-link">
        <span [inlineSVG]="'../assets/svg/user.svg'" class="main-nav__user-icon  u-visible--all-but-xl"></span>
        <span class="u-hidden--all-but-xl">Account</span>
      </a>
    </div>
  </div>
</nav>
