import { Actions, ConfTypes } from "./conf.actions";

export interface IConfState {
  isDemo: boolean;
  isPrivateOrgMode: boolean;
  environment: string | null;
  /** Is operating in web extension popup */
  isPopup: boolean;
  timestamp: string | null;
  ravenDsn: string | null;
}

export const initial: IConfState = {
  isDemo: false,
  isPrivateOrgMode: false,
  environment: null,
  isPopup: false,
  ravenDsn: null,
  timestamp: null
};

export function reducer(state = initial, action: Actions): IConfState {
  switch (action.type) {
    case ConfTypes.SET_CONF: {
      return {
        ...state,
        isDemo: action.payload.IS_DEMO,
        isPrivateOrgMode: action.payload.IS_PRIVATE_ORG_MODE,
        environment: action.payload.ENVIRONMENT,
        ravenDsn: action.payload.RAVEN_DSN,
        timestamp: new Date().toString()
      };
    }

    case ConfTypes.SET_IS_POPUP: {
      return {
        ...state,
        isPopup: true
      };
    }

    default: {
      return state;
    }
  }
}

export const getIsDemo = (state: IConfState) => state.isDemo;
export const getIsPrivateOrgMode = (state: IConfState) =>
  state.isPrivateOrgMode;
export const getTimestamp = (state: IConfState) => state.timestamp;
export const getIsPopup = (state: IConfState) => state.isPopup;
export const getRavenDsn = (state: IConfState) => state.ravenDsn;
