var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
import { ConfirmEmailTypes } from "./confirm-email.actions";
export var initialState = {
    resetCodeMessage: null,
    isVerified: false,
    hasStarted: false,
    hasFinished: false,
    errorMessage: null
};
export function reducer(state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case ConfirmEmailTypes.VERIFY_EMAIL:
            return __assign({}, state, { hasStarted: true, errorMessage: null });
        case ConfirmEmailTypes.VERIFY_EMAIL_SUCCESS:
            return __assign({}, state, { hasStarted: false, hasFinished: true, errorMessage: null, isVerified: true });
        case ConfirmEmailTypes.VERIFY_EMAIL_FAILURE:
            return __assign({}, state, { hasStarted: false, hasFinished: false, errorMessage: action.payload });
        case ConfirmEmailTypes.RESET_REGISTER_CODE:
            return __assign({}, state, { resetCodeMessage: null });
        case ConfirmEmailTypes.RESET_REGISTER_CODE_SUCCESS:
            return __assign({}, state, { resetCodeMessage: "Check your email for a new confirmation code." });
        case ConfirmEmailTypes.RESET_REGISTER_CODE_FAILURE:
            return __assign({}, state, { resetCodeMessage: "Unable to resend confirmation code." });
        default:
            return state;
    }
}
export var getResetCodeMessage = function (state) {
    return state.resetCodeMessage;
};
export var getIsVerified = function (state) { return state.isVerified; };
export var getHasStarted = function (state) { return state.hasStarted; };
export var getHasFinished = function (state) { return state.hasFinished; };
export var getErrorMessage = function (state) {
    return state.errorMessage;
};
