import {
  Component,
  ChangeDetectionStrategy,
  EventEmitter,
  Input,
  Output
} from "@angular/core";
import { FormGroupState } from "ngrx-forms";
import { IErrorReportingForm } from "./error-reporting.reducer";

@Component({
  selector: "app-error-reporting",
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: "./error-reporting.component.html"
})
export class ErrorReportingComponent {
  @Input()
  form: FormGroupState<IErrorReportingForm>;
  @Input()
  hasStarted = false;
  @Input()
  hasFinished = false;
  @Output()
  submit = new EventEmitter();
}
