import { Component, OnInit } from "@angular/core";

@Component({
  selector: "secret-add",
  styleUrls: [
    "../secret-row/secret-row.component.scss",
    "../list.component.scss"
  ],
  template: `
  <h2 class="secret__title secret__title--add">
    Add New Password
  </h2>

  <secret-form-container
    class="secret-form-component"
    [class.secret-form-component--is-active]="secretAnimated"
  ></secret-form-container>
  `
})
export class SecretAddComponent implements OnInit {
  secretAnimated = false;

  ngOnInit() {
    setTimeout(() => (this.secretAnimated = true), 10);
  }
}
