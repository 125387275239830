/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./popup-item.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./popup-item.component";
var styles_PopupItemComponent = [i0.styles];
var RenderType_PopupItemComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PopupItemComponent, data: {} });
export { RenderType_PopupItemComponent as RenderType_PopupItemComponent };
function View_PopupItemComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "popup-item__action popup-item__action--matched"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.clickAutofill($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "popup-item__action-inner"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "div", [["class", "popup-item__action-text"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Fill form fields"])), (_l()(), i1.ɵeld(4, 0, null, null, 1, ":svg:svg", [["class", "popup-item__icon"]], [[2, "popup-item__icon--copied", null]], null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 0, ":svg:use", [], [[1, ":xlink:href", 0]], null, null, null, null))], null, function (_ck, _v) { var currVal_0 = false; _ck(_v, 4, 0, currVal_0); var currVal_1 = (false ? "#icon-check" : "#icon-autofill"); _ck(_v, 5, 0, currVal_1); }); }
function View_PopupItemComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "autofill__message__container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.formFillMessage; _ck(_v, 2, 0, currVal_0); }); }
export function View_PopupItemComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 36, "div", [["class", "popup-item"]], [[2, "popup-item--active", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.setSelected.emit(_co.secret.id) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "popup-item__name"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵeld(3, 0, null, null, 4, "div", [["class", "popup-item__subtitle"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "div", [["class", "popup-item__subtitle__item"]], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, [" ", " "])), (_l()(), i1.ɵeld(6, 0, null, null, 1, "div", [["class", "popup-item__subtitle__item"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Click to view more options "])), (_l()(), i1.ɵeld(8, 0, null, null, 26, "div", [["class", "popup-item__actions"]], [[2, "popup-item__actions--matched", null]], null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PopupItemComponent_1)), i1.ɵdid(10, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(11, 0, null, null, 5, "div", [["class", "popup-item__action"]], [[8, "title", 0], [2, "popup-item__action--disabled", null], [2, "popup-item__action--copied", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = ((_co.secret.data.username ? _co.clickUsername($event) : null) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 4, "div", [["class", "popup-item__action-inner"]], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 1, "div", [["class", "popup-item__action-text"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Copy Username"])), (_l()(), i1.ɵeld(15, 0, null, null, 1, ":svg:svg", [["class", "popup-item__icon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(16, 0, null, null, 0, ":svg:use", [], [[1, ":xlink:href", 0]], null, null, null, null)), (_l()(), i1.ɵeld(17, 0, null, null, 5, "div", [["class", "popup-item__action"]], [[2, "popup-item__action--disabled", null], [2, "popup-item__action--copied", null], [8, "title", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.clickPassword($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(18, 0, null, null, 4, "div", [["class", "popup-item__action-inner"]], null, null, null, null, null)), (_l()(), i1.ɵeld(19, 0, null, null, 1, "div", [["class", "popup-item__action-text"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Copy Password"])), (_l()(), i1.ɵeld(21, 0, null, null, 1, ":svg:svg", [["class", "popup-item__icon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(22, 0, null, null, 0, ":svg:use", [], [[1, ":xlink:href", 0]], null, null, null, null)), (_l()(), i1.ɵeld(23, 0, null, null, 5, "div", [["class", "popup-item__action"]], [[2, "popup-item__action--disabled", null]], null, null, null, null)), (_l()(), i1.ɵeld(24, 0, null, null, 4, "div", [["class", "popup-item__action-inner"]], null, null, null, null, null)), (_l()(), i1.ɵeld(25, 0, null, null, 1, "a", [["class", "popup-item__action-text"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = ((_co.secret.data.url ? _co.openUrl.emit() : null) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" Open saved URL "])), (_l()(), i1.ɵeld(27, 0, null, null, 1, ":svg:svg", [["class", "popup-item__icon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(28, 0, null, null, 0, ":svg:use", [[":xlink:href", "#icon-external-link"]], null, null, null, null, null)), (_l()(), i1.ɵeld(29, 0, null, null, 5, "div", [["class", "popup-item__action"]], null, null, null, null, null)), (_l()(), i1.ɵeld(30, 0, null, null, 4, "div", [["class", "popup-item__action-inner"]], null, null, null, null, null)), (_l()(), i1.ɵeld(31, 0, null, null, 1, "a", [["class", "popup-item__action-text"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onDetail.emit() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["View/Edit Details"])), (_l()(), i1.ɵeld(33, 0, null, null, 1, ":svg:svg", [["class", "popup-item__icon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(34, 0, null, null, 0, ":svg:use", [[":xlink:href", "#icon-edit"]], null, null, null, null, null)), (_l()(), i1.ɵeld(35, 0, null, null, 1, "div", [["class", "popup-item__caret"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u221F"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PopupItemComponent_2)), i1.ɵdid(38, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_4 = _co.isMatched; _ck(_v, 10, 0, currVal_4); var currVal_14 = ((_co.formFillMessage !== "") && _co.isSelected); _ck(_v, 38, 0, currVal_14); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isSelected; _ck(_v, 0, 0, currVal_0); var currVal_1 = _co.secret.name; _ck(_v, 2, 0, currVal_1); var currVal_2 = _co.secret.data.username; _ck(_v, 5, 0, currVal_2); var currVal_3 = _co.isMatched; _ck(_v, 8, 0, currVal_3); var currVal_5 = (_co.isSelected ? "" : "Copy Username"); var currVal_6 = !_co.secret.data.username; var currVal_7 = _co.usernameCopied; _ck(_v, 11, 0, currVal_5, currVal_6, currVal_7); var currVal_8 = (_co.usernameCopied ? "#icon-check" : "#icon-copy-username"); _ck(_v, 16, 0, currVal_8); var currVal_9 = !_co.secret.secret_through_set[0].data.password; var currVal_10 = _co.passwordCopied; var currVal_11 = (_co.isSelected ? "" : "Copy Password"); _ck(_v, 17, 0, currVal_9, currVal_10, currVal_11); var currVal_12 = (_co.passwordCopied ? "#icon-check" : "#icon-copy-password"); _ck(_v, 22, 0, currVal_12); var currVal_13 = !_co.secret.data.url; _ck(_v, 23, 0, currVal_13); }); }
export function View_PopupItemComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-popup-item", [], null, null, null, View_PopupItemComponent_0, RenderType_PopupItemComponent)), i1.ɵdid(1, 49152, null, 0, i3.PopupItemComponent, [], null, null)], null, null); }
var PopupItemComponentNgFactory = i1.ɵccf("app-popup-item", i3.PopupItemComponent, View_PopupItemComponent_Host_0, { secret: "secret", formFillMessage: "formFillMessage", isMatched: "isMatched", isSelected: "isSelected", passwordCopied: "passwordCopied", usernameCopied: "usernameCopied" }, { openUrl: "openUrl", autofill: "autofill", onCopyUsername: "onCopyUsername", onCopyPassword: "onCopyPassword", onDetail: "onDetail", setSelected: "setSelected", closeSelected: "closeSelected" }, []);
export { PopupItemComponentNgFactory as PopupItemComponentNgFactory };
