/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./secret-row.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./secret-row.component";
var styles_SecretRowComponent = [i0.styles];
var RenderType_SecretRowComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SecretRowComponent, data: {} });
export { RenderType_SecretRowComponent as RenderType_SecretRowComponent };
export function View_SecretRowComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "secret"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "div", [["class", "secret__heading"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "h3", [["class", "secret__title"]], [[2, "secret__title--is-active", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toggleView(true) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(3, null, [" ", " "])), (_l()(), i1.ɵeld(4, 0, null, null, 2, "div", [["class", "secret__header-actions"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "button", [["class", "button button--gray secret__button"], ["id", "manageSecretBtn"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toggleView() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(6, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.active; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.secret.name; _ck(_v, 3, 0, currVal_1); var currVal_2 = (!_co.active ? "Manage" : "Close"); _ck(_v, 6, 0, currVal_2); }); }
export function View_SecretRowComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "secret-row", [], null, null, null, View_SecretRowComponent_0, RenderType_SecretRowComponent)), i1.ɵdid(1, 49152, null, 0, i2.SecretRowComponent, [], null, null)], null, null); }
var SecretRowComponentNgFactory = i1.ɵccf("secret-row", i2.SecretRowComponent, View_SecretRowComponent_Host_0, { secret: "secret", active: "active" }, { onSecretSelected: "onSecretSelected" }, []);
export { SecretRowComponentNgFactory as SecretRowComponentNgFactory };
