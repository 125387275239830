<div *ngIf="groups.length === 0 && !showCreate" class="l-container list-empty-state">
  <div class="list-empty-state__copy">
    <h2 class="heading-medium">
      This is where you&nbsp;share.
    </h2>
    <p class="text--large" *ngIf="pendingInviteGroups.length === 0">
      Press “Add New <span class="u-hidden--all-but-xl">Group</span>” to create your first&nbsp;group.
    </p>
    <p class="text--large" *ngIf="pendingInviteGroups.length > 0">
      You’ve been invited to
      <span *ngIf="pendingInviteGroups.length === 1">a group</span>
      <span *ngIf="pendingInviteGroups.length > 1">some groups</span>!
      Get started either by accepting <span *ngIf="pendingInviteGroups.length === 1">the invitation</span> <span *ngIf="pendingInviteGroups.length > 1">one of the invitations</span> below or by pressing “Add New <span class="u-hidden--all-but-xl">Group</span>”&nbsp;above. 
    </p>
  </div>
</div>

<div *ngIf="groups.length === 1 && !showCreate" class="l-container list-empty-state">
  <div class="list-empty-state__copy">
    <h2 class="heading-medium">
      Your first group is available to use.
    </h2>
    <p
      class="text--large"
      [class.text--less-bottom-margin]="pendingInviteGroups.length > 0">
      To use groups, go back to the Passwords screen, select a password to manage, and add your group to it. Then, anyone in your group will be able to see and edit that&nbsp;password.
    </p>
    <p class="text--large" *ngIf="pendingInviteGroups.length > 0">
      Remember to respond to your pending group invitation<span *ngIf="pendingInviteGroups.length > 1">s</span> by pressing &lsquo;Accept&rsquo; or&nbsp;&lsquo;Decline&rsquo;.
    </p>
  </div>
</div>
