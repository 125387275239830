import { ImportableSecret } from "./importableSecret";
import * as importer from "./importer.actions";

export interface IImporterState {
  fileName: string | null;
  secrets: ImportableSecret[] | null;
}

const initialState: IImporterState = {
  fileName: null,
  secrets: null
};

export function importerReducer(
  state = initialState,
  action: importer.Actions
): IImporterState {
  switch (action.type) {
    case importer.SET_FILE_NAME:
      return Object.assign({}, state, {
        fileName: action.payload
      });

    case importer.RESET_FILE_NAME:
      return Object.assign({}, state, {
        fileName: null
      });

    case importer.RESET_IMPORT_SECRETS:
      return Object.assign({}, state, {
        secrets: null
      });

    case importer.SET_IMPORT_SECRETS:
      return Object.assign({}, state, {
        secrets: action.payload
      });

    default:
      return state;
  }
}

export const getFileName = (state: IImporterState) => state.fileName;
export const getSecrets = (state: IImporterState) => state.secrets;
