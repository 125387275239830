/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./demo.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./demo.component";
var styles_DemoComponent = [i0.styles];
var RenderType_DemoComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DemoComponent, data: {} });
export { RenderType_DemoComponent as RenderType_DemoComponent };
export function View_DemoComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "demo"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Thanks for trying the Passit demo! "])), (_l()(), i1.ɵeld(2, 0, null, null, 1, "span", [["class", "u-responsive-break"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Data will be deleted daily."]))], null, null); }
export function View_DemoComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "demo", [], null, null, null, View_DemoComponent_0, RenderType_DemoComponent)), i1.ɵdid(1, 49152, null, 0, i2.DemoComponent, [], null, null)], null, null); }
var DemoComponentNgFactory = i1.ɵccf("demo", i2.DemoComponent, View_DemoComponent_Host_0, {}, {}, []);
export { DemoComponentNgFactory as DemoComponentNgFactory };
