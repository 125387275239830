import { Component } from "@angular/core";

@Component({
  selector: "demo",
  template: `
    <div class="demo">
      Thanks for trying the Passit demo!
      <span class="u-responsive-break">Data will be deleted daily.</span>
    </div>
  `,
  styleUrls: ["./demo.component.scss"]
})
export class DemoComponent {}
