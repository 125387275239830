var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
import { createFormGroupState, validate, markAsSubmitted, updateGroup, createFormStateReducerWithUpdate, SetValueAction } from "ngrx-forms";
import { required, pattern } from "ngrx-forms/validation";
import { environment } from "../../../environments/environment";
import { AccountActionTypes } from "../account.actions";
import { DEFAULT_API } from "../../constants";
import { oldPasswordValidators } from "../constants";
var FORM_ID = "Login Form";
export var validateAndUpdateFormState = updateGroup({
    email: validate(required, pattern(/^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/)),
    password: validate(oldPasswordValidators)
});
var initialFormState = validateAndUpdateFormState(createFormGroupState(FORM_ID, {
    email: "",
    password: "",
    rememberMe: environment.extension || environment.nativescript ? true : false,
    showUrl: false,
    url: environment.extension || environment.nativescript ? DEFAULT_API : ""
}));
export var initialState = {
    form: initialFormState,
    hasStarted: false,
    hasFinished: false,
    errorMessage: null
};
export var formReducer = createFormStateReducerWithUpdate(validateAndUpdateFormState);
export function reducer(state, action) {
    if (state === void 0) { state = initialState; }
    var form = formReducer(state.form, action);
    state = __assign({}, state, { form: form });
    switch (action.type) {
        case AccountActionTypes.LOGIN_REDIRECT:
            if (action.payload) {
                var setValueAction = new SetValueAction(form.controls.email.id, action.payload);
                form = formReducer(state.form, setValueAction);
            }
            return __assign({}, state, { form: form });
        case AccountActionTypes.LOGIN:
            return __assign({}, state, { form: markAsSubmitted(state.form), hasStarted: true, hasFinished: false, errorMessage: null });
        case AccountActionTypes.LOGIN_SUCCESS:
            return __assign({}, state, { hasStarted: false, hasFinished: true });
        case AccountActionTypes.LOGIN_FAILURE:
            return __assign({}, state, { hasStarted: false, errorMessage: action.payload });
        default:
            return state;
    }
}
export var getHasStarted = function (state) { return state.hasStarted; };
export var getHasFinished = function (state) { return state.hasFinished; };
export var getErrorMessage = function (state) { return state.errorMessage; };
export var getForm = function (state) { return state.form; };
