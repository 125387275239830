
    <importer-component
      [fileName]="fileName"
      [finishedSavingMessage]="finishedSavingMessage"
      [hasStartedSaving]="hasStartedSaving"
      [hasFinishedSaving]="hasFinishedSaving"
      [secrets]="secrets"
      (resetForm)="resetForm()"
      (resetFinishedMessage)="resetFinishedMessage()"
      (setFileName)="setFileName($event)"
      (setImportableSecrets)="setImportableSecrets($event)"
      (saveSecrets)="saveSecrets($event)"
    ></importer-component>
  