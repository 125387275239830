import { ISecret } from "passit-sdk-js/js/api.interfaces";

/**
 * Filter a list of secrets based on a search term
 * Set secretManaged to ensure it always shows
 */
export const filterOnSearch = (
  secrets: ISecret[],
  searchTerm: string,
  secretManaged?: number
) => {
  if (!searchTerm) {
    return secrets;
  }
  const term = searchTerm.toLowerCase();
  return secrets.filter(secret => {
    let name = secret.name;
    if (name) {
      name = name.toLowerCase();
    }
    let username: any = secret.data["username"];
    if (username) {
      username = username.toLowerCase();
    } else {
      username = "";
    }
    return (
      username.indexOf(term) >= 0 ||
      secret.id === secretManaged ||
      name.indexOf(term) >= 0
    );
  });
};

export const filterOnSelectGroup = (
  selectedGroupId: number | null,
  secrets: ISecret[],
) => {
    if (!selectedGroupId) {
      return secrets;
    }

    return secrets.filter(secret => secret.secret_through_set.find(through => through.group === selectedGroupId));
};
