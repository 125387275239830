
    <secret-list-component
      [secrets]="secrets$ | async"
      [groups]="groups$ | async"
      [groupOptions]="groupOptions$ | async"
      [selectedGroupId]="selectedGroupId$ | async"
      [totalSecretsCount]="totalSecretsCount$ | async"
      [secretManaged]="secretManaged$ | async"
      [showCreate]="showCreate$ | async"
      [secretIdFromUrl]="secretIdFromUrl"
      [searchText]="searchText$ | async"
      [firstTimeLoadingComplete]="firstTimeLoadingComplete$ | async"
      (secretWasSelected)="secretWasSelected($event)"
      (hideAddSecretForm)="hideAddSecretForm()"
      (showAddSecretForm)="showAddSecretForm()"
      (updateSelectedGroup)="updateSelectedGroup($event)"
      (searchUpdate)="searchUpdate($event)"
      [firstTimeLoadingComplete]="firstTimeLoadingComplete$ | async"
    ></secret-list-component>
  