
    <app-popup
      [secrets]="secrets$ | async"
      [selectedSecret]="selectedSecret$ | async"
      [search]="search$ | async"
      [formFillMessage]="formFillMessage"
      [matchedSecrets]="matchedSecrets$ | async"
      [usernameCopied]="usernameCopied$ | async"
      [passwordCopied]="passwordCopied$ | async"
      (setSelected)="setSelected($event)"
      (closeSelected)="closeSelected()"
      (searchUpdate)="searchUpdate($event)"
      (openUrl)="openUrl($event)"
      (signIn)="signIn($event)"
      (onCopyUsername)="copyUsername($event)"
      (onCopyPassword)="copyPassword($event)"
      (onDetail)="openDetails($event)"
      (openFullApp)="openFullApp()"
    ></app-popup>
  