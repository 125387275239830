import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
  selector: "search",
  styleUrls: ["./search.component.scss"],
  templateUrl: "./search.component.html"
})
export class SearchComponent {
  @Input() query = "";
  @Input() secretsLength: number;

  @Output() reset: EventEmitter<boolean>;
  @Output() searchTerm: EventEmitter<string>;

  constructor() {
    // this.query = "";
    this.reset = new EventEmitter();
    this.searchTerm = new EventEmitter();
  }

  /*
  * when reset button is clicked
  * reset this.query value
  * emit truth to parent (list)
  */
  public resetSearch(): void {
    this.query = "";
    this.reset.emit(true);
  }

  /*
  * when enter key pressed or button clicked
  * assign value of input to this.query
  * take value of input and emit it to parent (list)
  */
  public submitSearch(term: string) {
    this.query = term;
    this.searchTerm.emit(term);
  }
}
