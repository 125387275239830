<div class="import l-container-narrow u-margin-tb-50">

  <a class="text-link text-link--left-caret heading-back-link" [routerLink]=" ['/account'] ">Account Management</a>
  
  <h2 class="heading-main heading-main--pad-bottom">
    Import Passwords
  </h2>

  <div class="text--large">
    Passit can currently import CSV files and will pull data from the following&nbsp;columns:<br />url, username, password, name, extra.<br />
    Extra refers to notes.
  </div>

  <div>
    <form #importForm="ngForm">
      <div class="import__form-wrapper">
        <label 
          class="button import__input"
          [class.button--primary]="!fileName"
          [class.button--gray]="fileName">
            <input 
              #fileInput
              class="import__input-actual" 
              type="file" 
              required 
              accept=".csv, text/csv"
              [disabled]="hasStartedSaving || hasFinishedSaving"
              (change)="cleanFile($event)"/>
            <span>
              <div class="button__icon" [inlineSVG]="'../assets/svg/upload.svg'"></div>
              {{ !fileName ? 'Choose a CSV file' : 'Choose a different file' }}
            </span>
        </label>


        <label for="toggle-preview" class="import__toggle-preview form-field__checkbox form-field__checkbox--inline">
          <input 
            id="toggle-preview"
            class="form-field__checkbox__input"
            type="checkbox" 
            [checked]="autoPreview"
            [disabled]="hasStartedSaving || hasFinishedSaving"
            (click)="togglePreview()"/>
          <div class="form-field__checkbox__check form-field__checkbox__check--inline">
            <div [inlineSVG]="'../assets/svg/check.svg'"></div>
          </div>
          <div class="form-field__checkbox__text">
            Preview Import
          </div>
        </label>
      </div>
    </form>
  
  <h3 *ngIf="finishedSavingMessage">{{ finishedSavingMessage }}</h3>
  <h3 *ngIf="fileName && !finishedSavingMessage">
    <span *ngIf="hasStartedSaving">Importing</span>
    <span *ngIf="hasFinishedSaving">Successfully imported</span>
    <span *ngIf="!hasStartedSaving && !hasFinishedSaving && autoPreview">Previewing</span>
    <span *ngIf="!hasStartedSaving && !hasFinishedSaving && !autoPreview">Ready to import</span>
    &ldquo;{{ fileName }}&rdquo;
  </h3>
  </div>

</div>

<form (ngSubmit)="onSubmit()">
  <div class="l-container l-container--mobile-margin import__table-container" *ngIf="autoPreview && fileName">
    <div class="import__table-overlay" *ngIf="hasStartedSaving || hasFinishedSaving"></div>
    <table class="import__table">
      <tr>
        <th class="import__table-cell import__table-cell--header import__table-cell--import" (click)="toggleAll()">
          <label class="form-field__checkbox import__checkbox">
            <div
              class="form-field__checkbox__check form-field__checkbox__check--small"
              [class.form-field__checkbox__check--checked]="toggleAllState">
              <div [inlineSVG]="toggleAllUseMinus ? '../assets/svg/minus.svg' : '../assets/svg/check.svg'"></div>
            </div>
          </label>
        </th>
        <th *ngFor="let header of previewHeader" class="import__table-cell import__table-cell--header">
          {{ header }}
        </th>
      </tr>
      <tr 
        *ngFor="let secret of secrets; let i = index; trackBy: trackSecret;"
        class="import__table-row"
        [class.import__table-row--disabled]="!secret.importable"
        (click)="toggleImport(i)">
        <td class="import__table-cell import__table-cell--import">
          <label for="doImport{{i}}" class="form-field__checkbox import__checkbox">
            <input type="checkbox" class="form-field__checkbox__input" [checked]="secret.doImport" />
            <div class="form-field__checkbox__check form-field__checkbox__check--small">
              <div [inlineSVG]="'../assets/svg/check.svg'"></div>
            </div>
          </label>
        </td>
        <td class="import__table-cell import__table-cell--max-width-medium">
          <tooltip-content #nameTooltip placement="top" class="import__tooltip">
            {{ secret.name }}
          </tooltip-content>
          <div
            [tooltip]="nameTooltip"
            tooltipPlacement="top"
            [tooltipDisabled]="tooltipDisabled"
            (mouseover)="checkIfTooltipIsNecessary($event, 250)">
              <span>{{ secret.name }}</span>
          </div>
        </td>
        <td class="import__table-cell import__table-cell--max-width-medium">
          <tooltip-content #usernameTooltip placement="top" class="import__tooltip">
            {{ secret.visible_data.username }}
          </tooltip-content>
          <div
            [tooltip]="usernameTooltip"
            tooltipPlacement="top"
            [tooltipDisabled]="tooltipDisabled"
            (mouseover)="checkIfTooltipIsNecessary($event, 250)">
              <span>{{ secret.visible_data.username }}</span>
          </div>
        </td>
        <td class="import__table-cell import__table-cell--max-width-large">
          <tooltip-content #urlTooltip placement="top" class="import__tooltip">
            {{ secret.visible_data.url }}
          </tooltip-content>
          <div
            [tooltip]="urlTooltip"
            tooltipPlacement="top"
            [tooltipDisabled]="tooltipDisabled"
            (mouseover)="checkIfTooltipIsNecessary($event, 500)">
              <span>{{ secret.visible_data.url }}</span>
          </div>
        </td>
        <td class="import__table-cell import__table-cell--max-width-medium">
          <tooltip-content #passwordTooltip placement="top" class="import__tooltip">
            {{ secret.secrets.password }}
          </tooltip-content>
          <div
            [tooltip]="passwordTooltip"
            tooltipPlacement="top"
            [tooltipDisabled]="tooltipDisabled"
            (mouseover)="checkIfTooltipIsNecessary($event, 250)">
              <span>{{ secret.secrets.password }}</span>
          </div>
        </td>
        <td class="import__table-cell import__table-cell--max-width-medium">
          <tooltip-content #notesTooltip placement="top" class="import__tooltip tooltip--importer-special-notes">
            {{ secret.secrets.notes }}
          </tooltip-content>
          <div
            [tooltip]="notesTooltip"
            tooltipPlacement="top"
            [tooltipDisabled]="tooltipDisabled"
            (mouseover)="checkIfTooltipIsNecessary($event, 250)">
              <span>{{ secret.secrets.notes }}</span>
          </div>
        </td>
      </tr>
    </table>
  </div>
  <div class="import__actions l-container-narrow u-margin-tb-50" *ngIf="fileName">
    <button 
      type="submit"
      class="button button--primary"
      [disabled]="hasStartedSaving || hasFinishedSaving">
      Import Selected Passwords
    </button>
    <button
      type="button"
      class="button button--text button--text-danger"
      [disabled]="hasStartedSaving || hasFinishedSaving"
      (click)="onReset()">
      Reset
    </button>

    <progress-indicator
      [inProgress]="hasStartedSaving" 
      inProgressText="Importing"
      [completed]="hasFinishedSaving"
      completedText="Imported"></progress-indicator>

  </div>
</form>