var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
import { RegisterActionTypes } from "./register.actions";
import { createFormGroupState, validate, markAsUnsubmitted, SetValueAction, setValue, markAsSubmitted, updateGroup, createFormStateReducerWithUpdate } from "ngrx-forms";
import { required, pattern, notEqualTo, equalTo } from "ngrx-forms/validation";
import { environment } from "../../../environments/environment";
import { RegisterStages, passwordValidators } from "../constants";
import { DEFAULT_API } from "../../constants";
import { ConfirmEmailTypes } from "../confirm-email/confirm-email.actions";
export var FORM_ID = "RegisterForm";
export var URL_FORM_ID = "UrlForm";
export var validateAndUpdateFormState = updateGroup({
    email: validate(required, pattern(/^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/)),
    password: function (password, form) {
        return validate(password, passwordValidators.concat([
            notEqualTo(form.value.email)
        ]));
    },
    passwordConfirm: function (passwordConfirm, form) {
        if (form.controls.showConfirm.value) {
            return validate(passwordConfirm, [
                required,
                equalTo(form.value.password)
            ]);
        }
        return validate(passwordConfirm, []);
    }
});
var initialRegisterFormState = validateAndUpdateFormState(createFormGroupState(FORM_ID, {
    email: "",
    password: "",
    passwordConfirm: "",
    showConfirm: true,
    signUpNewsletter: false,
    rememberMe: environment.extension || environment.nativescript ? true : false
}));
var initialUrlFormState = createFormGroupState(URL_FORM_ID, {
    url: DEFAULT_API
});
export var initialState = {
    form: initialRegisterFormState,
    urlForm: initialUrlFormState,
    errorMessage: null,
    currentStage: RegisterStages.Email,
    isEmailTaken: false,
    urlDisplayName: DEFAULT_API,
    isUrlValid: false,
    showUrl: false,
    hasSubmitStarted: false,
    hasSubmitFinished: false
};
var formReducer = createFormStateReducerWithUpdate(validateAndUpdateFormState);
export var urlValidateAndUpdateFormState = updateGroup({
    url: validate(required)
});
export var urlFormReducer = createFormStateReducerWithUpdate(urlValidateAndUpdateFormState);
export function reducer(state, action) {
    if (state === void 0) { state = initialState; }
    var form = formReducer(state.form, action);
    if (form !== state.form) {
        state = __assign({}, state, { form: form });
    }
    var urlForm = urlFormReducer(state.urlForm, action);
    if (urlForm !== state.urlForm) {
        state = __assign({}, state, { urlForm: urlForm });
    }
    switch (action.type) {
        case SetValueAction.TYPE:
            return __assign({}, state, { isEmailTaken: false });
        case RegisterActionTypes.REGISTER:
            return __assign({}, state, { hasSubmitStarted: true, hasSubmitFinished: false, errorMessage: null });
        case RegisterActionTypes.REGISTER_SUCCESS:
            return __assign({}, state, { hasSubmitStarted: false, hasSubmitFinished: true, currentStage: state.currentStage + 1 });
        case RegisterActionTypes.REGISTER_FAILURE:
            return __assign({}, state, { hasSubmitStarted: false, errorMessage: action.payload });
        case RegisterActionTypes.CHECK_EMAIL:
            return __assign({}, state, { form: markAsSubmitted(state.form), hasSubmitStarted: true });
        case RegisterActionTypes.SET_IS_EMAIL_TAKEN:
            return __assign({}, state, { hasSubmitStarted: false, isEmailTaken: action.payload });
        case RegisterActionTypes.CHECK_EMAIL_SUCCESS:
            form = markAsUnsubmitted(form);
            state = __assign({}, state, { form: form });
            return __assign({}, state, { hasSubmitStarted: false, isEmailTaken: false, errorMessage: initialState.errorMessage, currentStage: state.currentStage + 1 });
        case RegisterActionTypes.CHECK_EMAIL_FAILURE:
            return __assign({}, state, { errorMessage: action.payload, hasSubmitStarted: false });
        case RegisterActionTypes.CHECK_URL:
            return __assign({}, state, { urlForm: markAsSubmitted(state.urlForm) });
        case RegisterActionTypes.CHECK_URL_SUCCESS:
            var updateForm = setValue(initialState.form, __assign({}, initialState.form.value));
            return __assign({}, state, { hasSubmitStarted: false, isUrlValid: true, showUrl: false, urlDisplayName: state.urlForm.value.url, currentStage: RegisterStages.Email, form: formReducer(updateForm, action) });
        case RegisterActionTypes.DISPLAY_URL:
            return __assign({}, state, { showUrl: true });
        case RegisterActionTypes.HIDE_URL:
            return __assign({}, state, { showUrl: false });
        case RegisterActionTypes.CHECK_URL_FAILURE:
            return __assign({}, state, { isUrlValid: false });
        case RegisterActionTypes.INCREMENT_STAGE:
        case ConfirmEmailTypes.VERIFY_EMAIL_SUCCESS:
            return __assign({}, state, { currentStage: state.currentStage + 1 });
        case RegisterActionTypes.SWITCH_STAGE:
            var newForm = setValue(initialState.form, __assign({}, initialState.form.value, { email: state.form.value.email, password: action.payload === RegisterStages.Password
                    ? state.form.value.password
                    : "" }));
            return __assign({}, state, { currentStage: action.payload, form: formReducer(newForm, action) });
        case RegisterActionTypes.REGISTER_CLEAR:
            return initialState;
        default:
            return state;
    }
}
export var getErrorMessage = function (state) { return state.errorMessage; };
export var getForm = function (state) { return state.form; };
export var getUrlForm = function (state) { return state.urlForm; };
export var getStage = function (state) { return state.currentStage; };
export var getUrlDisplayName = function (state) {
    return state.urlDisplayName;
};
export var getHasSubmitStarted = function (state) {
    return state.hasSubmitStarted;
};
export var getHasSubmitFinished = function (state) {
    return state.hasSubmitFinished;
};
export var getIsUrlValid = function (state) { return state.isUrlValid; };
export var getShowUrl = function (state) { return state.showUrl; };
export var getIsEmailTaken = function (state) { return state.isEmailTaken; };
