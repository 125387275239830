import { EventEmitter, OnInit } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
var GroupFormComponent = /** @class */ (function () {
    function GroupFormComponent(fb) {
        this.fb = fb;
        this.back = new EventEmitter();
        this.hideAddSecretForm = new EventEmitter();
        this.save = new EventEmitter();
        this.contactSearch = new EventEmitter();
        this.resetSearch = new EventEmitter();
        this.delete = new EventEmitter();
        this.updateFormValues = new EventEmitter();
        this.isNew = false;
        this.privateOrgMemberOptions = [];
        this.memberOptions = [];
        this.submitted = false;
    }
    Object.defineProperty(GroupFormComponent.prototype, "contacts", {
        // Sets group members when in private org mode
        set: function (contacts) {
            this.privateOrgMemberOptions = contacts;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(GroupFormComponent.prototype, "searchedContacts", {
        // Sets group members when in public org mode
        // Set to groupContacts or members depending on the value of contactLookup.
        set: function (members) {
            this.memberOptions = this.groupContacts;
            if (this.contactLookup) {
                this.memberOptions = members;
            }
        },
        enumerable: true,
        configurable: true
    });
    GroupFormComponent.prototype.ngOnInit = function () {
        this.buildForm();
    };
    GroupFormComponent.prototype.buildForm = function () {
        var _this = this;
        this.createGroupForm = this.fb.group({
            name: ["", [Validators.required]],
            members: []
        });
        this.createGroupForm.patchValue(this.groupForm);
        this.createGroupForm.valueChanges.subscribe(function (data) {
            _this.updateFormValues.emit(data);
        });
    };
    // Add selected contact to contact list. Reset disabled to false for all contacts.
    GroupFormComponent.prototype.onSelected = function (member) {
        this.resetSearch.emit(member);
    };
    GroupFormComponent.prototype.onDeselected = function (member) {
        if (this.createGroupForm.value.members.length === 0) {
            if (window.confirm("If you remove yourself from the group, it's gone forever. Is that okay?")) {
                this.save.emit();
            }
            else {
                this.createGroupForm.patchValue(this.groupForm);
            }
        }
    };
    GroupFormComponent.prototype.onSubmit = function () {
        this.submitted = true;
        if (this.createGroupForm.valid) {
            this.save.emit();
        }
    };
    GroupFormComponent.prototype.onDelete = function () {
        if (window.confirm("Once it's deleted, it's gone forever. Is that okay?")) {
            this.delete.emit(this.groupManaged);
        }
    };
    return GroupFormComponent;
}());
export { GroupFormComponent };
