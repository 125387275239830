import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { EffectsModule } from "@ngrx/effects";
import { StoreModule } from "@ngrx/store";
import { InlineSVGModule } from "ng-inline-svg";
import { SelectModule } from "ng-select";
import { ClipboardModule } from "ngx-clipboard";

import { GroupEffects } from "./group.effects";
import { groupReducer } from "./group.reducer";

import { GroupAddButtonComponent } from "./group-add/group-add-button.component";
import { GroupAddComponent } from "./group-add/group-add.component";
import { GroupDetailComponent } from "./group-detail.component";
import { GroupEmptyStateComponent } from "./group-empty-state/group-empty-state.component";
import { GroupFormComponent } from "./group-form/group-form.component";
import { GroupListComponent } from "./group-list.component";
import { GroupContainer } from "./group.container";

import { ContactsService } from "./contacts/contacts.service";
import { GroupService } from "./group.service";
import { GroupPendingListComponent } from "./group-pending-list/group-pending-list.component";

export const COMPONENTS = [
  GroupContainer,
  GroupAddComponent,
  GroupEmptyStateComponent,
  GroupListComponent,
  GroupPendingListComponent,
  GroupAddButtonComponent,
  GroupDetailComponent,
  GroupFormComponent
];

@NgModule({
  imports: [
    CommonModule,
    ClipboardModule,
    FormsModule,
    InlineSVGModule,
    ReactiveFormsModule,
    RouterModule,
    SelectModule,
    StoreModule.forFeature("group", groupReducer),
    EffectsModule.forFeature([GroupEffects])
  ],
  declarations: COMPONENTS,
  exports: COMPONENTS,
  providers: [ContactsService, GroupService]
})
export class GroupModule {}
