import { Injectable } from "@angular/core";
import { Effect } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { IState } from "../../app.reducers";
import * as fromAccount from "../account.reducer";
import {
  filter,
  switchMap,
  map,
  distinctUntilChanged,
  catchError
} from "rxjs/operators";
import { timer } from "rxjs/observable/timer";
import {
  StartAsyncValidationAction,
  ClearAsyncErrorAction,
  SetAsyncErrorAction
} from "ngrx-forms";
import { UserService } from "../user";
import { concat } from "rxjs";

@Injectable()
export class LoginFormEffects {
  /** Implements "as you type" check to determine if the server url is valid or not */
  @Effect()
  asyncServerUrlCheck$ = this.store.select(fromAccount.getLoginForm).pipe(
    filter(form => form.value.showUrl),
    distinctUntilChanged(
      (first, second) => first.value.url === second.value.url
    ),
    switchMap(form =>
      concat(
        timer(300).pipe(
          map(
            () => new StartAsyncValidationAction(form.controls.url.id, "exists")
          )
        ),
        this.userService.checkUrl(form.value.url).pipe(
          map(() => new ClearAsyncErrorAction(form.controls.url.id, "exists")),
          catchError(() => [
            new SetAsyncErrorAction(
              form.controls.url.id,
              "exists",
              form.value.url
            )
          ])
        )
      )
    )
  );

  constructor(private store: Store<IState>, private userService: UserService) {}
}
