import { Action } from "@ngrx/store";
import { ISecretForm } from "./secret-form.reducer";

export enum SecretFormActionTypes {
  SET_FORM_DATA = "[Secret Form] Set Form Data",
  TOGGLE_PASSWORD_IS_MASKED = "[Secret Form] Toggle Password is Masked",
  SET_PASSWORD_IS_MASKED = "[Secret Form] Set Password is Masked",
  TOGGLE_SHOW_NOTES = "[Secret Form] Toggle Show Notes",
  CREATE_SECRET = "[Secret Form] Create Secret",
  CREATE_SECRET_SUCCESS = "[Secret form] Create Secret Success",
  UPDATE_SECRET = "[Secret Form] Update Secret",
  DELETE_SECRET = "[Secret Form] Delete Secret",
  GENERATE_PASSWORD = "[Secret Form] Generate Password",
  DECRYPT_ERROR = "[Secret Form] Decrypt Error"
}

interface ISetFormDataPayload extends ISecretForm {
  id: number;
}

export class SetFormData implements Action {
  readonly type = SecretFormActionTypes.SET_FORM_DATA;

  constructor(public payload: ISetFormDataPayload) {}
}

export class TogglePasswordIsMasked implements Action {
  readonly type = SecretFormActionTypes.TOGGLE_PASSWORD_IS_MASKED;
}

export class SetPasswordIsMasked implements Action {
  readonly type = SecretFormActionTypes.SET_PASSWORD_IS_MASKED;

  constructor(public payload: boolean) {}
}

export class ToggleShowNotes implements Action {
  readonly type = SecretFormActionTypes.TOGGLE_SHOW_NOTES;
}

export class CreateSecret implements Action {
  readonly type = SecretFormActionTypes.CREATE_SECRET;
}

export class CreateSecretSuccessAction implements Action {
  readonly type = SecretFormActionTypes.CREATE_SECRET_SUCCESS;
}

export class UpdateSecret implements Action {
  readonly type = SecretFormActionTypes.UPDATE_SECRET;
}

export class DeleteSecret implements Action {
  readonly type = SecretFormActionTypes.DELETE_SECRET;
}

export class GeneratePassword implements Action {
  readonly type = SecretFormActionTypes.GENERATE_PASSWORD;
}

export class DecryptError implements Action {
  readonly type = SecretFormActionTypes.DECRYPT_ERROR;

  constructor(public payload: { message: string; form: ISetFormDataPayload }) {}
}

export type SecretFormActionsUnion =
  | SetFormData
  | TogglePasswordIsMasked
  | SetPasswordIsMasked
  | ToggleShowNotes
  | CreateSecret
  | CreateSecretSuccessAction
  | UpdateSecret
  | DeleteSecret
  | GeneratePassword
  | DecryptError;
