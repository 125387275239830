<div class="account l-container-narrow u-margin-tb-50">
  <a class="text-link text-link--left-caret heading-back-link" [routerLink]=" ['/account'] ">Account Management</a>
  
  <h2 class="heading-main heading-main--pad-bottom">
    Error Reporting
  </h2>

  <form novalidate [ngrxFormState]="form">
    <div class="text--large text--less-bottom-margin">
      For your safety and privacy, Passit has no in-app tracking by default. However, the Passit developers benefit from receiving error reports, which requires this sort of&nbsp;tracking.
    </div>
    <div class="text--large">
      If you are willing to opt in to share anonymous information, please do! It will help make Passit better for&nbsp;everyone.
    </div>

    <app-checkbox
      title="Enable error reporting"
      [control]="form.controls.optInErrorReporting"
    ></app-checkbox>

    <div class="u-relative">

      <button
        id="submit-button"
        class="button button--primary"
        [disabled]="hasStarted"
        type="submit">
        Save
      </button>

      <progress-indicator
        [inProgress]="hasStarted"
        inProgressText="Saving"
        [completed]="hasFinished"
        completedText="Saved"></progress-indicator>

    </div>
  </form>
</div>
