import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
  ElementRef,
  OnInit
} from "@angular/core";

/**
 * Confirm email component
 */
@Component({
  selector: "confirm-email-component",
  styleUrls: ["../account.component.scss"],
  templateUrl: "./confirm-email.component.html"
})
export class ConfirmEmailComponent implements OnInit {
  @ViewChild("codeInput") codeInput: ElementRef;

  /** The error message, passed from the container to the template */
  @Input() errorMessage: string;

  @Input() confirmCodeMessage: string;

  /** The confirmation code. Can be pulled as a param from the URL or entered in the text field. */
  @Input() code: string;

  /** Lets the template know if a server call has started */
  @Input() hasStarted: boolean;

  /** Lets the template know if a server call has finished (successfully) */
  @Input() hasFinished: boolean;

  /** Lets the template know if the form is inline with the register form or not */
  @Input() inline: boolean;

  /** Emits the confirmation code to the container and triggers the server call */
  @Output() confirmEmail = new EventEmitter<string>();

  @Output() resetRegisterCode = new EventEmitter();

  /**
   * The form uses this to trigger the confirmEmail emitter
   *
   * param form The code used to confirm the email address
   */

  ngOnInit() {
    this.codeInput.nativeElement.focus();
  }

  onSubmit(form: { code: string }) {
    this.confirmEmail.emit(form.code);
  }

  onClick() {
    this.resetRegisterCode.emit();
  }
}
