import { Injectable } from "@angular/core";
import { from } from "rxjs";

import { NgPassitSDK } from "../ngsdk/sdk";

@Injectable()
export class GetConfService {
  constructor(private sdk: NgPassitSDK) {}

  /** Get configuration from server and save it to local state
   */
  getConf() {
    return from(this.sdk.get_conf());
  }
}
