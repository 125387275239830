var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { LoggedInGuard } from "./logged-in.guard";
import { SetIsPopup } from "../get-conf/conf.actions";
import { Store } from "@ngrx/store";
var PopupLoggedInGuard = /** @class */ (function (_super) {
    __extends(PopupLoggedInGuard, _super);
    function PopupLoggedInGuard(store) {
        var _this = _super.call(this, store) || this;
        _this.store = store;
        return _this;
    }
    /** This is a hook to store that the app is running in a popup.
     * It's actually very hard to detect if the app is running in the popup or not.
     */
    PopupLoggedInGuard.prototype.canActivate = function () {
        this.store.dispatch(new SetIsPopup());
        return _super.prototype.canActivate.call(this);
    };
    return PopupLoggedInGuard;
}(LoggedInGuard));
export { PopupLoggedInGuard };
