var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
import { ConfTypes } from "./conf.actions";
export var initial = {
    isDemo: false,
    isPrivateOrgMode: false,
    environment: null,
    isPopup: false,
    ravenDsn: null,
    timestamp: null
};
export function reducer(state, action) {
    if (state === void 0) { state = initial; }
    switch (action.type) {
        case ConfTypes.SET_CONF: {
            return __assign({}, state, { isDemo: action.payload.IS_DEMO, isPrivateOrgMode: action.payload.IS_PRIVATE_ORG_MODE, environment: action.payload.ENVIRONMENT, ravenDsn: action.payload.RAVEN_DSN, timestamp: new Date().toString() });
        }
        case ConfTypes.SET_IS_POPUP: {
            return __assign({}, state, { isPopup: true });
        }
        default: {
            return state;
        }
    }
}
export var getIsDemo = function (state) { return state.isDemo; };
export var getIsPrivateOrgMode = function (state) {
    return state.isPrivateOrgMode;
};
export var getTimestamp = function (state) { return state.timestamp; };
export var getIsPopup = function (state) { return state.isPopup; };
export var getRavenDsn = function (state) { return state.ravenDsn; };
