import { Action } from "@ngrx/store";
import { ISecret } from "passit-sdk-js/js/api.interfaces";

export enum PopupActionTypes {
  SET_SELECTED_SECRET = "[Popup] Set Selected Secret",
  NULL_SELECTED_SECRET = "[Popup] Null Selected Secret",
  SET_SEARCH = "[Popup] Set Search",
  SET_CURRENT_URL = "[Popup] Set Current URL",
  COPY_USERNAME = "[Popup] Copy Username",
  COPY_PASSWORD = "[Popup] Copy Password",
  CLEAR_COPIED = "[Popup] Clear Copied"
}

export class SetSelectedSecretAction implements Action {
  readonly type = PopupActionTypes.SET_SELECTED_SECRET;
  constructor(public payload: number) {}
}

export class NullSelectedSecretAction implements Action {
  readonly type = PopupActionTypes.NULL_SELECTED_SECRET;
}

export class SetSearchAction implements Action {
  readonly type = PopupActionTypes.SET_SEARCH;
  constructor(public payload: string) {}
}

export class SetCurrentUrlAction implements Action {
  readonly type = PopupActionTypes.SET_CURRENT_URL;
  constructor(public payload: string) {}
}

export class CopyUsername implements Action {
  readonly type = PopupActionTypes.COPY_USERNAME;
  constructor(public payload: ISecret) {}
}

export class CopyPassword implements Action {
  readonly type = PopupActionTypes.COPY_PASSWORD;
  constructor(public payload: ISecret) {}
}

export class ClearCopied implements Action {
  readonly type = PopupActionTypes.CLEAR_COPIED;
}

export type PopupActions =
  | SetSelectedSecretAction
  | NullSelectedSecretAction
  | SetSearchAction
  | SetCurrentUrlAction
  | CopyUsername
  | CopyPassword
  | ClearCopied;
