/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./background.component";
var styles_BackgroundComponent = [];
var RenderType_BackgroundComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_BackgroundComponent, data: {} });
export { RenderType_BackgroundComponent as RenderType_BackgroundComponent };
export function View_BackgroundComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "h1", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Background page"]))], null, null); }
export function View_BackgroundComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-background", [], null, null, null, View_BackgroundComponent_0, RenderType_BackgroundComponent)), i0.ɵdid(1, 245760, null, 0, i1.BackgroundComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var BackgroundComponentNgFactory = i0.ɵccf("app-background", i1.BackgroundComponent, View_BackgroundComponent_Host_0, {}, {}, []);
export { BackgroundComponentNgFactory as BackgroundComponentNgFactory };
