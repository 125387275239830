import { Component, OnDestroy, OnInit } from "@angular/core";

// import { Store } from "@ngrx/store";
// import * as fromRoot from "../../app.reducers";

@Component({
  selector: "app-background",
  template: "<h1>Background page</h1>"
})
export class BackgroundComponent implements OnInit, OnDestroy {
  constructor() {
    // let listState = store.select(fromRoot.getAccountState);
    // listState.select("userToken").subscribe((text) => {
    console.log("");
  }

  ngOnInit() {
    // this.storage.getOptions().subscribe(options => {
    //   this.ping = options.ping;
    //   this.setOnMessageListener(true);
    //   this.setOnUpdateListener(this.ping);
    // });
  }

  ngOnDestroy() {
    // this.setOnUpdateListener(false);
    // this.setOnMessageListener(false);
  }
}
