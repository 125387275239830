import { LoggedInGuard } from "./logged-in.guard";
import { Injectable } from "@angular/core";
import { SetIsPopup } from "../get-conf/conf.actions";
import { Store } from "@ngrx/store";

import * as fromAccount from "../account/account.reducer";

@Injectable()
export class PopupLoggedInGuard extends LoggedInGuard {
  constructor(public store: Store<fromAccount.IAuthState>) {
    super(store);
  }
  /** This is a hook to store that the app is running in a popup.
   * It's actually very hard to detect if the app is running in the popup or not.
   */
  public canActivate() {
    this.store.dispatch(new SetIsPopup());
    return super.canActivate();
  }
}
