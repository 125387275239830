<div
  *ngIf="pendingInviteGroups && pendingInviteGroups.length > 0"
  class="container">
  <div
    *ngFor="let group of pendingInviteGroups"
    class="secret-list-item group-pending-list-item">

    <div class="secret">
      <div class="secret__heading">
        <h3 class="secret__title">
          {{ group.name }}
        </h3>
        <div class="secret__header-actions">
          <button
            type="button"
            class="button button--primary secret__button"
            (click)="acceptGroup.emit(group)">
            Accept
          </button>
          <button
            type="button"
            class="button button--primary secret__button"
            (click)="declineGroup.emit(group)">
            Decline
          </button>
        </div>
      </div>
    </div>

  </div>
</div>
