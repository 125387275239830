/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./group-empty-state.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./group-empty-state.component";
var styles_GroupEmptyStateComponent = [i0.styles];
var RenderType_GroupEmptyStateComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_GroupEmptyStateComponent, data: {} });
export { RenderType_GroupEmptyStateComponent as RenderType_GroupEmptyStateComponent };
function View_GroupEmptyStateComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "p", [["class", "text--large"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Press \u201CAdd New "])), (_l()(), i1.ɵeld(2, 0, null, null, 1, "span", [["class", "u-hidden--all-but-xl"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Group"])), (_l()(), i1.ɵted(-1, null, ["\u201D to create your first\u00A0group. "]))], null, null); }
function View_GroupEmptyStateComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["a group"]))], null, null); }
function View_GroupEmptyStateComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["some groups"]))], null, null); }
function View_GroupEmptyStateComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["the invitation"]))], null, null); }
function View_GroupEmptyStateComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["one of the invitations"]))], null, null); }
function View_GroupEmptyStateComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 14, "p", [["class", "text--large"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" You\u2019ve been invited to "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_GroupEmptyStateComponent_4)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_GroupEmptyStateComponent_5)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["! Get started either by accepting "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_GroupEmptyStateComponent_6)), i1.ɵdid(8, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_GroupEmptyStateComponent_7)), i1.ɵdid(10, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, [" below or by pressing \u201CAdd New "])), (_l()(), i1.ɵeld(12, 0, null, null, 1, "span", [["class", "u-hidden--all-but-xl"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Group"])), (_l()(), i1.ɵted(-1, null, ["\u201D\u00A0above. "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.pendingInviteGroups.length === 1); _ck(_v, 3, 0, currVal_0); var currVal_1 = (_co.pendingInviteGroups.length > 1); _ck(_v, 5, 0, currVal_1); var currVal_2 = (_co.pendingInviteGroups.length === 1); _ck(_v, 8, 0, currVal_2); var currVal_3 = (_co.pendingInviteGroups.length > 1); _ck(_v, 10, 0, currVal_3); }, null); }
function View_GroupEmptyStateComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "l-container list-empty-state"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "div", [["class", "list-empty-state__copy"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "h2", [["class", "heading-medium"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" This is where you\u00A0share. "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_GroupEmptyStateComponent_2)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_GroupEmptyStateComponent_3)), i1.ɵdid(7, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.pendingInviteGroups.length === 0); _ck(_v, 5, 0, currVal_0); var currVal_1 = (_co.pendingInviteGroups.length > 0); _ck(_v, 7, 0, currVal_1); }, null); }
function View_GroupEmptyStateComponent_10(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["s"]))], null, null); }
function View_GroupEmptyStateComponent_9(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "p", [["class", "text--large"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Remember to respond to your pending group invitation"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_GroupEmptyStateComponent_10)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, [" by pressing \u2018Accept\u2019 or\u00A0\u2018Decline\u2019. "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.pendingInviteGroups.length > 1); _ck(_v, 3, 0, currVal_0); }, null); }
function View_GroupEmptyStateComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "l-container list-empty-state"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "div", [["class", "list-empty-state__copy"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "h2", [["class", "heading-medium"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Your first group is available to use. "])), (_l()(), i1.ɵeld(4, 0, null, null, 1, "p", [["class", "text--large"]], [[2, "text--less-bottom-margin", null]], null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" To use groups, go back to the Passwords screen, select a password to manage, and add your group to it. Then, anyone in your group will be able to see and edit that\u00A0password. "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_GroupEmptyStateComponent_9)), i1.ɵdid(7, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = (_co.pendingInviteGroups.length > 0); _ck(_v, 7, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.pendingInviteGroups.length > 0); _ck(_v, 4, 0, currVal_0); }); }
export function View_GroupEmptyStateComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_GroupEmptyStateComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_GroupEmptyStateComponent_8)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.groups.length === 0) && !_co.showCreate); _ck(_v, 1, 0, currVal_0); var currVal_1 = ((_co.groups.length === 1) && !_co.showCreate); _ck(_v, 3, 0, currVal_1); }, null); }
export function View_GroupEmptyStateComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-group-empty-state", [], null, null, null, View_GroupEmptyStateComponent_0, RenderType_GroupEmptyStateComponent)), i1.ɵdid(1, 49152, null, 0, i3.GroupEmptyStateComponent, [], null, null)], null, null); }
var GroupEmptyStateComponentNgFactory = i1.ɵccf("app-group-empty-state", i3.GroupEmptyStateComponent, View_GroupEmptyStateComponent_Host_0, { showCreate: "showCreate", groups: "groups", pendingInviteGroups: "pendingInviteGroups" }, {}, []);
export { GroupEmptyStateComponentNgFactory as GroupEmptyStateComponentNgFactory };
