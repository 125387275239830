import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { routing, routingStore } from "../app.routing";

import { InlineSVGModule } from "ng-inline-svg";
import { TooltipModule } from "ngx-tooltip";
import { HotkeyModule } from "angular2-hotkeys";

import { BackgroundComponent } from "./background/";
import { PopupComponent, PopupContainer, PopupItemComponent } from "./popup/";
import { popupReducer } from "./popup/popup.reducer";

import { SharedModule } from "../shared/";
import { StoreModule } from "@ngrx/store";
import { EffectsModule } from "@ngrx/effects";
import { PopupEffects } from "./popup/popup.effects";

export const COMPONENTS = [
  PopupComponent,
  PopupContainer,
  BackgroundComponent,
  PopupItemComponent
];

@NgModule({
  imports: [
    CommonModule,
    HotkeyModule,
    InlineSVGModule,
    routing,
    routingStore,
    SharedModule,
    TooltipModule,
    StoreModule.forFeature("popup", popupReducer),
    EffectsModule.forFeature([PopupEffects])
  ],
  declarations: COMPONENTS,
  exports: COMPONENTS
})
export class ExtensionModule {}
