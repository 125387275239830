/* tslint:disable:max-classes-per-file */
import { Action } from "@ngrx/store";
import { ImportableSecret } from "./importableSecret";

export const SET_IMPORT_SECRETS = "SET_IMPORT_SECRETS";
export const RESET_IMPORT_SECRETS = "RESET_IMPORT_SECRETS";
export const SET_FILE_NAME = "SET_FILE_NAME";
export const RESET_FILE_NAME = "RESET_FILE_NAME";

export class SetImportSecretsAction implements Action {
  readonly type = SET_IMPORT_SECRETS;

  constructor(public payload: ImportableSecret[]) {}
}

export class ResetImportSecretsAction implements Action {
  readonly type = RESET_IMPORT_SECRETS;
}

export class SetFileNameAction implements Action {
  readonly type = SET_FILE_NAME;

  constructor(public payload: string) {}
}

export class ResetFileNameAction implements Action {
  readonly type = RESET_FILE_NAME;
}

export type Actions =
  | SetImportSecretsAction
  | ResetImportSecretsAction
  | SetFileNameAction
  | ResetFileNameAction;
