import { Action } from "@ngrx/store";
import { IAuthStore } from "../user";

export enum RegisterActionTypes {
  REGISTER = "[Register] Register",
  REGISTER_SUCCESS = "[Register] Success",
  REGISTER_FAILURE = "[Register] Failure",
  REGISTER_CLEAR = "[Register] Clear",
  CHECK_EMAIL = "[Register] Check Username",
  CHECK_EMAIL_SUCCESS = "[Register] Check Username Success",
  CHECK_EMAIL_FAILURE = "[Register] Check Username Failure",
  SET_IS_EMAIL_TAKEN = "[Register] Set Email Is Taken",
  CHECK_URL = "[Register] Check Url",
  CHECK_URL_SUCCESS = "Check Url Success",
  CHECK_URL_FAILURE = "[Register] Check Url Failure",
  NEWSLETTER_SUBSCRIBE = "Subscirbe to Newsletter",
  SWITCH_STAGE = "Switch Stage",
  INCREMENT_STAGE = "Increment Stage",
  DISPLAY_URL = "Display Url",
  HIDE_URL = "Hide Url"
}

export class RegisterAction implements Action {
  readonly type = RegisterActionTypes.REGISTER;
}

export class RegisterSuccessAction implements Action {
  readonly type = RegisterActionTypes.REGISTER_SUCCESS;

  constructor(public payload: IAuthStore) {}
}

export class RegisterFailureAction implements Action {
  readonly type = RegisterActionTypes.REGISTER_FAILURE;

  constructor(public payload: string) {}
}

export class CheckEmailAction implements Action {
  readonly type = RegisterActionTypes.CHECK_EMAIL;
}

export class CheckEmailSuccess implements Action {
  readonly type = RegisterActionTypes.CHECK_EMAIL_SUCCESS;
}

export class SetIsEmailTaken implements Action {
  readonly type = RegisterActionTypes.SET_IS_EMAIL_TAKEN;

  constructor(public payload: boolean) {}
}

/** Unexpected check email server side error */
export class CheckEmailFailure implements Action {
  readonly type = RegisterActionTypes.CHECK_EMAIL_FAILURE;
  constructor(public payload: string) {}
}

export class RegisterClearAction implements Action {
  readonly type = RegisterActionTypes.REGISTER_CLEAR;
}

export class IncrementStageAction implements Action {
  readonly type = RegisterActionTypes.INCREMENT_STAGE;
}

export class SwitchStageAction implements Action {
  readonly type = RegisterActionTypes.SWITCH_STAGE;

  constructor(public payload: number) {}
}

export class DisplayUrlAction implements Action {
  readonly type = RegisterActionTypes.DISPLAY_URL;
}

export class HideUrlAction implements Action {
  readonly type = RegisterActionTypes.HIDE_URL;
}

export class CheckUrlAction implements Action {
  readonly type = RegisterActionTypes.CHECK_URL;
}

export class CheckUrlSuccessAction implements Action {
  readonly type = RegisterActionTypes.CHECK_URL_SUCCESS;
}

export class CheckUrlFailureAction implements Action {
  readonly type = RegisterActionTypes.CHECK_URL_FAILURE;
}

export class NewsletterSubscribeAction implements Action {
  readonly type = RegisterActionTypes.NEWSLETTER_SUBSCRIBE;
}

export type RegisterActions =
  | RegisterAction
  | RegisterSuccessAction
  | RegisterFailureAction
  | RegisterClearAction
  | CheckEmailAction
  | CheckEmailSuccess
  | CheckEmailFailure
  | SetIsEmailTaken
  | CheckUrlAction
  | CheckUrlSuccessAction
  | CheckUrlFailureAction
  | NewsletterSubscribeAction
  | DisplayUrlAction
  | IncrementStageAction
  | HideUrlAction
  | SwitchStageAction;
