import { Injectable } from "@angular/core";
import { Actions, Effect, ofType } from "@ngrx/effects";
import { of } from "rxjs";
import { mergeMap, map, exhaustMap, catchError } from "rxjs/operators";
import { HandleAPIErrorAction } from "../account/account.actions";
import {
  DeleteSecretAction,
  GetSecretsAction,
  RemoveSecretAction,
  ReplaceSecretAction,
  ReplaceSecretSuccessAction,
  SetSecretsAction,
  SecretActionTypes
} from "./secret.actions";
import { SecretService } from "./secret.service";
import { SecretFormActionTypes } from "../list/secret-form/secret-form.actions";

@Injectable()
export class SecretEffects {
  @Effect()
  getSecrets$ = this.actions$.pipe(
    ofType<GetSecretsAction>(
      SecretActionTypes.GET_SECRETS,
      SecretFormActionTypes.CREATE_SECRET_SUCCESS
    ),
    mergeMap(() =>
      this.secretService.getSecrets().pipe(
        map(secrets => new SetSecretsAction(secrets)),
        catchError(error => of(new HandleAPIErrorAction(error)))
      )
    )
  );

  @Effect()
  replaceSecret$ = this.actions$.pipe(
    ofType<ReplaceSecretAction>(SecretActionTypes.REPLACE_SECRET),
    map(action => action.payload),
    exhaustMap(payload => {
      const newSecret = payload.secret;
      const groups = payload.groups;
      return this.secretService
        .updateGroupsForSecret(groups, newSecret.id)
        .then(() => {
          return this.secretService.updateSecret(newSecret).then(secret => {
            return new ReplaceSecretSuccessAction(secret);
          });
        });
    })
  );

  @Effect()
  deleteSecret$ = this.actions$.pipe(
    ofType<DeleteSecretAction>(SecretActionTypes.DELETE_SECRET),
    map(action => action.payload),
    exhaustMap(secretId => {
      return this.secretService
        .deleteSecret(secretId)
        .then(() => new RemoveSecretAction(secretId));
    })
  );

  constructor(
    private actions$: Actions,
    private secretService: SecretService
  ) {}
}
