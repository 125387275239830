import { Injectable } from "@angular/core";
import { from } from "rxjs";
import { NgPassitSDK } from "../../ngsdk/sdk";

@Injectable()
export class ContactsService {
  constructor(public sdk: NgPassitSDK) {}

  /** Get all contacts and save them in state */
  public getContacts() {
    return from(this.sdk.list_contacts());
  }

  /** Look up a user id by exact email address
   * Returns promise with user ID or rejection if no user with this email exists
   */

  public contactLookup(email: string) {
    return from(this.sdk.lookup_user_id(email));
  }
}
