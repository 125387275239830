import { Component, OnInit } from "@angular/core";
import { UserService } from "../user/user.service";
import * as fromRoot from "../../app.reducers";
import * as fromAccount from "../account.reducer";
import { ResetDeleteStateAction } from "../delete/delete.actions";
import { Store } from "@ngrx/store";

@Component({
  selector: "app-delete-container",
  template: `
    <app-delete
      [form]="form$ | async"
      [formErrors]="formErrors$ | async"
      (doAccountDelete)="doAccountDelete($event)"
    ></app-delete>
  `
})
export class DeleteContainer implements OnInit {
  form$ = this.store.select(fromAccount.getDeleteAccountForm);
  formErrors$ = this.store.select(fromAccount.getDeleteFormErrors);
  constructor(
    private userService: UserService,
    private store: Store<fromRoot.IState>) {}

  ngOnInit() {
    this.store.dispatch(new ResetDeleteStateAction());
  }

  doAccountDelete(password: string) {
    this.userService.deleteUserAccount(password);
  }

}
