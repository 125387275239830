/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./error-reporting.component.ngfactory";
import * as i2 from "./error-reporting.component";
import * as i3 from "@angular/common";
import * as i4 from "./error-reporting.container";
import * as i5 from "@ngrx/store";
var styles_ErrorReportingContainer = [];
var RenderType_ErrorReportingContainer = i0.ɵcrt({ encapsulation: 2, styles: styles_ErrorReportingContainer, data: {} });
export { RenderType_ErrorReportingContainer as RenderType_ErrorReportingContainer };
export function View_ErrorReportingContainer_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "app-error-reporting", [], null, [[null, "submit"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("submit" === en)) {
        var pd_0 = (_co.submit() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_ErrorReportingComponent_0, i1.RenderType_ErrorReportingComponent)), i0.ɵdid(1, 49152, null, 0, i2.ErrorReportingComponent, [], { form: [0, "form"], hasStarted: [1, "hasStarted"], hasFinished: [2, "hasFinished"] }, { submit: "submit" }), i0.ɵpid(131072, i3.AsyncPipe, [i0.ChangeDetectorRef]), i0.ɵpid(131072, i3.AsyncPipe, [i0.ChangeDetectorRef]), i0.ɵpid(131072, i3.AsyncPipe, [i0.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 1, 0, i0.ɵnov(_v, 2).transform(_co.form$)); var currVal_1 = i0.ɵunv(_v, 1, 1, i0.ɵnov(_v, 3).transform(_co.hasStarted$)); var currVal_2 = i0.ɵunv(_v, 1, 2, i0.ɵnov(_v, 4).transform(_co.hasFinished$)); _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }, null); }
export function View_ErrorReportingContainer_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "ng-component", [], null, null, null, View_ErrorReportingContainer_0, RenderType_ErrorReportingContainer)), i0.ɵdid(1, 114688, null, 0, i4.ErrorReportingContainer, [i5.Store], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ErrorReportingContainerNgFactory = i0.ɵccf("ng-component", i4.ErrorReportingContainer, View_ErrorReportingContainer_Host_0, {}, {}, []);
export { ErrorReportingContainerNgFactory as ErrorReportingContainerNgFactory };
