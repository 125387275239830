var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Actions, Effect, ofType } from "@ngrx/effects";
import { of } from "rxjs";
import { mergeMap, map, exhaustMap, catchError } from "rxjs/operators";
import { HandleAPIErrorAction } from "../account/account.actions";
import { RemoveSecretAction, ReplaceSecretSuccessAction, SetSecretsAction, SecretActionTypes } from "./secret.actions";
import { SecretService } from "./secret.service";
import { SecretFormActionTypes } from "../list/secret-form/secret-form.actions";
var SecretEffects = /** @class */ (function () {
    function SecretEffects(actions$, secretService) {
        var _this = this;
        this.actions$ = actions$;
        this.secretService = secretService;
        this.getSecrets$ = this.actions$.pipe(ofType(SecretActionTypes.GET_SECRETS, SecretFormActionTypes.CREATE_SECRET_SUCCESS), mergeMap(function () {
            return _this.secretService.getSecrets().pipe(map(function (secrets) { return new SetSecretsAction(secrets); }), catchError(function (error) { return of(new HandleAPIErrorAction(error)); }));
        }));
        this.replaceSecret$ = this.actions$.pipe(ofType(SecretActionTypes.REPLACE_SECRET), map(function (action) { return action.payload; }), exhaustMap(function (payload) {
            var newSecret = payload.secret;
            var groups = payload.groups;
            return _this.secretService
                .updateGroupsForSecret(groups, newSecret.id)
                .then(function () {
                return _this.secretService.updateSecret(newSecret).then(function (secret) {
                    return new ReplaceSecretSuccessAction(secret);
                });
            });
        }));
        this.deleteSecret$ = this.actions$.pipe(ofType(SecretActionTypes.DELETE_SECRET), map(function (action) { return action.payload; }), exhaustMap(function (secretId) {
            return _this.secretService
                .deleteSecret(secretId)
                .then(function () { return new RemoveSecretAction(secretId); });
        }));
    }
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], SecretEffects.prototype, "getSecrets$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], SecretEffects.prototype, "replaceSecret$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], SecretEffects.prototype, "deleteSecret$", void 0);
    return SecretEffects;
}());
export { SecretEffects };
