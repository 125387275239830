import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output
} from "@angular/core";

import { ISecret } from "passit-sdk-js/js/api.interfaces";

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: "secret-row",
  styleUrls: ["./secret-row.component.scss"],
  templateUrl: "./secret-row.component.html"
})
export class SecretRowComponent {
  @Input() secret: ISecret;
  @Input() active: boolean;
  @Output() onSecretSelected = new EventEmitter<ISecret>();

  constructor() {}

  public toggleView(onlyWhenClosed: boolean = false) {
    // One of our toggles only needs to work when the secret is closed;
    // the other one acts as a true toggle
    if ((onlyWhenClosed && !this.active) || !onlyWhenClosed) {
      this.onSecretSelected.emit(this.secret);
    }
  }
}
