import { Component, EventEmitter, Input, Output } from "@angular/core";
import { flyInOut } from "../../app.animations";
import { IChangePasswordForm } from "./change-password.reducer";
import { FormGroupState } from "ngrx-forms";

@Component({
  selector: "change-password",
  styleUrls: ["./change-password.styles.scss"],
  templateUrl: "./change-password.component.html",
  animations: [flyInOut]
})
export class ChangePasswordComponent {
  @Input() form: FormGroupState<IChangePasswordForm>;
  @Input() hasStarted: boolean;
  @Input() hasFinished: boolean;
  @Input() nonFieldErrors: string[];
  @Output() changePassword = new EventEmitter();
  @Output() toggleConfirm = new EventEmitter();

  onSubmit() {
    if (this.form.isValid) {
      this.changePassword.emit();
    }
  }
}
