var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { tap, withLatestFrom, map, exhaustMap } from "rxjs/operators";
import { of as observableOf } from "rxjs";
import { Router } from "@angular/router";
import { Actions, Effect, ofType } from "@ngrx/effects";
import { AccountActionTypes, APIFailureNetworkDownAction, CriticalAPIErrorAction, LoginFailureAction, LoginSuccessAction, LogoutAction, UserMustConfirmEmailAction, LogoutSuccessAction } from "./account.actions";
import * as fromAccount from "./account.reducer";
import { IS_EXTENSION } from "../constants";
import { UserService } from "./user";
import { Store } from "@ngrx/store";
var LoginEffects = /** @class */ (function () {
    function LoginEffects(actions$, userService, router, store) {
        var _this = this;
        this.actions$ = actions$;
        this.userService = userService;
        this.router = router;
        this.store = store;
        this.login$ = this.actions$.pipe(ofType(AccountActionTypes.LOGIN), withLatestFrom(this.store.select(fromAccount.getLoginForm)), map(function (_a) {
            var action = _a[0], form = _a[1];
            return form.value;
        }), exhaustMap(function (auth) {
            var callLogin = function () {
                return _this.userService
                    .login(auth.email, auth.password, auth.rememberMe ? auth.rememberMe : false)
                    .then(function (resp) { return new LoginSuccessAction(resp); })
                    .catch(function (err) { return new LoginFailureAction(err); });
            };
            var callCheckAndSetUrl = function (url) {
                return _this.userService
                    .checkAndSetUrl(url)
                    .then(function () { return callLogin(); })
                    .catch(function (err) { return new LoginFailureAction(err); });
            };
            if (auth.url) {
                return callCheckAndSetUrl(auth.url);
            }
            else {
                return callLogin();
            }
        }));
        this.loginSuccess$ = this.actions$.ofType(AccountActionTypes.LOGIN_SUCCESS).pipe(tap(function () {
            if (IS_EXTENSION) {
                _this.router.navigate(["/popup"]);
            }
            else {
                _this.router.navigate(["/list"]);
            }
        }));
        this.loginRedirect$ = this.actions$
            .ofType(AccountActionTypes.LOGIN_REDIRECT, AccountActionTypes.LOGOUT_SUCCESS)
            .pipe(tap(function () {
            _this.router.navigate(["/login"], { replaceUrl: true });
        }));
        this.logout$ = this.actions$.ofType(AccountActionTypes.LOGOUT).pipe(exhaustMap(function () {
            return _this.userService
                .logout()
                .then(function () { return new LogoutSuccessAction(); })
                .catch(function () { return new LogoutSuccessAction(); });
        }));
        this.logoutSuccess$ = this.actions$
            .ofType(AccountActionTypes.LOGOUT_SUCCESS)
            .pipe(tap(function () { return localStorage.clear(); }));
        this.handleAPIError$ = this.actions$
            .ofType(AccountActionTypes.HANDLE_API_ERROR)
            .pipe(map(function (action) { return action.payload; }), exhaustMap(function (err) {
            var res = err.res;
            if (res) {
                if (res.status === 0) {
                    // Not so bad, network is just down
                    return observableOf(new APIFailureNetworkDownAction());
                }
                if ([401, 403].includes(res.status)) {
                    if (res.status === 403 &&
                        res.error &&
                        res.error.detail === "User's email is not confirmed.") {
                        return observableOf(new UserMustConfirmEmailAction());
                    }
                    return observableOf(new LogoutAction());
                }
            }
            if (err.name === "Passit SDK Authentication Error") {
                return observableOf(new LogoutAction());
            }
            // This should never run
            console.error("Unable to use api and unable to handle error", err);
            return observableOf(new CriticalAPIErrorAction(err));
        }));
        this.userMustConfirmEmail$ = this.actions$
            .ofType(AccountActionTypes.USER_MUST_CONFIRM_EMAIL)
            .pipe(tap(function () { return _this.router.navigate(["/confirm-email"]); }));
    }
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], LoginEffects.prototype, "login$", void 0);
    __decorate([
        Effect({ dispatch: false }),
        __metadata("design:type", Object)
    ], LoginEffects.prototype, "loginSuccess$", void 0);
    __decorate([
        Effect({ dispatch: false }),
        __metadata("design:type", Object)
    ], LoginEffects.prototype, "loginRedirect$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], LoginEffects.prototype, "logout$", void 0);
    __decorate([
        Effect({ dispatch: false }),
        __metadata("design:type", Object)
    ], LoginEffects.prototype, "logoutSuccess$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], LoginEffects.prototype, "handleAPIError$", void 0);
    __decorate([
        Effect({ dispatch: false }),
        __metadata("design:type", Object)
    ], LoginEffects.prototype, "userMustConfirmEmail$", void 0);
    return LoginEffects;
}());
export { LoginEffects };
