var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Actions, Effect, ofType } from "@ngrx/effects";
import { of, from } from "rxjs";
import { exhaustMap, withLatestFrom, map, mergeMap, debounceTime, catchError } from "rxjs/operators";
import { SetContactsAction, ContactsActionTypes } from "./contacts/contacts.actions";
import { Store } from "@ngrx/store";
import { HandleAPIErrorAction } from "../account/account.actions";
import * as fromGroup from "../group/group.reducer";
import * as fromAccount from "../account/account.reducer";
import { ContactsService } from "./contacts/contacts.service";
import { GroupActionTypes, ContactLookupFailureAction, ContactLookupSuccessAction, CreateGroupSuccessAction, DeleteGroupSuccessAction, SetGroupsAction, UpdateGroupSuccessAction, AcceptGroupInviteSuccess, DeclineGroupInviteSuccess } from "./group.actions";
import { GroupService } from "./group.service";
var GroupEffects = /** @class */ (function () {
    function GroupEffects(actions$, groupService, contactsService, store) {
        var _this = this;
        this.actions$ = actions$;
        this.groupService = groupService;
        this.contactsService = contactsService;
        this.store = store;
        this.getGroups$ = this.actions$.pipe(ofType(GroupActionTypes.GET_GROUPS, GroupActionTypes.CREATE_GROUP_SUCCESS, GroupActionTypes.DELETE_GROUP_SUCCESS, GroupActionTypes.UPDATE_GROUP_SUCCESS, GroupActionTypes.ACCEPT_GROUP_INVITE_SUCCESS, GroupActionTypes.DECLINE_GROUP_INVITE_SUCCESS), exhaustMap(function () {
            return from(_this.groupService.getGroups()).pipe(map(function (groups) { return new SetGroupsAction(groups); }), catchError(function (error) { return of(new HandleAPIErrorAction(error)); }));
        }));
        this.createGroup$ = this.actions$.pipe(ofType(GroupActionTypes.CREATE_GROUP), withLatestFrom(this.store.select(fromGroup.getGroupForm), this.store.select(fromGroup.getCombinedContacts), this.store.select(fromAccount.getEmail), this.store.select(fromAccount.getUserId)), map(function (_a) {
            var action = _a[0], groupForm = _a[1], contacts = _a[2], ownEmail = _a[3], ownUserId = _a[4];
            var members = groupForm.members.map(function (member) {
                if (member === ownUserId) {
                    var myself = {
                        id: ownUserId,
                        email: ownEmail
                    };
                    return myself;
                }
                var contact = contacts.find(function (contact_) { return contact_.id === member; });
                if (contact) {
                    return contact;
                }
                else {
                    throw new Error("Unable to lookup email for " + member);
                }
            });
            if (!groupForm.members.includes(ownUserId)) {
                members.push({
                    id: ownUserId,
                    email: ownEmail
                });
            }
            return [groupForm, members];
        }), mergeMap(function (_a) {
            var groupForm = _a[0], members = _a[1];
            return _this.groupService
                .create(groupForm, members)
                .then(function () { return new CreateGroupSuccessAction(); });
        }));
        this.deleteGroup$ = this.actions$.pipe(ofType(GroupActionTypes.DELETE_GROUP), map(function (action) { return action.payload; }), exhaustMap(function (groupId) {
            return _this.groupService
                .deleteGroup(groupId)
                .then(function () { return new DeleteGroupSuccessAction(); });
        }));
        this.updateGroup$ = this.actions$.pipe(ofType(GroupActionTypes.UPDATE_GROUP), withLatestFrom(this.store.select(fromGroup.getGroupManaged), this.store.select(fromGroup.getGroupForm), this.store.select(fromGroup.getCombinedContacts)), mergeMap(function (_a) {
            var action = _a[0], groupManaged = _a[1], groupForm = _a[2], contacts = _a[3];
            var members = groupForm.members.map(function (member) {
                var contact = contacts.find(function (contact_) { return contact_.id === member; });
                if (contact) {
                    return contact;
                }
                else {
                    throw new Error("Unable to lookup email for " + member);
                }
            });
            return _this.groupService
                .updateGroupMembers(groupManaged, members)
                .then(function () {
                return _this.groupService
                    .update(groupManaged, groupForm)
                    .then(function () { return new UpdateGroupSuccessAction(); });
            });
        }));
        this.getContacts$ = this.actions$.pipe(ofType(ContactsActionTypes.GET_CONTACTS, GroupActionTypes.UPDATE_GROUP_SUCCESS, GroupActionTypes.CREATE_GROUP_SUCCESS), mergeMap(function () {
            return _this.contactsService.getContacts().pipe(map(function (contacts) { return new SetContactsAction(contacts); }), catchError(function (error) { return of(new HandleAPIErrorAction(error)); }));
        }));
        this.contactLookup$ = this.actions$.pipe(ofType(GroupActionTypes.CONTACT_LOOKUP), debounceTime(300), map(function (action) { return action.payload; }), mergeMap(function (email) {
            return _this.contactsService.contactLookup(email).pipe(map(function (resp) {
                return new ContactLookupSuccessAction({
                    value: resp,
                    label: "" + email,
                    disabled: false
                });
            }), catchError(function (error) { return of(new ContactLookupFailureAction()); }));
        }));
        this.acceptGroupInvite = this.actions$.pipe(ofType(GroupActionTypes.ACCEPT_GROUP_INVITE), exhaustMap(function (action) {
            return from(_this.groupService.acceptGroup(action.payload)).pipe(map(function () { return new AcceptGroupInviteSuccess(); }), catchError(function (error) { return of(new HandleAPIErrorAction(error)); }));
        }));
        this.declineGroupInvite = this.actions$.pipe(ofType(GroupActionTypes.DECLINE_GROUP_INVITE), exhaustMap(function (action) {
            return from(_this.groupService.declineGroup(action.payload)).pipe(map(function () { return new DeclineGroupInviteSuccess(); }), catchError(function (error) { return of(new HandleAPIErrorAction(error)); }));
        }));
    }
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], GroupEffects.prototype, "getGroups$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], GroupEffects.prototype, "createGroup$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], GroupEffects.prototype, "deleteGroup$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], GroupEffects.prototype, "updateGroup$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], GroupEffects.prototype, "getContacts$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], GroupEffects.prototype, "contactLookup$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], GroupEffects.prototype, "acceptGroupInvite", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], GroupEffects.prototype, "declineGroupInvite", void 0);
    return GroupEffects;
}());
export { GroupEffects };
