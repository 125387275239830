export const formFill = `
var isVisible = function (element) {
    return element.offsetWidth > 0 || element.offsetHeight > 0;
};
var LARGE_SCORE_VALUE = 1000;
var createFieldDict = function (fields) {
    var rval = [];
    for (var i = 0; i < fields.length; ++i) {
        var field = fields[i];
        if (field.tagName === "BUTTON") {
            var button = field;
            rval.push({
                name: button.name,
                id: button.id,
                "class": button.className,
                value: button.textContent,
                type: "button",
                pointer: field,
                itemNo: i
            });
        }
        else if (field.tagName === "A") {
            var anchor = field;
            rval.push({
                name: anchor.name || field.className,
                id: anchor.id,
                "class": anchor.className,
                type: "a",
                value: field.textContent,
                pointer: field,
                itemNo: i
            });
        }
        else {
            var input = field;
            rval.push({
                name: input.name,
                id: input.id,
                "class": input.className,
                value: input.type === "image" ? input.alt : input.value,
                type: input.type || "text",
                maxlength: input.maxLength > -1 ? input.maxLength : 10,
                pointer: field,
                itemNo: i
            });
        }
    }
    return rval;
};
var getMaximumElement = function (elements, scoreFunc) {
    if (elements) {
        var maxScore = -1;
        var maxElement = null;
        for (var _i = 0, elements_1 = elements; _i < elements_1.length; _i++) {
            var element = elements_1[_i];
            var score = scoreFunc(element);
            if (score > maxScore) {
                maxScore = score;
                maxElement = element;
            }
        }
        return maxElement;
    }
    return null;
};
var usernameScoreFunc = function (a, passwordFieldHint) {
    var score = 0;
    if (a.type === "text" || a.type === "email") {
        score += 2;
    }
    else {
        score -= LARGE_SCORE_VALUE;
    }
    if (passwordFieldHint) {
        var distance = passwordFieldHint.itemNo - a.itemNo;
        if (distance > 0) {
            score += 1.0 / distance;
        }
    }
    return score;
};
var guessUsernameField = function (elements, passwordFieldHint) {
    var usernameField = getMaximumElement(elements, function (a) {
        return usernameScoreFunc(a, passwordFieldHint);
    });
    if (usernameField !== null && usernameScoreFunc(usernameField) > 0) {
        return usernameField;
    }
    else {
        return null;
    }
};
var passwordScoreFunc = function (a) {
    var score = a.value ? 1 : 0;
    var name = a.name ? a.name.toLowerCase() : "";
    if (a.type === "password") {
        if (a.maxlength <= 4) {
            score -= LARGE_SCORE_VALUE;
        }
        else if (name.indexOf("creditcard") !== -1) {
            score -= LARGE_SCORE_VALUE;
        }
        else {
            score += 2;
        }
    }
    else {
        score -= LARGE_SCORE_VALUE;
    }
    return score;
};
var guessPasswordField = function (elements) {
    var passwordField = getMaximumElement(elements, passwordScoreFunc);
    if (passwordField !== null && passwordScoreFunc(passwordField) > 0) {
        return passwordField;
    }
    else {
        return null;
    }
};
var guessSubmitField = function (elements, allowImageButtons, $preferAfterThisField) {
    var submitScoreFunc = function (a) {
        if (a.type === "text" || a.type === "password") {
            return -LARGE_SCORE_VALUE;
        }
        var score = a.type === "submit" || a.type === "button" ? 1 : 0;
        score += a.type === "image" && allowImageButtons ? 0.75 : 0;
        score += a.type === "a" ? 0.25 : 0;
        var value = a.value ? a.value.toLowerCase() : "";
        if (value.indexOf("forgot") !== -1) {
            score -= LARGE_SCORE_VALUE;
        }
        if (value === "sign in" ||
            value === "log in" ||
            value === "log on" ||
            value === "submit" ||
            value === "login" ||
            value === "go") {
            score += 2;
        }
        else if (value.indexOf("sign in") !== -1 ||
            value.indexOf("log in") !== -1 ||
            value.indexOf("log on") !== -1 ||
            value.indexOf("submit") !== -1 ||
            value.indexOf("login") !== -1 ||
            value.indexOf("go") !== -1) {
            score += 1;
        }
        if ($preferAfterThisField) {
            var BITMASK = 4;
            var isAfter = $preferAfterThisField.compareDocumentPosition(a.pointer) & BITMASK;
            score += isAfter ? 1 : -1;
        }
        return score;
    };
    var submitField = getMaximumElement(elements, submitScoreFunc);
    if (submitField !== null && submitScoreFunc(submitField) > 0) {
        return submitField;
    }
    else {
        return null;
    }
};
var getLoginForm = function (form, requireFieldVisibility) {
    if (requireFieldVisibility === void 0) { requireFieldVisibility = true; }
    var fieldNodes = form.querySelectorAll("input:not([type=hidden]),button:not([type=hidden]),a");
    var fields = Array.prototype.slice.call(fieldNodes, 0);
    if (requireFieldVisibility) {
        fields = Array.prototype.filter.call(fields, function (field) {
            return isVisible(field);
        });
    }
    var fieldsRecord = createFieldDict(fields);
    var passwordField = guessPasswordField(fieldsRecord);
    var usernameField = null;
    var goAhead = !!passwordField;
    if (passwordField) {
        usernameField = guessUsernameField(fieldsRecord, passwordField);
    }
    goAhead = goAhead && usernameField !== null;
    if (goAhead) {
        var submitField = guessSubmitField(fieldsRecord, true, usernameField && usernameField.pointer);
        var fieldDict = createFieldDict([
            (passwordField ? passwordField : usernameField).pointer.closest("form")
        ]);
        if (!fieldDict) {
            return null;
        }
        return {
            usernameField: usernameField,
            passwordField: passwordField,
            submitField: submitField,
            allFields: fieldsRecord,
            formDict: fieldDict[0],
            id: form.id
        };
    }
    else {
        return null;
    }
};
var LOGIN_BUTTON_LABELS = ["login", "log in", "log on", "signin", "sign in"];
var SIGNUP_BUTTON_LABELS = [
    "signup",
    "sign up",
    "join",
    "create",
    "register",
    "start",
    "free",
    "trial"
];
var loginSubmitButtonScoreFunc = function (field) {
    var submitScore = 0;
    if (field && field.value) {
        var label = field.value.toLowerCase();
        var i = void 0;
        var s = void 0;
        for (i = 0; i < LOGIN_BUTTON_LABELS.length; ++i) {
            s = LOGIN_BUTTON_LABELS[i];
            if (label.indexOf(s) !== -1) {
                submitScore += 2;
            }
        }
        for (i = 0; i < SIGNUP_BUTTON_LABELS.length; ++i) {
            s = SIGNUP_BUTTON_LABELS[i];
            if (label.indexOf(s) !== -1) {
                submitScore -= LARGE_SCORE_VALUE;
            }
        }
    }
    return submitScore;
};
var LOGIN_FORM_ID_STRINGS = ["login", "signin"];
var SIGNUP_FORM_ID_STRINGS = ["signup", "regist"];
var loginIdScoreFunc = function (formId) {
    var idScore = 0;
    if (formId) {
        var id = formId.toLowerCase();
        var i = void 0;
        for (i = 0; i < LOGIN_FORM_ID_STRINGS.length; ++i) {
            var s = LOGIN_FORM_ID_STRINGS[i];
            if (id.indexOf(s) !== -1) {
                idScore += 1;
            }
        }
        if (idScore === 0) {
            for (i = 0; i < SIGNUP_FORM_ID_STRINGS.length; ++i) {
                var s = SIGNUP_FORM_ID_STRINGS[i];
                if (id.indexOf(s) !== -1) {
                    idScore -= LARGE_SCORE_VALUE;
                }
            }
        }
    }
    return idScore;
};
var countFieldsOfType = function (fields, type) {
    var count = 0;
    for (var _i = 0, fields_1 = fields; _i < fields_1.length; _i++) {
        var field = fields_1[_i];
        if (field.type === type) {
            ++count;
        }
    }
    return count;
};
var loginFormScoreFunc = function (formDict) {
    if (!formDict) {
        return 0;
    }
    var score = 0;
    if (formDict.usernameField) {
        score += usernameScoreFunc(formDict.usernameField);
    }
    if (!formDict.passwordField &&
        formDict.usernameField.emptyPasswordPermitted) {
    }
    else {
        score += passwordScoreFunc(formDict.passwordField);
    }
    score += loginSubmitButtonScoreFunc(formDict.submitField);
    score += loginIdScoreFunc(formDict.id);
    if (countFieldsOfType(formDict.allFields, "password") > 1) {
        score -= LARGE_SCORE_VALUE;
    }
    return score;
};
var guessLoginForm = function (hints) {
    var scoringFunction = loginFormScoreFunc;
    var formSelection = Array.prototype.slice.call(document.querySelectorAll("form"));
    var forms = formSelection.map(function (form) {
        return getLoginForm(form);
    });
    var loginForm = getMaximumElement(forms, scoringFunction);
    if (loginForm && scoringFunction(loginForm) < 0) {
        return null;
    }
    return loginForm;
};
var getCanonicalHost = function (fullUrl) {
    return "http://stub.com";
};
var fillLoginForm = function (formData) {
    var formDomain = getCanonicalHost(formData.loginUrl);
    var pageDomain = getCanonicalHost(document.URL);
    if (formDomain !== pageDomain) {
        throw new Error("Domain mismatch: " + pageDomain + ", expected: " + formDomain);
    }
    var un = formData.usernameField ? formData.usernameField.value : null;
    var pw = formData.passwordField ? formData.passwordField.value : null;
    setTimeout(function () {
        var sendKeyEvents = function (inputId) {
            var event = new KeyboardEvent("keydown");
            document.querySelector("#" + inputId).dispatchEvent(event);
            event = new KeyboardEvent("keypress");
            document.querySelector("#" + inputId).dispatchEvent(event);
            event = new KeyboardEvent("keyup");
            document.querySelector("#" + inputId).dispatchEvent(event);
        };
        var $user_input = (formData.usernameField
            ? formData.usernameField.pointer
            : formData.usernameField);
        if ($user_input) {
            var $user_form = $user_input.closest("form");
            $user_input.value = un;
            var userInputId_1 = $user_input.id;
            if (!userInputId_1) {
                userInputId_1 = "MITRO____184379378465893";
                $user_input.setAttribute("id", userInputId_1);
            }
            setTimeout(function () {
                sendKeyEvents(userInputId_1);
            }, 10);
        }
        if (formData.passwordField) {
            var $pass_input = formData.passwordField.pointer;
            var $pass_form = $pass_input.closest("form");
            $pass_input.value = pw;
            var passInputId_1 = $pass_input.getAttribute("id");
            if (!passInputId_1) {
                passInputId_1 = "MITRO____184379378465894";
                $pass_input.setAttribute("id", passInputId_1);
            }
            setTimeout(function () {
                sendKeyEvents(passInputId_1);
            }, 20);
            $pass_form[0].setAttribute("autocomplete", "off");
            if ($user_input) {
                $user_input.setAttribute("autocomplete", "off");
            }
            $pass_input.setAttribute("autocomplete", "off");
        }
        setTimeout(function () {
            var submit_button = formData.submitField;
            if (submit_button) {
                try {
                }
                catch (e) {
                    submit_button.pointer.removeAttribute("disabled");
                }
                submit_button.pointer.click();
            }
            else {
                try {
                    $pass_form[0].submit();
                }
                catch (e) {
                    if (e instanceof TypeError) {
                        try {
                            $pass_form[0].submit.click();
                        }
                        catch (e2) {
                            var $submit_thing = $user_form.querySelector(":submit");
                            $submit_thing.click();
                        }
                    }
                }
            }
        }, 300);
    }, 200);
};
var guessAndFillLoginForm = function (message) {
    var formData = {
        clientData: { loginUrl: document.URL, username: message.username },
        criticalData: { password: message.password }
    };
    var loginForm = guessLoginForm(formData);
    if (!loginForm) {
        return false;
    }
    loginForm.loginUrl = formData.clientData.loginUrl;
    if (loginForm.usernameField) {
        loginForm.usernameField.value = formData.clientData.username;
    }
    if (loginForm.passwordField) {
        loginForm.passwordField.value = formData.criticalData.password;
    }
    fillLoginForm(loginForm);
    return true;
};
if (typeof browser === "undefined" && typeof chrome !== "undefined") {
    chrome.runtime.onMessage.addListener(function (message) {
        guessAndFillLoginForm(message);
    });
}
else if (typeof browser !== "undefined") {
    browser.runtime.onMessage.addListener(function (message) {
        guessAndFillLoginForm(message);
    });
}
`;
