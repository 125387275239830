<div
  [class.l-container-narrow]="!inline"
  [class.u-margin-tb-50]="!inline">
  <h2 *ngIf="!inline" class="heading-main">Confirm Email</h2>

  <div class="register-step__feedback">
    We just sent an email to the address you gave us. Click the link in the message or enter the code into the box below to confirm
    your email and start using Passit.
  </div>

  <form #confirmForm="ngForm" (ngSubmit)="onSubmit(confirmForm.value)" class="u-margin-b-50">

    <app-non-field-messages *ngIf="confirmCodeMessage" [messages]="[confirmCodeMessage]"></app-non-field-messages>

    <div class="form-field form-field--large">
      <label for="code" class="form-field__label">Code</label>
      <input  #codeInput type="text" id="code" name="code" class="form-field__input form-field__input--30" [class.form-field__input--invalid]="errorMessage" [ngModel]="code">
    </div>

    <ul *ngIf="errorMessage" class="form-field__error-list">
      <li class="form-field__error">
        {{ errorMessage }}
      </li>
    </ul>

    <div class="auth-form__actions">
      <button class="button button--primary" (click)="onSubmit(confirmForm.value)" [disabled]="hasStarted" type="button">
        Confirm Code
      </button>

      <progress-indicator [inProgress]="hasStarted" inProgressText="Confirming email" [completed]="hasFinished" completedText="Confirmed email"></progress-indicator>
    </div>

    <p *ngIf="errorMessage"></p>

    <p>
      Don't have the email?
      <a class="text-link text-link--caret" [routerLink]="" (click)="!hasStarted ? onClick() : null">
        Resend confirmation
      </a>
    </p>
  </form>

</div>