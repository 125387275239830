import { Action } from "@ngrx/store";

export enum DeleteAccountActionTypes {
  DELETE_FAILURE = "[Delete] Failure",
  RESET_STATE = "[Reset] Action",
}

export class DeleteAccountFailureAction implements Action {
  readonly type = DeleteAccountActionTypes.DELETE_FAILURE;
}

export class ResetDeleteStateAction implements Action {
  readonly type = DeleteAccountActionTypes.RESET_STATE;
}

export type DeleteAccountActions =
  | DeleteAccountFailureAction
  | ResetDeleteStateAction;

