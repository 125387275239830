import { Injectable, ErrorHandler, Injector } from "@angular/core";
import * as Raven from "raven-js";
import { Store } from "@ngrx/store";
import { getOptInErrorReporting } from "./account/account.reducer";

@Injectable()
export class RavenErrorHandler implements ErrorHandler {
  reportError = false;

  /** Can't use normal dependency injection
   * https://stackoverflow.com/a/41585902/443457
   */
  constructor(injector: Injector) {
    setTimeout(() => {
      const store = injector.get(Store);
      store
        .select(getOptInErrorReporting)
        .subscribe(optIn => (this.reportError = optIn));
    });
  }

  handleError(err: any) {
    if (this.reportError) {
      Raven.captureException(err);
    } else {
      console.warn("Did not send error report because user did not opt in.");
    }
    console.error(err);
  }
}
