import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { IGroup, IGroupForm } from "../group.interfaces";
import { ISelectOptions } from "./group-form.interfaces";
import { IGroupUser } from "passit-sdk-js/js/api.interfaces";

@Component({
  selector: "group-form",
  styleUrls: ["../../list/secret-row/secret-row.component.scss"],
  templateUrl: "./group-form.component.html"
})
export class GroupFormComponent implements OnInit {
  @Output() back = new EventEmitter();
  @Output() hideAddSecretForm = new EventEmitter();
  @Output() save = new EventEmitter();
  @Output() contactSearch = new EventEmitter<string>();
  @Output() resetSearch = new EventEmitter();
  @Output() delete = new EventEmitter<number>();
  @Output() updateFormValues = new EventEmitter<IGroupForm>();

  @Input() groupContacts: ISelectOptions[];
  @Input() groupManaged: number;
  @Input() isNew = false;
  @Input() groupIsUpdating: boolean;
  @Input() groupIsUpdated: boolean;
  @Input() isPrivateOrgMode: boolean;
  @Input() contactLookup: ISelectOptions[];
  @Input() group: IGroup;
  @Input() groupForm: IGroupForm;
  @Input() singleGroupPendingContacts: IGroupUser[];

  privateOrgMemberOptions: ISelectOptions[] = [];
  memberOptions: ISelectOptions[] = [];
  createGroupForm: FormGroup;
  submitted = false;

  // Sets group members when in private org mode
  @Input()
  set contacts(contacts: ISelectOptions[]) {
    this.privateOrgMemberOptions = contacts;
  }

  // Sets group members when in public org mode
  // Set to groupContacts or members depending on the value of contactLookup.
  @Input()
  set searchedContacts(members: ISelectOptions[]) {
    this.memberOptions = this.groupContacts;
    if (this.contactLookup) {
      this.memberOptions = members;
    }
  }

  constructor(private fb: FormBuilder) {}

  ngOnInit() {
    this.buildForm();
  }

  buildForm() {
    this.createGroupForm = this.fb.group({
      name: ["", [Validators.required]],
      members: []
    });
    this.createGroupForm.patchValue(this.groupForm);
    this.createGroupForm.valueChanges.subscribe(data => {
      this.updateFormValues.emit(data);
    });
  }

  // Add selected contact to contact list. Reset disabled to false for all contacts.
  onSelected(member: ISelectOptions) {
    this.resetSearch.emit(member);
  }

  onDeselected(member: ISelectOptions) {
    if (this.createGroupForm.value.members.length === 0) {
      if (
        window.confirm(
          "If you remove yourself from the group, it's gone forever. Is that okay?"
        )
      ) {
        this.save.emit();
      } else {
        this.createGroupForm.patchValue(this.groupForm);
      }
    }
  }

  onSubmit() {
    this.submitted = true;
    if (this.createGroupForm.valid) {
      this.save.emit();
    }
  }

  onDelete() {
    if (window.confirm("Once it's deleted, it's gone forever. Is that okay?")) {
      this.delete.emit(this.groupManaged);
    }
  }
}
