/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "../list/list.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./group-form/group-form.component.ngfactory";
import * as i3 from "./group-form/group-form.component";
import * as i4 from "@angular/forms";
import * as i5 from "./group-detail.component.ngfactory";
import * as i6 from "./group-detail.component";
import * as i7 from "@angular/common";
import * as i8 from "./group-list.component";
var styles_GroupListComponent = [i0.styles];
var RenderType_GroupListComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_GroupListComponent, data: {} });
export { RenderType_GroupListComponent as RenderType_GroupListComponent };
function View_GroupListComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "group-form", [["class", "secret-form secret-form--is-active"]], null, [[null, "contactSearch"], [null, "save"], [null, "updateFormValues"], [null, "resetSearch"], [null, "delete"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("contactSearch" === en)) {
        var pd_0 = (_co.contactSearch.emit($event) !== false);
        ad = (pd_0 && ad);
    } if (("save" === en)) {
        var pd_1 = (_co.save.emit() !== false);
        ad = (pd_1 && ad);
    } if (("updateFormValues" === en)) {
        var pd_2 = (_co.updateFormValues.emit($event) !== false);
        ad = (pd_2 && ad);
    } if (("resetSearch" === en)) {
        var pd_3 = (_co.resetSearch.emit($event) !== false);
        ad = (pd_3 && ad);
    } if (("delete" === en)) {
        var pd_4 = (_co.delete.emit($event) !== false);
        ad = (pd_4 && ad);
    } return ad; }, i2.View_GroupFormComponent_0, i2.RenderType_GroupFormComponent)), i1.ɵdid(1, 114688, null, 0, i3.GroupFormComponent, [i4.FormBuilder], { groupContacts: [0, "groupContacts"], groupManaged: [1, "groupManaged"], isNew: [2, "isNew"], groupIsUpdating: [3, "groupIsUpdating"], groupIsUpdated: [4, "groupIsUpdated"], isPrivateOrgMode: [5, "isPrivateOrgMode"], contactLookup: [6, "contactLookup"], group: [7, "group"], groupForm: [8, "groupForm"], singleGroupPendingContacts: [9, "singleGroupPendingContacts"], contacts: [10, "contacts"], searchedContacts: [11, "searchedContacts"] }, { save: "save", contactSearch: "contactSearch", resetSearch: "resetSearch", delete: "delete", updateFormValues: "updateFormValues" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.groupContacts; var currVal_1 = _co.groupManaged; var currVal_2 = false; var currVal_3 = _co.groupIsUpdating; var currVal_4 = _co.groupIsUpdated; var currVal_5 = _co.isPrivateOrgMode; var currVal_6 = _co.contactLookup; var currVal_7 = _v.parent.context.$implicit; var currVal_8 = _co.groupForm; var currVal_9 = _co.singleGroupPendingContacts; var currVal_10 = _co.contacts; var currVal_11 = _co.searchedContacts; _ck(_v, 1, 1, [currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10, currVal_11]); }, null); }
function View_GroupListComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "secret-list-item"]], [[2, "secret-list-item--is-active", null]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "group-detail", [], null, [[null, "groupSelected"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("groupSelected" === en)) {
        var pd_0 = (_co.onGroupWasSelected($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_GroupDetailComponent_0, i5.RenderType_GroupDetailComponent)), i1.ɵdid(2, 49152, null, 0, i6.GroupDetailComponent, [], { active: [0, "active"], group: [1, "group"] }, { groupSelected: "groupSelected" }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_GroupListComponent_2)), i1.ɵdid(4, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = (_v.context.$implicit.id === _co.groupManaged); var currVal_2 = _v.context.$implicit; _ck(_v, 2, 0, currVal_1, currVal_2); var currVal_3 = (_v.context.$implicit.id === _co.groupManaged); _ck(_v, 4, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_v.context.$implicit.id === _co.groupManaged); _ck(_v, 0, 0, currVal_0); }); }
function View_GroupListComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "list-placeholder"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "div", [["class", "list-placeholder__heading"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "h3", [["class", "list-placeholder__title"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "div", [["class", "list-placeholder__text list-placeholder__text--title"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 2, "div", [["class", "secret__header-actions"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "button", [["class", "button button--placeholder list-placeholder__button"], ["disabled", "disabled"], ["type", "button"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 0, "div", [["class", "list-placeholder__text list-placeholder__text--button"]], null, null, null, null, null))], null, null); }
function View_GroupListComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "list-placeholders"]], [[2, "list-placeholders--empty", null]], null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_GroupListComponent_4)), i1.ɵdid(2, 278528, null, 0, i7.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var currVal_1 = " ".repeat(20).split(""); _ck(_v, 2, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.groups.length === 0); _ck(_v, 0, 0, currVal_0); }); }
export function View_GroupListComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "secret-list"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_GroupListComponent_1)), i1.ɵdid(2, 278528, null, 0, i7.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_GroupListComponent_3)), i1.ɵdid(4, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.groups; _ck(_v, 2, 0, currVal_0); var currVal_1 = (_co.groups.length === 0); _ck(_v, 4, 0, currVal_1); }, null); }
export function View_GroupListComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "group-list", [], null, null, null, View_GroupListComponent_0, RenderType_GroupListComponent)), i1.ɵdid(1, 49152, null, 0, i8.GroupListComponent, [], null, null)], null, null); }
var GroupListComponentNgFactory = i1.ɵccf("group-list", i8.GroupListComponent, View_GroupListComponent_Host_0, { groups: "groups", groupManaged: "groupManaged", groupForm: "groupForm", contacts: "contacts", showCreate: "showCreate", groupIsUpdating: "groupIsUpdating", groupIsUpdated: "groupIsUpdated", groupContacts: "groupContacts", searchedContacts: "searchedContacts", isPrivateOrgMode: "isPrivateOrgMode", contactLookup: "contactLookup", singleGroupPendingContacts: "singleGroupPendingContacts" }, { groupSelected: "groupSelected", hideAddSecretForm: "hideAddSecretForm", save: "save", saveNew: "saveNew", contactSearch: "contactSearch", delete: "delete", resetSearch: "resetSearch", updateFormValues: "updateFormValues", cancelDeselect: "cancelDeselect" }, []);
export { GroupListComponentNgFactory as GroupListComponentNgFactory };
