import {
  createFeatureSelector,
  createSelector,
  ActionReducerMap
} from "@ngrx/store";
import { AccountActions, AccountActionTypes } from "./account.actions";
import * as fromConfirmEmail from "./confirm-email/confirm-email.reducer";
import * as fromLogin from "./login/login.reducer";
import {
  RegisterActionTypes,
  RegisterAction,
  RegisterSuccessAction
} from "./register/register.actions";
import {
  ErrorReportingTypes,
  ErrorReportingActionsUnion
} from "./error-reporting/error-reporting.actions";
import * as fromRegister from "./register/register.reducer";
import * as fromChangePassword from "./change-password/change-password.reducer";
import * as fromDeleteAccount from "./delete/delete.reducer";
import * as fromErrorReporting from "./error-reporting/error-reporting.reducer";

export interface IAuthState {
  email: string | null;
  userId: number | null;
  url: string | null;
  userToken: string | null;
  privateKey: string | null;
  publicKey: string | null;
  rememberMe: boolean;
  optInErrorReporting: boolean;
}

export const initialState: IAuthState = {
  email: null,
  userId: null,
  url: null,
  userToken: null,
  privateKey: null,
  publicKey: null,
  rememberMe: false,
  optInErrorReporting: false
};

export function authReducer(
  state = initialState,
  action:
    | AccountActions
    | RegisterAction
    | RegisterSuccessAction
    | ErrorReportingActionsUnion
): IAuthState {
  switch (action.type) {
    case AccountActionTypes.LOGIN:
    case RegisterActionTypes.REGISTER:
      // Clear any stale state (except the url)
      return { ...initialState, url: state.url };

    case AccountActionTypes.LOGIN_SUCCESS:
    case RegisterActionTypes.REGISTER_SUCCESS:
      return {
        ...state,
        email: action.payload.email,
        userId: action.payload.userId,
        userToken: action.payload.userToken,
        privateKey: action.payload.privateKey,
        publicKey: action.payload.publicKey,
        rememberMe: action.payload.rememberMe,
        optInErrorReporting: action.payload.optInErrorReporting
      };

    case AccountActionTypes.SET_URL:
      return Object.assign({}, state, {
        url: action.payload
      });

    case ErrorReportingTypes.SAVE_FORM_SUCCESS:
      return {
        ...state,
        optInErrorReporting: action.payload.opt_in_error_reporting
      };

    default:
      return state;
  }
}

export interface IAccountState {
  auth: IAuthState;
  login: fromLogin.ILoginState;
  register: fromRegister.IRegisterState;
  confirmEmail: fromConfirmEmail.IConfirmEmailState;
  changePassword: fromChangePassword.IChangePasswordState;
  deleteAccount: fromDeleteAccount.IDeleteAccountState;
  errorReporting: fromErrorReporting.IState;
}

export const reducers: ActionReducerMap<IAccountState> = {
  auth: authReducer,
  login: fromLogin.reducer,
  register: fromRegister.reducer,
  confirmEmail: fromConfirmEmail.reducer,
  changePassword: fromChangePassword.reducer,
  deleteAccount: fromDeleteAccount.reducer,
  errorReporting: fromErrorReporting.reducer
};

export const selectAccountState = createFeatureSelector<IAccountState>(
  "account"
);
export const selectLoginState = createSelector(
  selectAccountState,
  (state: IAccountState) => state.login
);
export const getLoginHasStarted = createSelector(
  selectLoginState,
  fromLogin.getHasStarted
);
export const getLoginHasFinished = createSelector(
  selectLoginState,
  fromLogin.getHasFinished
);
export const getLoginErrorMessage = createSelector(
  selectLoginState,
  fromLogin.getErrorMessage
);
export const getLoginForm = createSelector(selectLoginState, fromLogin.getForm);

export const selectAuthState = createSelector(
  selectAccountState,
  (state: IAccountState) => state.auth
);
export const getIsLoggedIn = createSelector(
  selectAuthState,
  (state: IAuthState) => (state.userToken ? true : false)
);
export const getToken = createSelector(
  selectAuthState,
  (state: IAuthState) => state.userToken
);
export const getEmail = createSelector(
  selectAuthState,
  (state: IAuthState) => state.email
);
export const getUserId = createSelector(
  selectAuthState,
  (state: IAuthState) => state.userId
);
export const getUrl = createSelector(
  selectAuthState,
  (state: IAuthState) => state.url
);
export const getOptInErrorReporting = createSelector(
  selectAuthState,
  (state: IAuthState) => state.optInErrorReporting
);

export const getLoginState = (state: IAccountState) => state.login;

export const selectRegisterState = createSelector(
  selectAccountState,
  (state: IAccountState) => state.register
);

export const getRegisterForm = createSelector(
  selectRegisterState,
  fromRegister.getForm
);

export const getUrlForm = createSelector(
  selectRegisterState,
  fromRegister.getUrlForm
);

export const getHasSubmitStarted = createSelector(
  selectRegisterState,
  fromRegister.getHasSubmitStarted
);

export const getHasSubmitFinished = createSelector(
  selectRegisterState,
  fromRegister.getHasSubmitFinished
);

export const getRegisterErrorMessage = createSelector(
  selectRegisterState,
  fromRegister.getErrorMessage
);

export const getStage = createSelector(
  selectRegisterState,
  fromRegister.getStage
);

export const getUrlDisplayName = createSelector(
  selectRegisterState,
  fromRegister.getUrlDisplayName
);

export const getShowUrl = createSelector(
  selectRegisterState,
  fromRegister.getShowUrl
);

export const getRegisterIsEmailTaken = createSelector(
  selectRegisterState,
  fromRegister.getIsEmailTaken
);

export const getisUrlValid = createSelector(
  selectRegisterState,
  fromRegister.getIsUrlValid
);

export const selectConfirmEmailState = createSelector(
  selectAccountState,
  (state: IAccountState) => state.confirmEmail
);

export const getConfirmEmailMessage = createSelector(
  selectConfirmEmailState,
  fromConfirmEmail.getResetCodeMessage
);
export const getConfirmIsVerified = createSelector(
  selectConfirmEmailState,
  fromConfirmEmail.getIsVerified
);
export const getConfirmHasStarted = createSelector(
  selectConfirmEmailState,
  fromConfirmEmail.getHasStarted
);
export const getConfirmHasFinished = createSelector(
  selectConfirmEmailState,
  fromConfirmEmail.getHasFinished
);
export const getConfirmErrorMessage = createSelector(
  selectConfirmEmailState,
  fromConfirmEmail.getErrorMessage
);

export const selectChangePasswordState = createSelector(
  selectAccountState,
  (state: IAccountState) => state.changePassword
);
export const getChangePasswordForm = createSelector(
  selectChangePasswordState,
  fromChangePassword.getForm
);

export const selectDeleteAccountState = createSelector(
  selectAccountState,
  (state: IAccountState) => state.deleteAccount
);
export const getDeleteAccountForm = createSelector(
  selectDeleteAccountState,
  fromDeleteAccount.getForm
);
export const getDeleteFormErrors = createSelector(
  selectDeleteAccountState,
  fromDeleteAccount.getDeleteFormErrors
);

export const selectErrorReportingState = createSelector(
  selectAccountState,
  (state: IAccountState) => state.errorReporting
);
export const getErrorReportingForm = createSelector(
  selectErrorReportingState,
  fromErrorReporting.getForm
);
export const getErrorReportingHasFinished = createSelector(
  selectErrorReportingState,
  fromErrorReporting.getHasFinished
);
export const getErrorReportingHasStarted = createSelector(
  selectErrorReportingState,
  fromErrorReporting.getHasStarted
);
