import { Component, ChangeDetectionStrategy, Input } from "@angular/core";
import { AbstractControlState } from "ngrx-forms";

@Component({
  selector: "app-checkbox",
  templateUrl: "./checkbox.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CheckboxComponent {
  /**
   * The main label text for a checkbox component. Optional.
   */
  @Input() title: string;

  /**
   * Secondary text. Optional. A string for now, though future usage might
   * require HTML to be passed through
   */
  @Input() subtext: string;

  /**
   * If true, then the label element is intended to be an inline DOM element
   * instead of a block. Not the common use case so false by default.
   */
  @Input() inline = false;

  /**
   * Must use ngrx-form control with this component
   */
  @Input() control: AbstractControlState<boolean>;
  constructor() {}
}
