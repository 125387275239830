<div class="account l-container-narrow u-margin-tb-50">

  <a class="text-link text-link--left-caret heading-back-link" [routerLink]=" ['/account'] ">Account Management</a>
  
  <h2 class="heading-main heading-main--pad-bottom">
    Change Account Password
  </h2>

  <form novalidate [ngrxFormState]="form" (submit)="onSubmit()">
    <app-non-field-messages [messages]="nonFieldErrors"></app-non-field-messages>

    <div class="form-field form-field--large">
      <label for="oldPassword" class="form-field__label">Current Password</label>
      <input type="password"
        [ngrxFormControlState]="form.controls.oldPassword"
        class="form-field__input form-field__input--one-action"
        [class.form-field__input--invalid]="form.errors._oldPassword && form.isSubmitted"
        name="oldPassword"
        autocomplete="off"
      />
    </div>

    <ul *ngIf="form.errors._oldPassword && form.isSubmitted" class="form-field__error-list">
      <li *ngIf="form.errors._oldPassword.required" class="form-field__error">
        You must enter your current password
      </li>
      <li *ngIf="form.errors._oldPassword.minLength" class="form-field__error">
        Password must be at least {{ form.errors._oldPassword.minLength.minLength }} characters long.
      </li>
    </ul>

    <div class="form-field form-field--large">
      <label for="password" class="form-field__label">New Password</label>
      <input type="{{ form.controls.showConfirm.value ? 'password' : 'text' }}"
        [ngrxFormControlState]="form.controls.newPassword"
        class="form-field__input form-field__input--one-action"
        [class.form-field__input--invalid]="form.errors._newPassword && form.isSubmitted"
        name="password"
        autocomplete="off"
      />
      <div class="form-field__actions">
        <button type="button"
          tabindex="-1"
          (click)="toggleConfirm.emit()"
          class="form-field__action">
          <div class="button__icon button__icon--no-text" [inlineSVG]="form.controls.showConfirm.value ? '../assets/svg/eye.svg' : '../assets/svg/eye-slash.svg'"></div>
        </button>
      </div>
    </div>

    <ul *ngIf="form.errors._newPassword && form.isSubmitted" class="form-field__error-list">
      <li *ngIf="form.errors._newPassword.required" class="form-field__error">
        You must have a password!
      </li>
      <li *ngIf="form.errors._newPassword.minLength" class="form-field__error">
        Password must be at least {{ form.errors._newPassword.minLength.minLength }} characters long.
      </li>
      <li *ngIf="form.errors._newPassword.hasUniqueChars" class="form-field__error">
        Password must have more than 5 unique characters.
      </li>
      <li *ngIf="form.errors._newPassword.notNumeric" class="form-field__error">
        Password must not be only numbers.
      </li>
      <li *ngIf="form.errors._newPassword.notEqualTo" class="form-field__error">
        New password is the same as the old password.
      </li>
    </ul>

    <div class="form-field form-field--large" *ngIf="form.controls.showConfirm.value">
      <label for="password" class="form-field__label">Confirm Password</label>
      <input type="password"
        [ngrxFormControlState]="form.controls.newPasswordConfirm"
        class="form-field__input"
        [class.form-field__input--invalid]="form.errors._newPasswordConfirm && form.isSubmitted"
        name="passwordConfirm"
      />
    </div>

    <ul *ngIf="form.errors._newPasswordConfirm && form.isSubmitted" class="form-field__error-list">
      <li *ngIf="form.errors._newPasswordConfirm.required && form.controls.showConfirm.value" class="form-field__error">
        Confirm your password by entering it again here, or click the
        eye icon to visually inspect your new password.
      </li>
      <li *ngIf="form.errors._newPasswordConfirm.equalTo && form.controls.showConfirm.value" class="form-field__error">
        Passwords don't match.
      </li>
    </ul>

    <div class="change-password__actions">

      <button type="submit" id="changePass" class="button button--primary" [disabled]="hasStarted">
        Change Password
      </button>

      <progress-indicator
        [inProgress]="hasStarted" 
        inProgressText="Saving"
        [completed]="hasFinished"
        completedText="Saved"></progress-indicator>

    </div>

    <div class="change-password__note" [@flyInOut]="true" *ngIf="hasStarted">
      We’re currently changing your password. This takes a little while, so don’t navigate away.
    </div>
  </form>
</div>
