import { PopupActions, PopupActionTypes } from "./popup.actions";

export interface IPopupState {
  selectedSecret: number | null;
  search: string;
  lastOpened: string | null;
  currentUrl: string | null;
  usernameCopied: number | null;
  passwordCopied: number | null;
}

export const initialState: IPopupState = {
  selectedSecret: null,
  search: "",
  lastOpened: null,
  currentUrl: null,
  usernameCopied: null,
  passwordCopied: null
};

export function popupReducer(
  state = initialState,
  action: PopupActions
): IPopupState {
  switch (action.type) {
    case PopupActionTypes.SET_SELECTED_SECRET:
      return {
        ...state,
        selectedSecret: action.payload,
        lastOpened: new Date().toString()
      };

    case PopupActionTypes.NULL_SELECTED_SECRET:
      return {
        ...state,
        selectedSecret: initialState.selectedSecret,
        lastOpened: new Date().toString()
      };

    case PopupActionTypes.SET_SEARCH:
      return {
        ...state,
        search: action.payload,
        lastOpened: new Date().toString()
      };

    case PopupActionTypes.SET_CURRENT_URL:
      return {
        ...state,
        currentUrl: action.payload
      };

    case PopupActionTypes.COPY_USERNAME:
      return {
        ...state,
        usernameCopied: action.payload.id
      };

    case PopupActionTypes.COPY_PASSWORD:
      return {
        ...state,
        passwordCopied: action.payload.id
      };

    case PopupActionTypes.CLEAR_COPIED:
      return {
        ...state,
        usernameCopied: null,
        passwordCopied: null
      };

    default:
      return state;
  }
}
