/*
* @angular
*/
import { Component, ViewEncapsulation, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { Router, RoutesRecognized } from "@angular/router";

import { Store } from "@ngrx/store";
import * as Raven from "raven-js";

import { environment } from "../environments/environment";
import * as fromRoot from "./app.reducers";

import { GetConfAction } from "./get-conf/conf.actions";

/*
* passit
*/
import { AppDataService } from "./shared/app-data/app-data.service";

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: "app-root",
  styleUrls: ["./app.style.scss"],
  template: `
    <navbar-container></navbar-container>
    <main>
      <router-outlet></router-outlet>
    </main>
  `
})
export class AppComponent implements OnInit {
  public title: string;

  constructor(
    private appDataService: AppDataService,
    public store: Store<fromRoot.IState>,
    private router: Router,
    private titleService: Title
  ) {
    this.appDataService.rehydrate();

    this.title = "Passit";

    this.router.events.subscribe(event => {
      if (event instanceof RoutesRecognized) {
        let titleTag = "Passit";
        if (typeof event.state.root.firstChild!.data["title"] !== "undefined") {
          titleTag = event.state.root.firstChild!.data["title"] + " | Passit";
        }
        this.titleService.setTitle(titleTag);
      }
    });
  }

  ngOnInit() {
    this.store.dispatch(new GetConfAction());
    this.store
      .select(fromRoot.getConfState)
      .filter(conf => conf.ravenDsn !== null)
      .subscribe(conf => {
        Raven.config(conf.ravenDsn!, {
          release: environment.VERSION,
          environment: conf.environment || undefined
        }).install();
      });
  }
}
