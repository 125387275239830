import { Action } from "@ngrx/store";

import { ISecret } from "passit-sdk-js/js/api.interfaces";
import * as sdk from "passit-sdk-js/js/sdk.interfaces";

export enum SecretActionTypes {
  GET_SECRETS = "[Secret] Get Secrets",
  DELETE_SECRET = "[Secret] Delete Secret",
  REMOVE_SECRET = "Remove Secret",
  REPLACE_SECRET = "Replace secret",
  REPLACE_SECRET_SUCCESS = "[Secret] Replace secret success",
  SET_SECRETS = "Set Secrets"
}

export class ReplaceSecretSuccessAction implements Action {
  readonly type = SecretActionTypes.REPLACE_SECRET_SUCCESS;
  constructor(public payload: ISecret) {}
}

export class ReplaceSecretAction implements Action {
  readonly type = SecretActionTypes.REPLACE_SECRET;
  constructor(public payload: { secret: sdk.ISecret; groups: number[] }) {}
}

export class DeleteSecretAction implements Action {
  readonly type = SecretActionTypes.DELETE_SECRET;

  constructor(public payload: number) {}
}

export class GetSecretsAction implements Action {
  readonly type = SecretActionTypes.GET_SECRETS;
}

export class RemoveSecretAction implements Action {
  readonly type = SecretActionTypes.REMOVE_SECRET;

  constructor(public payload: number) {}
}

export class SetSecretsAction implements Action {
  readonly type = SecretActionTypes.SET_SECRETS;

  constructor(public payload: ISecret[]) {}
}

export type SecretActionsUnion =
  | DeleteSecretAction
  | RemoveSecretAction
  | ReplaceSecretAction
  | ReplaceSecretSuccessAction
  | GetSecretsAction
  | SetSecretsAction;
