import { Component, EventEmitter, Output } from "@angular/core";

@Component({
  selector: "group-add-button",
  templateUrl: "./group-add-button.component.html",
  styleUrls: ["../../list/list.component.scss"]
})
export class GroupAddButtonComponent {
  @Output() showAddGroupForm = new EventEmitter();
}
