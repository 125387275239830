var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import { sanitizeUrl } from "@braintree/sanitize-url";
import { getPopupSecrets, getPopupSearch, getPopupSelected, getMatchedSecrets, getPopupUsernameCopied, getPopupPasswordCopied } from "./popup.selectors";
import * as secretActions from "../../secrets/secret.actions";
import { SecretService } from "../../secrets/secret.service";
import { SetCurrentUrlAction, SetSearchAction, SetSelectedSecretAction, NullSelectedSecretAction, CopyUsername, CopyPassword } from "./popup.actions";
import { HotkeysService, Hotkey } from "angular2-hotkeys";
import { formFill } from "./autofill-compiled";
var PopupContainer = /** @class */ (function () {
    function PopupContainer(store, secretService, _hotkeysService) {
        this.store = store;
        this.secretService = secretService;
        this._hotkeysService = _hotkeysService;
        this.secrets$ = this.store.select(getPopupSecrets);
        this.search$ = this.store.select(getPopupSearch);
        this.selectedSecret$ = this.store.select(getPopupSelected);
        this.matchedSecrets$ = this.store.select(getMatchedSecrets);
        this.usernameCopied$ = this.store.select(getPopupUsernameCopied);
        this.passwordCopied$ = this.store.select(getPopupPasswordCopied);
        this.formFillMessage = "";
    }
    PopupContainer.prototype.ngOnInit = function () {
        // TODO make this not happen EVERY time
        this.setupHotkey();
        this.getSecrets();
        this.checkUrlForMatch();
    };
    PopupContainer.prototype.setupHotkey = function () {
        var _this = this;
        this._hotkeysService.add(new Hotkey("ctrl+shift+l", function (event) {
            _this.matchedSecrets$.subscribe(function (secrets) { return (secrets.length > 0 ? _this.signIn(secrets[0]) : null); });
            return false;
        }, ["input"]));
    };
    PopupContainer.prototype.getSecrets = function () {
        this.store.dispatch(new secretActions.GetSecretsAction());
    };
    PopupContainer.prototype.setSelected = function (selected) {
        this.store.dispatch(new SetSelectedSecretAction(selected));
        this.formFillMessage = "";
    };
    PopupContainer.prototype.closeSelected = function () {
        this.store.dispatch(new NullSelectedSecretAction());
    };
    PopupContainer.prototype.searchUpdate = function (term) {
        this.store.dispatch(new SetSearchAction(term));
    };
    PopupContainer.prototype.openFullApp = function () {
        browser.tabs.create({
            url: "/index.html"
        });
        window.close();
    };
    PopupContainer.prototype.copyUsername = function (secret) {
        this.store.dispatch(new CopyUsername(secret));
    };
    PopupContainer.prototype.copyPassword = function (secret) {
        this.store.dispatch(new CopyPassword(secret));
    };
    PopupContainer.prototype.openDetails = function (secret) {
        browser.tabs.create({
            url: "/index.html#/list;id=" + secret.id
        });
        window.close();
    };
    PopupContainer.prototype.signIn = function (secret) {
        var _this = this;
        var username = secret.data["username"];
        this.secretService.showSecret(secret).then(function (decrypted) {
            var password = decrypted["password"];
            _this.executeAutofillScript(username, password).then(function () {
                window.close();
            });
        });
    };
    PopupContainer.prototype.checkUrlForMatch = function () {
        var _this = this;
        // TODO critical path for potential security audit
        // This url comes from the current tab domain - it could attempt to inject code.
        this.getActiveTab().then(function (tab) {
            if (tab && tab.url !== undefined) {
                var url = sanitizeUrl(tab.url);
                _this.store.dispatch(new SetCurrentUrlAction(url));
            }
        });
    };
    /** Run the autofill script on the current tab, requires using the activeTab permission.
     */
    PopupContainer.prototype.executeAutofillScript = function (username, password) {
        return this.getActiveTab().then(function (tab) {
            if (!tab) {
                return;
            }
            // critical path for potential security audit
            username = username.substring(0, 200);
            password = password.substring(0, 200);
            return browser.tabs
                .executeScript(tab.id, {
                code: formFill
            })
                .then(function (response) {
                browser.tabs.sendMessage(tab.id, { username: username, password: password });
            });
        });
    };
    /** Firefox and Chrome compatible way to get the active tab. tabs.getCurrent does not work in Firefox.
     * from https://stackoverflow.com/a/41943267
     */
    PopupContainer.prototype.getActiveTab = function () {
        return __awaiter(this, void 0, void 0, function () {
            var tabs;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!browser.tabs) return [3 /*break*/, 2];
                        return [4 /*yield*/, browser.tabs.query({
                                active: true,
                                currentWindow: true
                            })];
                    case 1:
                        tabs = _a.sent();
                        return [2 /*return*/, tabs[0]];
                    case 2:
                        console.warn("no browser.tabs in popup, are you debugging?");
                        _a.label = 3;
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    PopupContainer.prototype.openUrl = function (secret) {
        var url = secret.data["url"];
        if (url !== undefined) {
            return browser.tabs.create({ url: sanitizeUrl(url) });
        }
    };
    return PopupContainer;
}());
export { PopupContainer };
