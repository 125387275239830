import { Injectable } from "@angular/core";
import { Actions, Effect, ofType } from "@ngrx/effects";
import { SecretService } from "../../secrets/secret.service";
import { CopyUsername, PopupActionTypes, ClearCopied, CopyPassword } from "./popup.actions";
import { map, tap, switchMap } from "rxjs/operators";
import { copyToClipboard } from "../../utils";
import { timer } from "rxjs";

@Injectable()
export class PopupEffects {
  @Effect()
  copyUsername$ = this.actions$.pipe(
    ofType<CopyUsername>(PopupActionTypes.COPY_USERNAME),
    map(action => action.payload),
    tap(secret =>
        copyToClipboard(secret.data["username"]!)
    ),
    switchMap(() =>
      timer(1000).pipe(
        map(
          () => new ClearCopied()
        )
      )
    )
  );

  @Effect()
  copyPassword$ = this.actions$.pipe(
    ofType<CopyPassword>(PopupActionTypes.COPY_PASSWORD),
    map(action => action.payload),
    tap(secret => {
      this.secretService.showSecret(secret).then(decrypted => {
        copyToClipboard(decrypted["password"]);
      });
    }),
    switchMap(() =>
      timer(1000).pipe(
        map(
          () => new ClearCopied()
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private secretService: SecretService
  ) {}
}
