var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
import { createFeatureSelector, createSelector } from "@ngrx/store";
import { ResetFormActionTypes } from "../form/reset-form.actions";
import * as fromSecretForm from "./secret-form/secret-form.reducer";
import { SecretFormActionTypes } from "./secret-form/secret-form.actions";
import { SecretActionTypes } from "../secrets/secret.actions";
import { ListActionTypes } from "./list.actions";
import { ROUTER_NAVIGATION } from "@ngrx/router-store";
export var initialListState = {
    searchText: "",
    secretManaged: null,
    showCreate: false,
    firstTimeLoadingComplete: false,
    selectedGroupId: null
};
export function listReducer(state, action) {
    if (state === void 0) { state = initialListState; }
    switch (action.type) {
        case ListActionTypes.CLEAR_MANAGED_SECRET:
            return Object.assign({}, state, {
                secretManaged: null
            });
        case ListActionTypes.SET_MANAGED_SECRET:
            var secretManaged = action.payload;
            return Object.assign({}, state, {
                secretManaged: secretManaged,
                secretIsUpdating: false,
                secretIsUpdated: false,
                showCreate: false
            });
        case ListActionTypes.SET_SEARCH_TEXT:
            return Object.assign({}, state, {
                searchText: action.payload
            });
        case ListActionTypes.SHOW_CREATE:
            return Object.assign({}, state, {
                showCreate: true,
                secretManaged: null,
                secretIsUpdating: false,
                secretIsUpdated: false
            });
        case ListActionTypes.HIDE_CREATE:
            return Object.assign({}, state, {
                showCreate: false
            });
        case ListActionTypes.UPDATE_SELECTED_GROUP:
            return Object.assign({}, state, {
                selectedGroupId: action.payload
            });
        case ResetFormActionTypes.RESET_FORMS:
            return Object.assign({}, state, __assign({}, initialListState));
        case SecretActionTypes.SET_SECRETS:
            return Object.assign({}, state, {
                firstTimeLoadingComplete: true
            });
        case SecretFormActionTypes.CREATE_SECRET:
            return Object.assign({}, state, {
                showCreate: true
            });
        case SecretFormActionTypes.CREATE_SECRET_SUCCESS:
            return Object.assign({}, state, {
                showCreate: false
            });
        case ROUTER_NAVIGATION:
            return Object.assign({}, state, {
                secretManaged: null,
                showCreate: false,
                searchText: null
            });
        default:
            return state;
    }
}
export var initialState = {
    list: initialListState,
    secretForm: fromSecretForm.initialFormState
};
export var reducers = {
    list: listReducer,
    secretForm: fromSecretForm.reducer
};
export var getState = createFeatureSelector("list");
export var getListState = createSelector(getState, function (state) { return state.list; });
export var getSecretFormState = createSelector(getState, function (state) { return state.secretForm; });
var getSecretManaged = function (state) { return state.secretManaged; };
var ɵ0 = getSecretManaged;
export var getListSecretManaged = createSelector(getListState, getSecretManaged);
var getShowCreate = function (state) { return state.showCreate; };
var ɵ1 = getShowCreate;
export var getListShowCreate = createSelector(getListState, getShowCreate);
export var getSearchText = createSelector(getListState, function (state) { return state.searchText; });
export var getSelectedGroup = createSelector(getListState, function (state) { return state.selectedGroupId; });
export var getFirstTimeLoadingComplete = createSelector(getListState, function (state) { return state.firstTimeLoadingComplete; });
export var getSecretForm = createSelector(getSecretFormState, fromSecretForm.getForm);
export var getSecretFormErrorMessage = createSelector(getSecretFormState, fromSecretForm.getErrorMessage);
export var getSecretId = createSelector(getSecretFormState, fromSecretForm.getSecretId);
export var getSecretIsNew = createSelector(getSecretFormState, fromSecretForm.getSecretIsNew);
export var getSecretIsUpdating = createSelector(getSecretFormState, fromSecretForm.getIsUpdating);
export var getSecretIsUpdated = createSelector(getSecretFormState, fromSecretForm.getIsUpdated);
export var getSecretPasswordIsMasked = createSelector(getSecretFormState, fromSecretForm.getPasswordIsMasked);
export var getSecretShowNotes = createSelector(getSecretFormState, fromSecretForm.getShowNotes);
export { ɵ0, ɵ1 };
