
    <secret-form-component
      [form]="form$ | async"
      [errorMessage]="errorMessage$ | async"
      [isNew]="isNew$ | async"
      [isUpdating]="isUpdating$ | async"
      [isUpdated]="isUpdated$ | async"
      [passwordIsMasked]="passwordIsMasked$ | async"
      [showNotes]="showNotes$ | async"
      [groups]="groups$ | async"
      (togglePasswordIsMasked)="togglePasswordIsMasked()"
      (setPasswordIsMasked)="setPasswordIsMasked($event)"
      (toggleShowNotes)="toggleShowNotes()"
      (saveSecret)="saveSecret($event)"
      (deleteSecret)="deleteSecret()"
      (generatePassword)="generatePassword()"
      (cancel)="cancel()"
    ></secret-form-component>
  