import {
  createFormGroupState,
  FormGroupState,
  validate,
  updateGroup,
  createFormStateReducerWithUpdate,
  Boxed,
  box
} from "ngrx-forms";
import {
  SecretFormActionTypes,
  SecretFormActionsUnion
} from "./secret-form.actions";
import {
  SecretActionTypes,
  SecretActionsUnion
} from "../../secrets/secret.actions";
import { required } from "ngrx-forms/validation";
import { ListActionTypes, ListActionsUnion } from "../list.actions";

export interface ISecretForm {
  name: string;
  username: string;
  url: string;
  password: string;
  groups: Boxed<number[]>;
  notes: string;
}

export const SECRET_FORM_ID = "Secret Form";
export const initialFormState = createFormGroupState<ISecretForm>(
  SECRET_FORM_ID,
  {
    name: "",
    username: "",
    url: "",
    password: "",
    groups: box([]),
    notes: ""
  }
);

export const setFormValues = (values: ISecretForm) =>
  createFormGroupState<ISecretForm>(SECRET_FORM_ID, values);

export interface ISecretFormState {
  secretId: number | null;
  form: FormGroupState<ISecretForm>;
  errorMessage: string | null;
  isUpdating: boolean;
  isUpdated: boolean;
  showNotes: boolean;
  passwordIsMasked: boolean;
}

export const initialState: ISecretFormState = {
  secretId: null,
  form: initialFormState,
  errorMessage: null,
  isUpdated: false,
  isUpdating: false,
  showNotes: false,
  passwordIsMasked: true
};

export const validateAndUpdateFormState = updateGroup<ISecretForm>({
  name: validate(required)
});
export const formReducer = createFormStateReducerWithUpdate<ISecretForm>(
  validateAndUpdateFormState
);

export function reducer(
  state = initialState,
  action: SecretFormActionsUnion | SecretActionsUnion | ListActionsUnion
): ISecretFormState {
  const form = formReducer(state.form, action);
  if (form !== state.form) {
    state = { ...state, form };
  }

  switch (action.type) {
    case SecretFormActionTypes.SET_FORM_DATA:
      let showNotes = false;
      if (action.payload.notes) {
        showNotes = true;
      }
      return {
        ...initialState,
        secretId: action.payload.id,
        form: setFormValues(action.payload),
        showNotes
      };

    case SecretFormActionTypes.TOGGLE_PASSWORD_IS_MASKED:
      return {
        ...state,
        passwordIsMasked: !state.passwordIsMasked
      };

    case SecretFormActionTypes.SET_PASSWORD_IS_MASKED:
      return {
        ...state,
        passwordIsMasked: action.payload
      };

    case SecretFormActionTypes.TOGGLE_SHOW_NOTES:
      return {
        ...state,
        showNotes: !state.showNotes
      };

    case SecretFormActionTypes.CREATE_SECRET:
      return Object.assign({}, state, {
        secretIsUpdating: true,
        secretIsUpdated: false
      });

    case SecretFormActionTypes.CREATE_SECRET_SUCCESS:
      return {
        ...state,
        isUpdating: false,
        isUpdated: true,
        errorMessage: initialState.errorMessage
      };

    case SecretActionTypes.REPLACE_SECRET:
      return {
        ...state,
        isUpdating: true,
        isUpdated: false
      };

    case SecretFormActionTypes.DECRYPT_ERROR:
      return {
        ...state,
        form: setFormValues(action.payload.form),
        errorMessage: action.payload.message
      };

    case SecretActionTypes.REPLACE_SECRET_SUCCESS:
      return {
        ...state,
        isUpdating: false,
        isUpdated: true,
        errorMessage: initialState.errorMessage
      };

    case ListActionTypes.SHOW_CREATE:
      return {
        ...initialState,
        form: formReducer(initialState.form, action)
      };
  }
  return state;
}

export const getForm = (state: ISecretFormState) => state.form;
export const getErrorMessage = (state: ISecretFormState) => state.errorMessage;
export const getSecretId = (state: ISecretFormState) => state.secretId;
export const getSecretIsNew = (state: ISecretFormState) =>
  state.secretId === null;
export const getIsUpdating = (state: ISecretFormState) => state.isUpdating;
export const getIsUpdated = (state: ISecretFormState) => state.isUpdated;
export const getPasswordIsMasked = (state: ISecretFormState) =>
  state.passwordIsMasked;
export const getShowNotes = (state: ISecretFormState) => state.showNotes;
